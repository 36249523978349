import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as DropdownArrow } from '../../assets/icons/DropdownArrow.svg';

const TriggerArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${(props) => props.rightOffset}px;
  transition: transform ${(props) => props.transitionDuration}ms;
  transform: ${(props) =>
    props.flipped
      ? 'translate3d(0, -50%, 0) scaleY(-1)'
      : 'translate3d(0, -50%, 0)'};

  svg {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
  }

  svg path {
    stroke: ${(props) => props.strokeColor || '#27b0d4'};
  }
  pointer-events: none;
`;

const TriggerArrow = (props) => (
  <TriggerArrowWrapper {...props}>
    <DropdownArrow />
  </TriggerArrowWrapper>
);

TriggerArrow.defaultProps = {
  rightOffset: 10,
  width: 20,
  height: 20,
  transitionDuration: 400
};

TriggerArrow.propTypes = {
  flipped: PropTypes.bool,
  strokeColor: PropTypes.string,
  className: PropTypes.string,
  rightOffset: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  transitionDuration: PropTypes.number
};

export default TriggerArrow;

export const TriggerArrowV2 = ({ className, size = 20, flipped, atRight, ...restProps }) => (
  <div
    className={cc([
      'transform transition-transform duration-[400ms]',
      flipped && 'scale-y-[-1]',
      atRight && 'absolute -translate-y-1/2 top-1/2 right-2.5',
      className,
    ])}
    {...restProps}
  >
    <DropdownArrow
      className="text-inherit"
      style={{
        width: size,
        height: size,
      }}
    />
  </div>
);
