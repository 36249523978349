import React from 'react';
import styled from 'styled-components';
import cc from 'classcat';
import media from 'utils/media';

const PlayerControlInfo = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  white-space: nowrap;
  padding: 3px 5px;
  border-radius: 10px;
  display: none;
  background-color: #fff;
  transform: translateX(-50%);
  font-size: 1.2em;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  opacity: 0;
  transition: 0.1s opacity;
`;

const PlayerButton = styled.button`
  margin: 0;
  padding: 8px;
  border: 0;
  outline: 0;
  box-shadow: none;
  appearance: none;
  line-height: normal;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  color: #f26521;
  border: 1px solid #f26521;

  ${media.sm`
    padding: 9px;
  `};

  &.is-active {
    background-color: #f26521;
    color: #fff;
  }

  &.is-reversed {
    background-color: #f26521;
    color: #fff;

    &.is-active {
      background-color: #fff;
      color: #f26521;
    }
  }

  &.has-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  &.is-small {
    padding: 6px;
  }

  &.is-off {
    cursor: default;
    color: #aeaeae;
    background-color: transparent;
    border-color: #aeaeae !important;
    position: relative;

    &.strike-through {
      &:before {
        content: ' ';
        display: block;
        width: 41px;
        height: 2px;
        background-color: #aeaeae;
        position: absolute;
        left: 0;
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }

  svg {
    color: inherit;
    border-radius: 0;
    background-color: transparent;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f26521;
  transition: all 0.1s linear;
  position: relative;

  &.is-off {
    color: #aeaeae;
  }

  &:not(.is-off):hover {
    transform: scale(1.07);
  }

  &.player-control-jumpbackward,
  &.player-control-playpause,
  &.player-control-jumpforward,
  &.player-control-fullscreen {
    ${PlayerButton} {
      border-color: transparent !important;
    }
  }

  &.player-control-fullscreen {
    & > span {
      display: none;
    }

    ${PlayerControlInfo} {
      top: auto;
      bottom: -30px;
      left: auto;
      right: -70px;
    }
  }

  &.is-blue {
    color: #27b0d4;

    &.is-off {
      color: #d8d8d8;
    }

    ${PlayerButton} {
      border: 1px solid #27b0d4;
      color: #27b0d4;

      &.is-off {
        color: #aeaeae !important;
        border-color: #aeaeae !important;
      }
    }
    
    ${PlayerButton}.is-active {
      background-color: #27b0d4;
      border: 1px solid #27b0d4;
      color: #fff;

      &.is-off {
        cursor: default;
        color: #aeaeae !important;
        background-color: transparent !important;
        border-color: #aeaeae !important;
      }
    }

    ${PlayerButton}.is-reversed {
      background-color: #27b0d4;

      &.is-active {
        color: #27b0d4;
      }
    }

    &.is-off {
      cursor: default;
      color: #aeaeae !important;
      background-color: transparent !important;
      border-color: #aeaeae !important;
    }
  }

  &.is-white {
    background-color: #fff;
  }

  span {
    font-size: 11px;
    margin-top: 5px;
    white-space: pre;
    text-align: center;
  }

  .is-fullscreen &:hover {
    &:hover ${PlayerControlInfo} {
      display: inline-block;
      opacity: 1;
      transition-delay: 0.3s;
    }
  }

  &.player-control-fullscreen {
    &:hover {
      &:hover ${PlayerControlInfo} {
        display: inline-block;
        opacity: 1;
        transition-delay: 0.3s;
      }
    }
  }
`;



const PlayerControl = ({
  onClick,
  className,
  storyName,
  children,
  active,
  off,
  ariaLabel,
  title,
  iconTextHidden,
  wrapperClassName,
}) => {
  return (
    <ButtonWrapper
      className={cc([
        'player-control',
        {
          'is-off': off,
          'is-blue': wrapperClassName === 'player-control-quiz' || wrapperClassName === 'player-control-search' || wrapperClassName === 'player-control-impulse',
          'is-fullscreen': wrapperClassName === 'player-control-fullscreen',
        },
        wrapperClassName
      ])}
    >
      <PlayerButton
        disabled={off}
        className={cc([
          'icon-button',
          {
            'is-active': active,
            'is-off': off,
          },
          className,
        ])}
        data-storyname={storyName}
        onClick={onClick}
        type="button"
        aria-label={ariaLabel}
      >
        {children}
      </PlayerButton>
      {title && !iconTextHidden && <span>{title}</span>}
      {title && (<PlayerControlInfo>{title}</PlayerControlInfo>)}
    </ButtonWrapper>
  );
};

export default PlayerControl;
