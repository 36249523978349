import React, { Fragment, useEffect } from 'react';
import { Sticky } from 'react-sticky-17';
import cc from 'classcat';
import { NavLink as BaseNavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { onLogoutRequest } from '../../actions/auth';
import { NavLink, Nav } from '../StyledElements';
import Fetcher from '../Fetcher';
import AccountMenuFlyout from './AccountMenuFlyout';
import HeaderMenuFlyout from './HeaderMenuFlyout';
import HeaderBurgerMenu from './HeaderBurgerMenu';
import { scrollTo } from '../../utils/scroll';
import { getMenuItemLinkProps } from '../../utils/menuItemLink';
import { wpEndpoints, wpFetch } from '../../modules/wp';
import SearchFlyout from '../Search/SearchFlyout';
import { LogoWrapper, HeaderContainer, NavSeparator, TestButtonLink, AccessCodeButton } from './headerStyles';

// Assets
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg';
import { ReactComponent as KeyboardIcon } from '../../assets/icons/Keyboard.svg';
import { isOrganizationOwner, getRolesAsBoolean, getMemberFromOrg } from '../../utils/userRoleHelpers';
import BugPopup from 'components/BugPopup';

const defaultMenuItems = [
  {
    url: '/boardstories',
    title: 'Boardstories',
    type: 'custom',
  },
  {
    url: '/aktuelles',
    title: 'Themen',
    type: 'custom',
  },
  {
    url: '/lizenzen',
    title: 'Lizenzen',
    type: 'custom',
  },
];

const Header = (props) => {
  const [options, setOptions] = React.useState({});
  const { user, organization } = props;
  const memberEntry = getMemberFromOrg(organization, user);
  const userRolesAsBoolean = getRolesAsBoolean(memberEntry);
  const amendedUser = { ...user, ...userRolesAsBoolean, ...{ rentalUntil: memberEntry?.rentalUntil } };

  useEffect(() => {
    const getOptions = async () => {
      wpFetch
        .url(wpEndpoints.acfOptions)
        .get()
        .json((data) => {
          if (data?.acf) setOptions(data.acf);
        });
    };

    getOptions();
  }, []);

  const onLogout = () => {
    props.onLogoutRequest().then(() => {
      props.history.push('/');
    });
  };

  const getHeaderMenuItems = (data) => {
    if (!data || !data.header || data.header.length === 0) {
      return null;
    }

    const allMenuItems = data.header[0];

    // "Un-normalize" the menu items
    const menuItems = allMenuItems
      .filter((i) => !i.menu_item_parent || i.menu_item_parent === '0')
      .map((item) => {
        const children = allMenuItems.filter((i) => item.ID === parseInt(i.menu_item_parent, 10));
        return {
          ...item,
          children,
        };
      });

    return menuItems;
  };

  const renderAccessCodeButton = () => {
    if (props.isAuthenticated || props.location.pathname !== '/') {
      return null;
    }

    return (
      <AccessCodeButton
        className={cc(['md:hidden', { hidden: props.accessCodeSectionIsVisible }])}
        onClick={() => scrollTo('#AccessCodeForm', { offset: -90 })}
      >
        <KeyboardIcon />
      </AccessCodeButton>
    );
  };

  const renderMenuItems = (menuItems) => {
    // hide lizenzen link for loggedIn external users
    if (amendedUser.isExternalUser) {
      menuItems = menuItems.filter((obj) => {
        return obj.url !== '/lizenzen';
      });
    }
    const renderedNavItems = menuItems.map((item, key) => {
      const { acf = {}, children = [] } = item;
      // Render menu items without teasers or children normally
      if ((!acf.teasers || !acf.teasers.length === 0) && children.length === 0) {
        return <NavLink {...getMenuItemLinkProps(item, key, props.location)} />;
      }

      return <HeaderMenuFlyout key={item.ID} item={item} location={props.location} />;
    });

    return renderedNavItems;
  };

  return (
    <Sticky topOffset={1}>
      {({ style, isSticky }) => (
        <HeaderContainer className={cc({ 'is-sticky': isSticky })} style={style}>
          <Fetcher wordpress transformResponse={getHeaderMenuItems} url={wpEndpoints.navMenus}>
            {({ data: headerMenuItems }) => {
              const menuItems = headerMenuItems || defaultMenuItems;

              return (
                <Fragment>
                  <HeaderBurgerMenu
                    className={cc({ 'has-small-offset': isSticky })}
                    menuItems={menuItems}
                    onLogout={onLogout}
                    user={amendedUser}
                  />
                  <LogoWrapper>
                    <BaseNavLink to="/">
                      <Logo className="logo" />
                    </BaseNavLink>
                  </LogoWrapper>
                  {renderAccessCodeButton()}
                  <Nav>
                    <SearchFlyout />
                    {renderMenuItems(menuItems)}
                    {props.isAuthenticated ? (
                      <>
                        <AccountMenuFlyout onLogout={onLogout} />
                        <BugPopup />
                      </>
                    ) : (
                      <Fragment>
                        <NavSeparator />
                        <NavLink to="/login">Anmelden</NavLink>
                        {options?.header_signup_cta?.url && (
                          <TestButtonLink to={options?.header_signup_cta?.url}>
                            {options?.header_signup_cta?.text || 'Jetzt testen!'}
                          </TestButtonLink>
                        )}
                      </Fragment>
                    )}
                  </Nav>
                </Fragment>
              );
            }}
          </Fetcher>
        </HeaderContainer>
      )}
    </Sticky>
  );
};

const select = (state) => ({
  user: state.user.account,
  organization: state.user.organization,
  isAuthenticated: state.auth.isAuthenticated,
  accessCodeSectionIsVisible: state.options.accessCodeSectionIsVisible,
});

const connectedHeader = connect(select, { onLogoutRequest })(Header);

export default withRouter(connectedHeader);
