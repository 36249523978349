import ErrorFallback from 'components/ErrorFallback';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { shallowEqual, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { apiEndpoints, apiFetch } from '../../modules/api';
import { createTracker } from '../../modules/tracking';
import { accessCodeHasValidDate, hasDateNotExpired } from '../../utils/helpers';
import { getMemberFromOrg, getRolesAsBoolean } from '../../utils/userRoleHelpers';
import { toggleFullscreen } from '../../utils/video';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

const BoardstoryPlayer = withRouter(
  ({
    history,
    isFree,
    videoState,
    trailerState,
    tigerCreationState,
    boardstoryId,
    boardstorySlug,
    boardstoryTitle,
    games,
    teaserImage,
    gameActive,
    renderPreview,
    renderController,
    hideInlinePlayer,
    nativePlayerUI,
    isExplain,
    videoHasAudio,
    instantLoadVideo,
  }) => {
    const { accessCode, isAdmin, userId, canPlayFullVideos, availableVideoTypes, videoTypes } = useSelector((state) => {
      const member = getMemberFromOrg(state.user?.organization, state.user?.account);
      const { isRentalUser } = getRolesAsBoolean(member);
      const rentalLicenseIsNotExpired = member?.rentalUntil && hasDateNotExpired(member.rentalUntil);
      const isAuthorizedGenericUser = !isRentalUser && state.user.currentOrder?.active;
      const isAuthorizedRentalUser = isRentalUser && rentalLicenseIsNotExpired;
      let accessCodeValid = accessCodeHasValidDate(state.user.accessCode) && boardstoryId === state.user.accessCode.boardstory;

      let canPlayFullVideos =
        isFree || (state.auth.isAuthenticated && (isAuthorizedGenericUser || isAuthorizedRentalUser)) || accessCodeValid;

      let availableVideoTypes = [];
      let videoTypes = [];
      // If a video is available and the user can watch it
      if (videoState.toLowerCase() === 'success') {
        availableVideoTypes.push('videos');
        if (canPlayFullVideos) {
          videoTypes.push('videos');
        }
      }

      if (tigerCreationState.toLowerCase() === 'success') {
        availableVideoTypes.push('tigerCreations');
        if (canPlayFullVideos) {
          videoTypes.push('tigerCreations');
        }
      }

      if (trailerState.toLowerCase() === 'success') {
        availableVideoTypes.push('trailers');
        videoTypes.push('trailers');
      }

      return {
        canPlayFullVideos,
        accessCode: state.user.accessCode,
        isAdmin: state.user.account.isAdmin,
        userId: state.user.account.id,
        availableVideoTypes,
        videoTypes,
      };
    }, shallowEqual);

    useEffect(() => {
      createVideoRequest();
    }, [canPlayFullVideos]);

    const [requestedVideoType, setRequestedVideoType] = useState(null);
    const [shouldRenderPlayer, setShouldRenderPlayer] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [videoError, setVideoError] = useState(undefined);
    const [playerLoaded, setPlayerLoaded] = useState(false);
    const [shouldPlay, setShouldPlay] = useState(false);
    const [videos, setVideos] = useState({});

    const playerElRef = useRef();
    const [playerContainerEl, setPlayerContainerEl] = useState(null);

    let trackingTimer = useRef();
    let lastTime = useRef(-1);
    let currentTime = useRef(0);
    const track = createTracker('boardstoryWatched');

    const trackingDataRef = useRef({
      boardstory: boardstoryId,
      ...(isFree ? { isAnonymous: true } : { user: userId, accessCode: accessCode?.code }),
    });

    // loads when player elements are loaded and rerendered
    useEffect(() => {
      if (shouldPlay) {
        playInFullscreen();
      }
    }, [playerLoaded]);

    useEffect(() => {
      return () => {
        if (trackingTimer.current) {
          clearInterval(trackingTimer.current);
        }
        trackingDataRef.current = {};
      };
    }, []);

    const createVideoRequest = () => {
      if (!boardstoryId || !videoTypes || videoTypes.length === 0) {
        return;
      }

      if (videoTypes) {
        videoTypes.forEach((videoType) => {
          if (!videos[videoType] && videos[videoType] !== null) {
            getVideo(videoType);
          }
        });
      }

      if (canPlayFullVideos && instantLoadVideo) setShouldRenderPlayer(true);
    };

    const getVideo = async (videoType) => {
      setLoading(true);
      let queryParams = { boardstoryId: boardstoryId };
      if (accessCode?.code && accessCode?.code?.length > 1 && videoType !== 'trailers') {
        queryParams.accessCode = accessCode?.code;
      }

      try {
        const response = await apiFetch.url(apiEndpoints[videoType]).query(queryParams).get().json();
        let video;
        if (videoType === 'tigerCreations') {
          video = response._embedded[videoType][0][0] || '';
        } else {
          const videosData = response._embedded[videoType];
          video = videosData && videosData?.length > 0 ? videosData[0] : null;

          // Trailer's attributes are named differently and have to be renamed
          if (videoType === 'trailers' && video) {
            video.pauseCuepoints = video.trailerPauseCuepoints;
            video.seekPoints = video.trailerSeekPoints;
            video.isTrailer = true;
          }
        }

        setLoading(false);

        setVideos((prevVideos) => ({
          ...prevVideos,
          [videoType]: video,
        }));
      } catch (error) {
        setLoading(false);
        setVideoError(error.json?.error || true);
      }
    };

    const handleVideoEnd = () => {
      if (trackingTimer.current && playerElRef.current) {
        track(trackingDataRef.current, {
          boardstoryTimestamp: playerElRef.current.currentTime,
          hasEnded: true,
        }).catch((e) => console.log(e));
      }
    };

    const onPlayRequest = (event, videoType) => {
      // Bail on video load error
      if (videoError) {
        // This error usually happens if the user doesn't have a valid license
        // to view the full version of the video
        if (videoError.indexOf('no valid orders') > -1) {
          history.push('/lizenzen');
        }
        return;
      }

      // Bail if there're no playable videos
      if (videoTypes?.length === 0) {
        return;
      }

      // If a specific video type (trailer or video) was requested, try to play it
      // Otherwise use the first available video type
      const reqVideoType = videoType && videoTypes.indexOf(videoType) > -1 ? videoType : videoTypes[0];

      // If video type is a Tiger Creation, take this shortcut
      if (reqVideoType === 'tigerCreations') {
        window.open(videos[reqVideoType]);
        return;
      }

      onPlayPress(reqVideoType);
      setRequestedVideoType(reqVideoType);

      // Render the player in case it hasn't been initiated yet
      setShouldRenderPlayer(true);
      setShouldPlay(true);
      playInFullscreen();
    };

    const onPlayPress = (videoType) => {
      const updateTrackingToken = (response) => {
        try {
          trackingDataRef.current = {
            ...trackingDataRef.current,
            trackingToken: response.trackingToken,
          };
        } catch (e) {
          console.log(e);
        }
      };

      const trackTimeStamp = () => {
        currentTime.current = Math.ceil(playerElRef.current?.currentTime) || 0;
        const hasChanged = lastTime.current !== currentTime.current;

        if (!hasChanged) return;

        try {
          track(trackingDataRef.current, { boardstoryTimestamp: currentTime.current })
            .then(updateTrackingToken)
            .catch((e) => console.log(e));
        } catch (e) {
          console.log(e);
        }

        lastTime.current = currentTime.current;
      };

      // Track regular videos only
      if (videoType === 'videos') {
        trackTimeStamp();

        if (!trackingTimer.current) {
          trackingTimer.current = setInterval(() => {
            trackTimeStamp();
          }, 30000);
        }
      }
    };

    const playInFullscreen = () => {
      const playerEl = playerElRef.current;

      if (!playerEl) return;

      let isPlaying =
        playerEl.currentTime > 0 && !playerEl.paused && !playerEl.ended && playerEl.readyState > playerEl.HAVE_CURRENT_DATA;

      playerEl.paused && !isPlaying && playerEl.play();

      if (playerContainerEl) toggleFullscreen(playerContainerEl);
    };

    const renderPlayer = () => {
      if (videoTypes?.length === 0 || videoError || isLoading) {
        return null;
      }

      const currentVideoType = requestedVideoType || videoTypes[0];
      const currentVideo = videos[currentVideoType];
      if (!currentVideo) {
        return null;
      }

      return (
        <VideoPlayer
          videoData={currentVideo}
          onMount={(playerContainer, player) => {
            playerElRef.current = player;

            setPlayerContainerEl(playerContainer);
            setPlayerLoaded(true);
          }}
          handleVideoEnd={handleVideoEnd}
          teaserImage={teaserImage}
          hideInlinePlayer={hideInlinePlayer}
          boardstorySlug={boardstorySlug}
          boardstoryTitle={boardstoryTitle}
          boardstoryId={boardstoryId}
          isExplain={isExplain}
          videoHasAudio={videoHasAudio}
          games={gameActive || isAdmin ? games : []}
          isAdmin={isAdmin}
          onPlayRequest={onPlayRequest}
          onPlayPress={onPlayPress}
          accessCodeConfig={accessCode}
          canPlayFullVideos={canPlayFullVideos}
          currentVideoType={currentVideoType}
          hasTrailer={availableVideoTypes.indexOf('trailers') > 0}
          nativePlayerUI={nativePlayerUI}
        />
      );
    };

    const preview = () => {
      if (!renderPreview) return null;

      return renderPreview({
        onPlayRequest: onPlayRequest,
        videoError: videoError,
        hasPlayableVideos: videoTypes?.length !== 0,
      });
    };

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {shouldRenderPlayer ? renderPlayer() : preview()}
        {renderController &&
          (!shouldRenderPlayer || hideInlinePlayer) &&
          renderController({
            onPlayRequest: onPlayRequest,
            allowedVideoTypes: videoTypes,
            availableVideoTypes: availableVideoTypes,
          })}
      </ErrorBoundary>
    );
  },
);

BoardstoryPlayer.propTypes = {
  isAdmin: PropTypes.bool,
  isFree: PropTypes.bool,
  userId: PropTypes.string,
  videoState: PropTypes.string,
  trailerState: PropTypes.string,
  tigerCreationState: PropTypes.string,
  boardstoryId: PropTypes.string.isRequired,
  boardstorySlug: PropTypes.string.isRequired,
  boardstoryTitle: PropTypes.string,
  games: PropTypes.array,
  gameActive: PropTypes.bool,
  renderPreview: PropTypes.func,
  renderController: PropTypes.func,
  videoTypes: PropTypes.array,
  availableVideoTypes: PropTypes.array,
  accessCodeConfig: PropTypes.object,
  hideInlinePlayer: PropTypes.bool,
  nativePlayerUI: PropTypes.bool,
};

export default BoardstoryPlayer;
