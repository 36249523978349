import React from 'react';
import { Form } from 'formik';
import AccessCodeForm from './AccessCodeForm';
import { Spacing } from '../StyledElements';
import {
  TeaserContainer,
  DarkRoundInput,
  TeaserHeadline,
  SmallOrangeButton
} from '../Footer/footerTeaserStyles';

export default (props) => {
  return (
    <TeaserContainer>
      <TeaserHeadline>Schüler- & Verleihcode Eingabe</TeaserHeadline>
      <Spacing mt={0.5} mb={2.25}>
        <p>
          Sie haben einen Schüler- oder Verleihcode? Dann geben Sie ihn hier ein und klicken auf „Code Eingabe“.
        </p>
      </Spacing>
      <AccessCodeForm
        renderForm={({
          handleChange,
          values,
          errors,
          touched
        }) => (
          <Form>
            <div className="input-wrapper">
              <DarkRoundInput
                name="accessCode"
                placeholder="Hier Code eingeben"
                onChange={handleChange}
                value={values.accessCode}
                error={touched.accessCode && errors.accessCode}
                errorColor="inherit"
              />
            </div>
            <Spacing textAlign="right" mt={1.5}>
              <SmallOrangeButton type="submit">Code Eingabe</SmallOrangeButton>
            </Spacing>
          </Form>
        )}
      />
    </TeaserContainer>
  );
};
