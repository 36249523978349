import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TopicAreaFilter from './TopicAreaFilter';
import { ShortDescription, BoxShadow, Thumbnail } from '../StyledElements';
import media from '../../utils/media';
import { Image, getWpImageSrcSet } from '../../components/Image';
import { breakpoints } from '../../config';
import useQueryParams from '../../modules/useQueryParams';

const TopicTitle = styled.div`
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  padding: 3px 0;
  font-size: 1rem;
  font-weight: bold;

  ${media.md`
    padding-top: 8px;
    padding-bottom: 7px;
    line-height: 1.25;
  `};

  ${media.lg`
    font-size: 1.5rem;
    font-weight: normal;
    padding-top: 5px;
    padding-bottom: 5px;
  `};
`;

const TopicTeaser = styled(BoxShadow)`
  color: #27b0d4;
  overflow: auto;
  transition: 0.3s transform;
  /* Fix for the WebKit overflow bug */
  z-index: 1;
  position: relative;

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    color: #f26521;
    transform: scale(1.05);
  }
`;

const topicAreaImageSizes = [
  {
    media: `(min-width: ${breakpoints.md}px)`,
    size: '33vw',
  },
  { size: '50vw' },
];

const TopicAreasList = ({ description, items, baseSlug }) => {
  const { category: activeTagSlug } = useQueryParams();

  const topics = useMemo(
    () =>
      items.filter(({ acf }) => {
        const hasTeaserImage = acf && acf.topicarea_teaser_image;

        if (!hasTeaserImage || !activeTagSlug) return hasTeaserImage;

        // If the active tag is set, filter the topics by the active tag
        return acf.tags?.find(({ slug }) => slug === activeTagSlug) !== undefined;
      }),
    [items, activeTagSlug],
  );

  // Extract a list of used tags from all topics
  const tags = useMemo(() => {
    const allTags = items.reduce((data, { acf }) => {
      if (acf && acf.tags) {
        acf.tags.forEach(({ slug, name }) => {
          if (!data[slug]) {
            data[slug] = {
              name,
              value: slug,
              category: 'category',
            };
          }
        });
      }

      return data;
    }, {});

    // Get the tags in an array format, sorted by name
    return Object.values(allTags).sort((a, b) => a.name.localeCompare(b.name));
  }, [items]);

  return (
    <div>
      {description && <ShortDescription>{description}</ShortDescription>}
      <TopicAreaFilter tags={tags} />
      <div className="flex flex-wrap m-[-5px] md:-m-2 lg:m-[-10px]">
        {topics.map((topic) => {
          const teaserTitle = topic.acf.teaser_title || topic.post_title;
          return (
            <div key={topic.ID} className="w-1/2 md:w-1/3 p-[5px] md:p-2 lg:p-[10px]">
              <TopicTeaser>
                <Link to={`/${baseSlug}/${topic.post_name}`}>
                  <Thumbnail className="wide-screen">
                    <Image
                      srcSet={getWpImageSrcSet(topic.acf.topicarea_teaser_image)}
                      alt={topic.acf.topicarea_teaser_image.alt}
                      sizes={topicAreaImageSizes}
                    />
                  </Thumbnail>
                  {teaserTitle && <TopicTitle>{teaserTitle}</TopicTitle>}
                </Link>
              </TopicTeaser>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TopicAreasList.propTypes = {
  baseSlug: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TopicAreasList;
