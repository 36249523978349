import React, { useRef } from 'react';
import { AudioPreviewButton } from './styles';
import { ReactComponent as MicrophoneIcon } from '../../assets/icons/Microphone.svg';

const AudioPreview = ({ url }) => {
  const audioRef = useRef();

  const togglePlayPause = () => {
    const audio = audioRef.current;

    if (!audio) return;

    try {
      if (audio.paused) {
        audio.currentTime = 0;
        audio.play();
      } else {
        audio.pause();
      }
    } catch (err) {
      // ignore
    }
  };

  return (
    <AudioPreviewButton onClick={togglePlayPause}>
      <audio ref={audioRef}>
        <source src={url} />
      </audio>
      <MicrophoneIcon />
    </AudioPreviewButton>
  );
};

export default AudioPreview;
