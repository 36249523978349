import React from 'react';
import { TextBlock, PageHeadline, NarrowWrapper } from '../StyledElements';
import UserList from './UserList';
import { organizationUserRoles } from 'config';

const UserManagement = () => (
  <NarrowWrapper className="until-desktop">
    <PageHeadline>Nutzerverwaltung</PageHeadline>
    <TextBlock centered mt={4.5} mb={6} fontSize={1.25} lineHeight={1.5}>
      Ihre Lizenz ermöglicht es Ihnen, weitere Kollegen einzuladen, die
      ebenfalls Onilo mit allen Funktionen nutzen können.
      <br />
      Tragen Sie hierfür die E-Mail-Adresse der Kollegen ein und diese werden
      per E-Mail informiert.
    </TextBlock>
    <UserList relevantUserRole={organizationUserRoles.USER} />
  </NarrowWrapper>
);

export default UserManagement;
