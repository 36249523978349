import { apiFetch, apiEndpoints } from '../modules/api';

export const GET_REQUEST = 'tags/GET_REQUEST';
export const GET_SUCCESS = 'tags/GET_SUCCESS';
export const GET_FAILURE = 'tags/GET_FAILURE';

const getTagsRequest = () => ({
  type: GET_REQUEST
});

const getTagsSuccess = (data) => ({
  type: GET_SUCCESS,
  data
});

const getTagsFailure = () => ({
  type: GET_FAILURE
});

export const getTags = () => (dispatch) => {
  dispatch(getTagsRequest());

  return apiFetch
    .url(apiEndpoints.tags)
    .get()
    .json((json) => json)
    .then((data) => {
      dispatch(getTagsSuccess(data));
    })
    .catch(() => dispatch(getTagsFailure()));
};
