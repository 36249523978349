import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PlayIcon } from '../assets/icons/PlayTransparent.svg';
import media from '../utils/media';

const PreviewWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: calc(50% - 47.5px);
    left: calc(50% - 47.5px);
    width: 95px;
    height: 95px;
    pointer-events: none;
    z-index: 1;

    ${media.md`
      top: calc(50% - 57.5px);
      left: calc(50% - 57.5px);
      width: 115px;
      height: 115px;
    `};
  }
`;

export default ({ children, className, onClick }) => (
  <PreviewWrapper className={className} onClick={onClick}>
    {children}
    <PlayIcon />
  </PreviewWrapper>
);
