import { apiFetch, apiEndpoints } from '../modules/api';
import qs from 'query-string';
import { getImages } from './images';

export const GET_REQUEST = 'boardstories/GET_REQUEST';
export const GET_SUCCESS = 'boardstories/GET_SUCCESS';
export const GET_FAILURE = 'boardstories/GET_FAILURE';
export const EVENT_TRACKED = 'boardstories/EVENT_TRACKED';

const getBoardstoriesRequest = () => ({
  type: GET_REQUEST
});

const getBoardstoriesSuccess = ({ data, currentPage, pagesCount }) => ({
  type: GET_SUCCESS,
  currentPage,
  pagesCount,
  data
});

const getBoardstoriesFailure = () => ({
  type: GET_FAILURE
});

const fetchBoardstoriesByPage = ({
  endpointUrl,
  objectName = 'boardstories'
}) => (dispatch) => {
  dispatch(getBoardstoriesRequest());

  apiFetch
    .url(endpointUrl)
    .get()
    .json((json) => {
      const boardstories = json._embedded[objectName];

      dispatch(
        getBoardstoriesSuccess({
          data: boardstories,
          currentPage: json.currentPage,
          pagesCount: json.pagesCount
        })
      );
      const boardstoryIds = boardstories
        .filter((b) => b.teaserImagesCount > 0)
        .map((b) => b.id);
      if (boardstoryIds.length > 0) {
        dispatch(getImages(boardstoryIds));
      }
    })
    .catch(() => dispatch(getBoardstoriesFailure()));
};

export const getBoardstories = ({
  page = 1,
  limit = 10,
  ...restQueryParams
}) => (dispatch) => {
  const query = qs.stringify(
    { page, limit, ...restQueryParams },
    { arrayFormat: 'bracket' }
  );

  dispatch(
    fetchBoardstoriesByPage({
      endpointUrl: `${apiEndpoints.boardstories.get}?${query}`,
      objectName: 'boardstories'
    })
  );
};

export const getBoardstoriesBookmarks = ({ page = 1, limit = 10 }) => (
  dispatch
) => {
  const query = qs.stringify({ page, limit });

  dispatch(
    fetchBoardstoriesByPage({
      endpointUrl: `${apiEndpoints.boardstories.bookmarks}?${query}`,
      objectName: 'bookmarks'
    })
  );
};