import React from 'react';
import styled from 'styled-components/macro';
import { Button, PageContainer } from '../../../components/StyledElements';
import BoardstoryTeaser from './BoardstoryTeaser';
import VideoPreview from '../../../components/VideoPreview';
import { ReactComponent as VideoHighlight } from '../../../assets/icons/VideoHighlight.svg';
import RecommendationBubble from '../../../assets/icons/RecommendationBubble.svg';
import { Image, getWpImageSrcSet } from '../../../components/Image';

const ContentContainer = styled(PageContainer)`
  p {
    font-size: inherit;
    margin: 0 0 0.75rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .recommendation__bubble {
    background-image: url(${RecommendationBubble});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    max-width: 520px;
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    display: block;
    padding-bottom: 62%;
    height: 0;
  }

  img,
  video {
    object-fit: cover;
  }

  .highlight-padding {
    padding: 4.1% 3.1% 5.2%;
  }

  .video-highlight {
    filter: drop-shadow(0px 25px 50px rgba(0, 98, 124, 0.3));
    z-index: 1;
  }

  .inset,
  .video__preview,
  .video__thumbnail,
  .video-container,
  .video-container > video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .video__thumbnail::before,
  .video-container::before {
    display: none;
  }

  .media-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 4.1%;
    margin-left: 3.1%;
    width: calc(100% - 6.2%);
    height: calc(100% - 15%);
    border-radius: 8px;
    overflow: hidden;
    z-index: 2;
  }
`;

export default ({ items }) => {
  const [videoActive, setVideoActive] = React.useState(false);

  if (!items) return null;

  const { headline, text, image, cta_after, cta_embedded, media_boardstory, media_type, media_video, media_image } =
    items || {};

  const onVideoPlay = () => {
    setVideoActive(true);
  };

  return (
    <ContentContainer>
      <div className="flex relative items-center justify-center">
        {image && (
          <div className="md:ml-4 relative z-10 w-24 sm:w-32 md:w-auto">
            <Image width="205" lazy srcSet={getWpImageSrcSet(image)} />
          </div>
        )}
        <div className="recommendation__bubble relative -ml-8 flex flex-col justify-center py-6 px-8 sm:pt-8 sm:pb-6 sm:px-12">
          <div
            className="text-center italic font-bold text-base sm:text-2xl leading-tight mb-1"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
          <div className="text-center text-sm sm:text-base leading-tight" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>

      {media_video && (
        <VideoContainer>
          <VideoHighlight className="video-highlight inset" />

          <div className="media-wrapper">
            {media_type === 'video' && (
              <>
                {videoActive ? (
                  <video
                    autoPlay
                    className="inset"
                    controls
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <source src={media_video} />
                  </video>
                ) : (
                  <>
                    <VideoPreview onClick={onVideoPlay} className="cursor-pointer inset">
                      {media_image && <Image lazy srcSet={getWpImageSrcSet(media_image)} className="inset" />}
                    </VideoPreview>
                  </>
                )}
              </>
            )}

            {media_type === 'image' && media_image && <Image lazy srcSet={getWpImageSrcSet(media_image)} className="inset" />}

            {media_type === 'boardstory' && media_boardstory && (
              <BoardstoryTeaser renderTitle={false} boardstorySlug={media_boardstory} />
            )}

            {cta_embedded && (
              <Button className="z-10 absolute right-6 bottom-6" as="a" href={cta_embedded.url}>
                {cta_embedded.text}
              </Button>
            )}
          </div>
        </VideoContainer>
      )}

      {cta_after && (
        <div className="text-center mt-4 mb-8">
          <Button as="a" href={cta_after.url}>
            {cta_after.text}
          </Button>
        </div>
      )}
    </ContentContainer>
  );
};
