import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';

import { updateQs } from '../../utils/queryString';
import { wpFetch, wpEndpoints } from '../../modules/wp';
import { normalize } from '../../utils/helpers';
import Fetcher from '../../components/Fetcher';
import {
  PageContainer,
  Spacing,
  TextBlock
} from '../../components/StyledElements';
import PageTitle from '../../components/PageTitle';
import SearchAutocomplete from '../../components/Search/SearchAutocomplete';
import Pagination from '../../components/Pagination';
import NewsList from './NewsList';
import NewsFilterSection from './NewsFilterSection';
import NewsPostTeaser from './NewsPostTeaser';
import { contentPagesWithOwnSlug } from '../../config';
import { fetchNewsSuggestions } from '../../components/Search/searchUtil';
import withQueryString from '../../modules/withQueryString';

class News extends Component {
  static defaultProps = {
    queryParams: {}
  };

  state = {
    error: undefined,
    news: [],
    suggestions: [],
    categories: {},
    loadingSuggestions: false,
    pagesCount: 0
  };

  componentDidMount() {
    this.fetchNews();
    this.fetchCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.fetchNews();
    }
  }

  getFetchQuery() {
    const { queryParams } = this.props;
    let fetchQuery = {
      page: queryParams.p || 1,
      search: queryParams.q,
      categories_not_in: contentPagesWithOwnSlug.join(',')
    };

    // Filter by post_date year
    if (queryParams.year && !isNaN(queryParams.year)) {
      const year = parseInt(queryParams.year, 10);

      fetchQuery = {
        ...fetchQuery,
        before: `${year + 1}-01-01T00:00:00`,
        after: `${year}-01-01T00:00:00`,
        date_query_column: 'post_date'
      };
    }

    // Filter by category
    if (queryParams.category) {
      fetchQuery = {
        ...fetchQuery,
        categories_in: queryParams.category
      };
    }

    return pickBy(fetchQuery);
  }

  fetchNews() {
    const query = this.getFetchQuery();

    this.setState({ error: undefined });

    wpFetch
      .url(wpEndpoints.posts)
      .query({ per_page: 10, page: this.props.queryParams.p, ...query })
      .get()
      .res((response) => {
        const pagesCount = Number(response.headers.get('X-WP-TotalPages'));
        this.setState({ pagesCount });

        return response.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          this.setState({ news: data, error: undefined });
        } else {
          this.setState({ error: 'Keine Beiträge gefunden' });
        }
      })
      .catch(({ json: error }) => {
        if (error && error.message) {
          this.setState({ error: error.message });
        }
      });
  }

  // Fetches the first 100 categories
  fetchCategories() {
    wpFetch
      .url(wpEndpoints.categories)
      .query({ per_page: 100 })
      .get()
      .json((categoriesArray) => {
        this.setState({ categories: normalize(categoriesArray) });
      });
  }

  onSearchTermChange = (searchTerm) => {
    const newQuery = {
      q: searchTerm === '' ? undefined : searchTerm
    };

    this.props.history.push(
      updateQs(newQuery, this.props.location.search, 'p')
    );
  };

  getSuggestions = () => {
    return this.state.suggestions
      .map((i) => (i && i.title ? i.title : null))
      .filter((i) => !isEmpty(i));
  };

  onSuggestionClick = (suggestion) => {
    const post = this.state.suggestions.find((item) => {
      return item.title === suggestion;
    });

    if (post && post.slug) {
      this.props.history.push(`/aktuelles/${post.slug}`);
    }
  };

  fetchSuggestions = debounce(async (searchTerm) => {
    this.setState({ loadingSuggestions: true });
    const suggestions = await fetchNewsSuggestions(searchTerm);
    this.setState({ loadingSuggestions: false, suggestions });
  }, 500);

  render() {
    return (
      <PageContainer className="with-placeholder">
        <PageTitle headTitle="Themen">Alle Neuigkeiten im Überblick</PageTitle>
        <NewsPostTeaser />
        <SearchAutocomplete
          initialInputValue={this.props.queryParams.q}
          items={this.getSuggestions()}
          onChange={this.onSearchTermChange}
          onSuggestionClick={this.onSuggestionClick}
          fetchItems={this.fetchSuggestions}
          shouldFilterItems={false}
          loading={this.state.loadingSuggestions}
        />
        <Fetcher wordpress url={wpEndpoints.postsYears}>
          {({ data, fetching }) =>
            !fetching && (
              <NewsFilterSection
                categories={this.state.categories}
                years={data}
              />
            )
          }
        </Fetcher>
        {this.state.error ? (
          <TextBlock mt={3} mb={3} centered fontSize={1.5}>
            {this.state.error}
          </TextBlock>
        ) : (
          <NewsList
            categories={this.state.categories}
            items={this.state.news}
          />
        )}
        {this.state.pagesCount > 1 && (
          <Spacing mt={4.5}>
            <Pagination
              currentPage={parseInt(this.props.queryParams.p, 10) || 1}
              pagesCount={this.state.pagesCount}
            />
          </Spacing>
        )}
      </PageContainer>
    );
  }
}

export default withQueryString(News);
