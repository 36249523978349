import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { StickyContainer } from 'react-sticky-17';
import isEmpty from 'lodash/isEmpty';
import { SectionSeparator } from '../../components/StyledElements';
import Fetcher from '../../components/Fetcher';
import YoastHead from '../../components/SEO/YoastHead';
import Placeholder from '../../components/Placeholder';
import { wpEndpoints } from '../../modules/wp';
import { sectionsBeforeLogin, sectionsAfterLogin } from './sections';

const FrontPage = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const renderSections = (pageData) => {
    const { acf: data = {} } = pageData;
    const sections = isAuthenticated ? sectionsAfterLogin : sectionsBeforeLogin;
    const activeBlocks = isAuthenticated ? data['active_blocks_loggedin'] : data['active_blocks_guest'];
    const signupButton = data?.signup_cta;
    const readMoreButton = data?.read_more_cta;

    return sections.map((section, key) => {
      const { name } = section;
      const SectionComponent = section.component;

      // Directly render components without the name prop
      if (!name) {
        return <SectionComponent key={key} />;
      }

      // Bail if current section doesn't have any data to render
      if (
        isEmpty(data[name]) ||
        (section.requiredPropName !== undefined && !data[name][section.requiredPropName]) ||
        (activeBlocks !== undefined && !activeBlocks.includes(name))
      ) {
        return null;
      }

      const sectionProps = {
        [section.dataPropName || 'items']: data[name],
        ...section.props,
      };

      return (
        <Fragment key={name}>
          <SectionComponent
            headline={data[name + '_headline'] || section.defaultHeadline}
            text={data[name + '_text']}
            readMoreButton={readMoreButton}
            isAuthenticated={isAuthenticated}
            signupButton={signupButton}
            {...sectionProps}
          />
          {!section.hasNoSeparator && <SectionSeparator className="is-wide is-centered" />}
        </Fragment>
      );
    });
  };

  return (
    <Fetcher wordpress url={wpEndpoints.acfOptions}>
      {({ data, fetching }) => (
        <Placeholder loading={fetching} animationDelay={1250}>
          {data && data.acf && (
            <Fragment>
              <YoastHead titleTemplate="" meta={data.acf.frontpage_meta} />
              {/* The StickyContainer is needed to wrap the AccountNavbar */}
              {isAuthenticated ? <StickyContainer>{renderSections(data)}</StickyContainer> : renderSections(data)}
            </Fragment>
          )}
        </Placeholder>
      )}
    </Fetcher>
  );
};

export default FrontPage;
