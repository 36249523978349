import React from 'react';
import { vidisLogin } from '../config';
import { VidisButtonContainer } from './StyledElements';

export default () => {
    if (!vidisLogin || !vidisLogin.url || !vidisLogin.url.length) return;

    const script = document.createElement("script");
    script.src = "https://repo.vidis.schule/repository/vidis-cdn/latest/vidisLogin.umd.js";
    document.body.appendChild(script);

    return (
      <VidisButtonContainer>
        <vidis-login size="s" cookie="false" loginurl={vidisLogin.url}></vidis-login>
      </VidisButtonContainer>
    );
};
