import React from 'react';
import { ButtonLink } from './StyledElements';

const SignupCTA = ({ button, className }) => {
  if (!button?.url) return null;

  return (
    <ButtonLink className={className} to={button.url}>
      {button.text || 'Jetzt Onilo testen'}
    </ButtonLink>
  );
};

export default SignupCTA;
