import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cc from 'classcat';
import styled from 'styled-components';
import { InlineItem, Spacing, ItemTags, ItemTag, BaseButton } from '../../components/StyledElements';
import DownloadLink from '../../components/DownloadLink';
import { openModal } from '../../components/Modal/modalActions';
import { Collapsible, TriggerArrow } from '../../components/Accordion';
import { breakpoints, fixedTagCategoryOrder } from '../../config';
import sortBy from 'lodash/fp/sortBy';
import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import take from 'lodash/fp/take';
import takeRight from 'lodash/fp/takeRight';
import { getMemberFromOrg, getRolesAsBoolean } from '../../utils/userRoleHelpers';
import { createTracker } from '../../modules/tracking';
import { accessCodeHasValidDate } from 'utils/helpers';

const PropertyName = styled.span`
  font-weight: bold;
  font-style: italic;
  line-height: 1.5;
  color: #27b0d4;
`;

const FilterSectionTrigger = styled(BaseButton)`
  font-style: italic;
  color: #27b0d4;
  padding-right: 55px;
  position: relative;
`;

const BoardstoryItemTags = styled(ItemTags)`
  margin-top: 8px;
`;

const TagLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const renderTrigger = ({ isOpen, onClick, trigger }) => {
  return (
    <FilterSectionTrigger onClick={onClick}>
      <PropertyName>{trigger + ' '}</PropertyName>
      <TriggerArrow rightOffset={20} flipped={isOpen} />
    </FilterSectionTrigger>
  );
};

class BoardstoryProperties extends Component {
  trackMaterialDownload = createTracker('boardstoryMaterialDownloaded');

  state = {
    showAccordion: window.innerWidth <= breakpoints.md,
  };

  renderPropertyValues = (propName, children) => {
    if (children instanceof Array) {
      return children.map((c) => <InlineItem key={c}>{c}</InlineItem>);
    }

    return (
      <div>
        <PropertyName>{propName + ': '}</PropertyName>
        {children}
      </div>
    );
  };

  onDownloadRequest = (event, boardstoryMaterialId) => {
    const canDownload =
      this.props.free || this.props.hasFullAccess || (this.props.accessCode && accessCodeHasValidDate(this.props.accessCode));

    if (canDownload) {
      this.trackMaterialDownload({
        boardstory: this.props.id,
        boardstoryMaterial: boardstoryMaterialId,
        ...(this.props.free ? { isAnonymous: true } : { user: this.props.user.id }),
      }).catch(() => {});
      return;
    }

    event.preventDefault();

    const modalProps = this.props.isAuthenticated
      ? {
          buttonValue: 'Lizenzen',
          pushTo: '/lizenzen',
        }
      : {
          buttonValue: 'Anmelden',
          pushTo: '/login',
        };

    this.props.openModal({
      ...modalProps,
      content: 'Um das Unterrichtsmaterial herunterladen zu können, müssen Sie angemeldet sein.',
    });
  };

  updateDimensions = () => {
    const showAccordion = window.innerWidth <= breakpoints.md;
    this.setState({ showAccordion });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderCollapsableTags() {
    return (
      <Spacing mt={1}>
        <Collapsible overflowWhenOpen="visible" trigger="Eigenschaften" renderTrigger={renderTrigger}>
          {this.renderTags()}
        </Collapsible>
      </Spacing>
    );
  }

  renderTags() {
    const sortedTags = flow(
      sortBy((t) => fixedTagCategoryOrder[t.category]),
      groupBy('category'),
    )(this.props.tags);
    const categories = Object.keys(sortedTags);

    return (
      <BoardstoryItemTags>
        {categories.map((category) => (
          <div key={category}>
            <PropertyName>{category}: </PropertyName>
            {sortedTags[category].map((tag) => {
              const link = `/boardstories?tags[]=${tag.id}`;

              return (
                <ItemTag key={tag.id}>
                  <TagLink to={link}>{tag.descriptor}</TagLink>
                </ItemTag>
              );
            })}
          </div>
        ))}
      </BoardstoryItemTags>
    );
  }

  renderAttachmentBox() {
    let attachments = this.props.attachments;
    // if accessCode is set we need to filter the attachments
    if (this.props.accessCode?.code && this.props.accessCode?.hiddenAttachments) {
      attachments = attachments.filter((attachment) => !this.props.accessCode?.hiddenAttachments.includes(attachment.id));
    }
    return (
      <Spacing mt={1}>
        <PropertyName>Unterrichtsmaterial Download:</PropertyName>
        {attachments.length > 3 ? this.renderCollapsableAttachments(attachments) : this.renderAttachments(attachments)}
      </Spacing>
    );
  }

  // If we have more then 3 attachments we want to hide all but the first 3 in a collapsible
  renderCollapsableAttachments(attachments) {
    return (
      <Spacing mt={0.5}>
        {this.renderAttachments(take(3, attachments))}
        <Spacing mt={0.5}>
          <Collapsible overflowWhenOpen="visible" trigger="Mehr Material anzeigen" renderTrigger={renderTrigger}>
            {this.renderAttachments(takeRight(attachments.length - 3, attachments))}
          </Collapsible>
        </Spacing>
      </Spacing>
    );
  }

  renderAttachments(attachments) {
    return (
      <div>
        {attachments.map(({ id, title, path }, key) => (
          <DownloadLink
            key={key}
            href={
              this.props.free ||
              this.props.hasFullAccess ||
              (this.props.accessCode && accessCodeHasValidDate(this.props.accessCode))
                ? path
                : undefined
            }
            data-storyname={this.props.slug}
            className={cc([
              'link-download-attachment',
              {
                'is-inactive': !(
                  this.props.free ||
                  this.props.hasFullAccess ||
                  (this.props.accessCode && accessCodeHasValidDate(this.props.accessCode))
                ),
              },
            ])}
            onClick={(event) => this.onDownloadRequest(event, id)}
          >
            {title}
          </DownloadLink>
        ))}
      </div>
    );
  }

  render() {
    const { isOwner } = getRolesAsBoolean(getMemberFromOrg(this.props.organization, this.props.user));
    const hideAttachments = !this.props.user.isExternalUser && this.props.currentOrder.allowRentals && !isOwner;

    return (
      <Fragment>
        {this.props.author && this.renderPropertyValues('Autor', this.props.author)}
        {this.props.illustrator && this.renderPropertyValues('Illustrator', this.props.illustrator)}
        {this.props.publisher && this.renderPropertyValues('Verlag', this.props.publisher)}
        {this.props.tags && this.props.tags.length > 0 && this.state.showAccordion
          ? this.renderCollapsableTags()
          : this.renderTags()}
        {!hideAttachments && this.props.attachments && this.props.attachments.length > 0 && this.renderAttachmentBox()}
      </Fragment>
    );
  }
}

const select = (state) => {
  return {
    user: state.user.account,
    currentOrder: state.user.currentOrder,
    organization: state.user.organization,
    isAuthenticated: state.auth.isAuthenticated,
    hasFullAccess: state.auth.isAuthenticated && state.user.currentOrder && state.user.currentOrder.active,
    accessCode: state.user?.accessCode,
  };
};

export default connect(select, { openModal })(BoardstoryProperties);
