import React from 'react';

export default function SvgCrop({ left, top, height, width }) {
  return (
    <svg height="100%" width="100%">
      <defs>
        <mask id="mask" x="0" y="0" height="100%" width="100%">
          <rect height="100%" width="100%" fill="#fff" />
          <rect x={left} y={top} height={height} width={width} fill="#000" />
        </mask>
      </defs>
      <rect
        height="100%"
        width="100%"
        fill="rgba(0, 0, 0, 0.7)"
        mask="url(#mask)"
        fillOpacity="1"
      />
    </svg>
  );
}
