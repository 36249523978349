import { breakpoints } from '../../config';
import { Image } from '../../components/Image';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import media from '../../utils/media';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H1, Separator, BoxShadow, Thumbnail } from '../StyledElements';

const TopicTitle = styled.div`
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  padding: 3px 3px;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.md`
    padding-top: 8px;
    padding-bottom: 7px;
    line-height: 1.25;
  `};

  ${media.lg`
    font-size: 1.5rem;
    font-weight: normal;
    padding-top: 5px;
    padding-bottom: 5px;
  `};
`;

const TopicTeaser = styled(BoxShadow)`
  color: #27b0d4;
  overflow: auto;
  transition: 0.3s transform;
  /* Fix for the WebKit overflow bug */
  z-index: 1;
  position: relative;

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    color: #f26521;
    transform: scale(1.05);
  }
`;

const topicAreaImageSizes = [
  {
    media: `(min-width: ${breakpoints.md}px)`,
    size: '33vw',
  },
  { size: '50vw' },
];

const SectionHeadline = styled(H1)`
  text-align: center;
  margin: 0 0 2.5rem;

  ${media.md`
    margin-bottom: 3rem; 
  `};

  ${media.lg`
    margin-bottom: 4.5rem; 
  `};
`;

const SimilarBoardstories = ({ history, similarBoardstories, similarBsTeaser }) => {
  if (similarBoardstories.length === 0) return null;

  return (
    <Fragment>
      <Separator className="is-wide is-centered" />
      <SectionHeadline>Kennen Sie schon diese Boardstories?</SectionHeadline>
      <div className="flex flex-wrap justify-center m-[-5px] md:-m-2 lg:m-[-10px]">
        {similarBoardstories.map((bs) => {
          const { title, slug, id } = bs;
          const teaserImage = similarBsTeaser.find((i) => i.boardstoryId === id);
          const teaser = teaserImage ? teaserImage.path.medium : '';
          const teaserTitle = teaserImage ? teaserImage.title : '';
          const boardstoryUrl = `/boardstory/${slug}`;
          const pushToBoardstory = () => {
            history.push(boardstoryUrl);
          };

          return (
            <div key={slug} className="w-1/2 md:w-1/3 p-[5px] md:p-2 lg:p-[10px]">
              <TopicTeaser>
                <Thumbnail onClick={pushToBoardstory} className="wide-screen">
                  <Image srcSet={teaser} alt={teaserTitle} sizes={topicAreaImageSizes} />
                </Thumbnail>
                <Link to={boardstoryUrl}>
                  <TopicTitle>{title}</TopicTitle>
                </Link>
              </TopicTeaser>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

SimilarBoardstories.propTypes = {
  history: PropTypes.object,
  similarBoardstories: PropTypes.array,
  similarBsTeaser: PropTypes.array,
};

export default withRouter(SimilarBoardstories);
