import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { Formik } from 'formik';
import styled from 'styled-components';
import validate from '../../modules/validate';
import { apiFetch, apiEndpoints } from '../../modules/api';
import media from '../../utils/media';
import FormFields from '../FormFields';
import { Button, BoxShadow, Form, Spacing, H2 } from '../StyledElements';
import { PlusIcon } from '../../components/StyledIcons';

const initialValues = {
  email: '',
};

const fields = [
  {
    name: 'email',
    placeholder: 'E-Mail-Adresse',
    type: 'email',
  },
];

class AddUser extends Component {
  static defaultProps = {
    existingMembers: [],
  };

  state = {
    shouldRenderForm: false,
  };

  validateValues = (values) => {
    let errors = validate(values);

    if (this.props.existingMembers.find((i) => i.user.email === values.email)) {
      errors.email = 'Dieser User wurde bereits hinzugefügt';
    }

    return errors;
  };

  onSubmit = (values, actions) => {
    apiFetch
      .url(apiEndpoints.invitations)
      .json({
        organizationId: this.props.organizationId,
        email: values.email,
        userRole: this.props.relevantUserRole,
      })
      .post()
      .json((response) => {
        actions.setSubmitting(false);
        this.setState({ shouldRenderForm: false });
        this.props.onSuccess && this.props.onSuccess(response);
      })
      .catch(({ json }) => {
        actions.setSubmitting(false);
        if (json && json.error && json.error.indexOf('has already been invited') > -1) {
          actions.setErrors({ email: 'Dieser User wurde bereits eingeladen' });
        }
        if (json && json.error && json.error.indexOf('rental accesses exceeded') > -1) {
          actions.setErrors({ email: 'Alle Verleihzugänge wurden bereits verwendet' });
        }
      });
  };

  toggleForm = () => {
    this.setState({ shouldRenderForm: !this.state.shouldRenderForm });
  };

  render() {
    const { shouldRenderForm } = this.state;

    if (!shouldRenderForm) {
      return (
        <FormWrapper className="is-clickable" onClick={this.toggleForm} invertColors={this.props.invertColors}>
          <IconWrapper>
            <PlusIcon invertColors={this.props.invertColors} />
          </IconWrapper>
          <H2>{this.props.addUserText}</H2>
        </FormWrapper>
      );
    }

    return (
      <FormWrapper className="has-smaller-pt">
        <IconWrapper className="close-icon" onClick={this.toggleForm}>
          <PlusIcon />
        </IconWrapper>
        <Formik initialValues={initialValues} validate={this.validateValues} onSubmit={this.onSubmit}>
          {({ errors, handleSubmit, isSubmitting, ...restProps }) => (
            <Form onSubmit={handleSubmit}>
              <FormFields fields={fields} errors={errors} {...restProps} />
              <Spacing textAlign="right">
                <Button className={cc(['smaller', { loading: isSubmitting }])} type="submit" disabled={isSubmitting}>
                  Speichern
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    );
  }
}

AddUser.propTypes = {
  organizationId: PropTypes.string.isRequired,
  existingMembers: PropTypes.arrayOf(PropTypes.object),
  onSuccess: PropTypes.func,
  relevantUserRole: PropTypes.string.isRequired,
  addUserText: PropTypes.string.isRequired,
  invertColors: PropTypes.bool,
};

export default AddUser;

const FormWrapper = styled(BoxShadow)`
  padding: 1.5em 1em 1.5em;
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;

  &.has-smaller-pt {
    padding-top: 0.75em;
  }

  > :last-child {
    flex: 1;
  }

  &.is-clickable {
    cursor: pointer;
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;

  ${media.lg`
    margin-right: 2em;
  `};

  svg {
    width: 40px;
    height: 40px;

    ${media.lg`
      width: 46px;
      height: 46px;
    `};
  }

  &.close-icon {
    transform: rotate(45deg);
    margin-top: 1.5em;
    align-self: flex-start;
  }
`;
