import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { apiFetch, apiEndpoints } from '../../modules/api';
import PageTitle from '../../components/PageTitle';
import { PageContainer, TextBlock } from '../../components/StyledElements';

export default class extends Component {
  state = {
    success: false
  };

  componentDidMount() {
    const { confirmationToken } = this.props.match.params;
    if (!confirmationToken || !confirmationToken.length) {
      return this.props.history.push('/login');
    }

    apiFetch
      .url(apiEndpoints.users.confirmForceNewPassword)
      .json({ confirmationToken })
      .post()
      .json((response) => {
        this.setState({ success: true });
      })
      .catch(() => this.props.history.push('/'));
  }

  render() {
    return (
      <PageContainer narrow>
        <PageTitle>Neues Passwort vergeben</PageTitle>
        <TextBlock fontSize={1.5} italic centered mt={2.5}>
          {this.state.success ? (
            <Fragment>
              Das Passwort wurde aktualisiert.<br />
              <Link to="/login">Jetzt anmelden</Link>
            </Fragment>
          ) : (
            'Das Passwort wird aktualisiert...'
          )}
        </TextBlock>
      </PageContainer>
    );
  }
}
