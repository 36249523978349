import React from 'react';
import styled from 'styled-components';
import { joinValues } from '../utils/helpers';
import UserCardTimestamp from './UserCardTimestamp';

const Content = styled.div`
  color: #27b0d4;
  font-size: 1rem;
  display: inline-block;
  line-height: 1.5;
  flex: 1 auto;

  .username {
    font-size: 1.5em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.25;
    margin: 0;
  }
`;

export default ({ user, renderSalutation = true, children, className, timestamp }) => {
  const { salutation, firstName, lastName, email } = user;
  const nameParts = renderSalutation ? [salutation, firstName, lastName] : [firstName, lastName];
  const fullName = user.name || joinValues(...nameParts);

  return (
    <Content className={className}>
      <h2 className="username">{fullName && fullName.length > 1 ? fullName : 'Kein Name angegeben'}</h2>
      {children ? (
        <div>{children}</div>
      ) : (
        <div className="text-blue-60">{email && email.match('^.{20,}@onilo.de$') ? 'Externer SSO-Account' : email}</div>
      )}
      {timestamp && <UserCardTimestamp timestamp={timestamp} className="md:hidden" />}
    </Content>
  );
};
