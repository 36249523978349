export const ADD_FORM_VALUES = 'order/ADD_FORM_VALUES';
export const RESET_FORM_VALUES = 'order/RESET_FORM_VALUES';
export const CREATE_ORDER = 'order/CREATE_ORDER';
export const RESET_ORDER = 'order/RESET_ORDER';
export const UPDATE_PROMOCODE = 'order/UPDATE_PROMOCODE';

export const addFormValues = (data) => ({
  type: ADD_FORM_VALUES,
  formValues: data
});

export const resetFormValues = (data) => ({
  type: RESET_FORM_VALUES,
  formValues: data
});

export const createOrder = (license) => ({
  type: CREATE_ORDER,
  license
});

export const resetOrder = () => ({
  type: RESET_ORDER
});

export const updatePromoCode = (promoCode) => ({
  type: UPDATE_PROMOCODE,
  promoCode
});