import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FakeLink,
  BlockLink,
  ListItemContainer,
  PostContent,
  ItemTags,
  ItemTag,
  Thumbnail as BaseThumbnail,
} from '../../components/StyledElements';
import { Image } from '../../components/Image';
import truncate from '../../utils/truncate';
import { getPostItemBaseSlug } from '../../utils/wpHelpers';
import media from '../../utils/media';
import { breakpoints } from '../../config';

const ItemContainer = styled(ListItemContainer)`
  padding: 0.5rem;
  flex-wrap: nowrap;
  align-items: flex-start;
  cursor: pointer;

  &.is-highlighted {
    border: 3px solid ${({ theme }) => theme.oniloBlue};
  }
`;

const NewsBlockLink = styled(BlockLink)`
  margin-top: 1.5rem;
  &:first-child {
    margin-top: 0;
  }

  &.is-vertical {
    margin-top: 0;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    > * {
      height: 100%;
      flex-direction: column;
    }
  }
`;

const Thumbnail = styled(BaseThumbnail)`
  width: 64px;
  margin-right: 0.5rem;
  border-radius: 2px;

  ${media.md`
    width: 210px;
  `};

  ${media.lg`
    width: 212px;
  `};

  &.full-width {
    width: calc(100% + 1rem);
    margin: -0.5rem -0.5rem 0.5rem;
  }
`;

const ItemContent = styled.div`
  padding-bottom: 0.5rem;
  flex: 1;
  font-size: 1.125rem;
  line-height: 1.44;

  a {
    font-style: italic;
  }

  ${media.md`
    padding: 8px 12px 3px 8px;
  `};

  ${media.lg`
    font-size: 1rem;
    padding-left: 0.75rem;
    line-height: 1.63;
  `};
`;

const PostHeadline = styled.h2`
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.33;
  margin: 0.5rem 0;

  ${ItemTags} + & {
    margin-top: 0;
  }

  ${media.md`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
  `};
`;

const teaserImageSizes = [
  {
    media: `(min-width: ${breakpoints.md}px)`,
    size: '212px',
  },
  {
    size: '64px',
  },
];

const NewsListItem = ({ categories, item, vertical }) => {
  // Find the ID of the category used to mark news for guests
  // Which we don't want to render
  const guestCategoryId = categories ? Object.values(categories).find(({ slug }) => slug === 'for_guests')?.id : null;

  const renderItemCategories = (categoryIds) => {
    if (!categoryIds || categoryIds.length === 0) {
      return null;
    }

    return (
      <ItemTags>
        {categories &&
          categoryIds
            .filter((i) => i !== guestCategoryId)
            .map((id) => (categories[id] ? <ItemTag key={id}>{categories[id].name}</ItemTag> : null))}
      </ItemTags>
    );
  };

  const { featured_image_src: thumbnailSrc = {}, title, acf = {} } = item;
  const baseSlug = getPostItemBaseSlug(item);
  const postUrl = `/${baseSlug}/${item.slug}`;

  return (
    <NewsBlockLink
      className={cc({
        'is-vertical': vertical,
      })}
      to={postUrl}
    >
      <ItemContainer
        className={cc({
          'is-highlighted': acf.highlighted,
        })}
      >
        {thumbnailSrc.srcset && (
          <Thumbnail
            className={cc({
              'full-width': vertical,
            })}
          >
            <Image src={thumbnailSrc.medium[0]} srcSet={thumbnailSrc.srcset} alt={thumbnailSrc.alt} sizes={teaserImageSizes} />
          </Thumbnail>
        )}
        <ItemContent>
          {renderItemCategories(item.categories)}
          <PostHeadline dangerouslySetInnerHTML={{ __html: title?.rendered }} />
          {acf.short_text && <PostContent>{truncate(acf.short_text, 280)}</PostContent>}
          <div>
            <FakeLink>Mehr erfahren</FakeLink>
          </div>
        </ItemContent>
      </ItemContainer>
    </NewsBlockLink>
  );
};

NewsListItem.propTypes = {
  item: PropTypes.object,
  categories: PropTypes.object,
  vertical: PropTypes.bool,
};

export default NewsListItem;
