import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { Formik, Form } from 'formik';
import { Button, Spacing, TextBlock } from '../../components/StyledElements';
import FormFields from '../../components/FormFields';
import { baseNameFields } from '../../components/OrderDataForm/orderDataConfig';
import { hasNameUpdated } from '../../utils/helpers';
import validate from '../../modules/validate';

const validateFields = ({ email, salutation, ...restValues }) => {
  return validate(restValues);
};

class AccountUserData extends React.PureComponent {
  state = {
    updated: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (hasNameUpdated(prevProps.user, this.props.user)) {
      this.setState({ updated: true });
    }
  }

  onSubmit = (values, bag) => {
    this.setState({ updated: false }, async () => {
      if (hasNameUpdated(values, this.props.user)) {
        await this.props.updateUser(this.props.user.id, values);
      }
      bag.setSubmitting(false);
    });
  };

  render() {
    const { user } = this.props;

    return (
      <Formik
        initialValues={{
          salutation: user.salutation,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }}
        validate={validateFields}
        onSubmit={this.onSubmit}
      >
        {(formProps) => (
          <Form>
            <FormFields fields={baseNameFields} {...formProps} />
            <Spacing textAlign="center" mt={4.5}>
              <Button className={cc({ loading: formProps.isSubmitting })} type="submit" disabled={formProps.isSubmitting}>
                Änderungen speichern
              </Button>
            </Spacing>
            {this.state.updated && (
              <TextBlock centered mt={1} fontSize={0.875}>
                Die Daten wurden erfolgreich aktualisiert
              </TextBlock>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

AccountUserData.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default AccountUserData;
