import wretch from 'wretch';
import store from '../store';
import { onLogoutSuccess } from '../actions/auth';

// Logout on 401 errors
const handleUnauthorized = (err) => {
  store.dispatch(onLogoutSuccess());
};

export const apiFetch = wretch()
  .options({ credentials: 'include', mode: 'cors' })
  .errorType('json')
  .catcher(401, (err) => handleUnauthorized(err));

export const apiEndpoints = {
  tags: '/api/tags',
  videos: '/api/videos',
  tigerCreations: '/api/tiger-creations',
  trailers: '/api/trailers',
  boardstories: {
    get: '/api/boardstories',
    bookmarks: '/api/bookmarks',
    similar: '/api/similar-boardstories',
    trackingEvent: '/api/track',
  },
  attachments: {
    get: '/api/attachments?boardstoryId=',
  },
  images: {
    get: '/api/images',
  },
  licenses: {
    get: '/api/licenses',
  },
  organizations: {
    get: '/api/organizations',
    post: '/api/organizations',
    patch: '/api/organizations',
  },
  orders: {
    get: '/api/orders',
    getCurrent: '/api/orders/current',
    post: '/api/orders',
  },
  users: {
    confirmEmailChange: '/api/confirm-email-change',
    confirmForceNewPassword: '/api/confirm-force-new-password',
    confirmRegistration: '/api/confirm-registration',
    forceNewPassword: '/api/force-new-password',
    get: '/api/users',
    patch: '/api/users',
    requestEmailChange: '/api/request-email-change',
    requestPasswordReset: '/api/request-password-reset',
    resetPassword: '/api/reset-password',
    self: '/api/me',
    signup: '/api/users',
    tosConsent: '/api/tos-consent',
  },
  invitations: '/api/invitations',
  accessCodes: {
    root: '/api/access-codes',
    own: '/api/me/access-codes',
  },
  promoCodes: '/api/promocodes',
  newsletter: '/api/newsletter',
};
