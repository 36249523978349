import React from 'react';
import styled from 'styled-components/macro';
import { PageContainer } from '../../../components/StyledElements';
import { Image, getWpImageSrcSet } from '../../../components/Image';

const ItemContainer = styled.div`
  p {
    font-size: inherit;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export default ({ items }) => {
  if (!items) return null;

  const { headline, title, content, image, button } = items;

  return (
    <PageContainer>
      <h2 className="mb-8 text-2xl italic text-center">{headline}</h2>
      <ItemContainer className="grid md:grid-cols-2 overflow-hidden rounded-[30px]">
        {image && (
          <div className="relative content-[''] before:block before:pt-[56.25%]">
            <Image className="inset-0 absolute object-cover h-full w-full" lazy srcSet={getWpImageSrcSet(image)} />
          </div>
        )}
        <div className="item__content bg-white p-8 flex-1 h-full flex flex-col justify-center">
          <h3 className="text-left text-2xl italic font-normal m-0 mb-2">{title}</h3>
          <div className="text-base" dangerouslySetInnerHTML={{ __html: content }} />
          {button && button.link && (
            <div className="mt-2">
              <a className="italic no-underline" href={button.link}>
                {button.text || 'Mehr erfahren'}
              </a>
            </div>
          )}
        </div>
      </ItemContainer>
    </PageContainer>
  );
};
