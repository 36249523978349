import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TextBlock, Thumbnail, ListItemContainer, FakeLink, BlockLink, MarkdownWrapper } from '../../components/StyledElements';
import { Image, getApiImageSrcSet } from '../../components/Image';
import BoardstoryShortcuts from './BoardstoryShortcuts';
import BoardstoryPlayer from '../../components/Boardstory/BoardstoryPlayer';
import { breakpoints } from '../../config';
import ReactMarkdown from 'react-markdown';

const teaserImageSizes = [
  {
    media: `(min-width: ${breakpoints.md}px)`,
    size: '30vw',
  },
  {
    size: '100vw',
  },
];

const BoardstoryListItemContent = ({ boardstory, className }) => (
  <BoardstoryPlayer
    isFree={boardstory.free}
    isExplain={boardstory.explain}
    boardstorySlug={boardstory.slug}
    boardstoryId={boardstory.id}
    videoHasAudio={boardstory.hasAudio}
    videoState={boardstory.videoState}
    trailerState={boardstory.trailerState}
    tigerCreationState={boardstory.tigerCreationState}
    games={boardstory.gameInfo}
    gameActive={boardstory.gameActive}
    hideInlinePlayer={true}
    renderController={({ onPlayRequest, allowedVideoTypes, availableVideoTypes }) => (
      <BoardstoryShortcuts
        boardstory={boardstory}
        onPlayRequest={onPlayRequest}
        className={className}
        allowedVideoTypes={allowedVideoTypes}
        availableVideoTypes={availableVideoTypes}
      />
    )}
  />
);

const BoardstoryListItem = ({ className, boardstory, history, teaserImage }) => {
  const boardstoryUrl = `/boardstory/${boardstory.slug}`;

  const pushToBoardstory = () => {
    history.push(boardstoryUrl);
  };

  return (
    <ListItemContainer className={className}>
      <div className="w-full md:w-[30%]">
        <Thumbnail onClick={pushToBoardstory} className="rounded-t-sm lg:rounded-sm">
          <Image
            srcSet={getApiImageSrcSet(teaserImage)}
            alt={teaserImage && teaserImage.title ? teaserImage.title : boardstory.title}
            sizes={teaserImageSizes}
          />
        </Thumbnail>
        <BoardstoryListItemContent boardstory={boardstory} className="lg:hidden is-horizontal" />
      </div>
      <div className="flex-1 py-6 px-4 sm:p-6 md:py-0 md:px-[1.3rem]">
        <BlockLink to={boardstoryUrl}>
          <h2 className="h2">{boardstory.title}</h2>
          {boardstory.description && (
            <MarkdownWrapper lh={1.63} mx={100} of={'hidden'}>
              <ReactMarkdown>{boardstory.description}</ReactMarkdown>
            </MarkdownWrapper>
          )}
          <TextBlock italic lineHeight={1.63}>
            <FakeLink>Infos & Lehrmaterial</FakeLink>
          </TextBlock>
        </BlockLink>
      </div>
      <BoardstoryListItemContent boardstory={boardstory} className="hidden lg:flex" />
    </ListItemContainer>
  );
};

BoardstoryListItem.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  boardstory: PropTypes.object,
  teaserImage: PropTypes.object,
};

export default withRouter(BoardstoryListItem);
