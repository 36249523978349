import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  font-size: 15px;
  line-height: 1.5;
`;

const getLifetimeLabels = function (lifetime) {
  if (lifetime > 1) {
    return { unit: "Monate", duration: lifetime }
  } else if (lifetime < 1) {
    return { unit: "Tage", duration: Math.floor(lifetime * 31) }
  } else {
    return { unit: "Monat", duration: lifetime }
  }
}

export default ({ maxUsers, lifetime, className, licenseName }) => {
  const customAccountsLabel = {"Schullizenz" : "eine Schule"}
  const accounts = maxUsers > 1 ? 'Zugänge' : 'Zugang';
  const { duration, unit } = getLifetimeLabels(lifetime)
  const accountsLabel = customAccountsLabel[licenseName] || maxUsers + " " + accounts

  return (
    <Content className={className}>
      {accountsLabel} / {duration} {unit}
    </Content>
  );
};
