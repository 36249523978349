import React, { Component } from 'react';
import styled from 'styled-components';
import cc from 'classcat';
import { Spinner } from '../../../components/StyledElements';
import Report from '../../../assets/icons/Report';

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);

  &.is-transparent {
    cursor: none;
  }

  .inner {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    margin-left: -30px;
    background-color: #f26521;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    margin-left: -20px;
    transform: translateY(-50%);
  }
`;

class Overlay extends Component {
  renderContent() {
    const { error, loading } = this.props;
    const iconProps = {
      height: 40,
      width: 40,
      fill: '#fff'
    };
    if (error) {
      return (
        <span className="inner">
          <Report {...iconProps} />
        </span>
      );
    } else if (loading) {
      return <Spinner />;
    }
  }

  render() {
    const { className, onClick } = this.props;
    return (
      <OverlayWrapper
        data-storyname={this.props.storyName}
        className={cc([className, 'player-control-overlay'])}
        onClick={onClick}
      >
        {this.renderContent()}
      </OverlayWrapper>
    );
  }
}

export default Overlay;
