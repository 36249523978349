import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { apiFetch, apiEndpoints } from '../../modules/api';
import { Formik, Form } from 'formik';
import { Button, Spacing, TextBlock } from '../../components/StyledElements';
import FormFields from '../../components/FormFields';

const fields = [
  {
    name: 'email',
    placeholder: 'E-Mail-Adresse',
    type: 'email',
  },
];

class AccountEmail extends React.PureComponent {
  state = {
    hint: '',
    errors: {},
  };

  onSubmit = ({ email }, bag) => {
    this.setState({ hint: '', errors: {} });

    apiFetch
      .url(`${apiEndpoints.users.requestEmailChange}`)
      .json({ email })
      .post()
      .json(() => {
        this.setState({
          hint: 'Es wurde eine Bestätigungsmail an die neue Adresse gesendet. Bitte klicken sie auf den Bestätigungslink.',
        });
      })
      .catch((e) => {
        if (e.json.error.match(/exists/gi))
          this.setState({
            errors: { email: 'Diese Email ist bereits in Benutzung' },
          });
      })
      .finally(() => {
        bag.setSubmitting(false);
      });
  };

  render() {
    const { user } = this.props;

    return (
      <Formik initialValues={{ email: user.email }} onSubmit={this.onSubmit}>
        {(formProps) => (
          <Form>
            <FormFields fields={fields} {...formProps} errors={this.state.errors} />
            <Spacing textAlign="center" mt={4.5}>
              {!this.state.hint ? (
                <Button className={cc({ loading: formProps.isSubmitting })} type="submit" disabled={formProps.isSubmitting}>
                  Änderungen speichern
                </Button>
              ) : (
                <TextBlock centered mt={1} fontSize={0.875}>
                  {this.state.hint}
                </TextBlock>
              )}
            </Spacing>
          </Form>
        )}
      </Formik>
    );
  }
}

AccountEmail.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AccountEmail;
