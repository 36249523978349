import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { StickyContainer } from 'react-sticky-17';
import styled, { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import PrivateRoute from '../PrivateRoute';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Head from './Head';
import ModalRoot from '../Modal/ModalRoot';
import ScrollToTop from '../ScrollToTop';
import AutoLogin from '../AutoLogin';

import FrontPage from '../../pages/FrontPage/FrontPage';
import ContentPage from '../../pages/ContentPage/ContentPage';
import NewsPost from '../../pages/News/NewsPost';
import News from '../../pages/News/News';
import Blog from '../../pages/Blog/Blog';
import Login from '../../pages/User/Login/Login';
import Signup from '../../pages/User/Signup';
import NotFound from '../../pages/ErrorPage/NotFound';
import ServerError from '../../pages/ErrorPage/ServerError';
import ConfirmRegistration from '../../pages/User/ConfirmRegistration';
import RequestPasswordReset from '../../pages/User/RequestPasswordReset';
import ResetPassword from '../../pages/User/ResetPassword';
import ForceNewPassword from '../../pages/User/ForceNewPassword';
import ConfirmTos from '../../pages/User/ConfirmTos';
import ConfirmForceNewPassword from '../../pages/User/ConfirmForceNewPassword';
import ConfirmEmailChange from '../../pages/User/ConfirmEmailChange';
import AcceptInvitation from '../../pages/User/AcceptInvitation';
import BoardstorySingle from '../../pages/Boardstories/BoardstorySingle';
import Boardstories from '../../pages/Boardstories/Boardstories';
import Licenses from '../../pages/Licenses/Licenses';
import Account from '../../pages/Account/Account';
import Order from '../../pages/Order/Order';
import TopicAreaIndex from '../../pages/TopicAreaIndex';
import SearchIndex from '../../pages/SearchIndex';
import { contentPagesWithOwnSlug } from '../../config';
import theme from 'theme';
import RentalUserManagement from '../UserManagement/RentalUserManagement/RentalUserManagement';

const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const AppContent = styled.main`
  flex: 1 auto;
  background-color: #ecfbfe;
`;

const SAML_LOGIN_URL = 'https://onilo.de/api/saml2/lux/login';

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <StickyContainer>
          <ScrollToTop />
          <AutoLogin />
          <Head />
          <AppWrapper>
            <Header />
            <AppContent className="main-content">
              <ErrorBoundary FallbackComponent={ServerError}>
                <Switch>
                  <Route exact path="/kostenlose-webinare">
                    <Redirect to="/kostenlose-online-fortbildungen" />
                  </Route>
                  <Route exact path="/" component={FrontPage} />
                  <Route path="/aktuelles/:slug" component={NewsPost} />
                  <Route path="/blog/:slug" component={NewsPost} />
                  <Route path="/blog" component={Blog} />
                  {contentPagesWithOwnSlug.map((pageSlug) => (
                    <Route
                      key={pageSlug}
                      path={`/${pageSlug}`}
                      render={(routeProps) => <TopicAreaIndex {...routeProps} slug={pageSlug} />}
                    />
                  ))}
                  <Route path="/search" component={SearchIndex} />
                  <Route path="/aktuelles" component={News} />
                  <Route path="/boardstories" component={Boardstories} />
                  <Route
                    path="/boardstory/:idOrSlug/:accessCode?"
                    component={BoardstorySingle}
                  />
                  <Route path="/lizenzen" component={Licenses} />
                  <PrivateRoute path="/order" component={Order} redirectTo="/signup" />
                  <Route path="/login" component={Login} />
                  <Route exact path="/signup" component={Signup} />
                  <Route path="/signup/:invitationToken" component={AcceptInvitation} />
                  <Route path="/request-password-reset" component={RequestPasswordReset} />
                  <Route path="/resetPassword/:resetToken" component={ResetPassword} />
                  <Route path="/force-new-password" component={ForceNewPassword} />
                  <Route path="/confirm-tos" component={ConfirmTos} />
                  <Route path="/confirm-force-new-password/:confirmationToken" component={ConfirmForceNewPassword} />
                  <Route path="/confirmRegistration/:confirmationToken" component={ConfirmRegistration} />
                  <Route path="/confirmEmailChange/:emailChangeToken" component={ConfirmEmailChange} />
                  <PrivateRoute path="/account" component={Account} />
                  <Route
                    path="/luxemburg"
                    render={() => {
                      window.location.href = SAML_LOGIN_URL;
                      return null;
                    }}
                  />
                  <Route
                    path="/luxemburg-app"
                    render={() => {
                      // Set the cookie to expire in 10 mins
                      const expiryDate = new Date();
                      expiryDate.setTime(expiryDate.getTime() + 10 * 60 * 1000);
                      document.cookie = `clientType=app; expires=${expiryDate.toUTCString()}; path=/`;

                      window.location.href = SAML_LOGIN_URL;

                      return null;
                    }}
                  />
                  <Route path="/:slug" component={ContentPage} />
                  <Route component={NotFound} />
                </Switch>
              </ErrorBoundary>
            </AppContent>
            <Footer />
            <ModalRoot />
          </AppWrapper>
        </StickyContainer>
      </ThemeProvider>
    );
  }
}

export default App;
