import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { apiFetch, apiEndpoints } from '../../modules/api';
import FormFields from '../../components/FormFields';
import PageTitle from '../../components/PageTitle';
import validate from '../../modules/validate';
import { PageContainer, TextBlock, Spacing, Button, Form } from '../../components/StyledElements';

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const fields = [
  {
    name: 'password',
    placeholder: 'Neues Passwort',
    type: 'password',
  },
  {
    name: 'passwordConfirmation',
    placeholder: 'Passwort wiederholen',
    type: 'password',
  },
];

const validateValues = (values) => {
  return validate(values, {
    passwordField: 'password',
    passwordConfirmationField: 'passwordConfirmation',
  });
};

export default class extends Component {
  state = {
    success: false,
  };

  componentDidMount() {
    const { resetToken } = this.props.match.params;
    if (!resetToken || !resetToken.length) {
      this.props.history.push('/requestResetPassword');
    }
  }

  onSubmit = (values, { setSubmitting }) => {
    const { resetToken } = this.props.match.params;

    apiFetch
      .url(apiEndpoints.users.resetPassword)
      .json({ newPassword: values.password, resetToken })
      .post()
      .json(() => {
        window.scrollTo(0, 0);
        this.setState({ success: true });
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  render() {
    if (this.state.success) {
      return (
        <PageContainer narrow>
          <PageTitle>Neues Passwort vergeben</PageTitle>
          <TextBlock fontSize={1.5} italic centered mt={2.5}>
            Ihr Passwort wurde erfolgreich aktualisiert.
            <br />
            <Link
              to={{
                pathname: '/login',
                state: { from: { pathname: '/' } },
              }}
            >
              Jetzt anmelden
            </Link>
          </TextBlock>
        </PageContainer>
      );
    }

    return (
      <PageContainer narrow>
        <PageTitle>Passwort vergessen</PageTitle>
        <Formik initialValues={initialValues} validate={validateValues} onSubmit={this.onSubmit}>
          {({ errors, values, handleSubmit, isSubmitting, ...restProps }) => (
            <Form onSubmit={handleSubmit}>
              <FormFields fields={fields} values={values} errors={errors} {...restProps} />
              <Spacing textAlign="center" mt={1}>
                <Button type="submit" className={isSubmitting ? 'loading' : undefined} disabled={isSubmitting}>
                  Passwort ändern
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </PageContainer>
    );
  }
}
