import React from 'react';
import { wpEndpoints } from '../modules/wp';
import { ButtonLink, FlexCenter } from './StyledElements';
import Fetcher from './Fetcher';

const AcceptedInvitationInfo = ({ isNewUser }) => {
  return (
    <Fetcher wordpress url={wpEndpoints.acfOptions}>
      {({ data }) => {
        const successText = isNewUser
          ? data?.acf?.onboarding_invite?.text_new_users
          : data?.acf?.onboarding_invite?.text_registered_users;

        if (!successText) {
          return null;
        }

        return (
          <>
            <div
              className="text-inherit text-center text-2xl"
              dangerouslySetInnerHTML={{
                __html: successText,
              }}
            />
            {!isNewUser && (
              <FlexCenter className="mt-10">
                <ButtonLink className="" to="/login">
                  Anmelden
                </ButtonLink>
              </FlexCenter>
            )}
          </>
        );
      }}
    </Fetcher>
  );
};

export default AcceptedInvitationInfo;
