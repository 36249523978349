import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { de } from 'date-fns/locale';
import { addDays } from 'date-fns'
import { DateRange } from 'react-date-range';
import './styles.css'
import AutoFormatInput from 'components/AutoFormatInput/AutoFormatInput';

const FixedDateRangePicker = ({startingDate, licenceEndDate, licenceStartDate, onDateChange}) => {
  const [startDate, setStartDate] = useState(startingDate);
  const [endDate, setEndDate] = useState(addDays(startDate, 13)); // Set the end date as 13 days from the start date
  const [focusedRange, setFocusedRange] = useState([0, 0]); // [0, 0] is the default value for the focused range

  const formatDate = (date) => {
    return `${date?.getDate().toString().padStart(2, '0')}.${(date?.getMonth() + 1).toString().padStart(2, '0')}.${date?.getFullYear()}`
  }

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    onDateChange(ranges.selection.startDate);
    setEndDate(addDays(ranges.selection.startDate, 13)); // Update the end date as 13 days from the start date
    //set startDateInput value to the selected date formatted as dd.MM.yyyy with trailing zeros
    document.getElementsByName('startDateInput')[0].value = formatDate(ranges.selection.startDate);
    setFocusedRange([0, 0]);
  };

  useEffect(() => {
    document.getElementsByName('startDateInput')[0].value = formatDate(new Date())
  }, [])

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  };


  const checkAndSetDate = (date) => {
    setStartDate(date);
    onDateChange(date);
    setEndDate(addDays(date, 13));
  }

  return (
    <div>
      <DateRange
        ranges={[selectionRange]}
        onChange={handleSelect}
        locale={de}
        months={1}
        showDateDisplay={false}
        rangeColors={['#27b0d4', '#27b0d4']}
        color={'#27b0d4'}
        date={new Date()}
        shownDate={new Date()}
        minDate={new Date()}
        maxDate={new Date(licenceEndDate)}
        startDatePlaceholder="Startdatum"
        endDatePlaceholder="Enddatum"
        editableDateInputs={true}
        showPreview={false}
        dateDisplayFormat="dd.MM.yyyy"
        onRangeFocusChange={() => { if(focusedRange[1] === 1) setFocusedRange([0, 0])}}
        focusedRange={focusedRange}
        initialFocusedRange={focusedRange}
        moveRangeOnFirstSelection={true}
      />
      <AutoFormatInput 
        format="##.##.####" 
        type="text" 
        name="startDateInput" 
        userValue={formatDate(startDate)} 
        onValueChange={checkAndSetDate} 
        licenceEndDate={licenceEndDate} 
        licenceStartDate={licenceStartDate} />
    </div>
  );
};

export default FixedDateRangePicker;