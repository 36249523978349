import React from 'react';
import styled from 'styled-components';
import TooltipLargeIcon from '../assets/icons/TooltipLarge.svg';
import media from '../utils/media';

const Tooltip = styled.div`
  background-image: url(${TooltipLargeIcon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
  padding: 1em 1em 1.5em 2em;

  &:after {
    content: '';
    display: block;
    padding-bottom: 50%;
  }

  ${media.md`
    width: 398px;
    height: 200px;
  `};
`;

const TooltipContent = styled.div`
  line-height: 1.21;
  text-align: center;
  color: #fff;
  pointer-events: all;

  span {
    text-shadow: 0 2px 0 #1f92b0;
    font-size: 1.25em;
    font-weight: bold;
    font-style: italic;
    color: #fff;

    ${media.md`
      font-size: 1.75em;
    `};
  }

  a {
    text-decoration: none;
  }
`;

export default ({ children, className }) => (
  <Tooltip className={className}>
    <TooltipContent>{children}</TooltipContent>
  </Tooltip>
);
