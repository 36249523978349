import React from 'react';
import NewsletterForm from '../../../components/Newsletter/NewsletterForm';
import { PageContainer } from '../../../components/StyledElements';
import ConfettiIcon from '../../../assets/icons/Confetti.svg';

const NewsletterTeaser = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${ConfettiIcon})`,
      }}
      className="bg-repeat bg-center overflow-hidden bg-[length:75%]"
    >
      <PageContainer className="bg-blue-light">
        <h2 className="front-page-headline mb-4">Newsletter Anmeldung</h2>
        <div className="text-center text-lg md:text-2xl mb-6">
          Melden Sie sich jetzt für den Newsletter an und erhalten Sie kostenlos viele interessante Informationen rund um das
          Thema digitales Lernen mit Onilo.
        </div>
        <div className="mx-auto max-w-sm">
          <NewsletterForm mode="light" position="standalone" />
        </div>
      </PageContainer>
    </div>
  );
};

export default NewsletterTeaser;
