import React from 'react';
import cc from 'classcat';
import styled from 'styled-components/macro';
import { PageContainer, FrontPageHeadline, BaseButton } from '../../../components/StyledElements';
import Fetcher from '../../../components/Fetcher';
import TopicAreasList from '../../../components/TopicArea/TopicAreasList';
import { wpEndpoints } from '../../../modules/wp';
import media from '../../../utils/media';
import { scrollTo } from '../../../utils/scroll';
import { contentPagesWithOwnSlug } from '../../../config';
import { ReactComponent as DownArrow } from '../../../assets/icons/DownArrow.svg';

const ShowAllWrapper = styled(BaseButton)`
  margin: 2.5rem auto 0;
  display: block;
  text-align: center;

  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &.expanded svg {
    transform: rotate(180deg);
  }

  ${media.md` margin-top: 3rem; `};
  ${media.lg` margin-top: 4.5rem; `};
`;

const ShowAllSubtitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.5;
  margin-top: 1.5rem;

  ${media.md`
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
  `};
`;

const TopicAreasTeaser = ({ settings, headline, maxItemsToRender = 6 }) => {
  const [showAll, setShowAll] = React.useState(false);
  const teaserContainerRef = React.useRef(null);
  const { topicareas_page: page } = settings;

  if (!page.ID) return null;

  const toggleShowAll = () => {
    const nextShowAll = !showAll;

    if (!nextShowAll && teaserContainerRef.current !== null) {
      scrollTo(teaserContainerRef.current);
    }

    setShowAll(nextShowAll);
  };

  return (
    <PageContainer ref={teaserContainerRef}>
      <FrontPageHeadline
        dangerouslySetInnerHTML={{
          __html: settings.headline || headline,
        }}
      />
      <Fetcher wordpress url={`${wpEndpoints.pages}/${page.ID}`}>
        {({ data }) => {
          if (!data || !data.acf || !data.acf.items) {
            return null;
          }

          const { items } = data.acf;
          const baseSlug = contentPagesWithOwnSlug.indexOf(page.post_name) > -1 ? page.post_name : 'post';

          return (
            <>
              <TopicAreasList baseSlug={baseSlug} items={showAll ? items : items.slice(0, maxItemsToRender)} />
              {items.length > maxItemsToRender && (
                <ShowAllWrapper className={cc({ expanded: showAll })} onClick={toggleShowAll}>
                  <DownArrow className="text-blue" height={17} width={36} />
                  <ShowAllSubtitle>{showAll ? 'Weniger' : 'Alle'} anzeigen</ShowAllSubtitle>
                </ShowAllWrapper>
              )}
            </>
          );
        }}
      </Fetcher>
    </PageContainer>
  );
};

export default TopicAreasTeaser;
