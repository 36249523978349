import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  TextBlock,
  H2,
  BaseButton,
  Button,
  RoundInput,
} from '../StyledElements';
import AccessCodeForm from './AccessCodeForm';
import Collapsible from '../Accordion/Collapsible';

const CodeInput = styled(RoundInput)`
  ::placeholder {
    text-align: center;
    font-style: normal;
    color: rgba(39, 176, 212, 0.6);
  }
`;

const FormWrapper = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .input-wrapper {
    flex: 1;
    margin-right: 10px;
  }

  ${Button} {
    min-width: 0;
  }
`;

const InfoButton = styled(BaseButton)`
  font-style: italic;
  color: #f26521;
  text-decoration: underline;
  line-height: 1.63;
`;

const AccessCodeOverlay = (props) => (
  <div>
    <H2>Schüler- & Verleihcode Eingabe:</H2>
    <TextBlock mt={0.5} mb={1.5} lineHeight={1.63}>
      Sie haben einen Schüler- oder Verleihcode? Dann geben Sie ihn hier ein und klicken auf „Code Eingabe“.
    </TextBlock>
    <AccessCodeForm
      onAccessCodeSubmit={props.onAccessCodeSubmit}
      renderForm={({ handleSubmit, handleChange, values, errors, touched }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <CodeInput
              name="accessCode"
              placeholder="Hier Code eingeben"
              onChange={handleChange}
              value={values.accessCode}
              error={touched.accessCode && errors.accessCode}
            />
          </div>
          <Button type="submit">OK</Button>
        </FormWrapper>
      )}
    />
    <Collapsible
      renderTrigger={({ onClick }) => (
        <InfoButton onClick={onClick}>Was ist ein Schülercode?</InfoButton>
      )}
    >
      <TextBlock lineHeight={1.6}>
        Damit die Schüler Boardstories auch im Computerraum, auf Tablets und zu
        Hause lesen können, kann für jede Boardstory ein Schülercode erstellt
        werden. Sie benötigen hierfür eine gültige Lizenz. Das Feld zur
        Erstellung des Schülercodes finden Sie auf der Seite der jeweiligen
        Boardstory.
      </TextBlock>
    </Collapsible>
    <Collapsible
      renderTrigger={({ onClick }) => (
        <InfoButton onClick={onClick}>Was ist ein Verleihcode?</InfoButton>
      )}
    >
      <TextBlock lineHeight={1.6}>
        Der Verleihcode eignet sich für Bibliothekskunden, die eine bestimmte
        Boardstory ausleihen und lesen möchten. Sie benötigen zum Verleihen eine
        gültige Verleihlizenz. Das Feld zur Erstellung des Verleihcodes finden
        Sie auf der Seite der jeweiligen Boardstory.
      </TextBlock>
    </Collapsible>
  </div>
);

AccessCodeOverlay.propTypes = {
  onAccessCodeSubmit: PropTypes.func.isRequired,
};

export default AccessCodeOverlay;
