import React from "react";
import styled from "styled-components";

const TimeWrapper = styled.div`
  padding: 0 5px;
  line-height: 34px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  font-family: inherit;
  text-align: center;
  
  &.duration {
    min-width: 41px;
  }

  &.time-elapsed {
    min-width: 47px;
  }
`;

const formatTime = seconds => {
  const date = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));
  seconds = isNaN(seconds) || seconds > 86400 ? 0 : Math.floor(seconds);
  date.setSeconds(seconds);
  const duration = date
    .toISOString()
    .substr(11, 8)
    .replace(/^0{1,2}:?/, "");
  return duration;
};

export default ({ showTimeElapsed, currentTime, duration, className }) => {
  return (
    <TimeWrapper className={className}>
      {showTimeElapsed
        ? `-${formatTime(duration - currentTime)}`
        : formatTime(currentTime)}
    </TimeWrapper>
  );
};
