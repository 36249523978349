import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { FadeTransition } from '../Transition';
import NewsListItem from '../../pages/News/NewsListItem';
import BoardstoryListItem from '../../pages/Boardstories/BoardstoryListItem';

const SearchResultsList = (props) => {
  if (props.boardstories.length === 0 && props.posts.length === 0) {
    return null;
  }
  // Sort the items by the created date
  const items = [...props.boardstories, ...props.posts].sort((a, b) => {
    const dateA = a.date || a.createdAt;
    const dateB = b.date || b.createdAt;
    return new Date(dateB) - new Date(dateA);
  });

  return (
    <TransitionGroup>
      {items.map((item, index) => (
        <FadeTransition key={item.id || index}>
          {item.type === 'post' ? (
            <NewsListItem item={item} categories={props.postsCategories} />
          ) : (
            <BoardstoryListItem
              boardstory={item}
              teaserImage={props.teaserImages[item.id] || item.image}
            />
          )}
        </FadeTransition>
      ))}
    </TransitionGroup>
  );
};

SearchResultsList.defaultProps = {
  posts: [],
  boardstories: [],
  postsCategories: {},
  teaserImages: {}
};

SearchResultsList.propTypes = {
  posts: PropTypes.array,
  boardstories: PropTypes.array,
  teaserImages: PropTypes.object,
  postsCategories: PropTypes.object
};

export default SearchResultsList;
