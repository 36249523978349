export const exampleFields = [
  {
    name: 'property',
    placeholder: 'Eigenschaft'
  },
  {
    name: 'readingLevel',
    placeholder: 'Lesestufe'
  },
  {
    name: 'subject',
    placeholder: 'Unterrichtsfach'
  }
];

export const exampleOptions = {
  property: [
    {
      value: 'withSubtitles',
      name: 'Mit Text'
    },
    {
      value: 'withoutSubtitles',
      name: 'Ohne Text'
    }
  ],
  readingLevel: [
    {
      value: '1',
      name: '1'
    },
    {
      name: '2',
      value: '2'
    }
  ],
  subject: [
    {
      value: 'maths',
      name: 'Mathe'
    },
    {
      value: 'physics',
      name: 'Physik'
    }
  ]
};
