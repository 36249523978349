import React from 'react';
import { PageContainer, FrontPageHeadline } from '../../../components/StyledElements';
import { Image, getWpImageSrcSet } from '../../../components/Image';

export default ({ items }) => {
  if (!items) return null;

  const { headline, text, image_desktop, image_mobile } = items;

  return (
    <PageContainer>
      <FrontPageHeadline className="m-small">{headline}</FrontPageHeadline>
      <div className="italic text-center text-xl leading-tight mb-12" dangerouslySetInnerHTML={{ __html: text }} />
      <Image className="shadow-img hidden md:block mx-auto rounded" lazy srcSet={getWpImageSrcSet(image_desktop)} />
      <Image className="shadow-img md:hidden mx-auto rounded" lazy srcSet={getWpImageSrcSet(image_mobile)} />
    </PageContainer>
  );
};
