import * as actions from '../actions/options';

const initialState = {
  pageTitle: '',
  accessCodeSectionIsVisible: true
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.PAGE_TITLE_UPDATE:
      return {
        ...state,
        pageTitle: action.pageTitle
      };
    case actions.ACCESS_CODE_SECTION_VISIBILITY_UPDATE:
      return {
        ...state,
        accessCodeSectionIsVisible: action.accessCodeSectionIsVisible
      };
    default:
      return state;
  }
};
