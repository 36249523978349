import React from 'react';

const CROSS = 'X ';
const HYTHEN = '- ';

export default (text, Component) => {
  if (!text || !text.length) return text;

  return text.split('\n').map((item, key) => {
    let className;

    if (item.indexOf(CROSS) === 0) {
      className = 'out';
    } else if (item.indexOf(HYTHEN) === 0) {
      className = 'in';
    }
    return (
      <Component key={key} className={className}>
        {className ? item.substr(2, item.length) : item}
      </Component>
    );
  });
};
