import { getVideoSizeFromContainer } from './gameUtil';

export const getSearchObjectPositionStyle = ({ topLeft, bottomRight }, containerSize, aspectRatio) => {
  const { videoSize, diff } = getVideoSizeFromContainer(containerSize, aspectRatio);
  let top = (topLeft.y / 10000) * videoSize.height + diff.height;
  let left = (topLeft.x / 10000) * videoSize.width + diff.width;
  let height = ((bottomRight.y - topLeft.y) / 10000) * videoSize.height;
  let width = ((bottomRight.x - topLeft.x) / 10000) * videoSize.width;

  const style = {
    top,
    left,
    height,
    width,
  };
  return style;
};

export const SearchGameStatus = {
  Init: 1,
  IntroOverlay: 2,
  GameInProgress: 3,
  SuccessFeedback: 4,
  SuccessOverlay: 5,
  GiveUpOverlay: 6,
  HelpOverlay: 7,
};
