export default (values, options = {}) => {
  let errors = {};

  Object.keys(values).forEach((name) => {
    if (!values[name] || values[name].length === 0) {
      errors[name] = 'Dieses Feld muss ausgefüllt werden';
    }
  });

  if (options.passwordField && !errors[options.passwordField]) {
    if (values[options.passwordField].length < 8) {
      errors[options.passwordField] =
        'Das Passwort muss mindestens 8 Zeichen lang sein';
    }
  }

  if (options.passwordField && options.passwordConfirmationField) {
    const password = values[options.passwordField];
    const passwordConfirmation = values[options.passwordConfirmationField];

    if (password && passwordConfirmation && password !== passwordConfirmation) {
      errors[options.passwordConfirmationField] =
        'Die Passwörter müssen übereinstimmen';
    }
  }

  return errors;
};
