import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  H1,
  H2,
  Separator,
  BoxShadow,
  ExternalButtonLink
} from '../StyledElements';
import media from '../../utils/media';
import { Image } from '../../components/Image';

const BookContainer = styled(BoxShadow)`
  padding: 0 14px 2.5em;
  margin-top: 3em;

  ${media.md`
    padding: 0 28px 27px 0;
    display: flex;

    > div {
      
      &:first-child {
        margin-right: 14.5px;
      }

      &:last-child {
        flex: 1;
        margin-left: 14.5px;
      }
    }
  `};

  ${media.lg`
    padding: 0 48px 42px 0;

    > div {
      &:first-child {
        margin-right: 23.5px;
      }

      &:last-child {
        margin-left: 23.5px;
      }
    }
  `};
`;

const BookCover = styled.div`
  position: relative;
  margin-bottom: -30px;

  ${media.md`
    padding: 0 45px;
  `};

  ${media.lg`
    padding: 0 65px;
    margin-bottom: -45px;
  `};

  img {
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 223px;
    top: -30px;

    ${media.lg`
      top: -45px;
    `};
  }
`;

const SectionHeadline = styled(H1)`
  text-align: center;
  margin: 0 0 2.5rem;

  ${media.md`
    margin-bottom: 3rem; 
  `};

  ${media.lg`
    margin-bottom: 4.5rem; 
  `};
`;

const Description = styled.p`
  line-height: 1.63;
  margin: 0.5rem 0 1.5rem;
`;

const ButtonContainer = styled.div`
  text-align: center;

  ${media.md` text-align: left; `};
`;

const BookContent = styled.div`
  margin-top: 2.5em;

  ${media.md`
    margin-top: 33px;
    margin-bottom: 1em;
  `};

  ${media.lg`
    margin-top: 54px;
    margin-bottom: 11px;
  `};
`;

export default ({ bookReference }) => {
  if (!bookReference) return null;
  const { title, externalLink, description } = bookReference;

  return (
    <Fragment>
      <Separator className="is-wide is-centered" />
      <SectionHeadline>Das Buch zur Boardstory</SectionHeadline>
      <BookContainer>
        {bookReference.path && (
          <BookCover>
            <Image
              src={bookReference.path.medium}
              alt={bookReference.title}
            />
          </BookCover>
        )}
        <BookContent>
          <H2>{title}</H2>
          {description && <Description>{description}</Description>}
          {externalLink && (
            <ButtonContainer>
              <ExternalButtonLink
                className="smaller"
                target="_blank"
                href={externalLink}
              >
                Mehr erfahren
              </ExternalButtonLink>
            </ButtonContainer>
          )}
        </BookContent>
      </BookContainer>
    </Fragment>
  );
};
