import React from 'react';
import styled from 'styled-components/macro';
import { PageContainer, ButtonLink } from '../../../components/StyledElements';
import SignupCTA from '../../../components/SignupCTA';
import media from '../../../utils/media';
import { Image, getWpImageProps } from '../../../components/Image';

const PanelContent = styled.div`
  text-align: center;

  p {
    line-height: 1.6;
  }

  ul li {
    line-height: 1.9;
    font-size: 1.125em;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: calc(100% + 20px);
  border-radius: 4px;
  height: 195px;
  margin: -10px -10px 20px;
  border-radius: 4px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 24px;

  ${media.md`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}
`;

const GridItem = styled.div`
  background: #fff;
  box-shadow: 0px 8px 16px rgba(0, 98, 124, 0.1);
  border-radius: 4px;
  padding: 10px;
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`;

const TargetGroups = ({ headline, text, items, signupButton }) => {
  const renderContent = (item) => {
    return (
      <>
        {item.image && (
          <ImageContainer>
            <Image {...getWpImageProps(item.image)} />
          </ImageContainer>
        )}
        <PanelContent>
          {item.title && <h2>{item.title}</h2>}
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
          {item.button && item.button.link && (
            <ButtonLink mt={1} className="fluid" to={item.button.link}>
              {item.button.text || 'Mehr erfahren'}
            </ButtonLink>
          )}
        </PanelContent>
      </>
    );
  };

  if (!items || items.length === 0) return null;

  return (
    <>
      <PageContainer>
        <h2 className="front-page-headline mb-4">{headline}</h2>
        <div className="italic text-center text-xl leading-tight" dangerouslySetInnerHTML={{ __html: text }} />
        <Grid>
          {items.map((item, key) => (
            <GridItem key={key}>{renderContent(item)}</GridItem>
          ))}
        </Grid>

        {signupButton?.url && (
          <div className="text-center mt-12 mb-8">
            <SignupCTA button={signupButton} />
          </div>
        )}
      </PageContainer>
    </>
  );
};

export default TargetGroups;
