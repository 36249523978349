import React from 'react';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import PageTitle from '../../../components/PageTitle';
import { PageContainer, TextBlock } from '../../../components/StyledElements';
import LoginView from './LoginView';

const Login = (props) => {
  const lastLocation = useLastLocation();
  const pathname = lastLocation?.pathname || '/';
  const { from } = props.location.state || { from: { pathname } };

  return (
    <PageContainer narrow>
      <PageTitle>Anmelden</PageTitle>
      <LoginView redirectTo={from ? from.pathname : '/'} />
      <TextBlock fontSize={1.5} italic centered mt={4.5}>
        Sie möchten Onilo nutzen? Dann entscheiden Sie sich jetzt für eine Lizenz. <Link to="/lizenzen">Zu den Lizenzen</Link>
      </TextBlock>
    </PageContainer>
  );
};

export default Login;
