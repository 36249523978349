import * as actions from '../actions/order';

const initialState = {
  formValues: {},
  license: {},
  promoCode: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.ADD_FORM_VALUES:
      return {
        ...state,
        formValues: action.formValues
      };
    case actions.RESET_FORM_VALUES:
      return {
        ...state,
        formValues: initialState.formValues
      };
    case actions.CREATE_ORDER:
      return {
        ...state,
        license: action.license
      };
    case actions.UPDATE_PROMOCODE:
      return {
        ...state,
        promoCode: action.promoCode
      };
    case actions.RESET_ORDER:
      return initialState;
    default:
      return state;
  }
};
