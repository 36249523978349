import isEmpty from 'lodash/isEmpty';
import * as actions from '../actions/user';

const initialState = {
  account: {},
  currentOrder: {},
  license: {},
  accessCode: {},
  organization: {},
  bookmarks: [],
  errors: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.ACCOUNT_FETCH_SUCCESS:
      const { bookmarks = [], ...restAccountData } = action.account || {};

      return {
        ...state,
        account: restAccountData,
        bookmarks
      };
    case actions.CURRENT_ORDER_FETCH_SUCCESS:
      return {
        ...state,
        currentOrder: action.currentOrder,
        license: isEmpty(action.currentOrder)
          ? initialState.license
          : state.license
      };
    case actions.LICENSE_FETCH_SUCCESS:
      return {
        ...state,
        license: action.license
      };
    case actions.ORGANIZATION_FETCH_SUCCESS:
      return {
        ...state,
        organization: action.organization
      };
    case actions.ACCESS_CODE_UPDATE:
      return {
        ...state,
        accessCode: action.accessCode || initialState.accessCode
      };
    case actions.ADD_BOARDSTORY_BOOKMARK:
      return {
        ...state,
        bookmarks: [...state.bookmarks, action.boardstoryId]
      };
    case actions.DELETE_BOARDSTORY_BOOKMARK:
      return {
        ...state,
        bookmarks: state.bookmarks.filter((b) => b !== action.boardstoryId)
      };
    default:
      return state;
  }
};
