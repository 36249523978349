import React from 'react';
import { PageContainer } from '../../../components/StyledElements';

export default ({ items }) => {
  if (!items) return null;

  const { headline, text } = items;

  return (
    <PageContainer>
      <h2 className="front-page-headline mb-4">{headline}</h2>
      <div className="italic text-xl leading-tight" dangerouslySetInnerHTML={{ __html: text }} />
    </PageContainer>
  );
};
