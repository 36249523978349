import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { apiEndpoints, apiFetch } from '../modules/api';
import { SmallRoundInput, Button } from './StyledElements';
import { updatePromoCode } from '../actions/order';
import media from '../utils/media';
import validate from '../modules/validate';

const InlineFormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
    margin-bottom: 1rem;
  }

  ${media.sm`
    flex-wrap: nowrap;
    width: auto;

    > div {
      margin-right: 30px;
      margin-bottom: 0;
    }
  `};
`;

const PromoCodeForm = (props) => {
  const onSubmit = ({ promoCode }, actions) => {
    apiFetch
      .url(`${apiEndpoints.promoCodes}/${encodeURI(promoCode)}/check`)
      .query({ licenseId: props.licenseId })
      .get()
      .json((response) => {
        actions.setSubmitting(false);
        if (response.isValid) {
          actions.resetForm();
          props.updatePromoCode({
            code: promoCode,
            discountPercentage: response.discountPercent,
          });
        } else if (response.errors.length > 0) {
          let errorMessage = 'Der eingegebene Aktionscode ist nicht mehr gültig';
          if (response.errors[0].indexOf('Inapplicable') > -1) {
            errorMessage = 'Der eingegebene Aktionscode kann für die ausgewählte Lizenz nicht angewendet werden';
          }
          actions.setErrors({ promoCode: errorMessage });
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        actions.setErrors({
          promoCode: 'Der eingegebene Aktionscode ist nicht mehr gültig oder uns nicht bekannt',
        });
      });
  };

  if (!props.licenseId) return null;

  return (
    <Formik
      initialValues={{
        promoCode: '',
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ handleChange, values, errors, touched }) => (
        <Form>
          <InlineFormGroup>
            <SmallRoundInput
              name="promoCode"
              onChange={handleChange}
              value={values.promoCode}
              placeholder="Gutscheincode einlösen"
              error={touched.promoCode && errors.promoCode}
            />
            <Button type="submit" style={{ minWidth: 132 }} className="smaller with-shadow blue">
              Einlösen
            </Button>
          </InlineFormGroup>
        </Form>
      )}
    </Formik>
  );
};

PromoCodeForm.propTypes = {
  updatePromoCode: PropTypes.func,
};

const select = (state) => ({
  licenseId: state.order.license.id,
});

export default connect(select, { updatePromoCode })(PromoCodeForm);
