import React from 'react';
import styled from 'styled-components';

const PriceWrapper = styled.div`
  line-height: 1;
  text-align: center;

  .amount {
    font-size: 4em;
    font-weight: bold;
  }

  .currency {
    font-size: 2em;
  }
`;

export default ({ price, className }) => {
  if (price === 0) {
    return (
      <PriceWrapper className={className}>
        <span className="amount">gratis</span>
      </PriceWrapper>
    );
  }

  return (
    <PriceWrapper className={className}>
      <span className="amount">{(price * 1.19).toFixed()}</span>
      <span className="currency">€*</span>
    </PriceWrapper>
  );
};
