import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { FadeTransition } from '../../components/Transition';
import NewsListItem from './NewsListItem';

const NewsList = (props) => {
  if (!props.items || props.items.length === 0) {
    return null;
  }

  return (
    <TransitionGroup>
      {props.items.map((item, index) => (
        <FadeTransition key={item.id || index}>
          <NewsListItem item={item} categories={props.categories} />
        </FadeTransition>
      ))}
    </TransitionGroup>
  );
};

NewsList.defaultProps = {
  categories: {}
};

NewsList.propTypes = {
  categories: PropTypes.object,
  items: PropTypes.array
};

export default NewsList;
