import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  auth,
  redirectTo,
  extraProps = {},
  ...rest
}) => (
  <Route
    {...rest}
    render={(routeProps) =>
      auth.isAuthenticated ? (
        <Component {...routeProps} {...extraProps} />
      ) : (
        <Redirect
          to={{
            pathname: redirectTo || '/login',
            state: { from: routeProps.location }
          }}
        />
      )
    }
  />
);

const select = (state) => ({
  auth: state.auth
});

export default connect(select)(PrivateRoute);
