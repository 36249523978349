import styled from 'styled-components';
import { InlineButton, BaseRoundInput } from '../StyledElements';
import media from '../../utils/media';

export const TeaserContainer = styled.div`
  border-radius: 4px;
  background-color: #21a1c3;
  padding: 1.5rem 1.25rem 2rem;

  ${media.md`
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  `}
  
  ${media.lg`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  `};

  p {
    margin: 0;
    font-size: 1.125rem;
    ${media.md` font-size: 1rem; `};
  }

  &.light-mode { 
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const TeaserHeadline = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.25;

  ${media.md`
    font-size: 1rem;
    line-height: 1.5;
  `};
`;

export const DarkRoundInput = styled(BaseRoundInput)`
  border-radius: 24px;
  height: 48px;
  background-color: #3cbee0;
  border: solid 1px #21a1c3;
  color: #fff;
  padding: 10px 20px 8px;
  font-size: 1.25rem;

  ::placeholder {
    color: inherit;
    opacity: 0.6;
  }
`;

export const SmallOrangeButton = styled(InlineButton)`
  background-color: #f6793d;
  color: #fff;
  border-color: #f6793d;
  padding-top: 6px;
  padding-bottom: 4px;
`;