import { Component } from 'react';
import pickBy from 'lodash/pickBy';

import { wpFetch, wpEndpoints } from '../../modules/wp';
import {
  PageContainer,
  Spacing,
  TextBlock
} from '../../components/StyledElements';
import PageTitle from '../../components/PageTitle';
import Pagination from '../../components/Pagination';
import NewsListItem from '../News/NewsListItem';
import styled from 'styled-components/macro';
import media from '../../utils/media';
import withQueryString from '../../modules/withQueryString';

const NewsGrid = styled.div`
  display: grid;
  gap: 12px;
  grid-auto-rows: 1fr;

  ${media.md`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`;

class Blog extends Component {
  static defaultProps = {
    queryParams: {}
  };

  state = {
    error: undefined,
    news: [],
    suggestions: [],
    categories: {},
    loadingSuggestions: false,
    pagesCount: 0
  };

  componentDidMount() {
    this.fetchNews();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.fetchNews();
    }
  }

  getFetchQuery() {
    const { queryParams } = this.props;
    let fetchQuery = {
      page: queryParams.p || 1,
      search: queryParams.q,
      categories_in: ['blog']
    };

    // Filter by post_date year
    if (queryParams.year && !isNaN(queryParams.year)) {
      const year = parseInt(queryParams.year, 10);

      fetchQuery = {
        ...fetchQuery,
        before: `${year + 1}-01-01T00:00:00`,
        after: `${year}-01-01T00:00:00`,
        date_query_column: 'post_date'
      };
    }

    // Filter by category
    if (queryParams.category) {
      fetchQuery = {
        ...fetchQuery,
        categories_in: queryParams.category
      };
    }

    return pickBy(fetchQuery);
  }

  fetchNews() {
    const query = this.getFetchQuery();

    this.setState({ error: undefined });

    wpFetch
      .url(wpEndpoints.posts)
      .query({ per_page: 9, page: this.props.queryParams.p, ...query })
      .get()
      .res((response) => {
        const pagesCount = Number(response.headers.get('X-WP-TotalPages'));
        this.setState({ pagesCount });

        return response.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          this.setState({ news: data, error: undefined });
        } else {
          this.setState({ error: 'Keine Beiträge gefunden' });
        }
      })
      .catch(({ json: error }) => {
        if (error && error.message) {
          this.setState({ error: error.message });
        }
      });
  }

  render() {
    return (
      <PageContainer className="with-placeholder">
        <PageTitle headTitle="Themen">Blog</PageTitle>
        {this.state.error ? (
          <TextBlock mt={3} mb={3} centered fontSize={1.5}>
            {this.state.error}
          </TextBlock>
        ) : (
          <NewsGrid>
            {this.state.news.map((post) => (
              <NewsListItem vertical key={post.ID} item={post} />
            ))}
          </NewsGrid>
        )}
        {this.state.pagesCount > 1 && (
          <Spacing mt={4.5}>
            <Pagination
              currentPage={parseInt(this.props.queryParams.p, 10) || 1}
              pagesCount={this.state.pagesCount}
            />
          </Spacing>
        )}
      </PageContainer>
    );
  }
}

export default withQueryString(Blog);
