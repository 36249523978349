import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import InteractiveHeadline from './InteractiveHeadline';
import FormFields from './FormFields';
import { InfoHeadline, TextBlock, FormSectionHeadline } from './StyledElements';
import PrettyFormSection from './PrettyFormSection';
import { openModal } from './Modal/modalActions';

class FormSection extends Component {
  static defaultProps = {
    isInteractive: false,
    renderPrettyFields: true,
    defaultText: 'Keine Daten angegeben.'
  };

  state = {
    isActive: false,
    renderHiddenFields: false
  };

  componentWillMount() {
    const {
      groupName,
      emptyValues,
      initialValues,
      isActiveInitial
    } = this.props;

    if (typeof isActiveInitial === 'boolean') {
      return this.setState({ isActive: isActiveInitial });
    }

    // Show the section fields if the initial values aren't empty
    if (
      emptyValues &&
      !isEqual(emptyValues[groupName], initialValues[groupName])
    ) {
      this.setState({ isActive: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupName } = this.props;
    // Expand current section if there're new errors in any of its fields
    if (
      prevProps.errors[groupName] !== this.props.errors[groupName] &&
      !isEmpty(this.props.errors[groupName]) &&
      !this.state.isActive
    ) {
      this.setState({ isActive: true });
    }
  }

  toggleSection = () => {
    const nextIsActive = !this.state.isActive;
    const { groupName, values, initialValues } = this.props;

    if (
      !nextIsActive &&
      !isEqual(values[groupName], initialValues[groupName])
    ) {
      return this.props.openModal({
        buttonValue: 'Fortfahren',
        renderCloseButton: false,
        onConfirm: this.onWarningClick,
        content:
          'Ihre Eingaben sind noch nicht gesichert. Wenn Sie fortfahren gehen alle Änderungen verloren.'
      });
    }

    this.setState({ isActive: nextIsActive });
  };

  resetSectionValues() {
    const { groupName } = this.props;

    const newValues = {
      ...this.props.values,
      [groupName]: this.props.initialValues[groupName]
    };

    this.props.setValues(newValues);
    this.props.onReset && this.props.onReset(newValues);
  }

  displayHiddenFields = () => {
    if (this.state.renderHiddenFields) return;

    this.setState({ renderHiddenFields: true });
  };

  onWarningClick = () => {
    this.resetSectionValues();

    this.setState({
      isActive: false
    });
  };

  renderPrettySection() {
    const { defaultText, renderPrettyFields } = this.props;

    if (renderPrettyFields) {
      return (
        <PrettyFormSection
          values={this.props.values[this.props.groupName]}
          defaultText={defaultText}
          onButtonClick={this.toggleSection}
        />
      );
    }

    return <InfoHeadline>{defaultText}</InfoHeadline>;
  }

  renderHeader() {
    const { headlineValue, isInteractive } = this.props;
    if (!headlineValue) return null;

    if (isInteractive) {
      return (
        <InteractiveHeadline
          headlineValue={headlineValue}
          buttonValue={this.state.isActive ? 'Abbrechen' : 'Bearbeiten'}
          onClick={this.toggleSection}
        />
      );
    }

    return <FormSectionHeadline>{headlineValue}</FormSectionHeadline>;
  }

  render() {
    return (
      <Fragment>
        {this.renderHeader()}
        {this.state.isActive || !this.props.isInteractive ? (
          <Fragment>
            <FormFields
              renderHiddenFields={this.state.renderHiddenFields}
              onHiddenFieldsRequest={this.displayHiddenFields}
              {...this.props}
            />
            <TextBlock mt={1} fontSize={0.875}>
              * Pflichtfelder
            </TextBlock>
          </Fragment>
        ) : (
          this.renderPrettySection()
        )}
      </Fragment>
    );
  }
}

export default connect(null, { openModal })(FormSection);
