import Modal from 'components/Modal/Modal';
import usePreventRouteChange from 'modules/usePreventRouteChange';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { updateAccessCode } from '../../actions/user';
import BoardstoryLoader from '../../components/Boardstory/BoardstoryLoader';
import BoardstoryBook from '../../components/Boardstory/BookReference';
import SimilarBoardstories from '../../components/Boardstory/SimilarBoardstories';
import Placeholder from '../../components/Placeholder';
import {
  Button,
  FlexColumn,
  FlexContainer,
  Headline,
  MarkdownWrapper,
  PageContainer,
  PageHeadline,
} from '../../components/StyledElements';
import { apiEndpoints, apiFetch } from '../../modules/api';
import { afterStartingDate, hasDateNotExpired } from '../../utils/helpers';
import NotFound from '../ErrorPage/NotFound';
import ServerError from '../ErrorPage/ServerError';
import BoardstorySingleItem from './BoardstorySingleItem';

const hostUrl = window.location.protocol + '//' + window.location.hostname;

const BoardstorySingle = ({ match, location }) => {
  const { idOrSlug: boardstoryIdOrSlug, accessCode: accessCodeParam } = match.params;
  const [isExternalUser, setIsExternalUser] = useState(false);

  const { wasBlocked, setWasBlocked } = usePreventRouteChange(isExternalUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessCodeParam) {
      const fetchAccessCode = async () => {
        try {
          const response = await apiFetch.url(`${apiEndpoints.accessCodes.root}/${accessCodeParam}`).get();
          const data = await response.json();
          if (data && hasDateNotExpired(data.expirationDate) && afterStartingDate(data.startingDate)) {
            dispatch(updateAccessCode(data));
            setIsExternalUser(data.isExternalUser);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchAccessCode();
    }
  }, [accessCodeParam, dispatch]);

  if (!boardstoryIdOrSlug) {
    return <Redirect to="/boardstories" />;
  }

  return (
    <BoardstoryLoader boardstoryIdOrSlug={boardstoryIdOrSlug}>
      {({ boardstory, attachments, teaserImage, bookReference, similarBoardstories, similarBsTeaser, error, isLoading }) => {
        if (error) {
          return error === 404 ? <NotFound /> : <ServerError />;
        }

        if (isLoading) {
          return <Placeholder loading={isLoading} />;
        } else if (!boardstory) {
          return null;
        } else if (boardstory.id === boardstoryIdOrSlug) {
          // Don't render boardstories by id
          return <NotFound />;
        }

        return (
          <PageContainer>
            <Helmet>
              <title>{boardstory.title}</title>
              {boardstory.description && <meta name="description" content={boardstory.description} />}
              <link rel="canonical" href={`${hostUrl}/boardstory/${boardstory.slug}`} />
            </Helmet>
            <PageHeadline>{boardstory.title}</PageHeadline>
            <BoardstorySingleItem
              teaserImage={teaserImage}
              attachments={attachments}
              boardstory={boardstory}
              accessCodeParam={accessCodeParam}
            />
            <FlexContainer>
              {boardstory.description && (
                <FlexColumn>
                  <Headline>Inhalt</Headline>
                  <MarkdownWrapper lh={1.63}>
                    <ReactMarkdown>{boardstory.description}</ReactMarkdown>
                  </MarkdownWrapper>
                </FlexColumn>
              )}
            </FlexContainer>
            <BoardstoryBook bookReference={bookReference} />
            <SimilarBoardstories similarBoardstories={similarBoardstories} similarBsTeaser={similarBsTeaser} />
            <Modal isOpen={wasBlocked} onRequestClose={() => setWasBlocked(false)}>
              <h1>Wichtig:</h1>
              <p>Bitte bleibt für eure schulischen Aufgaben auf dieser Seite.</p>
              <Button onClick={() => setWasBlocked(false)}>Ok</Button>
            </Modal>
          </PageContainer>
        );
      }}
    </BoardstoryLoader>
  );
};

export default BoardstorySingle;