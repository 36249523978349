/* Forked from: https://github.com/glennflanagan/react-collapsible */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const renderTriggerElement = ({ isOpen, onClick, trigger, triggerWhenOpen }) => {
  return (
    <div className="collapsible-trigger" onClick={onClick}>
      {isOpen && triggerWhenOpen ? triggerWhenOpen : trigger}
    </div>
  );
};

class Collapsible extends Component {
  constructor(props) {
    super(props);

    // Defaults the dropdown to be closed
    if (props.open) {
      this.state = {
        isClosed: false,
        shouldSwitchAutoOnNextCycle: false,
        height: 'auto',
        hasBeenOpened: true,
        overflow: props.overflowWhenOpen,
        inTransition: false,
      };
    } else {
      this.state = {
        isClosed: true,
        shouldSwitchAutoOnNextCycle: false,
        height: 0,
        hasBeenOpened: false,
        overflow: 'hidden',
        inTransition: false,
      };
    }

    this.innerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shouldOpenOnNextCycle) {
      this.continueOpenCollapsible();
    }

    if (prevState.height === 'auto' && this.state.shouldSwitchAutoOnNextCycle === true) {
      window.setTimeout(() => {
        // Set small timeout to ensure a true re-render
        this.setState({
          height: 0,
          overflow: 'hidden',
          isClosed: true,
          shouldSwitchAutoOnNextCycle: false,
        });
      }, 50);
    }

    // If there has been a change in the open prop (controlled by accordion)
    if (prevProps.open !== this.props.open) {
      if (this.props.open === true) {
        this.openCollapsible();
      } else {
        this.closeCollapsible();
      }
    }
  }

  closeCollapsible() {
    const height = this.innerRef.current?.offsetHeight;

    this.setState({
      shouldSwitchAutoOnNextCycle: true,
      height,
      inTransition: true,
    });
  }

  openCollapsible() {
    this.setState({
      inTransition: true,
      shouldOpenOnNextCycle: true,
    });
  }

  continueOpenCollapsible() {
    const height = this.innerRef.current?.offsetHeight;

    this.setState({
      height,
      isClosed: false,
      hasBeenOpened: true,
      inTransition: true,
      shouldOpenOnNextCycle: false,
    });
  }

  handleTriggerClick = (event) => {
    event.preventDefault();

    if (this.props.triggerDisabled) {
      return;
    }

    if (this.props.handleTriggerClick) {
      this.props.handleTriggerClick(this.props.accordionPosition);
    } else {
      if (this.state.isClosed === true) {
        this.openCollapsible();
      } else {
        this.closeCollapsible();
      }
    }
  };

  handleTransitionEnd = () => {
    // Switch to height auto to make the container responsive
    if (!this.state.isClosed) {
      this.setState({
        height: 'auto',
        overflow: this.props.overflowWhenOpen,
        inTransition: false,
      });
      this.props.onOpen();
    } else {
      this.setState({ inTransition: false });
      this.props.onClose();
    }
  };

  render() {
    const transition = `height ${this.props.transitionTime}ms ${this.props.easing}`;

    const dropdownStyle = {
      height: this.state.height,
      WebkitTransition: transition,
      msTransition: transition,
      transition,
      overflow: this.state.overflow,
    };

    const triggerProps = {
      trigger: this.props.trigger,
      triggerWhenOpen: this.props.triggerWhenOpen,
      isOpen: !this.state.isClosed,
      onClick: this.handleTriggerClick,
    };

    // Don't render children until the first opening of the Collapsible if lazy rendering is enabled
    const children =
      this.props.lazyRender && !this.state.hasBeenOpened && this.state.isClosed && !this.state.inTransition
        ? null
        : this.props.children;

    const renderTriggerFunc = this.props.renderTrigger || renderTriggerElement;

    return (
      <div className={this.props.className}>
        {renderTriggerFunc(triggerProps)}
        <div style={dropdownStyle} className="collapsible-outer" onTransitionEnd={this.handleTransitionEnd}>
          <div className="collapsible-inner" ref={this.innerRef}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

Collapsible.propTypes = {
  transitionTime: PropTypes.number,
  easing: PropTypes.string,
  open: PropTypes.bool,
  accordionPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleTriggerClick: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  triggerWhenOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  triggerDisabled: PropTypes.bool,
  renderTrigger: PropTypes.func,
  lazyRender: PropTypes.bool,
  overflowWhenOpen: PropTypes.oneOf(['hidden', 'visible', 'auto', 'scroll', 'inherit', 'initial', 'unset']),
};

Collapsible.defaultProps = {
  transitionTime: 300,
  easing: 'linear',
  open: false,
  lazyRender: false,
  overflowWhenOpen: 'hidden',
  onOpen: () => {},
  onClose: () => {},
};

export default Collapsible;
