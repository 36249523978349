import React from 'react';
import { PageContainer, TextBlock, RentalSectionHeadline } from '../../StyledElements';
import RentalsCounter from './RentalsCounter';
import UserList from '../UserList';
import { organizationUserRoles } from 'config';
import { Helmet } from 'react-helmet-async';

const RentalUserManagement = () => {
  return (
    <>
      <Helmet>
        <title>Verleihzugänge- und Codes</title>
      </Helmet>
      <PageContainer narrow mt={1}>
        <RentalsCounter />
        <RentalSectionHeadline>Verwaltung der Verleihzugänge</RentalSectionHeadline>
        <TextBlock centered mb={6} fontSize={1.25} lineHeight={1.5}>
          Ihre Lizenz ermöglicht es Ihnen, Kunden einzuladen, die ebenfalls Onilo nutzen können. Tragen Sie hierfür die
          E-Mail-Adresse der Kunden ein und diese werden per E-Mail informiert.
        </TextBlock>
        <UserList relevantUserRole={organizationUserRoles.RENTAL_USER} />
      </PageContainer>
    </>
  );
};

export default RentalUserManagement;
