import { combineReducers } from 'redux';
import { LOGOUT } from '../actions/auth';
import auth from './auth';
import images from './images';
import tags from './tags';
import boardstories from './boardstories';
import order from './order';
import user from './user';
import options from './options';
import preferences from './preferences';
import modalReducer from '../components/Modal/modalReducer';

const appReducer = combineReducers({
  auth,
  images,
  boardstories,
  order,
  user,
  options,
  modal: modalReducer,
  preferences,
  tags
});

const rootReducer = (state, action) => {
  // Reset user state on logout
  if (action.type === LOGOUT) {
    state = { preferences: state.preferences, options: state.options };
  }

  return appReducer(state, action);
};

export default rootReducer;
