import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import vatRates from '../../data/vat-rates';
import { formatAmount } from '../../utils/helpers';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;

  &.is-last {
    font-weight: bold;
    padding-bottom: 0;
    padding-top: 1em;
    border-top: solid 2px rgba(39, 176, 212, 0.5);
  }
`;

const Col = styled.div`
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.25;

  &:last-of-type {
    text-align: right;
  }
`;

const getTaxRate = (country) => {
  if (!country) return 0;
  return vatRates[country] || 0;
};

const OrderTotal = ({ price, country, promoCode }) => {
  if (!price) return null;

  const taxRate = getTaxRate(country);
  const { discountPercentage = 0 } = promoCode;
  const discountValue = (price * discountPercentage) / 100;
  const tax = (price - discountValue) * (taxRate / 100);

  return (
    <div>
      <Row>
        <Col>Preis (netto)</Col>
        <Col>{formatAmount(price)} €</Col>
      </Row>
      {promoCode.code && (
        <Row>
          <Col>Gutscheincode {promoCode.code}</Col>
          <Col>-{formatAmount(discountValue)} €</Col>
        </Row>
      )}
      <Row>
        <Col>{taxRate}% Mehrwertsteuer</Col>
        <Col>{formatAmount(tax)} €</Col>
      </Row>
      <Row className="is-last">
        <Col>SUMME</Col>
        <Col>{formatAmount(price + tax - discountValue)} €</Col>
      </Row>
    </div>
  );
};

OrderTotal.defaultProps = {
  promoCode: {}
};

OrderTotal.propTypes = {
  promoCode: PropTypes.object,
  price: PropTypes.number,
  country: PropTypes.string
};

const select = (state) => ({
  promoCode: state.order.promoCode
});

export default connect(select)(OrderTotal);
