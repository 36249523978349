import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const YoastHead = ({ meta, titleTemplate }) => {
  return (
    <Helmet titleTemplate={titleTemplate}>
      {meta.yoast_wpseo_title && <title>{meta.yoast_wpseo_title}</title>}
      {meta.yoast_wpseo_metadesc && <meta name="description" content={meta.yoast_wpseo_metadesc} />}
    </Helmet>
  );
};

YoastHead.defaultProps = {
  meta: {},
  titleTemplate: '%s | ONILO.DE',
};

YoastHead.propTypes = {
  meta: PropTypes.object,
};

export default YoastHead;
