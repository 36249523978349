import styled from 'styled-components';
import media from '../../utils/media';
import {
  BackgroundImage,
  BoxShadow,
  PageContainer
} from '../../components/StyledElements';
import BaseLicenseMeta from '../../components/License/LicenseMeta';
import BaseLicensePrice from '../../components/License/LicensePrice';
import BaseLicenseDescription from '../../components/License/LicenseDescription';

export const CustomPageContainer = styled(PageContainer)`
  ${media.md` margin-top: 4.5rem; `};
  ${media.lg` margin-top: 6rem; `};
`;

export const LicenseName = styled.h2`
  font-weight: 400;
  font-style: italic;
  font-size: 1.25em;
  margin: 0;

  ${media.md` font-size: 1.5em; `};

  ${media.lg` margin: 1rem 0; `};

  &::after {
    content: '';
    max-width: 80px;
    width: 100%;
    display: none;
    height: 2px;
    border-radius: 2px;
    background-color: #27b0d4;
    margin: 1rem auto 0;

    ${media.lg` display: block; `};
  }
`;

export const LicenseMeta = styled(BaseLicenseMeta)`
  display: none;

  ${media.lg`
    display: block;
    text-align: center;
    margin-bottom: 1em;
  `};

  &.inline {
    display: block;

    ${media.lg` display: none; `};
  }
`;

export const MoreInfoLinkContainer = styled.div`
  font-size: 15px;
`;

export const CardGridWrapper = styled(BackgroundImage)`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  ${media.md` margin-top: 4.5rem; `};
  ${media.lg` margin-top: 6rem; `};
`;

export const CardMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.125em;

  ${media.md` padding: 1.5em; `};

  > div:not(:last-child) {
    ${media.md` flex: 1; `} ${media.lg` flex-basis: 100%; `};
  }
`;

export const LicenseDescription = styled(BaseLicenseDescription)`
  background-color: #ebf7fa;
  padding: 0 5%;
  font-size: 0.875rem;

  > li {
    display: inline-block;
    margin-right: 1rem;
  }

  ${media.md` padding: 0 10%; `};

  ${media.lg`
    margin: 4.5em auto 0;
    width: 100%;
    max-width: 200px;
    padding: 0;
    background-color: transparent;

    > li {
      display: block;
      margin-right: 0;
    }
  `};
`;

export const CardContent = styled(BoxShadow)`
  transition-property: background-color, transform, color;
  transition-duration: 0.3s;
  cursor: pointer;
  overflow: auto;

  ${media.lg`
    margin: 0;
    text-align: center;
  `};

  &:hover,
  &.is-selected {
    background-color: #27b0d4;
    color: #fff;

    ${LicenseName}::after {
      background-color: #fff;
    }

    ${MoreInfoLinkContainer} a {
      color: #fff;
    }

    ${media.lg` transform: scale(1.05); `};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.5em;
  text-align: center;
  flex-basis: 100%;

  ${media.md`
    flex: 0 auto;
    margin-top: 0;
    margin-left: 1.25em;
  `};

  ${media.lg`
    margin-top: 1.375em;
    flex-basis: 100%;
    margin-left: 0;
  `};
`;

export const LicensePrice = styled(BaseLicensePrice)`
  text-align: right;
  flex: 1;

  ${media.lg`
    text-align: center;
    margin: 3.5em 0 1em;
  `};

  .amount {
    font-size: 3em;

    ${media.sm` font-size: 3.375em; `};
  }
`;
