import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { PageContainer } from '../../components/StyledElements';
import { createOrder } from '../../actions/order';
import { getRolesAsBoolean, getMemberFromOrg } from '../../utils/userRoleHelpers';
import { RentalUserVariant, GenericVariant } from './AccountLicenseVariants';
import isEmpty from 'lodash/isEmpty';
import { NavLink } from 'react-router-dom';

const AccountLicense = ({ currentOrder, organization, user, license }) => {
  const relevantMember = getMemberFromOrg(organization, user);
  const { isOwner, isRentalUser, isGenericUser, isExternalUser } = getRolesAsBoolean(relevantMember);
  const hasOrder = !isEmpty(currentOrder);
  const displayGenericVariant = hasOrder && (isGenericUser || isOwner);

  return (
    <>
      <Helmet>
        <title>Meine Lizenz</title>
      </Helmet>
      <PageContainer narrow>
        {displayGenericVariant && (
          <GenericVariant currentOrder={currentOrder} isGenericUser={isGenericUser} isOwner={isOwner} license={license} />
        )}
        {isRentalUser && <RentalUserVariant relevantMember={relevantMember} />}
        {isExternalUser && (
          <h2 className="mt-10 text-center">
            <NavLink to="/lizenzen">Übersicht aller Lizenzen</NavLink>
          </h2>
        )}
      </PageContainer>
    </>
  );
};

AccountLicense.propTypes = {
  user: PropTypes.object,
  currentOrder: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
  createOrder: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default connect(null, { createOrder })(AccountLicense);
