import React from 'react';
import styled from 'styled-components';
import { BoxShadow, Headline, Grid, Column, FakeLink, BlockLink } from '../../components/StyledElements';
import media from '../../utils/media';
import { parseHtmlEntities } from '../../utils/htmlEntities';
import { getPostItemBaseSlug } from '../../utils/wpHelpers';
import Fetcher from '../../components/Fetcher';
import { wpEndpoints } from '../../modules/wp';
import { Image, imageSizesByColumn } from '../../components/Image';
import Placeholder from '../../components/Placeholder';
import { contentPagesWithOwnSlug } from '../../config';

const TeaserContainer = styled(BoxShadow)`
  padding: 20px;
  font-size: 1.125rem;
  line-height: 1.44;

  ${media.md`
    padding: 40px;
  `};

  ${media.lg`
    font-size: 1rem;
    line-height: 1.63;
  `};

  img {
    display: block;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TeaserGrid = styled(Grid)`
  flex-direction: column-reverse;

  ${media.md`
    flex-direction: row;
  `};
`;

const NewsPostTeaser = () => {
  const renderTeaserContent = (data) => {
    if (!data) return null;

    const { featured_image_src: thumbnailSrc = {} } = data;

    return (
      <TeaserGrid className="tablet desktop">
        <Column>
          <Headline>{parseHtmlEntities(data.title.rendered)}</Headline>
          <div>{data?.acf?.short_text}</div>
          <div>
            <FakeLink>Mehr erfahren</FakeLink>
          </div>
        </Column>
        {thumbnailSrc.srcset && (
          <Column>
            <Image
              src={thumbnailSrc.medium[0]}
              srcSet={thumbnailSrc.srcset}
              alt={thumbnailSrc.alt}
              sizes={imageSizesByColumn[2]}
            />
          </Column>
        )}
      </TeaserGrid>
    );
  };

  return (
    <React.Fragment>
      <Fetcher wordpress url={wpEndpoints.acfOptions}>
        {({ data, fetching }) => {
          const pinnedPost = data?.acf?.news_teaser?.pinned_post;
          if (!pinnedPost) return <></>;

          return (
            <Fetcher wordpress url={`${wpEndpoints.posts}/${pinnedPost}`}>
              {({ data, fetching }) => {
                const postUrl = data ? `/${getPostItemBaseSlug(data)}/${data.slug}` : '';

                return (
                  <Placeholder loading={fetching} className="min-h-[280px] sm:min-h-[250px] md:min-h-[220px]">
                    <BlockLink to={postUrl}>
                      <TeaserContainer>{renderTeaserContent(data)}</TeaserContainer>
                    </BlockLink>
                  </Placeholder>
                );
              }}
            </Fetcher>
          );
        }}
      </Fetcher>
      <div className="mb-12 md:mb-[72px] lg:mb-24" />
    </React.Fragment>
  );
};

export default NewsPostTeaser;
