import { getVideoSizeFromContainer } from './gameUtil';

export const IMPULSE_ICON_SIZES = {
  default: {
    height: 53,
    width: 50,
  },
  fullscreen: {
    height: 100,
    width: 95,
  },
};

export const IMPULSE_MODAL_BG_COLOR = '#f9f9f9';

const MODAL_OUTER_GUTTER = 20;

const positionCoordinates = {
  topLeft: {
    x: 0,
    y: 0,
    diffX: MODAL_OUTER_GUTTER,
    diffY: MODAL_OUTER_GUTTER,
  },
  topRight: {
    x: 9999,
    y: 0,
    diffX: -MODAL_OUTER_GUTTER,
    diffY: MODAL_OUTER_GUTTER,
  },
  bottomLeft: {
    x: 0,
    y: 9999,
    diffX: MODAL_OUTER_GUTTER,
    diffY: -MODAL_OUTER_GUTTER,
  },
  bottomRight: {
    x: 9999,
    y: 9999,
    diffX: -MODAL_OUTER_GUTTER,
    diffY: -MODAL_OUTER_GUTTER,
  },
  center: {
    x: 5000,
    y: 5000,
    diffX: 0,
    diffY: 0,
  },
};

export function getImpulseModalPosition(position, selfSize, containerSize) {
  const { x, y, diffX, diffY } = positionCoordinates[position];
  const { height, width } = selfSize;

  let top = (y / 10000) * containerSize.height - height / 2,
    left = (x / 10000) * containerSize.width - width / 2;
  // Make sure the pin follows the constraints
  top = Math.min(Math.max(0, top), containerSize.height - height) + diffY;
  left = Math.min(Math.max(0, left), containerSize.width - width) + diffX;

  return {
    top,
    left,
  };
}

// Position the given object inside the video (ignoring the possible surrounding black bars)
export function getImpulsePinPositionStyle(position, containerSize, objectSize, aspectRatio) {
  let { x = 0, y = 0 } = position || {};
  const { height, width } = objectSize;
  // Calculate actual video size based on the video being 4:3 centered inside a container of any size
  const { videoSize, diff } = getVideoSizeFromContainer(containerSize, aspectRatio);
  let top = (y / 10000) * videoSize.height - height,
    left = (x / 10000) * videoSize.width - width / 2;
  // Make sure the pin follows the constraints
  top = Math.min(Math.max(0, top), videoSize.height - height) + diff.height;
  left = Math.min(Math.max(0, left), videoSize.width - width) + diff.width;

  return {
    top,
    left,
    position: 'absolute',
  };
}
