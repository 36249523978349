import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink, BaseButton } from '../StyledElements';
import UserColumn from '../UserColumn';
import UserAvatar from '../UserAvatar';
import MenuFlyout from '../MenuFlyout';
import { hasDateNotExpired } from '../../utils/helpers';
import { getMemberFromOrg, getRolesAsBoolean } from '../../utils/userRoleHelpers';

const AccountLink = styled(NavLink)`
  margin-left: 1em;
  margin-right: 0;
  font-style: normal;

  svg {
    margin-left: 15px;
  }

  &.active {
    font-weight: normal;
    border: 0;
  }
`;

const MenuContent = styled.div`
  padding: 1.5em 1.875em;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2em;
  border-bottom: 1px solid rgba(39, 176, 212, 0.2);

  .user-description {
    margin-left: 20px;
  }
`;

const LogoutButton = styled(BaseButton)`
  font-size: 1.25em;
  line-height: 1.25;
  text-align: center;
  width: 100%;
  background-color: rgba(39, 176, 212, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 11px 0 10px;
  transition-property: background-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in;

  &:hover {
    color: #fff;
    background-color: #f26521;
  }
`;

const MenuLinks = styled.div`
  margin-top: 1em;
`;

const MenuLink = styled(Link)`
  display: block;
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.67;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: #f26521;
  }
`;

const MenuLinkWithWarning = styled(Link)`
  display: block;
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.67;
  text-decoration: none;
  color: #f26521;
`;

const getUserAvatar = (isRenewable) => {
  if (isRenewable) {
    return (
      <MenuLink to="/account/license">
        <UserAvatar isRenewable={true} />
      </MenuLink>
    );
  } else {
    return <UserAvatar isRenewable={false} />;
  }
};

class AccountMenuFlyout extends Component {
  renderMenu = ({ user, currentOrder, onLogout, organization }) => {
    const { isOwner } = getRolesAsBoolean(getMemberFromOrg(organization, user));
    const showRentalOption = !user.isExternalUser && currentOrder.allowRentals && isOwner;

    return () => (
      <Fragment>
        <MenuContent>
          <UserRow>
            {getUserAvatar(currentOrder.isRenewable && !user.isExternalUser)}
            <UserColumn className="user-description" user={user} />
          </UserRow>
          <MenuLinks>
            {!user.isExternalUser && <MenuLink to="/account">Meine Daten</MenuLink>}
            {!user.isExternalUser && <MenuLink to="/account/license">Meine Lizenz</MenuLink>}
            {showRentalOption && <MenuLink to="/account/rental-license">Verleihzugänge & -codes</MenuLink>}
            <MenuLink to="/account/merkliste">Merkliste</MenuLink>
            {currentOrder.isRenewable && !user.isExternalUser && (
              <MenuLinkWithWarning to="/account/license">
                {hasDateNotExpired(currentOrder.validUntil)
                  ? 'Lizenz läuft bald ab. Verlängern?'
                  : 'Lizenz abgelaufen. Verlängern?'}
              </MenuLinkWithWarning>
            )}
          </MenuLinks>
        </MenuContent>
        <LogoutButton onClick={onLogout}>Abmelden</LogoutButton>
      </Fragment>
    );
  };

  render() {
    const { user, currentOrder } = this.props;
    const renderMenu = this.renderMenu(this.props);

    if (!user.email) return null;
    const linkDestionation = user.isExternalUser ? '/account/merkliste' : '/account';
    return (
      <MenuFlyout renderFlyout={renderMenu} placementPaddingSize={10}>
        <AccountLink to={linkDestionation}>
          {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'Mein Profil'}
          <UserAvatar isRenewable={currentOrder.isRenewable && !user.isExternalUser} />
        </AccountLink>
      </MenuFlyout>
    );
  }
}

AccountMenuFlyout.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func,
  currentOrder: PropTypes.object,
  organization: PropTypes.object,
};

const select = (state) => ({
  user: state.user.account,
  currentOrder: state.user.currentOrder,
  organization: state.user.organization,
});

export default connect(select)(AccountMenuFlyout);
