import React from 'react';
import { TextBlock } from './StyledElements';

export default ({ error }) => {
  return error ? (
    <TextBlock centered mt={1} fontSize={0.875} color="#ff001f">
      Fehler: {error}
    </TextBlock>
  ) : null;
};
