import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { saml2Login } from '../../../config';
import { apiFetch } from '../../../modules/api';
import validate from '../../../modules/validate';
import { onLoginSuccess } from '../../../actions/auth';
import { getUserAccount } from '../../../actions/user';
import FormFields from '../../../components/FormFields';
import VidisLoginButton from '../../../components/VidisLoginButton';
import { TextBlock, Spacing, Button, Form, IAMLogin, VidisButtonContainer } from '../../../components/StyledElements';

const initialValues = {
  email: '',
  password: '',
};

const fields = [
  {
    name: 'email',
    placeholder: 'E-Mail-Adresse',
    type: 'email',
  },
  {
    name: 'password',
    placeholder: 'Passwort',
    type: 'password',
  },
];

const LoginView = (props) => {
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    apiFetch
      .url('/auth/login')
      .json({ username: values.email, password: values.password })
      .post()
      .res(() => {
        props.onLoginSuccess();
        props.getUserAccount();
      })
      .then(() => {
        if (props.redirectTo) {
          props.history.push(props.redirectTo);
        }
      })
      .catch(({ json = {} }) => {
        const { errorDescription, error } = json;
        // Redirect migrated users to the special password reset form
        if (error === 'must_reset_password') {
          return props.history.push({
            pathname: '/force-new-password',
            state: { email: values.email },
          });
        }

        const errorMessage = errorDescription?.includes('invalid')
          ? 'Ungültige Zugangsdaten'
          : 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.';

        setErrors({ password: errorMessage });

        setSubmitting(false);
      });
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ errors, handleSubmit, isSubmitting, ...restProps }) => (
          <Form onSubmit={handleSubmit}>
            <FormFields fields={fields} errors={errors} {...restProps} />
            <Spacing textAlign="center" mt={1}>
              <Button type="submit" disabled={isSubmitting} className={isSubmitting ? 'loading wide' : 'wide'}>
                Anmelden
              </Button>
            </Spacing>
          </Form>
        )}
      </Formik>
      <IAMLogin>
        <a href={saml2Login.path}>
          <Button>
            <div className="IAMIcon"></div>
            <div className="loginText"> {saml2Login.text} </div>
            <div className="LUXIcon"></div>
          </Button>
        </a>
      </IAMLogin>
      <VidisLoginButton />
      <TextBlock centered bold italic mt={2.5}>
        <Link to="/request-password-reset">Passwort vergessen</Link>
      </TextBlock>
    </Fragment>
  );
};

const connectedLoginView = connect(null, { onLoginSuccess, getUserAccount })(LoginView);
export default withRouter(connectedLoginView);
