import React from 'react';
import {
  TextBlock,
  Button,
  FormSectionHeadline,
  Spacing
} from '../../components/StyledElements';

export default ({ onClick }) => {
  return (
    <div>
      <FormSectionHeadline className="text-center">
        Geschafft!
      </FormSectionHeadline>
      <TextBlock centered fontSize={1.25} lineHeight={1.5}>
        Danke für Ihre Bestellung! Sie können Ihre Lizenz ab sofort nutzen.
      </TextBlock>
      <Spacing textAlign="center" mt={4.5} mb={6}>
        <Button onClick={onClick}>Zur Onilo Startseite</Button>
      </Spacing>
    </div>
  );
};
