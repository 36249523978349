export const PAGE_TITLE_UPDATE = 'options/PAGE_TITLE_UPDATE';
export const ACCESS_CODE_SECTION_VISIBILITY_UPDATE = 'options/ACCESS_CODE_SECTION_VISIBILITY_UPDATE';

export const onPageTitleUpdate = (pageTitle) => ({
  type: PAGE_TITLE_UPDATE,
  pageTitle
});

export const onAccessCodeSectionVisibilityUpdate = (isVisible) => ({
  type: ACCESS_CODE_SECTION_VISIBILITY_UPDATE,
  accessCodeSectionIsVisible: isVisible
});
