import styled from 'styled-components';

const inputHeight = 72;

export const SearchInput = styled.input`
  display: block;
  box-shadow: none;
  border-radius: ${inputHeight / 2}px;
  background-color: #ecfbfe;
  border: solid 1px #27b0d4;
  outline: 0;
  height: ${inputHeight}px;
  width: 100%;
  padding: 1.4rem 4.5rem 1.1rem 1.25rem;
  font-size: 1.5rem;
  word-wrap: break-word;
  white-space: normal;
  color: #27b0d4;

  .small & {
    height: 48px;
    border-radius: 24px;
    font-size: 1.25rem;
    padding: 12px 60px 10px 24px;
  }
`;

export const SearchContainer = styled.div`
  max-width: 580px;
  margin-bottom: 3em;
  width: 100%;
  position: relative;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  z-index: 11;
`;

export const SearchButton = styled.button`
  outline: 0;
  border: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  .small & {
    right: 18px;
    transform: translate3d(0, -50%, 0);
  }
`;

export const SearchMenu = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
  box-shadow: 0 8px 10px 0 rgba(0, 98, 124, 0.1);
  position: absolute;
  top: ${inputHeight / 2}px;
  left: 0;
  right: 0;
  z-index: 10;
  padding: ${inputHeight / 2 + 16}px 0 1.5rem;
`;

export const SearchMenuItem = styled.div`
  font-size: 1.25em;
  font-style: italic;
  line-height: 1.25;
  cursor: pointer;
  padding: 7px 24px 6px;
  background-color: transparent;

  &.is-highlighted {
    border-radius: 2px;
    background-color: #f26521;
    color: #fff;
    padding-left: 14px;
    padding-right: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ShowResultsButton = styled(SearchMenuItem)`
  font-size: 1em;
  text-align: center;
  margin-top: 1em;
  color: #f26521;
  text-decoration: underline;

  &.is-highlighted {
    font-weight: bold;
    border-radius: 0;
    background-color: transparent;
    color: #f26521;
  }
`;
