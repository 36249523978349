import styled from 'styled-components';

import { ReactComponent as InfoIconSVG } from '../assets/icons/InfoIcon.svg';

export const InfoIcon = styled(InfoIconSVG)`
  color: ${(props) => props.color || props.theme.oniloDarkOrange};
  width: ${(props) => props.sideLength || 1}rem;
  height: ${(props) => props.sideLength || 1}rem;
  margin-top: -2px;
`;

export const PlusIcon = ({ invertColors }) => (
  <svg width="46" height="46" viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="2" stroke={invertColors ? '#FFF' : '#27B0D4'} fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M23 1.667v42.666M44.333 23H1.667" />
    </g>
  </svg>
);
