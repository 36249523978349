import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { Fragment } from 'react';
import { Portal } from 'react-portal';

const PopperTooltip = (props) => {
  const openTimeout = useRef(null);
  const closeTimeout = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  useEffect(() => {
    return () => {
      window.clearTimeout(openTimeout.current);
      window.clearTimeout(closeTimeout.current);
    };
  }, []);

  const openTooltip = (event, duration = props.duration) => {
    if (closeTimeout.current) {
      window.clearTimeout(closeTimeout.current);
    }

    openTimeout.current = window.setTimeout(() => {
      onTooltipStateUpdated(true);
    }, duration);
  };

  const closeTooltip = (event, duration = props.duration) => {
    if (openTimeout.current) {
      window.clearTimeout(openTimeout.current);
    }

    closeTimeout.current = window.setTimeout(() => {
      onTooltipStateUpdated(false);
    }, duration);
  };

  const toggleTooltip = (event, duration = props.duration) => {
    const tooltipFunc = props.isOpen ? closeTooltip : openTooltip;
    tooltipFunc(event, duration);
  };

  const onTooltipStateUpdated = (nextIsOpen) => {
    if (props.onUpdated) {
      props.onUpdated(nextIsOpen);
    }
  };

  const getTriggerEventListeners = () => {
    if (props.triggerOn === 'click') {
      return { onClick: toggleTooltip };
    }

    return {
      onMouseEnter: openTooltip,
      onMouseLeave: closeTooltip,
      onFocus: openTooltip,
      onBlur: (event) => closeTooltip(event, 0),
    };
  };

  const getTooltipEventListeners = () => {
    if (props.triggerOn === 'hover') {
      return {
        onMouseEnter: openTooltip,
        onMouseLeave: closeTooltip,
      };
    }
  };

  const { isOpen, children, placementPaddingSize } = props;

  return (
    <Fragment>
      <div className="inline-block" {...getTriggerEventListeners()} ref={setReferenceElement}>
        {children}
      </div>
      {isOpen && (
        <Portal>
          {props.triggerOn === 'click' && <div className="fixed inset-0 z-[9999]" onClick={closeTooltip} />}
          <div
            className="z-[9999] aria-hidden:hidden"
            aria-hidden={!isOpen}
            ref={setPopperElement}
            style={{
              ...styles.popper,
              paddingTop: placementPaddingSize,
            }}
            {...attributes.popper}
            {...getTooltipEventListeners()}
          >
            {props.render({ isOpen })}
          </div>
        </Portal>
      )}
    </Fragment>
  );
};

PopperTooltip.defaultProps = {
  duration: 0,
  triggerOn: 'hover',
  isOpen: false,
};

PopperTooltip.propTypes = {
  render: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  onUpdated: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  placementPaddingSize: PropTypes.number,
  triggerOn: PropTypes.oneOf(['click', 'hover']),
};

export default PopperTooltip;
