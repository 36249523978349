import qs from 'query-string';
import omit from 'lodash/omit';
import without from 'lodash/without';
import values from 'lodash/fp/values';
import flatten from 'lodash/fp/flatten';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import intersection from 'lodash/fp/intersection';

const qsOptions = { arrayFormat: 'bracket' };

const buildSearchQuery = (query) => ({
  search: qs.stringify(query, qsOptions)
});

export const updateQs = (newQuery, searchQuery, omitKeys) => {
  const oldQuery = omit(qs.parse(searchQuery), omitKeys);

  return {
    search: qs.stringify({ ...oldQuery, ...newQuery })
  };
};

export const addFilter = (descriptor, searchQuery, omitKey, searchSubject) => {
  const newQuery = omit(qs.parse(searchQuery, qsOptions), omitKey);
  const currentTags = newQuery.tags || [];
  switch(searchSubject){
    case "year":
      newQuery.year = descriptor
      break;
    case "category":
      newQuery.category = descriptor
      break;
    default:
      newQuery.tags = [...currentTags, descriptor]
}
  return buildSearchQuery(newQuery);
};

export const removeFilter = (filter, searchQuery) => {
  const {descriptor, category} = filter;
  let newQuery = qs.parse(searchQuery, qsOptions);
  if (["year", "category"].includes(category)) delete newQuery[category];
  newQuery.tags = without(newQuery.tags, descriptor);

  return buildSearchQuery(newQuery);
};

export const getValuesFromQs = (locationSearch, options) =>{
  // This intersection makes sure we ignore unknown descriptors
  return intersection(
    flow(values, flatten)(qs.parse(locationSearch, qsOptions) || []),
    flow(
      values,
      flatten,
      map((option) => option.value ? option.value : option)
    )(options)
  )};
