import React from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import AccessCodeForm from './AccessCodeForm';
import {
  Button,
  BoxShadow,
  TextBlock,
  Spacing,
  BigRoundInput
} from '../StyledElements';

const TeaserContainer = styled(BoxShadow)`
  padding: 2.5em 1.25em 3em;
  margin-top: 1.5em;
`;

const TeaserTitle = styled.h1`
  margin: 0;
  font-size: 2.25rem;
  text-align: center;
`;

export default (props) => {
  return (
    <TeaserContainer>
      <TeaserTitle>Schüler- & Verleihcode Eingabe</TeaserTitle>
      <TextBlock centered mt={1.5} mb={2} lineHeight={1.63}>
        Sie haben einen Schüler- oder Verleihcode? Dann geben Sie ihn hier ein und klicken auf „Code Eingabe“.
      </TextBlock>
      <AccessCodeForm
        onAccessCodeSubmit={props.onAccessCodeSubmit}
        renderForm={({
          handleChange,
          values,
          errors,
          touched
        }) => (
          <Form>
            <div className="input-wrapper">
              <BigRoundInput
                name="accessCode"
                placeholder="Hier Code eingeben"
                onChange={handleChange}
                value={values.accessCode}
                error={touched.accessCode && errors.accessCode}
              />
            </div>
            <Spacing textAlign="center" mt={1.5}>
              <Button style={{ minWidth: 138 }} type="submit">
                Senden
              </Button>
            </Spacing>
          </Form>
        )}
      />
    </TeaserContainer>
  );
};
