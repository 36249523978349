import React from 'react';
import cc from 'classcat';
import {
  SearchInput,
  SearchContainer,
  SearchInputContainer,
  SearchButton,
  SearchMenu,
  SearchMenuItem,
  ShowResultsButton
} from './searchStyles';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';

const SearchDropdown = ({
  getInputProps,
  getItemProps,
  getRootProps,
  isOpen,
  inputValue,
  selectedItem,
  highlightedIndex,
  items,
  onShowAllResults,
  closeMenu,
  loading,
  renderSuggestionKey,
  renderSuggestionItem,
  autoFocus,
  placeholder
}) => {
  const onShowAllResultsClick = (event) => {
    event.preventDefault();
    
    closeMenu();
    onShowAllResults(inputValue);
  };

  const renderSearchMenu = (suggestions) => {
    if (!isOpen || !inputValue) {
      return null;
    }

    if (loading) {
      return (
        <SearchMenu className="search-suggestions-list">
          <SearchMenuItem className="search-suggestions-list-item">
            Wird gesucht...
          </SearchMenuItem>
        </SearchMenu>
      );
    }

    return (
      <SearchMenu className="search-suggestions-list">
        {suggestions.map((item, index) => (
          <SearchMenuItem
            {...getItemProps({ item })}
            key={renderSuggestionKey ? renderSuggestionKey(item) : item}
            selected={selectedItem === item}
            className={cc([
              { 'is-highlighted': highlightedIndex === index },
              'search-suggestions-list-item'
            ])}
          >
            {renderSuggestionItem ? renderSuggestionItem(item) : item}
          </SearchMenuItem>
        ))}
        {suggestions.length > 1 && (
          <ShowResultsButton
            {...getItemProps({ item: 'Alle Ergebnisse anzeigen' })}
            onClick={onShowAllResultsClick}
            className={cc([
              {
                'is-highlighted': highlightedIndex === suggestions.length
              },
              'search-suggestions-list-item'
            ])}
          >
            Alle Ergebnisse anzeigen
          </ShowResultsButton>
        )}
        {suggestions.length === 0 && (
          <SearchMenuItem>Keine Treffer</SearchMenuItem>
        )}
      </SearchMenu>
    );
  };

  // Render the first 5 suggestions
  const suggestions = items.slice(0, 5);

  return (
    <SearchContainer
      className="search-dropdown-container"
      {...getRootProps({}, { suppressRefError: true })}
    >
      <SearchInputContainer className="search-input-container">
        <SearchInput
          autoCorrect="off"
          autoFocus={autoFocus}
          spellCheck="false"
          {...getInputProps({
            placeholder,
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                // If no results are highlighted or the "Show all results" button is clicked
                // In the latter case the index of the button always equals to suggestions.length
                if (
                  highlightedIndex === null ||
                  highlightedIndex === suggestions.length
                ) {
                  // Prevent Downshift's default 'Enter' behavior.
                  event.nativeEvent.preventDownshiftDefault = true;
                  onShowAllResultsClick(event);
                }
              }
            }
          })}
        />
        <SearchButton onClick={onShowAllResultsClick}>
          <SearchIcon />
        </SearchButton>
      </SearchInputContainer>
      {renderSearchMenu(suggestions)}
    </SearchContainer>
  );
};

export default SearchDropdown;
