import React, { Component } from 'react';
import SelectField from '../SelectField';
import { BigFloatingLabel } from '../FloatingLabel';
import regionsData from './regions';
import values from 'lodash/values';

class RegionField extends Component {
  componentDidUpdate(prevProps) {
    // Reset region, if country selection changes
    if (prevProps.country !== this.props.country) {
      this.props.setFieldValue(this.props.name, '');
    }
  }

  render() {
    let { country, options, setFieldValue, ...restProps } = this.props;
    const regions = getRegions(country);

    return regions.length === 0 ? (
      <BigFloatingLabel type="text" {...restProps} />
    ) : (
      <SelectField options={regions} {...restProps} />
    );
  }
}

const getRegions = (cName) => {
  const country = values(regionsData).find((c) => c.name.de === cName);
  if (!country) return [];

  return country.states.map(({ customData: { de } }) => {
    return { name: de, value: de };
  });
};

export default RegionField;
