import React from 'react';
import styled from 'styled-components';
import { PageContainer, FrontPageHeadline } from '../../../components/StyledElements';
import { ReactComponent as ColorFilter } from '../../../assets/icons/ColorFilter.svg';

const LogoWrapper = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
  margin: 0 auto;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: translate(0, -50%);
    transition: opacity 0.5s;
  }

  .img-filtered {
    filter: saturate(0) url(#colorFilter) brightness(150%) hue-rotate(15deg);
    opacity: 0.6;
  }

  .img-unfiltered {
    opacity: 0;
  }

  &:hover {
    .img-filtered,
    .img-prefiltered {
      opacity: 0;
    }
    .img-unfiltered {
      opacity: 1;
    }
  }
`;

export default ({ headline, items = [] }) => {
  if (!items || items.length === 0) return null;

  return (
    <PageContainer className="text-center">
      <FrontPageHeadline>{headline}</FrontPageHeadline>
      <div className="flex flex-wrap -m-2">
        {items.map(({ image, image_colored, url }, key) => (
          <div key={key} className="p-2 w-1/3 md:w-1/6">
            <LogoWrapper>
              <a href={url ? url : undefined} target="_blank" rel="noopener noreferrer">
                <img loading="lazy" className="img-unfiltered" src={image.url} alt={image.alt} />
                <img
                  loading="lazy"
                  className={image_colored ? 'img-prefiltered' : 'img-filtered'}
                  src={image_colored.url || image.url}
                  alt={image_colored.alt || image.alt}
                />
              </a>
            </LogoWrapper>
          </div>
        ))}
      </div>
      <ColorFilter width={0} height={0} />
    </PageContainer>
  );
};
