import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { onAccessCodeSectionVisibilityUpdate } from '../../actions/options';
import { Container, Spacing } from '../StyledElements';
import { Collapsible } from '../Accordion';
import AccessCodeTeaser from './AccessCodeTeaser';
import KeyboardIcon from '../../assets/icons/Keyboard.svg';
import TriggerButton from '../TriggerButton';

const FormTrigger = styled(TriggerButton)`
  background-image: url(${KeyboardIcon});
  background-repeat: no-repeat;
  background-position: 10% top;
  padding: 0 calc(10% + 50px);
`;

const renderTrigger = (triggerProps) => <FormTrigger {...triggerProps} />;

const AccessCodeMobileSection = ({ className, onAccessCodeSectionVisibilityUpdate, ...restProps }) => {
  const { ref } = useInView({
    onChange: onAccessCodeSectionVisibilityUpdate,
  });

  return (
    <Container ref={ref} id="AccessCodeForm" className={className}>
      <Spacing mt={1.5} mb={1.5}>
        <Collapsible trigger="Schüler- & Verleihcode" renderTrigger={renderTrigger}>
          <AccessCodeTeaser {...restProps} />
        </Collapsible>
      </Spacing>
    </Container>
  );
};

export default connect(null, { onAccessCodeSectionVisibilityUpdate })(AccessCodeMobileSection);
