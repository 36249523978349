import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { withRouter } from 'react-router-dom';
import cc from 'classcat';
import { MenuOverlay, MenuWrap, MenuInner, BurgerButton } from './menuStyles';
import { ReactComponent as BurgerIcon } from '../../assets/icons/Burger.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseBig.svg';

class BurgerMenu extends Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    // Close the menu on location change
    if (prevProps.location !== this.props.location && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  toggleMenu = (event, nextIsOpen) => {
    const isOpen = nextIsOpen !== undefined ? nextIsOpen : !this.state.isOpen;
    this.setState({ isOpen });
  };

  render() {
    if (!this.props.render && !this.props.children) {
      return null;
    }

    const { isOpen } = this.state;
    const menuClassName = cc([{ open: isOpen }, this.props.className]);

    return (
      <Fragment>
        <BurgerButton className="lg:hidden" onClick={this.toggleMenu}>
          {isOpen ? <CloseIcon width={36} height={36} /> : <BurgerIcon />}
        </BurgerButton>
        <Portal>
          <MenuOverlay className={menuClassName} onClick={this.toggleMenu} />
          <MenuWrap className={menuClassName}>
            <MenuInner>
              {this.props.render
                ? this.props.render({
                    ...this.state,
                    toggleMenu: this.toggleMenu,
                  })
                : this.props.children}
            </MenuInner>
          </MenuWrap>
        </Portal>
      </Fragment>
    );
  }
}

BurgerMenu.propTypes = {
  location: PropTypes.object,
  className: PropTypes.string,
  render: PropTypes.func,
};

export default withRouter(BurgerMenu);
