import { breakpoints } from '../../config';
export { default as Image } from './Image';

const wpImageSizes = ['medium', 'medium_large', 'large'];

// Create an image srcset from an ACF image object
export const getWpImageSrcSet = (image) => {
  if (!image || !image.sizes) return undefined;
  
  let srcSet = {};

  wpImageSizes.forEach((size) => {
    const imageWidth = image.sizes[`${size}-width`];
    const imageSrc = image.sizes[size];
    if (imageSrc && imageWidth && imageWidth > 0) {
      srcSet[`${imageWidth}w`] = imageSrc;
    }
  });

  if (image.width > 0) {
    srcSet[`${image.width}w`] = image.url;
  }

  return srcSet;
};

export const imageSizesByColumn = {
  1: '100vw',
  2: [
    {
      media: `(min-width: ${breakpoints.md}px)`,
      size: '50vw'
    },
    {
      size: '100vw'
    }
  ]
};

const boardstoryImageSizes = { small: 700, medium: 920, large: 1440 };

export const getApiImageSrcSet = (teaserImage) => {
  if (!teaserImage || !teaserImage.path) return undefined;

  let srcSet = {};

  Object.keys(boardstoryImageSizes).forEach((sizeName) => {
    const imageSrc = teaserImage.path[sizeName];
    if (imageSrc) {
      srcSet[`${boardstoryImageSizes[sizeName]}w`] = imageSrc;
    }
  });

  return srcSet;
};

export const getWpImageProps = (image) => ({
  srcSet: getWpImageSrcSet(image),
  alt: image.alt,
  width: image.width
})