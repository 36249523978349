import cc from 'classcat';
import findIndex from 'lodash/findIndex';
import { Component } from 'react';
import videoConnect, { apiHelpers } from 'react-html5video';
import { connect } from 'react-redux';

import { ReactComponent as AutoPlayIcon } from '../../assets/icons/Autoplay.svg';
import { ReactComponent as FullscreenEnterIcon } from '../../assets/icons/FullscreenEnter.svg';
import { ReactComponent as FullscreenExitIcon } from '../../assets/icons/FullscreenExit.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/Pause.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/Play.svg';
import { ReactComponent as QuizIcon } from '../../assets/icons/Quiz.svg';
import { ReactComponent as SearchFindIcon } from '../../assets/icons/SearchFind.svg';
import { ReactComponent as SkipLeftIcon } from '../../assets/icons/SkipLeft.svg';
import { ReactComponent as SkipRightIcon } from '../../assets/icons/SkipRight.svg';
import { ReactComponent as SoundOffIcon } from '../../assets/icons/SoundOff.svg';
import { ReactComponent as SoundOnIcon } from '../../assets/icons/SoundOn.svg';
import { ReactComponent as SpeechBubbleIcon } from '../../assets/icons/SpeechBubble.svg';
import { ReactComponent as TextIcon } from '../../assets/icons/Text.svg';
import { ReactComponent as TrailerIcon } from '../../assets/icons/Trailer.svg';
import { fullscreenChangeEvents, toggleFullscreen } from '../../utils/video';
import BoardstoryBookmark from '../Boardstory/BoardstoryBookmark';
import BoardstoryGames from '../BoardstoryGames/BoardstoryGames';
import GameCoordinatesTooltip from '../BoardstoryGames/GameCoordinatesTooltip';
import {
  gameTypeLabels,
  getActiveGameTypes,
  getAvailableGameCuepoints,
  getRelevantGameCuepoints,
  trailerHasGameType,
} from '../BoardstoryGames/shared/gameUtil';
import { BoardstoryGameTypes } from '../BoardstoryGames/shared/types';
import { openModal } from '../Modal/modalActions';
import { Thumbnail } from '../StyledElements';
import Overlay from './controls/Overlay';
import PlayerControl from './controls/PlayerControl';
import Seek from './controls/Seek';
import Time from './controls/Time';
import { BottomControls, ControlsWrapper, FullscreenButton, PlayerContainer, TimeControlList } from './videoPlayerStyles';

const GameIcons = {
  search: SearchFindIcon,
  quiz: QuizIcon,
  impulse: SpeechBubbleIcon,
};

const copy = {
  play: 'Play video',
  pause: 'Pause video',
  mute: 'Mute video',
  unmute: 'Unmute video',
  nosound: 'No sound',
  volume: 'Change volume',
  fullscreen: 'View video fullscreen',
  seek: 'Seek video',
};

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const iPhone = /iPhone|iPod/.test(navigator.userAgent);

const buildControls = (games) => {
  let gameControls = games?.length ? [BoardstoryGameTypes.Impulse, BoardstoryGameTypes.Search, BoardstoryGameTypes.Quiz] : [];

  return [
    'JumpBackward',
    'PlayPause',
    'JumpForward',
    'AutoPlay',
    'VideoTextToggle',
    'Volume',
    ...gameControls,
    'Trailer',
    'Bookmark',
  ];
};

const buildTigerControls = () => {
  return ['Empty', 'StartTigerCreation', 'Bookmark'];
};

const { toggleMute, mute, togglePause, setCurrentTime, getPercentagePlayed, getPercentageBuffered } = apiHelpers;

const KEYS = {
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  ESC: 27,
  F5: 116,
};

function getAvailableGameTypes(games) {
  if (!games) return [];

  return games.reduce((types, i) => (types.includes(i.gameType) ? types : [...types, i.gameType]), []);
}

class VideoPlayer extends Component {
  static defaultProps = {
    copy,
    buildControls,
    buildTigerControls,
    video: {},
    fullscreenControls: [],
    playbackEventTimeThreshold: 180, // 3 minutes
  };

  constructor(props) {
    super(props);

    const availableGameTypes = getAvailableGameTypes(props.games);

    this.mouseTimer = null;

    this.state = {
      showVideoWithText: !this.isActiveForAccessCode('subtitlesEnabled') ? false : !this.props.isExplain,
      isFullscreen: false,
      isTransparent: false,
      autoPlay: this.isActiveForAccessCode('autoplayEnabled'),
      activeGameTypes: this.filterActiveGameTypes(availableGameTypes),
      currentGameId: null,
      searchGameCurrentlyActive: false,
      hovered: false,
      controlsHovered: false,
    };

    this.prevCurrentTime = null;

    this.availableGameTypes = availableGameTypes;
    this.prevGameCuepoint = -1;
    this.nextGameCuepointIndex = 0;
    this.nextPauseCuepointIndex = 0;

    this.playbackTimeEventFired = false;

    this.setPlayerContainerRef = (element) => {
      this.playerContainerEl = element;
      props.playerContainerRef && props.playerContainerRef(element);
    };

    this.setPlayerRef = (element) => {
      this.playerEl = element;
      props.playerRef && props.playerRef(element);
    };
  }

  filterActiveGameTypes(games) {
    //remove game from games if access code is set and game is not active
    if (!this.isActiveForAccessCode('quizEnabled')) games = games.filter((game) => game !== 'quiz');
    if (!this.isActiveForAccessCode('impulseEnabled')) games = games.filter((game) => game !== 'impulse');
    if (!this.isActiveForAccessCode('searchGameEnabled')) games = games.filter((game) => game !== 'search');

    return games;
  }

  isActiveForAccessCode(key) {
    const { accessCodeConfig } = this.props;
    if (!accessCodeConfig || !accessCodeConfig?.code) return true; // no access code set, so all is active
    return accessCodeConfig?.code && accessCodeConfig.hasOwnProperty(key) && accessCodeConfig[key] === true;
  }

  iPhoneGameInfo() {
    return this.props.openModal({
      buttonValue: 'Zum App Store',
      renderCloseButton: true,
      onConfirm: () => {
        window.open('itms-apps://apps.apple.com/de/app/onilo/id1523051643', '_blank');
      },
      content: 'Spiele sind nur in der App verfügbar. Lade dir hier die App herunter, um Spiele zu spielen.',
    });
  }

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }

  onMouseMove() {
    clearTimeout(this.mouseTimer);
    if (this.state.isTransparent && this.state.isFullscreen && !this.state.searchGameCurrentlyActive) {
      this.setState({ isTransparent: false });
    }

    this.mouseTimer = setTimeout(() => {
      if (!this.state.isTransparent && this.state.isFullscreen && (!this.state.controlsHovered || this.isTouchDevice())) {
        this.setState({ isTransparent: true });
      }
    }, 1300);
  }

  isSafariVersionAbove(versionNumber) {
    var userAgent = navigator.userAgent;
    var isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

    if (isSafari) {
      var safariVersionMatch = userAgent.match(/version\/([\d.]+)/i);
      if (safariVersionMatch && safariVersionMatch.length > 1) {
        var safariVersion = safariVersionMatch[1];
        var parsedVersion = safariVersion.split('.').map(parseFloat).join('.');
        return parsedVersion >= versionNumber;
      }
    }

    return false;
  }

  componentDidMount() {
    if (this.playerContainerEl) {
      fullscreenChangeEvents.forEach((eventName) => {
        if (eventName === 'webkitfullscreenchange' && this.isSafariVersionAbove('16.5')) return; // fix for safari, because now it is handling fullscreenchange with standard api
        document?.addEventListener(eventName, this.handleFullscreenChange);
      });
    }
    if (this.props.onMount) this.props.onMount(this.playerContainerEl, this.playerEl);
    window?.addEventListener('mousemove', this.onMouseMove.bind(this));
    window?.addEventListener('touchstart', this.onMouseMove.bind(this));
  }

  componentWillUnmount() {
    fullscreenChangeEvents.forEach((eventName) => {
      if (eventName === 'webkitfullscreenchange' && this.isSafariVersionAbove('16.5')) return;
      document?.removeEventListener(eventName, this.handleFullscreenChange);
    });
    window?.removeEventListener('mousemove', this.onMouseMove.bind(this));
    window?.removeEventListener('touchstart', this.onMouseMove.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    // If the video source has been updated  (i.e. trailer -> video)
    if (this.props.videoData !== prevProps.videoData) {
      // Reload the video
      this.props.videoEl.load();
      if (!this.isActiveForAccessCode('soundEnabled')) mute(this.props.videoEl);

      // Reset playback time event
      this.playbackTimeEventFired = false;
    }

    // If event hasn't been fired yet, current time is above the threshold,
    // while the previous time was below it, fire the "playback time reached" event
    if (
      !this.playbackTimeEventFired &&
      this.props.video.currentTime > this.props.playbackEventTimeThreshold &&
      prevProps.video.currentTime < this.props.playbackEventTimeThreshold
    ) {
      if (this.props.onPlaybackTimeReached) {
        this.props.onPlaybackTimeReached();
        this.playbackTimeEventFired = true;
      }
    }
  }

  handleFullscreenChange = (e) => {
    const nextIsFullscreen = !this.state.isFullscreen;
    this.setState({ isFullscreen: nextIsFullscreen });

    // Pause the player on full screen exit
    if (!nextIsFullscreen && this.props.videoEl) {
      this.props.videoEl.pause();
    }

    // Auto-focus when going into fullscreen mode for the keyboard controls to work
    if (nextIsFullscreen) {
      this.playerContainerEl.focus();
      //set timer for controls to become transparent in fullscreen
      this.mouseTimer = setTimeout(() => {
        if (!this.state.isTransparent && this.state.isFullscreen) {
          this.setState({ isTransparent: true });
        }
      }, 2500);
    }

    try {
      if (window.screen?.orientation?.lock && window.screen.orientation.lock === 'function' && document.fullscreenElement)
        window.screen.orientation.lock('landscape');
      else if (window.screen?.orientation?.unlock) window.screen.orientation.unlock();
    } catch (e) {
      console.log(e);
    }
  };

  handlePlayPause = () => {
    if (this.playerEl.paused) {
      this.props.onPlayPress?.(this.props.currentVideoType);
    }

    this.props.onPlayPauseClick();
  };

  // Keyboard events handler
  handleKeyDown = (event) => {
    if (Object.values(KEYS).find((usableKey) => usableKey === event.keyCode)) {
      event.preventDefault();
    }

    switch (event.keyCode) {
      case KEYS.RIGHT:
      case KEYS.PAGE_DOWN:
        return this.jumpForward();
      case KEYS.LEFT:
      case KEYS.PAGE_UP:
        return this.jumpBackward();
      case KEYS.SPACE:
      case KEYS.ESC:
      case KEYS.F5:
        return this.handlePlayPause();
      default:
        return;
    }
  };

  // Toggles video source: with or without text
  toggleVideoText = () => {
    // Save the current time of the previous video
    this.prevCurrentTime = this.props.video.currentTime;

    this.setState(
      {
        showVideoWithText: !this.state.showVideoWithText,
      },
      () => {
        // Load the video
        this.props.videoEl.load();
        if (!this.isActiveForAccessCode('soundEnabled')) mute(this.props.videoEl);

        // Wait for the video to load and continue playback
        this.props.videoEl?.addEventListener('loadeddata', this.continuePlayback);
      },
    );
  };

  // Continues playback after a video source has been replaced
  continuePlayback = () => {
    const { videoEl } = this.props;
    if (!this.prevCurrentTime) return;

    // Update video's current time and continue playback
    videoEl.currentTime = this.prevCurrentTime;
    if (!this.isActiveForAccessCode('soundEnabled')) mute(this.props.videoEl);

    let isPlaying =
      videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > videoEl.HAVE_CURRENT_DATA;
    if (!isPlaying) videoEl.play();

    videoEl.removeEventListener('loadeddata', this.continuePlayback);
  };

  // Jumps a single seekpoint forward
  jumpForward = () => {
    if (!this.props.videoEl || !this.props.videoData.seekPoints) {
      return;
    }

    let currentTime = this.props.video.currentTime * 1000;
    const seekPoint = this.props.videoData.seekPoints.find((i) => i > currentTime);

    // Update current time, convert seekpoint into seconds
    if (seekPoint) {
      currentTime = seekPoint / 1000;
      this.props.videoEl.currentTime = currentTime;

      this.updateNextCuepointIndices(currentTime);
    }
  };

  // Jumps 2 seekpoints back
  jumpBackward = () => {
    if (!this.props.videoEl || !this.props.videoData.seekPoints) {
      return;
    }

    // .reverse() mutates the original array, so create a copy first
    const seekPoints = [...this.props.videoData.seekPoints].reverse();
    let currentTime = this.props.video.currentTime * 1000;

    const prevSeekPointIndex = findIndex(seekPoints, (i) => i < currentTime);
    let seekPoint = 0;

    // Go one more seekpoint deeper
    if (prevSeekPointIndex > -1 && prevSeekPointIndex < seekPoints.length) {
      seekPoint = seekPoints[prevSeekPointIndex + 1];
    }

    currentTime = seekPoint / 1000 || 0;
    this.props.videoEl.currentTime = currentTime;

    this.updateNextCuepointIndices(currentTime);
  };

  onProgress = (event) => {
    const { currentTime } = event.target;

    this.handlePauseCuepoint(currentTime);

    this.handleGameCuepoint(currentTime);
  };

  // Pauses on pauseCuepoints unless autoPlay is active
  handlePauseCuepoint(currentTime) {
    if (this.state.autoPlay) {
      return;
    }

    const { pauseCuepoints } = this.props.videoData;
    if (!pauseCuepoints || pauseCuepoints.length === 0) {
      return;
    }

    const nextCuepoint = pauseCuepoints[this.nextPauseCuepointIndex];

    if (currentTime * 1000 >= nextCuepoint) {
      // Skip 0 cuepoint
      if (nextCuepoint !== 0) {
        this.props.videoEl.pause();
      }
      if (this.nextPauseCuepointIndex + 1 < pauseCuepoints.length) {
        this.nextPauseCuepointIndex++;
      }
    }
  }

  handleGameCuepoint(time) {
    const gameCuepoints = this.getGameCuepoints();

    if (!gameCuepoints) return;

    const nextGameCuepoint = gameCuepoints[this.nextGameCuepointIndex];
    const timeInMs = time * 1000;

    if (
      nextGameCuepoint?.position &&
      timeInMs >= nextGameCuepoint.position &&
      this.prevGameCuepoint !== nextGameCuepoint.position
    ) {
      const distance = timeInMs - nextGameCuepoint.position;

      // When less than 1s left before the game
      // pause the video so the user can directly interact with it
      if (distance < 500) {
        this.pause();

        this.setCurrentGame(nextGameCuepoint.position);

        this.props.onGameCuepoint && this.props.onGameCuepoint(nextGameCuepoint.position);
      }
      // Increment the next expected game cuepoint
      if (this.nextGameCuepointIndex + 1 < gameCuepoints.length) {
        this.nextGameCuepointIndex++;
      }

      this.prevGameCuepoint = nextGameCuepoint.position;
    }
  }

  setCurrentGame(cuepoint) {
    if (!this.props.games) return;

    const game = this.props.games.find((g) => g.position * 1000 === cuepoint);

    if (game) {
      this.setState({
        currentGameId: game.id,
      });
    }
  }

  onCurrentGameDismiss = () => {
    this.setState({
      currentGameId: null,
    });
  };

  onSearchGameStart = () => {
    this.setState({
      searchGameCurrentlyActive: true,
      isTransparent: true,
    });
  };

  onSearchGameEnd = () => {
    this.setState({
      searchGameCurrentlyActive: false,
    });
  };

  getGameCuepoints() {
    if (!this.props.games) return null;

    return getRelevantGameCuepoints(
      this.props.videoData,
      this.props.video?.duration,
      this.props.games,
      this.state.activeGameTypes,
    );
  }

  getAvailableGameCuepoints() {
    if (!this.props.games) return null;

    return getAvailableGameCuepoints(this.props.games, this.availableGameTypes);
  }

  // Recalculates the next cuepoint indices
  updateNextCuepointIndices = (currentTime) => {
    const { pauseCuepoints } = this.props.videoData;
    const gameCuepoints = this.getGameCuepoints();
    const timeMs = currentTime * 1000;

    if (pauseCuepoints) {
      const nextPauseCuepointIndex = findIndex(pauseCuepoints, (i) => i > timeMs);

      this.nextPauseCuepointIndex = nextPauseCuepointIndex > -1 ? nextPauseCuepointIndex : 0;
    }

    if (gameCuepoints) {
      const nextGameCuepointIndex = findIndex(gameCuepoints, (e) => e.position > timeMs);

      this.nextGameCuepointIndex = nextGameCuepointIndex > -1 ? nextGameCuepointIndex : 0;

      this.prevGameCuepoint = -1;
    }
  };

  handleAutoPlayChange = () => {
    const autoPlay = !this.state.autoPlay;

    if (autoPlay) {
      this.updateNextCuepointIndices(this.props.video.currentTime);
    }

    this.setState({ autoPlay });
  };

  handleSeekChange = (event) => {
    const seekTime = (event.target.value * this.props.video.duration) / 100;
    this.updateNextCuepointIndices(seekTime);

    this.props.onSeekChange(event);
  };

  toggleGameType = (gameType) => {
    if (iPhone) return this.iPhoneGameInfo();

    if (this.state.activeGameTypes.includes(gameType)) {
      this.setState({
        activeGameTypes: this.state.activeGameTypes.filter((i) => i !== gameType),
      });
    } else {
      this.setState({
        activeGameTypes: [...this.state.activeGameTypes, gameType],
      });
    }
  };

  maybeLaunchGameOnBoardstoryEnd() {
    const gameCuepoints = this.getGameCuepoints();

    // A game cuepoint of -1 (auto-converted to -1000 ms) implies the end of boardstory
    if (gameCuepoints.filter((e) => e.position === -1000).length > 0) {
      this.setCurrentGame(-1000);
    }
  }

  onVideoEnd = () => {
    this.maybeLaunchGameOnBoardstoryEnd();

    this.props.handleVideoEnd && this.props.handleVideoEnd();
  };

  toggleTrailer = (e) => {
    const prevIsTrailer = this.props.currentVideoType === 'trailers';

    if (prevIsTrailer && !this.props.canPlayFullVideos)
      return this.props.openModal({
        buttonValue: 'Lizenz prüfen',
        pushTo: '/account/license',
        content:
          'Um die Boardstory in voller Länge abspielen zu können, müssen Sie eine gültige und nicht abgelaufene Lizenz besitzen.',
      });

    this.props.onPlayRequest &&
      this.props.onPlayRequest(e, prevIsTrailer ? 'videos' : 'trailers', {
        label: prevIsTrailer ? 'button-video-play' : 'button-trailer-play',
      });
  };

  getControl = (control, i) => {
    if (!control) return <div className="player-control"></div>;
    const { copy, video, onVolumeClick, onFullscreenClick, videoData, fullscreenControls, videoHasAudio } = this.props;

    if (!this.state.isFullscreen && fullscreenControls.indexOf(control) > -1) {
      return null;
    }

    const controlsProps = {
      storyName: this.props.boardstorySlug,
      wrapperClassName: `player-control-${control.toLowerCase()}`,
    };
    const { seekPoints, pauseCuepoints } = this.props.videoData;

    switch (control) {
      case 'Seek':
        return (
          <Seek
            key={i}
            ariaLabel={copy.seek}
            onChange={this.handleSeekChange}
            cuePoints={videoData.pauseCuepoints}
            gameCuePoints={this.getGameCuepoints()}
            {...video}
            {...controlsProps}
          />
        );
      case 'AutoPlay':
        if (!this.isActiveForAccessCode('autoplayEnabled'))
          return (
            <PlayerControl key={i} className="is-off" active={false} off={true} title="Kein Autoplay" {...controlsProps}>
              <AutoPlayIcon />
            </PlayerControl>
          );
        if (!pauseCuepoints) return null;
        return (
          <PlayerControl
            key={i}
            active={this.state.autoPlay}
            onClick={this.handleAutoPlayChange}
            {...controlsProps}
            title={this.state.autoPlay ? `Autoplay \nein` : `Autoplay \naus`}
          >
            <AutoPlayIcon />
          </PlayerControl>
        );
      case 'PlayPause':
        return (
          <PlayerControl
            key={i}
            onClick={this.handlePlayPause}
            aria-label={video.paused ? copy.play : copy.pause}
            title={video.paused ? 'Play' : 'Pause'}
            {...controlsProps}
          >
            {video.paused ? <PlayIcon /> : <PauseIcon />}
          </PlayerControl>
        );
      case 'StartTigerCreation':
        return (
          <PlayerControl key={i} onClick={this.props?.onPlayRequest} aria-label="Start" title="Start" {...controlsProps}>
            <PlayIcon />
          </PlayerControl>
        );
      case 'Empty':
        return <div style={{ width: '74px' }}></div>;
      case 'JumpForward':
        if (!seekPoints) return null;
        return (
          <PlayerControl
            key={i}
            onClick={this.jumpForward}
            title="Vor"
            {...controlsProps}
            wrapperClassName={cc(['fullscreen-mr-auto', controlsProps.wrapperClassName])}
          >
            <SkipRightIcon />
          </PlayerControl>
        );
      case 'JumpBackward':
        if (!seekPoints) return null;
        return (
          <PlayerControl key={i} onClick={this.jumpBackward} title="Zurück" {...controlsProps}>
            <SkipLeftIcon />
          </PlayerControl>
        );
      case 'Fullscreen':
        const FullscreenIcon = this.state.isFullscreen ? FullscreenExitIcon : FullscreenEnterIcon;

        return (
          <PlayerControl
            key={i}
            className="is-reversed is-small has-shadow"
            active={true}
            onClick={onFullscreenClick}
            ariaLabel={copy.fullscreen}
            title={this.state.isFullscreen ? 'Vollbildmodus beenden' : 'Vollbildmodus'}
            toolTipPosition="bottom"
            {...controlsProps}
          >
            <FullscreenIcon width={30} height={30} />
          </PlayerControl>
        );
      case 'TimeElapsed':
        return <Time key={i} className="time-elapsed" showTimeElapsed={true} {...video} />;
      case 'Duration':
        return <Time key={i} className="duration" {...video} />;
      case 'VideoTextToggle':
        if (!this.isActiveForAccessCode('subtitlesEnabled'))
          return (
            <PlayerControl
              key={i}
              className="is-off"
              off={true}
              active={false}
              title={this.props.isExplain ? `Keine\nUntertitel` : `Kein Text`}
              {...controlsProps}
            >
              {this.props.isExplain ? <SpeechBubbleIcon /> : <TextIcon />}
            </PlayerControl>
          );

        return (
          <PlayerControl
            key={i}
            onClick={this.toggleVideoText}
            active={this.state.showVideoWithText}
            title={
              this.props.isExplain
                ? this.state.showVideoWithText
                  ? `Untertitel \nein`
                  : `Untertitel \naus`
                : this.state.showVideoWithText
                ? `Text \nein`
                : `Text \naus`
            }
            {...controlsProps}
          >
            {this.props.isExplain ? <SpeechBubbleIcon /> : <TextIcon />}
          </PlayerControl>
        );
      case 'Bookmark':
        if (this.state.isFullscreen) return null;

        return (
          <BoardstoryBookmark key={i} boardstoryId={this.props.boardstoryId}>
            {({ onClick, icon: Icon, isBookmarked }) => (
              <PlayerControl
                title={isBookmarked ? 'Gemerkt' : 'Merken'}
                active={isBookmarked}
                onClick={onClick}
                {...controlsProps}
              >
                <Icon />
              </PlayerControl>
            )}
          </BoardstoryBookmark>
        );

      case 'Trailer':
        if (this.state.isFullscreen) return null;

        //if () return (<div class="player-control"></div>)

        return (
          <PlayerControl
            key={i}
            title={videoData.isTrailer ? 'Zur Vollversion' : 'Zum Trailer'}
            active={this.props.currentVideoType === 'trailers'}
            off={!this.props.hasTrailer}
            onClick={this.toggleTrailer}
            {...controlsProps}
            wrapperClassName={cc(['inline-ml-auto', controlsProps.wrapperClassName])}
          >
            <TrailerIcon />
          </PlayerControl>
        );

      case 'Volume':
        if (!videoHasAudio || !this.isActiveForAccessCode('soundEnabled'))
          return (
            <PlayerControl
              key={i}
              className="is-off strike-through"
              off={true}
              active={false}
              ariaLabel={copy.nosound}
              title={`Kein Ton\nverfügbar`}
              {...controlsProps}
            >
              <SoundOffIcon />
            </PlayerControl>
          );

        return (
          <PlayerControl
            key={i}
            onClick={onVolumeClick}
            active={!video.muted}
            ariaLabel={video.muted ? copy.unmute : copy.mute}
            title={video.muted ? 'Ton \naus' : 'Ton \nein'}
            {...controlsProps}
          >
            {video.muted ? <SoundOffIcon /> : <SoundOnIcon />}
          </PlayerControl>
        );
      case BoardstoryGameTypes.Impulse:
      case BoardstoryGameTypes.Quiz:
      case BoardstoryGameTypes.Search:
        const Icon = GameIcons[control];
        const isTrailer = this.props.currentVideoType === 'trailers';

        if (
          (control == BoardstoryGameTypes.Search && !this.isActiveForAccessCode('searchGameEnabled')) ||
          (control == BoardstoryGameTypes.Quiz && !this.isActiveForAccessCode('quizEnabled')) ||
          (control == BoardstoryGameTypes.Impulse && !this.isActiveForAccessCode('impulseEnabled'))
        ) {
          return (
            <PlayerControl
              key={i}
              className="is-off"
              off={true}
              active={false}
              title={gameTypeLabels[control]}
              {...controlsProps}
            >
              <Icon style={{ color: '#d8d8d8' }} />
            </PlayerControl>
          );
        }

        if (!Icon || !this.availableGameTypes.includes(control)) return;

        return (
          <PlayerControl
            key={i}
            onClick={() => this.toggleGameType(control)}
            active={!iPhone && this.state.activeGameTypes.includes(control)}
            off={
              (isTrailer && !trailerHasGameType(this.props?.video?.duration, control, this.getAvailableGameCuepoints())) ||
              !this.availableGameTypes.includes(control)
            }
            title={
              !iPhone &&
              this.state.activeGameTypes.includes(control) &&
              !(isTrailer && !trailerHasGameType(this.props?.video?.duration, control, this.getAvailableGameCuepoints()))
                ? `${gameTypeLabels[control]} \nein`
                : `${gameTypeLabels[control]} \naus`
            }
            {...controlsProps}
          >
            <Icon />
          </PlayerControl>
        );

      default:
        return null;
    }
  };

  renderControls() {
    return (
      <ControlsWrapper
        onMouseOver={this.handleMouseOverControls}
        onMouseOut={this.handleMouseOutControls}
        className={cc([
          'player-controls',
          { 'is-fullscreen': this.state.isFullscreen, 'is-transparent': this.state.isTransparent },
        ])}
      >
        {this.props.buildControls(this.props.games).map(this.getControl)}
      </ControlsWrapper>
    );
  }

  renderTigerControls() {
    return (
      <ControlsWrapper
        onMouseOver={this.handleMouseOverControls}
        onMouseOut={this.handleMouseOutControls}
        className="player-controls is-tiger"
      >
        {this.props.buildTigerControls().map(this.getControl)}
      </ControlsWrapper>
    );
  }

  play = () => {
    if (!this.isActiveForAccessCode('soundEnabled')) mute(this.props.videoEl);
    let isPlaying =
      this.props.videoEl.currentTime > 0 &&
      !this.props.videoEl.paused &&
      !this.props.videoEl.ended &&
      this.props.videoEl.readyState > this.props.videoEl.HAVE_CURRENT_DATA;
    this.props.videoEl && !isPlaying && this.props.videoEl.play();
  };

  pause = () => {
    this.props.videoEl && this.props.videoEl.pause();
  };

  handleMouseOver = () => {
    this.setState({ hovered: true });
  };

  handleMouseOut = () => {
    this.setState({ hovered: false });
  };

  handleMouseOverControls = () => {
    this.setState({ controlsHovered: true });
  };

  handleMouseOutControls = () => {
    this.setState({ controlsHovered: false });
  };

  render() {
    const videoType = this.state.showVideoWithText ? 'withText' : 'withoutText';
    const className = cc([
      this.props.className,
      {
        'is-hidden': this.props.hideInlinePlayer && !this.state.isFullscreen,
      },
    ]);
    const controls = this.props.buildControls(this.props.games);
    const shouldRenderControls = controls && controls.length && !this.props.video.error;

    // (admin-only feature) used to track mouse cursor position to display game coordinates
    const playerHoverHandlers = this.props.isAdmin
      ? {
          onMouseOver: this.handleMouseOver,
          onMouseOut: this.handleMouseOut,
        }
      : {};

    if (this.props.nativePlayerUI) {
      return (
        <Thumbnail className="video-container">
          <video
            controls
            className={cc([
              'video-player',
              { 'is-fullscreen': this.state.isFullscreen, 'is-transparent': this.state.isTransparent },
            ])}
            data-storyname={this.props.boardstorySlug}
            ref={this.props.playerRef}
            onTimeUpdate={this.onProgress}
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
          >
            {this.props.videoData[videoType]?.map(({ url, mimeType }, i) => (
              <source key={i} src={url} type={mimeType} />
            ))}
          </video>
        </Thumbnail>
      );
    }

    const videoAspectRatio = this.props.videoEl?.videoWidth / this.props.videoEl?.videoHeight;
    const aspectRatio = isNaN(videoAspectRatio) ? 4 / 3 : videoAspectRatio;

    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // bug in safari shows artefacts in webm videos so we change the order for safari browsers, to prefer mp4
    if (isSafari && this.props.videoData[videoType][0]?.mimeType == 'video/webm') {
      this.props.videoData[videoType] = this.props.videoData[videoType].reverse();
    }

    // mute video if sound is disabled
    if (this.props.videoEl && !this.isActiveForAccessCode('soundEnabled')) mute(this.props.videoEl);

    // if tigerCreations
    if (this.props.currentVideoType == 'tigerCreations') {
      return (
        <div>
          <PlayerContainer
            tabIndex={0}
            className={className}
            ref={this.setPlayerContainerRef}
            style={this.props.style}
            onEnded={this.onVideoEnd}
            onKeyDown={this.handleKeyDown}
            {...playerHoverHandlers}
          >
            <video
              onClick={this.props.onPlayRequest}
              ref={this.setPlayerRef}
              poster={this.props.teaserImage?.path?.large}
              style={{ cursor: 'pointer' }}
            ></video>
          </PlayerContainer>
          {shouldRenderControls && !this.state.isFullscreen && !this.props.hideInlinePlayer && this.renderTigerControls()}
        </div>
      );
    }

    return (
      <div>
        <PlayerContainer
          tabIndex={0}
          className={className}
          ref={this.setPlayerContainerRef}
          style={this.props.style}
          onEnded={this.onVideoEnd}
          onKeyDown={this.handleKeyDown}
          {...playerHoverHandlers}
        >
          <video
            className={cc([
              'video-player',
              { 'is-fullscreen': this.state.isFullscreen, 'is-transparent': this.state.isTransparent },
            ])}
            data-storyname={this.props.boardstorySlug}
            controls={iOS && this.state.isFullscreen}
            ref={this.setPlayerRef}
            onTimeUpdate={this.onProgress}
          >
            {this.props.videoData[videoType]?.map(({ url, mimeType }, i) => (
              <source key={i} src={url} type={mimeType} />
            ))}
          </video>
          {!iOS && (
            <Overlay
              className={cc(['player-overlay', { 'is-transparent': this.state.isTransparent }])}
              onClick={this.handlePlayPause}
              storyName={this.props.boardstorySlug}
              {...this.props.video}
            />
          )}
          {this.props.isAdmin && this.state.hovered && <GameCoordinatesTooltip aspectRatio={aspectRatio} />}

          {shouldRenderControls && !iOS && (
            <>
              <FullscreenButton>{this.getControl('Fullscreen')}</FullscreenButton>
              <BottomControls>
                <TimeControlList>
                  {(!this.state.isTransparent || !this.state.isFullscreen) && this.getControl('Duration')}
                  {(!this.state.isTransparent || !this.state.isFullscreen) && this.getControl('TimeElapsed')}
                </TimeControlList>
                {(!this.state.isTransparent || !this.state.isFullscreen) && this.getControl('Seek')}

                {this.state.isFullscreen && this.renderControls()}
              </BottomControls>
            </>
          )}

          {this.props.videoEl &&
            // this.props.currentVideoType !== 'trailers' &&
            this.props.games &&
            this.props.games.length > 0 && (
              <BoardstoryGames
                gameTypes={getActiveGameTypes(this.availableGameTypes, this.state.activeGameTypes)}
                isFullscreen={this.state.isFullscreen}
                currentGameId={this.state.currentGameId}
                onCurrentGameDismiss={this.onCurrentGameDismiss}
                onSearchGameStart={this.onSearchGameStart}
                onSearchGameEnd={this.onSearchGameEnd}
                boardstoryTitle={this.props.boardstoryTitle}
                games={this.props.games}
                aspectRatio={aspectRatio}
                time={this.props.videoEl.currentTime}
                ended={this.props.videoEl.ended}
                onRequestPause={this.pause}
                onRequestPlay={this.play}
              />
            )}
        </PlayerContainer>

        {shouldRenderControls && !this.state.isFullscreen && !this.props.hideInlinePlayer && this.renderControls()}
      </div>
    );
  }
}

const connectedPlayer = videoConnect(
  connect(null, { openModal })(VideoPlayer),
  ({ networkState, readyState, error, ...restState }) => ({
    video: {
      readyState,
      networkState,
      error: error || networkState === 3,
      // TODO: This is not pretty. Doing device detection to remove
      // spinner on iOS devices for a quick and dirty win. We should see if
      // we can use the same readyState check safely across all browsers.
      loading: readyState < (iOS ? 1 : 4),
      percentagePlayed: getPercentagePlayed(restState),
      percentageBuffered: getPercentageBuffered(restState),
      ...restState,
    },
  }),
  (videoEl, state) => ({
    videoEl,
    onFullscreenClick: () => toggleFullscreen(videoEl.parentElement),
    onVolumeClick: () => toggleMute(videoEl, state),
    onPlayPauseClick: () => togglePause(videoEl, state),
    onSeekChange: (e) => setCurrentTime(videoEl, state, (e.target.value * state.duration) / 100),
  }),
);

export default connectedPlayer;
