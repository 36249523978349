import React from 'react';
import { Container } from '../../../components/StyledElements';

export default ({ items }) => {
  if (!items || !items.length) return null;

  return (
    <div className="bg-blue">
      <Container className="flex flex-col justify-center items-center text-white text-center italic p-5 space-y-6 md:space-y-0 md:flex-row md:p-14 md:justify-around">
        {items.map((i) => (
          <div key={i.entity}>
            <div className="text-4xl md:text-6xl">{i.number}</div>
            <div className="text-xl md:text-3xl">{i.entity}</div>
          </div>
        ))}
      </Container>
    </div>
  );
};
