import styled from 'styled-components';
import { ButtonLink, BaseButton } from '../StyledElements';
import media from '../../utils/media';
import HeaderGradient from '../../assets/icons/HeaderGradient.svg';
import HeaderGradientMobile from '../../assets/icons/HeaderGradientM.svg';

export const LogoWrapper = styled.div`
  margin: 0 auto;
  ${media.lg` margin: 0; `};

  .logo {
    transition: all 0.2s ease-in;
    width: 123px;
    height: 28px;

    ${media.md`
      width: 158px;
      height: 35px;
    `};
  }
  a {
    text-decoration: none;
  }
`;

export const HeaderContainer = styled.header`
  position: relative;
  height: 80px;
  background-color: #27b0d4;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
  transition: height 0.2s ease-in;
  z-index: 100;

  ${media.md`
    height: 100px;
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${media.lg`
    padding-left: 80px;
    padding-right: 80px;
  `};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${HeaderGradientMobile});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
    z-index: -1;

    ${media.md` background-image: url(${HeaderGradient}); `};
  }

  &.is-sticky {
    height: 60px;

    .logo {
      width: 105px;
      height: 23px;
    }
  }

  a,
  a:hover {
    color: inherit;
  }
`;

export const NavSeparator = styled.div`
  display: inline-block;
  margin-right: 24px;
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  color: #fff;

  ${media.lg` margin-right: 32px; `};

  &::after {
    content: '|';
  }
`;

export const TestButtonLink = styled(ButtonLink)`
  width: 114px;
  min-width: 0;
  height: 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1;
  text-align: center;
  padding: 6px 10px;
  display: inline-flex;
  align-items: center;
`;

export const AccessCodeButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  right: 36px;
  opacity: 1;
  transition: opacity 0.3s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;
