import { BaseButton, FlexCenter } from '../StyledElements';
import { Collapsible, TriggerArrow } from '../Accordion';
import { exampleFields, exampleOptions } from './exampleFilterData';
import concat from 'lodash/fp/concat';
import FilterSection from '../Filter/FilterSection';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import React from 'react';
import sortBy from 'lodash/fp/sortBy';
import styled from 'styled-components';
import uniq from 'lodash/fp/uniq';
import { fixedTagCategoryOrder } from '../../config';

const FilterSectionTrigger = styled(BaseButton)`
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;
  color: #f26521;
  padding-right: 55px;
  position: relative;
  margin-bottom: 2rem;
`;

const renderTrigger = ({ isOpen, onClick, trigger }) => {
  return (
    <FlexCenter>
      <FilterSectionTrigger onClick={onClick}>
        {trigger + ' '}
        <TriggerArrow
          strokeColor={'#f26521'}
          rightOffset={20}
          flipped={isOpen}
        />
      </FilterSectionTrigger>
    </FlexCenter>
  );
};

const getOptions = (tags) =>
  tags.reduce((acc, { category, descriptor, id, sortPriority }) => {
    acc[category] = flow(
      concat({
        category,
        value: id,
        name: descriptor,
        sortPriority
      }),
      sortBy(['sortPriority'])
    )(acc[category] || []);

    return acc;
  }, {});

const getFields = (tags) =>
  flow(
    map((t) => t.category),
    uniq,
    sortBy((t) => fixedTagCategoryOrder[t]),
    map((c) => ({ name: c, placeholder: c }))
  )(tags);

const BoardstoryFilterSection = ({ tags }) => {
  const options = getOptions(tags);
  const fields = getFields(tags);

  return (
    <Collapsible
      overflowWhenOpen="visible"
      trigger="Boardstories filtern"
      renderTrigger={renderTrigger}
    >
      <FilterSection
        options={options || exampleOptions}
        fields={fields || exampleFields}
        shouldRenderFilterName={true}
        colNumber={3}
      />
    </Collapsible>
  );
};

BoardstoryFilterSection.propTypes = {
  // An array of filters available
  // See exampleFilters for reference
  fields: PropTypes.arrayOf(PropTypes.object),
  // An object with keys corresponding to the filter names
  // See exampleOptions for reference
  options: PropTypes.object
};

export default BoardstoryFilterSection;
