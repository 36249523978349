import React from "react";

const Report = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path d="M12.984 12.984v-6h-1.969v6h1.969zM12 17.297c.703 0 1.313-.609 1.313-1.313s-.609-1.266-1.313-1.266-1.313.563-1.313 1.266.609 1.313 1.313 1.313zM15.75 3L21 8.25v7.5L15.75 21h-7.5L3 15.75v-7.5L8.25 3h7.5z" />
  </svg>
);

export default Report;

