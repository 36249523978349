import React from 'react';
import isString from 'lodash/isString';
import { NavLink } from 'react-router-dom';

const FlexibleLink = ({ to, children, ...restProps }) => {
  if (to && isString(to) && to.startsWith('/')) {
    return <NavLink to={to} children={children} {...restProps} />;
  }

  return <a href={to} children={children} {...restProps} />;
};

export default FlexibleLink;
