import React, { Fragment } from 'react';
import styled from 'styled-components';
import { InfoHeadline } from './StyledElements';
import InteractiveHeadline from './InteractiveHeadline';
import { joinValues, hasNonEmptyValues } from '../utils/helpers';

const TextRow = styled.p`
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.25;
  margin: 0;
  padding: 0;
`;

const RowHeader = styled.p`
  opacity: 0.6;
  font-size: 1em;
  font-style: italic;
  line-height: 1.5;
  margin: 1em 0 0;
`;

export default ({ headlineValue, values, defaultText, onButtonClick }) => {
  const renderHeader = () => {
    if (!headlineValue) return null;

    return (
      <InteractiveHeadline
        headlineValue={headlineValue}
        buttonValue="Bearbeiten"
        onClick={onButtonClick}
      />
    );
  };

  if (!values) {
    return null;
  }  
  
  if (!hasNonEmptyValues(values) && defaultText) {
    return (
      <div>
        {renderHeader()}
        <InfoHeadline>{defaultText}</InfoHeadline>
      </div>
    );
  }

  const {
    salutation,
    firstName,
    lastName,
    street,
    streetNumber,
    zip,
    city,
    country,
    phone,
    email,
    name,
    additionalInformation1,
    additionalInformation2
  } = values;  

  const fullName = joinValues(salutation, firstName, lastName);

  return (
    <div>
      {renderHeader()}
      {name && name !== fullName && <TextRow>{name}</TextRow>}
      <TextRow>{fullName}</TextRow>
      <TextRow>
        {street} {streetNumber}
      </TextRow>
      {additionalInformation1 && <TextRow>{additionalInformation1}</TextRow>}
      {additionalInformation2 && <TextRow>{additionalInformation2}</TextRow>}
      {zip &&
        city &&
        country && (
          <TextRow>
            {zip} {city} / {country}
          </TextRow>
        )}
      {phone && (
        <Fragment>
          <RowHeader>Telefon</RowHeader>
          <TextRow>{phone}</TextRow>
        </Fragment>
      )}
      {email && (
        <Fragment>
          <RowHeader>E-Mail</RowHeader>
          <TextRow>{email}</TextRow>
        </Fragment>
      )}
    </div>
  );
};
