import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
`;

function noop() {}

function format(value, pattern) {
  if (!pattern) return value;

  const placeholder = '#';

  let endOfValue = 0;
  let characterIndex = 0;
  let newValue = value;

  return [...pattern]
    .map((patternCharacter, index) => {
      const character = newValue[characterIndex];
      const patternLength = pattern.length;

      if (!endOfValue) {
        if (index === patternLength - 1) endOfValue = patternLength;
        if (character === undefined)
          endOfValue = pattern.indexOf(placeholder, index);
      }

      if (patternCharacter === placeholder) {
        characterIndex = characterIndex + 1;
        return character;
      }

      return patternCharacter;
    })
    .splice(0, endOfValue)
    .join('');
}

function stripPatternCharacters(value) {
  return value.replace(/[^\dA-z]/g, '');
}

function isUserCharacter(character) {
  return /[\dA-z]/.test(character);
}

const AutoFormatInput = ({
  onChange = noop,
  onValueChange = noop,
  format: pattern,
  userValue,
  licenceEndDate,
  licenceStartDate,
  ...rest
}) => {
  const [value, setValue] = useState(format(userValue, pattern));
  const [lastValidDate, setLastValidDate] = useState(format(userValue, pattern));
  const [isFocused, setIsFocused] = useState(false);
  const [dateInputError, setDateInputError] = useState('');
  const inputRef = useRef();
  const infoRef = useRef({});

  const isValidDate = (date) => {
    const dateRegex = /^(\d{1,2}).(\d{1,2}).(\d{4})$/;
    const match = date.match(dateRegex);
    if (!match) return false;
    return true
  };


  function handleChange(event) {
    const { target } = event;
    const { value: inputValue, selectionStart: cursorPosition } = target;
    const didDelete = inputValue.length < value.length;

    infoRef.current.cursorPosition = cursorPosition;

    let rawValue = stripPatternCharacters(inputValue);

    if (didDelete) {
      const patternCharacterDeleted = !isUserCharacter(
        [...value][cursorPosition]
      );

      if (patternCharacterDeleted) {
        const firstBit = inputValue.substr(0, cursorPosition);
        const rawFirstBit = stripPatternCharacters(firstBit);

        rawValue =
          rawFirstBit.substr(0, rawFirstBit.length - 1) +
          stripPatternCharacters(
            inputValue.substr(cursorPosition, inputValue.length)
          );

        infoRef.current.cursorPosition =
          firstBit.replace(/([\d\w]+)[^\dA-z]+$/, '$1').length - 1;
      }
    }

    const formattedValue = format(rawValue, pattern);

    infoRef.current.endOfSection = false;

    if (!didDelete) {
      const formattedCharacters = [...formattedValue];
      const nextCharacter = formattedCharacters[cursorPosition];
      const nextCharacterIsPattern = !isUserCharacter(nextCharacter);
      const nextUserCharacterIndex = formattedValue
        .substr(cursorPosition)
        .search(/[\dA-z]/);
      const numbersAhead = nextUserCharacterIndex !== -1;

      infoRef.current.endOfSection = nextCharacterIsPattern && !numbersAhead;

      if (
        nextCharacterIsPattern &&
        !isUserCharacter(formattedCharacters[cursorPosition - 1]) &&
        numbersAhead
      )
        infoRef.current.cursorPosition =
          cursorPosition + nextUserCharacterIndex + 1;
    }


    if(!isFocused || isValidDate(formattedValue)) {
      const dateRegex = /^(\d{1,2}).(\d{1,2}).(\d{4})$/;
      const match = formattedValue.match(dateRegex);

      if (!match) {
        setValue(formattedValue);
        return
      }

      const date = new Date(match[3], match[2] - 1, match[1])
      if (date > new Date(licenceEndDate).setHours(23, 59, 59, 999)) {
        setDateInputError(`Ungültige Datumseingabe. \nBitte geben Sie ein Datum innerhalb Ihrer Lizenzlaufzeit ein.`)
        setValue(formattedValue);
        return
      } else if (date < new Date().setHours(0, 0, 0, 0)) {
        setDateInputError('Das Startdatum muss in der Zukunft liegen.')
        setValue(formattedValue);
        return
      } else {
        setDateInputError('')
        setLastValidDate(formattedValue);
        onValueChange(date);
        onChange(event);
        setValue(formattedValue);
      }
    } else {
      setDateInputError("Ungültige Datumseingabe. \nBitte Datum im Format DD.MM.YYYY eingeben.")
      setValue(formattedValue);
    }
  }

  useEffect(() => {
    const { cursorPosition, endOfSection } = infoRef.current;

    if (endOfSection) return;

    inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  }, [value]);

  useEffect(() => {
    if(isFocused) return;
    setDateInputError('')
    setValue(userValue);
  }, [userValue]);

  return (
    <>
      <input
        maxLength={pattern && pattern.length}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={inputRef}
        value={value}
        placeholder='DD.MM.YYYY'
        {...rest}
      />
      <ErrorMessage>{dateInputError}</ErrorMessage> 
    </>
    
  );
}

export default AutoFormatInput;