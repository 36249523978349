import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getUserAccount } from '../actions/user';
import { onLoginSuccess } from '../actions/auth';
import { connect } from 'react-redux';

class AutoLogin extends Component {
  componentDidMount() {
    this.props.getUserAccount();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.account.id && this.props.user.account.id) {
      this.props.onLoginSuccess();
    }
  }

  render() {
    return this.props.children || null;
  }
}

const select = (state) => ({
  user: state.user,
});

const connectedAutoLogin = connect(
  select,
  { getUserAccount, onLoginSuccess }
)(AutoLogin);

export default withRouter(connectedAutoLogin);
