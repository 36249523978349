import { apiFetch } from '../modules/api';
export const LOGIN = 'auth/LOGIN';
export const LOGOUT = 'auth/LOGOUT';

export const onLoginSuccess = () => ({
  type: LOGIN
});

export const onLogoutSuccess = () => ({
  type: LOGOUT
});

export const onLogoutRequest = () => (dispatch) => {
  return apiFetch
    .url('/auth/logout')
    .post()
    .res(() => {
      dispatch(onLogoutSuccess());
    })
    .catch(() => dispatch(onLogoutSuccess()));
};