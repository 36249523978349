import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Accordion extends Component {
  static defaultProps = {
    closeable: true
  };

  constructor(props) {
    super(props);

    this.state = {
      openPosition: !isNaN(props.initialPosition) ? props.initialPosition : null
    };
  }

  handleTriggerClick = (position) => {
    const nextOpenPosition =
      this.props.closeable && position === this.state.openPosition
        ? null
        : position;

    this.setState({
      openPosition: nextOpenPosition
    });

    this.props.onTriggerClick && this.props.onTriggerClick(position);
  };

  render() {
    return (
      <Fragment>
        {this.props.children.map((node, index) =>
          React.cloneElement(node, {
            key: 'Collapsible' + index,
            handleTriggerClick: this.handleTriggerClick,
            open: this.state.openPosition === index,
            accordionPosition: index
          })
        )}
      </Fragment>
    );
  }
}

Accordion.propTypes = {
  onTriggerClick: PropTypes.func,
  closeable: PropTypes.bool,
  children: PropTypes.array,
  initialPosition: PropTypes.number
};

export default Accordion;
