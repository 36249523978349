import React from 'react';
import FilterSection from '../Filter/FilterSection';
import PropTypes from 'prop-types';

const TopicAreaFilter = ({ tags }) => {
  return (
    <FilterSection
      centerFilter
      options={{
        category: tags,
      }}
      fields={[
        {
          name: 'category',
          placeholder: 'Art der Themenwelt',
          searchable: true,
        },
      ]}
    />
  );
};

TopicAreaFilter.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TopicAreaFilter;
