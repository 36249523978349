import styled from 'styled-components';
import { PageContainer } from '../../components/StyledElements';
import media from '../../utils/media';
import ConfettiIcon from '../../assets/icons/Confetti.svg';

export const HugeHeadline = styled.h1`
  font-size: 100px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  text-align: center;
  margin: 0;

  ${media.md` font-size: 150px; `};
  ${media.lg` font-size: 200px; `};
`;

export const PageContent = styled.div`
  margin-top: 29px;
  font-size: 1.5rem;
  font-weight: normal;
  font-style: italic;
  text-align: center;

  ${media.md` font-size: 2rem; `};
  ${media.lg` font-size: 2.25rem; `};

  p {
    margin: 0 0 1rem;
  }
`;

export const CustomPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  max-height: 1024px;
`;

export const PageBackground = styled.div`
  background-image: url(${ConfettiIcon});
  background-size: cover;
  background-repeat: repeat;
  overflow: auto;
`;