import { useState, useCallback } from 'react';
import { BoardstoryGameTypes } from './types';

export const isWithinTimeWindow = (g, currentTime, window) => {
  return Math.abs(g.position - currentTime) < window / 2;
};

export default function useBoardstoryGamesSharedUI({
  games,
  time,
  ended,
  currentGameId,
  onRequestPlay,
  onRequestPause,
  onCurrentGameDismiss,
}) {
  const currentImpulseGames = games.filter(
    (g) => g.gameType === BoardstoryGameTypes.Impulse && isWithinTimeWindow(g, time, 2)
  );
  const currentSearchGame = games.find(
    (g) => g.gameType === BoardstoryGameTypes.Search && isWithinTimeWindow(g, time, 2)
  );
  const currentGame = games.find((g) => g.id === currentGameId);
  const [activeImpulseId, setActiveImpulseId] = useState(null);
  
  const onImpulseToggle = (id) => {
    if (activeImpulseId === id) {
      onImpulseClose();
    } else {
      onImpulseOpen(id);
    }
  };
  
  const onImpulseOpen = useCallback((id) => {
    setActiveImpulseId(id);
    onRequestPause && onRequestPause();
  }, []);

  const onImpulseClose = useCallback(() => {
    setActiveImpulseId(null);
    onRequestPlay && onRequestPlay();
  }, []);

  const onSearchGameStatusChange = (active) => {
    if (active) {
      onRequestPause();
    } else {
      onGameEnd();
    }
  };

  const onGameEnd = () => {
    onCurrentGameDismiss();
    if (!ended) {
      onRequestPlay();
    }
  };
  
  const activeImpulse = games.find(
    (i) => i.id === activeImpulseId && i.gameType === BoardstoryGameTypes.Impulse
  );

  return {
    currentGame,
    currentSearchGame,
    currentImpulseGames,
    activeImpulseId,
    activeImpulse,
    onImpulseToggle,
    onImpulseOpen,
    onImpulseClose,
    onSearchGameStatusChange,
    onGameEnd,
  };
}
