import React, { useState, useEffect } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import { Spinner } from './StyledElements';

const Placeholder = ({ children, loading, animationDelay, className }) => {
  const [showAnimation, setShowAnimation] = useState(!animationDelay);

  useEffect(() => {
    let willUnmount = false;
    let animationTimeout;

    if (animationDelay && animationDelay > 0) {
      animationTimeout = window.setTimeout(() => {
        if (!willUnmount) {
          setShowAnimation(true);
        }
      }, animationDelay);
    }

    return () => {
      willUnmount = true;

      if (animationTimeout) {
        window.clearTimeout(animationTimeout);
      }
    };
  }, [animationDelay]);

  if (loading) {
    return <div className={cc([className, 'relative w-full'])}>{showAnimation && <Spinner />}</div>;
  }

  return children || null;
};

Placeholder.defaultProps = {
  loading: true,
  animationDelay: 750,
  className: 'min-h-[calc(100vh-80px)] md:min-h-[calc(100vh-100px)]',
};

Placeholder.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  ready: PropTypes.bool,
  animationDelay: PropTypes.number,
  className: PropTypes.string,
};

export default Placeholder;
