import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getVideoSizeFromContainer } from '../BoardstoryGames/shared/gameUtil';

const ContainerMeasurer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
`;

const Tooltip = styled.div`
  position: fixed;
  pointer-events: none;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 8px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  white-space: nowrap;
  font-size: 1rem;
  line-height: 1.5;
  justify-content: space-between;
  min-width: 130px;

  > span {
    flex: 1;
  }
`;

// Converts
const getCoordinateValue = (value) => {
  return Math.max(Math.min(Math.round(value * 10000), 10000), 0);
};

// Calculates the tooltip position based on coordinates to make sure it's always visible
const getTooltipTransform = ({ x, y }) => {
  const xPercent = x < 500 ? 0 : x < 9500 ? -50 : -100;
  const yPercent = y > 500 ? -125 : 125;

  return `translate(${xPercent}%, ${yPercent}%)`;
};

export default function GameCoordinatesTooltip({ aspectRatio }) {
  const ref = useRef();
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const handleTooltipPosition = ({ clientX, clientY }) => {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();

      const { videoSize, diff } = getVideoSizeFromContainer(
        {
          height: rect.height,
          width: rect.width,
        },
        aspectRatio,
      );

      // Calculate coordinates using the relative position of mouse cursor on the video
      let x = getCoordinateValue((clientX - rect.x - diff.width) / videoSize.width);
      let y = getCoordinateValue((clientY - rect.y - diff.height) / videoSize.height);

      setPosition({ top: clientY, left: clientX, x, y });
    };

    window.addEventListener('mousemove', handleTooltipPosition);

    return () => {
      window.removeEventListener('mousemove', handleTooltipPosition);
    };
  }, [aspectRatio]);

  return (
    <>
      <ContainerMeasurer ref={ref} />

      {position && (
        <Tooltip
          style={{
            top: position.top,
            left: position.left,
            transform: getTooltipTransform(position),
          }}
        >
          <span>X: {position.x}</span>&nbsp;&nbsp;&nbsp;<span>Y: {position.y}</span>
        </Tooltip>
      )}
    </>
  );
}
