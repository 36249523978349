import { InfoIcon } from '../../../components/StyledIcons';
import { TextBlock } from '../../../components/StyledElements';
import { isBefore, parseJSON } from 'date-fns';
import LicenseTeaser from '../../../components/License/LicenseTeaser';
import dateFormat from 'dateformat';
import PropTypes from 'prop-types';

const RentalUserVariant = ({ relevantMember }) => {
  const rentalUntil = parseJSON(relevantMember.rentalUntil);
  const hasActiveRental = isBefore(Date.now(), rentalUntil);

  return hasActiveRental ? (
    <>
      <LicenseTeaser name="Verleihzugang">
        <TextBlock centered bold italic lineHeigth={1.5}>
          Laufzeit bis:
        </TextBlock>
        <TextBlock mt={0.5} mb={1.5} centered italic fontSize={2.25}>
          {dateFormat(rentalUntil, 'dd.mm.yyyy')}
        </TextBlock>
      </LicenseTeaser>
      <TextBlock centered fontSize={1.25} lineHeigth={1.5}>
        <InfoIcon sideLength={1.2} /> Sie möchten Ihren Zugang verlängern?
        Bitte wenden Sie sich hierfür an Ihre Bibliothek.
      </TextBlock>
    </>
  ) : null;
};

RentalUserVariant.propTypes = {
  relevantMember: PropTypes.object.isRequired,
};

export default RentalUserVariant;
