import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import range from 'lodash/range';
import getPageRange from '../utils/getPageRange';
import { ReactComponent as LeftDoubleArrow } from '../assets/icons/LeftDoubleArrow.svg';
import { ReactComponent as RightDoubleArrow } from '../assets/icons/RightDoubleArrow.svg';

const PaginationContainer = styled.nav`
  text-align: center;
`;

const PageLinksList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    list-style-type: none;
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const PageLink = styled(Link)`
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  background-color: rgba(39, 176, 212, 0.2);
  font-size: 1.125em;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;
  text-decoration: none;
  color: #27b0d4;
  user-select: none;
  transition: background-color 0.3s;

  &.is-active {
    background-color: #f26521;
    color: #fff;
    cursor: initial;
  }

  &.arrow-icon {
    background-color: transparent;
  }
`;

const getUrlFromObject = (location) => {
  return location.pathname + '?' + location.search;
};

const Pagination = ({ currentPage, pagesCount, pagesToShow, history, location }) => {
  const queryString = qs.parse(location.search);

  const getPageUrl = (pageNumber) => ({
    pathname: location.pathname,
    search: qs.stringify({ ...queryString, p: pageNumber }),
  });

  const renderPageLink = (pageNumber) => {
    return (
      <li key={pageNumber}>
        <PageLink className={pageNumber === currentPage ? 'is-active' : undefined} to={getPageUrl(pageNumber)}>
          {pageNumber}
        </PageLink>
      </li>
    );
  };

  const { firstPage, lastPage } = getPageRange(currentPage, pagesCount, pagesToShow);

  return (
    <PaginationContainer>
      <Helmet>
        {currentPage > 1 && <link rel="prev" href={getUrlFromObject(getPageUrl(currentPage - 1))} />}
        {pagesCount > currentPage && <link rel="next" href={getUrlFromObject(getPageUrl(currentPage + 1))} />}
      </Helmet>
      <PageLinksList>
        {firstPage > 1 && (
          <li>
            <PageLink className="arrow-icon" to={getPageUrl(1)}>
              <LeftDoubleArrow />
            </PageLink>
          </li>
        )}
        {range(firstPage, lastPage + 1).map(renderPageLink)}
        {pagesCount > lastPage && (
          <li>
            <PageLink className="arrow-icon" to={getPageUrl(pagesCount)}>
              <RightDoubleArrow />
            </PageLink>
          </li>
        )}
      </PageLinksList>
    </PaginationContainer>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  pagesToShow: 5,
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pagesCount: PropTypes.number,
  pagesToShow: PropTypes.number,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Pagination);
