import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { NavLink as BaseNavLink, Link } from 'react-router-dom';
import media from '../utils/media';
import FlexibleLink from './FlexibleLink';
import CloseIcon from '../assets/icons/Close.svg';
import CloseIconWhite from '../assets/icons/CloseWhite.svg';
import LoaderImg from '../assets/icons/Loader@2x.png';
import IAMpng from '../assets/icons/IAM.png';
import LUXsvg from '../assets/icons/luxemburg.svg';
import ConfettiIcon from '../assets/icons/Confetti.svg';

export const FlexCenter = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  align-items: center;
  justify-content: center;
  flex-grow: ${(props) => (props.grow ? 1 : 0)};
`;

export const Container = styled.div`
  ${(props) => props.bgColor && `background-color: ${props.bgColor}`};
  ${(props) => props.position && `position: ${props.position}`};
  max-width: ${(props) => !props.fullScreen && (props.narrow ? 652 : 972)}px;
  ${(props) => !props.fullScreen && `padding: 0 1em`};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: ${(props) => props.theme.oniloBlue};

  &.fluid {
    max-width: 100%;
  }

  &.with-placeholder {
    /* Pushes the footer to the very bottom */
    min-height: calc(100vh - 80px);
    ${media.md` min-height: calc(100vh - 100px); `};
  }
`;

export const PageContainer = styled(Container)`
  margin-top: ${(props) => props.mt ?? 4.5}em;
  margin-bottom: 4.5em;

  ${media.md`
    margin-top: ${(props) => props.mt ?? 6}em;
    margin-bottom: 6em;
  `};

  &.no-mt {
    margin-top: 0;
  }
`;

export const AltContainer = styled(Container)`
  margin-top: 2.5rem;
  margin-bottom: 3rem;

  ${media.md`
    margin-top: 3rem;
    margin-bottom: 4.5rem; 
  `};

  ${media.lg`
    margin-top: 4.5rem;
    margin-bottom: 6rem; 
  `};
`;

export const H1 = styled.h1`
  font-weight: 400;
  font-size: 2em;
  font-style: italic;
  margin: 0 0 2.5rem;
  color: ${(props) => props.theme.oniloBlue};
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`};

  ${media.md` font-size: 2.25em; `};

  &.text-inherit {
    color: inherit;
  }
`;

export const PageHeadline = styled(H1)`
  text-align: center;
  margin: 4.5rem 0 2.5rem;

  ${media.md` margin: 6rem 0 4.5rem; `};
`;

export const FrontPageHeadline = styled(H1).attrs({ as: 'h2' })`
  text-align: center;
  margin: 3rem 0 2.5rem;
  ${media.md` margin: 4.5rem 0 3rem; `};
  ${media.lg` margin: 6rem 0 4.5rem; `};

  &.m-small {
    margin: 0 0 1rem;
  }
`;

export const PageSectionHeadline = styled(H1)`
  text-align: center;
  margin: 2.5rem 0;

  ${media.md`
    margin-top: 3rem;
    margin-bottom: 3rem; 
  `};

  ${media.lg`
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; 
  `};
`;

export const RentalSectionHeadline = styled(H1)`
  text-align: center;
  margin: 2.5rem 0;

  ${media.md`
    margin-top: 3rem;
    margin-bottom: 2rem; 
  `};
`;

export const FormSectionHeadline = styled(H1)`
  margin: 0 0 2rem;
`;

export const H2 = styled.h2`
  font-weight: 400;
  font-style: italic;
  font-size: 1.5em;
  margin: 0;
`;

export const Headline = styled(H2)`
  margin-bottom: 0.5rem;
`;

export const InfoHeadline = styled(H2)`
  color: #89d5e8;
`;

export const ResponsiveH2 = styled(H2)`
  ${media.md`
    font-size: 2.25em;
  `};
`;

export const MetaHeadline = styled.h3`
  text-align: center;
  font-style: italic;
  font-weight: bold;
  color: ${(props) => props.theme.oniloDarkOrange};
  font-size: 1rem;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;

  ${media.md`
    font-weight: normal;
    font-size: 1.5em;
  `};
`;

export const TextBlock = styled.p`
  font-size: ${(props) => props.fontSize || 1}rem;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  line-height: ${(props) => props.lineHeight || 1.25};
  margin-top: ${(props) => props.mt || 0}rem;
  margin-bottom: ${(props) => props.mb || 0}rem;
  ${(props) => props.color && `color: ${props.color};`};
  width: 100%;

  &.text-inherit {
    color: inherit;
  }
`;

export const BoldPassage = styled.span`
  font-weight: 700;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3em 0;

  &.flex-wrap {
    flex-wrap: wrap;
  }
`;

export const FlexColumn = styled.div`
  width: 100%;

  ${media.md`
    width: 50%;
    margin-bottom: 0;
  `};
`;

export const BaseButton = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    &.loading {
      cursor: wait;
    }
  }
`;

export const ButtonWithIcon = styled(BaseButton)`
  font-weight: bold;
  font-style: italic;
  line-height: 1.5;
  font-size: 1rem;
  svg {
    margin-left: 12px;
    margin-top: -3px;
    height: 36px;
    width: 36px;
  }

  &:disabled {
    span, svg {
      opacity: 0.4;
    }
  }

`;

export const Button = styled(BaseButton)`
  display: inline-block;
  min-width: 220px;
  height: 56px;
  border-radius: 28px;
  background-color: ${(props) => props.theme.oniloDarkOrange};
  padding: 16px 24px 13px;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #fff;
  transition: background-color 0.3s ease-in;
  text-decoration: none;
  ${(props) => props.mt && `margin-top: ${props.mt}rem;`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}rem;`};

  &.fluid {
    min-width: 0;
  }

  &.smaller,
  &.small,
  &.big,
  &.smallerNoHeight {
    border-radius: 24px;
    min-width: 0;
    height: auto;
  }

  &.smallerNoHeight {
    font-size: 1rem;
    padding: 15px 26px 13px;
  }

  &.smaller {
    height: 48px;
    font-size: 1rem;
    padding: 15px 26px 13px;
  }

  &.small {
    height: auto;
    font-size: 0.875rem;
    padding: 6px 14px 5px;
  }

  &.big {
    height: auto;
    font-size: 1rem;
    padding: 15px 26px 13px;
  }

  &.squarelike {
    height: auto;
    font-size: 0.875rem;
    border-radius: 4px;
    padding: 4px 8px 2px 6px;
  }

  &.blue {
    background-color: ${(props) => props.theme.oniloBlue};
  }

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.oniloLightOrange};
    color: #fff;
  }

  &:disabled,
  &.loading {
    background-color: #fff;
    border: solid 1px ${(props) => props.theme.oniloDarkOrange};
    color: ${(props) => props.theme.oniloDarkOrange};
  }

  &.outlined {
    background-color: transparent;
    border: solid 1px ${(props) => props.theme.oniloDarkOrange};
    color: ${(props) => props.theme.oniloDarkOrange};
  }

  &.with-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &.with-icon {
    display: inline-flex;
    align-items: center;
    padding-top: 13px;

    svg {
      margin-left: 0.4em;
    }
  }

  &.wide {
    min-width: 246px;
  }
`;

export const ExternalButtonLink = Button.withComponent('a');

export const ButtonLink = Button.withComponent(Link);

export const InlineButton = styled(BaseButton)`
  border-radius: 24px;
  border: solid 1px ${(props) => props.theme.oniloBlue};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: ${(props) => props.theme.oniloBlue};
  padding: 4px 10px 2px;
  transition: all 0.2s ease-in;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.oniloLightOrange};
    border-color: ${(props) => props.theme.oniloLightOrange};
    color: #fff;
  }
`;

export const CloseButton = styled(BaseButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;

  &.white {
    background-image: url(${CloseIconWhite});
  }

  &.small {
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
  }
`;

export const InlineItem = styled.span`
  border-radius: 4px;
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  font-size: 0.875em;
  padding: 4px 8px 2px 6px;
  color: #fff;
  background-color: ${(props) => props.theme.oniloDarkOrange};
  margin: 0.5rem;
  line-height: 1.25;
`;

export const ItemTags = styled.ul`
  padding: 0;
  margin: 0;
`;

export const ItemTag = styled(InlineItem.withComponent('li'))`
  list-style-type: none;
  margin: 0 8px 8px 0;
`;

export const Form = FlexCenter.withComponent('form');

export const Spacing = styled.div`
  width: 100%;
  margin-top: ${(props) => props.mt || 0}rem;
  margin-bottom: ${(props) => props.mb || 0}rem;
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`};
`;

export const BlockSpacing = styled(Spacing)`
  margin: 2.5rem 0;

  ${media.md`
    margin-top: 3rem;
    margin-bottom: 3rem; 
  `};

  ${media.lg`
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; 
  `};
`;

export const Column = styled.div`
  border: solid 10px transparent;
  box-sizing: border-box;
  background-clip: padding-box;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  max-width: 960px;
  width: 100%;

  > div {
    flex-grow: 1;
    flex-basis: 100%;
  }

  &.tablet > div {
    ${media.md` flex-basis: 0;`};
  }

  &.desktop > div {
    ${media.lg` flex-basis: 0;`};
  }
`;

export const BackgroundImage = styled.div`
  background-image: ${(props) => (props.url ? `url(${props.url})` : 'none')};
  background-position: center;
  background-size: ${(props) => props.size || 'contain'};
  width: 100%;
`;

export const SectionSeparator = styled.div`
  margin-top: ${(props) => props.mt || 0}rem;
  margin-bottom: ${(props) => props.mb || 0}rem;
  position: relative;
  width: 100%;
  display: block;
  height: 3px;

  &::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 3px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.oniloBlue};
  }

  &.is-centered::before {
    left: 50%;
    transform: translateX(-50%);
  }

  &.is-wide::before {
    width: 200px;
  }
`;

export const Separator = styled(SectionSeparator)`
  margin: 3em 0;

  ${media.md`
    margin-top: 4.5em;
    margin-bottom: 4.5em; 
  `};

  ${media.lg`
    margin-top: 6em;
    margin-bottom: 6em; 
  `};
`;

export const NarrowWrapper = styled.div`
  width: 100%;
  max-width: 460px;
  margin: 0 auto;

  &.until-desktop {
    ${media.lg` max-width: 100%; `};
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  ${media.md`
    > div:first-child {
      flex: 1;
    }
    > div:last-child {
      width: 33.33%;
      flex: 0 auto;
      margin-left: 0.5em;
    }
  `};
`;

export const ErrorContent = styled.div`
  display: block;
  font-size: 0.875rem;
  padding-top: 0.5rem;
  color: ${(props) => props.color || '#ff001f'};
`;

export const NavLink = styled(BaseNavLink)`
  display: inline-block;
  font-weight: normal;
  text-decoration: none;
  font-size: 1.25em;
  font-style: italic;
  line-height: 1;
  border: 0;
  outline: 0;
  cursor: pointer;
  user-select: none;

  &:hover {
    font-weight: normal;
    text-decoration: none;
  }

  &.active {
    font-weight: bold;
    border-bottom: 1px solid #fff;
  }

  &.is-dark {
    color: ${(props) => props.theme.oniloBlue};

    &.active {
      font-weight: 400;
      border-color: ${(props) => props.theme.oniloBlue};
    }
  }
`;

export const MenuItemLink = styled(FlexibleLink)`
  display: block;
  font-size: 1.5rem;
  font-style: italic;
  color: ${(props) => props.theme.oniloBlue};
  text-decoration: none;
  line-height: 1.25;
  padding: 5px 0;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.oniloLightOrange};
    font-weight: normal;
    text-decoration: none;
  }

  &.active {
    text-decoration: underline;
  }
`;

export const BoxShadow = styled.div`
  border-radius: 4px;
  background-color: ${(props) => (props.invertColors ? props.theme.oniloBlue : '#fff')};
  color: ${(props) => (props.invertColors ? '#fff' : 'inherit')};
  box-shadow: 0 8px 16px 4px rgba(0, 98, 124, 0.1);
`;

export const StickyShadow = styled.div`
  &.is-sticky {
    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 98, 124, 0.1);
    z-index: 100;
    background-color: ${(props) => props.bgColor || '#ecfbfe'};
  }
`;

export const Thumbnail = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  background-image: ${(props) => (props.bg ? `url(${props.bg})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;

  > div,
  > img,
  > video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 75%;
  }

  &.wide-screen::before {
    padding-top: 56.25%;
  }

  &.unklickable {
    pointer-events: none;
    cursor: initial;
  }

  &.standalone {
    border-radius: 4px;
    box-shadow: 0 8px 16px 4px rgba(0, 98, 124, 0.1);
  }

  &.darkened::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const PostContent = styled.div`
  p {
    margin-bottom: 2.5rem;
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    line-height: 1.6;
  }

  a {
    color: ${(props) => props.theme.oniloDarkOrange};
    font-style: italic;

    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  h1,
  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  figure {
    margin: 0;
  }

  .alignright {
    float: right;
    margin: 0 0 1em 1em;
  }
  .alignleft {
    float: left;
    margin: 0 1em 1em 0;
  }
  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  img {
    border-radius: 4px;
    max-width: 100%;
    height: auto;
  }
  .wp-caption {
    max-width: 100%;
  }
  .wp-caption-text {
    margin-top: 5px;
    &::before {
      content: '©';
      padding-right: 5px;
    }
  }
`;

export const ListItemContainer = styled(BoxShadow.withComponent('article'))`
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0;
  }

  ${media.md`
    flex-wrap: nowrap;
    padding: 8px;
  `};
`;

export const VideoContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
    height: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Figure = styled.figure`
  margin: 0;
  figcaption {
    margin-top: 5px;
  }
  img {
    border-radius: 4px;
  }
`;

export const ShortDescription = styled.p`
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
  color: ${(props) => props.theme.oniloBlue};
  margin: 0 0 3rem;

  ${media.md` margin-bottom: 4.5rem; `};
`;

const StyledBaseRoundInput = styled.input`
  background-color: #ecfbfe;
  border: solid 1px ${(props) => props.theme.oniloBlue};
  outline: 0;
  width: 100%;
`;

export const BaseRoundInput = ({ error, errorColor, ...inputProps }) => (
  <div>
    <StyledBaseRoundInput {...inputProps} />
    {error && <ErrorContent color={errorColor}>{error}</ErrorContent>}
  </div>
);

export const RoundInput = styled(BaseRoundInput)`
  height: 56px;
  border-radius: 28px;
  font-size: 1.125rem;
  line-height: 1.33;
  padding: 10px 20px;
`;

export const BigRoundInput = styled(BaseRoundInput)`
  height: 72px;
  border-radius: 36px;
  font-size: 1.5rem;
  line-height: 1.25;
  padding: 20px;
  color: ${(props) => props.theme.oniloBlue};
`;

export const SmallRoundInput = styled(BaseRoundInput)`
  border-radius: 24px;
  font-size: 1.25rem;
  line-height: 1.25;
  padding: 12px 20px 9px;
`;

/* A link-like element to use within an anchor */

export const FakeLink = styled.span`
  font-style: italic;
  text-decoration: underline;
  color: ${(props) => props.theme.oniloDarkOrange};
  transition: color 300ms;

  &:hover {
    font-weight: bold;
  }
`;

export const BlockLink = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
`;

export const Nav = styled.nav`
  display: none;
  align-items: center;
  margin-left: auto;

  ${media.lg` display: flex; `};

  > * {
    margin-right: 24px;

    ${media.lg` margin-right: 32px; `};

    &:first-child {
      margin-left: 24px;
      ${media.lg` margin-left: 32px; `};
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const animationRuleSpinner = css`
  ${rotateAnimation} 0.7s linear infinite;
`;

export const Spinner = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${LoaderImg});
  background-repeat: no-repeat;
  background-size: 100%;
  height: 50px;
  width: 50px;
  animation: ${animationRuleSpinner};
`;

export const MarkdownWrapper = styled.div`
  ${(props) => props.lh && `line-height: ${props.lh}rem`};
  ${(props) => props.fs && `font-size: ${props.fs}rem`};
  ${(props) => props.mx && `max-height: ${props.mx}px`};
  ${(props) => props.of && `overflow: ${props.of}`};
  ${(props) => props.ta && `text-align: ${props.ta}`};
  ${(props) => props.mt && `margin-top: ${props.mt}rem`};
`;

export const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;

  .frc-captcha {
    background-color: transparent;
    border: none;
  }

  .frc-button {
    background-color: transparent;
  }

  &.dark-mode {
    .frc-captcha b {
      color: #fff;
    }

    .frc-text {
      color: #fff;
    }

    .frc-button {
      border: #fff solid 1px;
      color: #fff;
    }

    .frc-icon {
      fill: #fff;
      stroke: #fff;
    }

    .frc-banner {
      color: #fff;
    }
  }

  &.light-mode {
    .frc-captcha b {
      color: #27b0d4;
    }

    .frc-text {
      color: #27b0d4;
    }

    .frc-button {
      border: #27b0d4 solid 1px;
      color: #27b0d4;
    }

    .frc-icon {
      fill: #27b0d4;
      stroke: #27b0d4;
    }

    .frc-banner {
      color: #27b0d4;
    }
  }
`;

export const SectionBackground = styled.div`
  padding: 0.2rem;

  &.white {
    background: rgb(255, 255, 255);
  }

  &.darkblue {
    background-image: radial-gradient(at 50% 100%, rgb(147, 215, 233) 0%, rgb(39, 176, 212) 100%);
    color: rgb(255, 255, 255);

    h1 {
      color: rgb(255, 255, 255) !important;
    }
  }

  &.confetti {
    background-image: url(${ConfettiIcon});
    background-repeat: repeat;

    div:first-of-type {
      background: white;
      padding: 1rem 0.5rem;
    }
  }
`;

export const VidisButtonContainer = styled.div`
  margin: auto;
  width: 220px;
`;

export const IAMLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    button {
      font-size: 14px;
      width: 200px;
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #ecfbfe;
      border: 1px solid #f26521;
      color: #27b0d4;
      font-weight: normal;
    }
  }

  div.IAMIcon {
    display: inline-block;
    background-image: url(${IAMpng});
    background-repeat: no-repeat;
    background-size: contain;
    height: 22px;
    width: 20px;
    margin-right: 0.75rem;
  }

  div.loginText {
    min-inline-size: fit-content;
  }

  div.LUXIcon {
    display: inline-block;
    background-image: url(${LUXsvg});
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-left: 0.75rem;
  }
`;

export const PopUpContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  color: white;
  background-color: ${(props) => props.theme.oniloLightOrange};
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.3rem 0;
  z-index: 20;

  p {
    margin: 0px;
    padding: 0 0.3rem;
    ${media.lg` margin: .5rem 0;`};
  }

  a {
    color: white;
  }

  ${media.lg`
  text-align: center;
  position: absolute;
  top: -9.4rem;
  left: 4rem;

  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 1.5rem solid ${(props) => props.theme.oniloLightOrange};;
    border-right: .75rem solid transparent;
    border-top: .75rem solid ${(props) => props.theme.oniloLightOrange};;
    border-bottom: 1.25rem solid transparent;
    left: 2rem;
    bottom: -1.55rem;
  } 
  &.hide {
    display: none;
  `}
`;
