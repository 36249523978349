import React, { useEffect, useRef } from 'react';
import cc from 'classcat';
import QuizProgressBar from './QuizProgressBar';
import {
  AbsoluteFill,
  QuizCloseButton,
  QuizInnerNarrow,
  QuizMainView,
  QuizModalContainer,
  QuizOptionButton,
  QuizOverallFeedback,
  QuizTopBar,
  RoundedTextButton,
  TextButton,
} from './styles';
import useBoardstoryGameQuiz, { OptionStatus } from './shared/useBoardstoryGameQuiz';
import Confetti from '../../assets/icons/ConfettiQuiz.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseQuiz.svg';
import Modal from '../Modal/Modal';
import { FadeMount } from 'components/Transition';
import { TransitionGroup } from 'react-transition-group';
import MascotWithLightbulbImg from '../../assets/images/mascot-lightbulb.png';
import MascotImg from '../../assets/images/mascot.png';

function BoardstoryGameQuiz({ boardstoryTitle, data, onClose }) {
  const [
    {
      questionIndex,
      quizInProgress,
      questions,
      currentQuestion,
      quizIntro,
      correctAnswers,
      quizFinished,
      selectedOptionIndex,
      selectedOptionStatus,
    },
    handlers,
  ] = useBoardstoryGameQuiz(data);
  const { notes } = data?.quizDetails || {};

  const contentRef = useRef(null);

  useEffect(() => {
    // Scroll to the top of the quiz each time the quiz state changes
    if (contentRef.current && contentRef.current.scroll) {
      contentRef.current.scroll(0, 0);
    }
  }, [questionIndex]);

  const renderQuestion = (question) => {
    if (!question) return null;

    return (
      <div key={questionIndex}>
        <h3 className="text-center mt-0 mb-6 mlandscape:mb-3 text-1xl font-normal mlandscape:text-lg">{question.question}</h3>
        <div className="grid sm:grid-cols-2 gap-1">
          {question.options.map((o, index) => (
            <QuizOptionButton
              key={index}
              onClick={() => handlers.onOptionSelect(index)}
              style={{
                borderColor: handlers.getOptionColor(index),
              }}
              className="py-4 px-5 mlandscape:py-2 flex flex-row items-start bg-white cursor-pointer text-left text-lg md:text-xl mlandscape:text-base mlandscape:leading-tight"
              type="button"
            >
              <span className="question-letter">{String.fromCharCode(65 + index)}</span>
              <span>{o.option}</span>
            </QuizOptionButton>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      animate
      isOpen={data !== null}
      onRequestClose={onClose}
      wrapContent={false}
      usePortal={false}
      overlayBgColor="rgba(0, 0, 0, 0.5)"
      overlayClassName="flex"
    >
      <QuizModalContainer>
        {/* Header */}
        {quizInProgress && (
          <QuizTopBar>
            <div className="flex flex-col items-center py-6 px-10 lg:pt-10 lg:pb-8 mlandscape:p-3">
              <h2 className="quiz-topbar--title">
                <span className="mlandscape:mr-2 text-orange-dark">Quiz:</span>
                <br className="mlandscape:hidden" />
                <span>{boardstoryTitle}</span>
              </h2>
              <QuizInnerNarrow className="mt-3 mlandscape:mt-0">
                <QuizProgressBar
                  height={20}
                  progress={(questionIndex + 1) / questions.length}
                  text={`Frage ${questionIndex + 1} von ${questions.length}`}
                />
              </QuizInnerNarrow>

              <QuizCloseButton type="button" onClick={onClose}>
                <CloseIcon />
              </QuizCloseButton>
            </div>
          </QuizTopBar>
        )}

        {/* Main view */}
        <QuizMainView>
          {!quizInProgress && (
            <>
              {correctAnswers.length === questions.length && questions.length > 0 && (
                <AbsoluteFill
                  style={{
                    background: `url(${Confetti})`,
                    pointerEvents: 'none',
                  }}
                />
              )}
              <QuizCloseButton type="button" onClick={onClose}>
                <CloseIcon />
              </QuizCloseButton>
            </>
          )}

          {!quizInProgress && (
            <QuizTopBar>
              <div className="flex flex-col items-center py-6 px-10 mlandscape:p-3 lg:pt-10 lg:pb-8 mlandscape:flex-row mlandscape:justify-center">
                <h2 className="italic text-center m-0 text-3xl mlandscape:text-lg text-orange-dark mlandscape:mr-3">
                  Onilo Boardstory Quiz
                </h2>
                <h2 className="italic text-center m-0 text-3xl mlandscape:text-lg subtitle">{boardstoryTitle}</h2>
              </div>
            </QuizTopBar>
          )}

          <div
            ref={contentRef}
            className="flex flex-col flex-1 items-center overflow-y-scroll relative z-10 px-4 py-6 lg:pt-10 lg:px-8 lg:pb-8 mlandscape:p-4"
          >
            <div className="w-full flex-1">
              {data !== null && (
                <TransitionGroup exit={false}>
                  <FadeMount key={questionIndex} active={true}>
                    {quizInProgress ? (
                      renderQuestion(currentQuestion)
                    ) : (
                      /* Quiz start / end (feedback) screen */
                      <QuizInnerNarrow className="mx-auto">
                        {quizIntro ? (
                          <div className="relative">
                            <QuizOverallFeedback className="chat-bubble--small leading-normal rounded-2xl bg-white relative py-5 px-7 mlandscape:p-4">
                              <span className="text-xl mlandscape:text-lg">{notes?.start}</span>
                            </QuizOverallFeedback>
                            <img
                              style={{
                                height: '50vh',
                                minHeight: 300,
                                maxHeight: 460,
                              }}
                              className="hidden md:block mlandscape:hidden -ml-16"
                              src={MascotWithLightbulbImg}
                              loading="lazy"
                            />
                          </div>
                        ) : (
                          <div className="relative">
                            <QuizOverallFeedback className="leading-normal rounded-2xl bg-white relative py-5 px-7 mlandscape:p-4">
                              <div
                                className={cc([
                                  correctAnswers.length > 0 ? 'text-success' : 'text-error',
                                  'font-bold mb-2 text-xl',
                                ])}
                              >
                                {correctAnswers.length > 0 ? 'Herzlichen Glückwunsch' : 'Versuchs nochmal'}
                              </div>

                              <div className="text-xl mlandscape:text-base">
                                Du hast <span className="font-bold">{handlers.getFeedbackText()}</span> Fragen richtig
                                beanwortet. {notes?.[handlers.getQuizStatus()]}
                              </div>
                            </QuizOverallFeedback>

                            <img
                              className="hidden md:block mlandscape:hidden -ml-40 -mb-8"
                              src={MascotImg}
                              loading="lazy"
                              style={{
                                maxHeight: 350,
                                minHeight: 250,
                                height: '35vh',
                              }}
                            />
                          </div>
                        )}
                      </QuizInnerNarrow>
                    )}
                  </FadeMount>
                </TransitionGroup>
              )}
            </div>

            <div className="relative mt-4 mlandscape:mt-2 w-full flex flex-col items-center">
              {quizInProgress && (
                <div className="absolute -bottom-3 left-4 hidden md:block mlandscape:hidden">
                  <img className="h-32" src={MascotImg} loading="lazy" />
                </div>
              )}
              <QuizInnerNarrow>
                {quizIntro || quizFinished ? (
                  <div className="text-center mt-8">
                    {quizIntro && (
                      <RoundedTextButton className="w-full" onClick={handlers.onSubmitQuestion} children="Quiz starten" />
                    )}
                    {quizFinished && (
                      <>
                        <RoundedTextButton className="w-full" onClick={onClose} children="Quiz beenden" />
                        <TextButton className="fluid full-width" onClick={handlers.onRestartQuiz}>
                          Quiz wiederholen
                        </TextButton>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <p
                      aria-hidden={selectedOptionStatus === OptionStatus.Init}
                      className={cc([
                        selectedOptionStatus !== OptionStatus.Init && 'is-visible',
                        selectedOptionStatus === OptionStatus.Correct ? 'text-success' : 'text-error',
                        'text-center m-0 text-1xl mlandscape:text-lg font-bold invisible',
                      ])}
                    >
                      {selectedOptionStatus === OptionStatus.Correct ? 'Richtig, sehr gut!' : 'Leider falsch'}
                    </p>

                    <div className="text-center mt-8 mlandscape:mt-2">
                      {selectedOptionStatus === OptionStatus.Init ? (
                        <RoundedTextButton
                          className="w-full"
                          disabled={selectedOptionIndex === null}
                          onClick={handlers.onConfirmSelectedOption}
                          children="Antwort bestätigen"
                        />
                      ) : (
                        <RoundedTextButton
                          className="w-full"
                          onClick={handlers.onSubmitQuestion}
                          children={questionIndex === questions.length - 1 ? 'Zum Ergebnis »' : 'Zur nächsten Frage »'}
                        />
                      )}
                    </div>
                  </>
                )}
              </QuizInnerNarrow>
            </div>
          </div>
        </QuizMainView>
      </QuizModalContainer>
    </Modal>
  );
}

export default BoardstoryGameQuiz;
