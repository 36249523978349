import React, { Component } from 'react';
import { Formik } from 'formik';
import { apiFetch, apiEndpoints } from '../../modules/api';
import FormFields from '../../components/FormFields';
import PageTitle from '../../components/PageTitle';
import validate from '../../modules/validate';
import { PageContainer, TextBlock, Spacing, Button, Form } from '../../components/StyledElements';

const initialValues = {
  email: '',
};

const fields = [
  {
    name: 'email',
    placeholder: 'E-Mail-Adresse',
    type: 'email',
  },
];

export default class extends Component {
  state = {
    requestedEmail: null,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    apiFetch
      .url(apiEndpoints.users.requestPasswordReset)
      .json(values)
      .post()
      .json(() => {
        window.scrollTo(0, 0);
        this.setState({ requestedEmail: values.email });
      })
      .catch(({ json }) => {
        if (json.error && json.error.indexOf('exist')) {
          setErrors({ email: 'Dieser User existiert nicht' });
        }
        setSubmitting(false);
      });
  };

  render() {
    if (this.state.requestedEmail) {
      return (
        <PageContainer narrow>
          <PageTitle>Passwort vergessen</PageTitle>
          <TextBlock fontSize={1.5} italic centered mt={2.5}>
            Eine E-Mail wurde an {this.state.requestedEmail} gesendet. Folgen Sie den Anweisungen, um das Passwort
            zurückzusetzen.
          </TextBlock>
        </PageContainer>
      );
    }

    return (
      <PageContainer narrow>
        <PageTitle>Passwort vergessen</PageTitle>
        <TextBlock fontSize={1.5} italic centered mb={2.5}>
          Sie haben Ihr Passwort vergessen? Geben Sie hier Ihre Emailadresse ein und wir senden Ihnen einen Link zum
          Zurücksetzen.
        </TextBlock>
        <Formik initialValues={initialValues} validate={validate} onSubmit={this.onSubmit}>
          {({ errors, values, handleSubmit, isSubmitting, ...restProps }) => (
            <Form onSubmit={handleSubmit}>
              <FormFields fields={fields} values={values} errors={errors} {...restProps} />
              <Spacing textAlign="center" mt={1}>
                <Button type="submit" className={isSubmitting ? 'loading' : undefined} disabled={isSubmitting}>
                  Absenden
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </PageContainer>
    );
  }
}
