export default {
	"Österreich": 20,
	"Belgien": 21,
	"Bulgarien": 20,
	"Dänemark": 25,
	"Deutschland": 19,
	"Estland": 20,
	"Griechenland": 24,
	"Spanien": 21,
	"Finnland": 24,
	"Frankreich": 20,
	"Kroatien": 25,
	"Island": 24,
	"Italien": 22,
	"Lettland": 21,
	"Litauen": 21,
	"Luxemburg": 17,
	"Ungarn": 27,
	"Irland": 23,
	"Malta": 18,
	"Niederlande": 21,
	"Polen": 23,
	"Portugal": 23,
	"Rumänien": 24,
	"Slowakei": 20,
	"Slowenien": 22,
	"Schweden": 25,
	"Tschechien": 19,
	"Vereinigtes Königreich": 20,
	"Zypern": 19
}