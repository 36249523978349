import React, { Component } from 'react';
import Downshift from 'downshift';
import { FlexCenter } from '../../components/StyledElements';
import SearchDropdown from './SearchDropdown';

class SearchAutocomplete extends Component {
  static defaultProps = {
    shouldFilterItems: true
  };

  constructor(props) {
    super(props);

    this.state = {
      inputValue: props.initialInputValue || '',
      selectedItem: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.initialInputValue !== this.props.initialInputValue &&
      this.props.initialInputValue !== this.state.inputValue
    ) {
      this.setState({ inputValue: this.props.initialInputValue });
    }
  }

  onInputValueChange = (inputValue) => {
    this.setState({ inputValue });

    // Triggers when the input value is empty
    if (!inputValue) {
      return this.onChange(inputValue);
    }

    // Trigger async update of items
    this.props.fetchItems(inputValue);
  };

  onChange = (selectedItem) => {
    this.setState({ selectedItem });

    this.props.onChange(selectedItem);
  };

  itemToString = (i) => (i == null ? '' : String(i));

  render() {
    const { selectedItem, inputValue } = this.state;
    let { items } = this.props;

    // Filter items unless explicitly asked not to
    if (this.props.shouldFilterItems) {
      items = items.filter(
        (i) =>
          !inputValue || i.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    }

    return (
      <FlexCenter>
        <Downshift
          inputValue={inputValue}
          selectedItem={selectedItem}
          itemToString={this.props.itemToString || this.itemToString}
          onInputValueChange={this.onInputValueChange}
          onChange={this.props.onSuggestionClick || this.onChange}
          render={(downShiftProps) =>
            SearchDropdown({
              ...downShiftProps,
              items,
              onShowAllResults: this.props.onChange,
              loading: this.props.loading,
              renderSuggestionKey: this.props.renderSuggestionKey,
              renderSuggestionItem: this.props.renderSuggestionItem,
              autoFocus: this.props.autoFocus,
              placeholder: this.props.placeholder
            })
          }
        />
      </FlexCenter>
    );
  }
}

SearchAutocomplete.defaultProps = {
  placeholder: 'Suchbegriff'
};

export default SearchAutocomplete;
