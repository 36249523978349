import React, { useRef } from 'react';
import Modal from '../Modal/Modal';
import { getImpulseModalPosition } from './shared/gameImpulseConfig';
import AudioPreview from './AudioPreview';
import { isValidUrl } from '../../utils/helpers';
import { FadeMount } from '../Transition';
import {
  RoundedTextButton,
  ImpulseInner,
  ImpulseContainer,
  AudioPreviewContainer,
  AbsoluteFill,
} from './styles';

function BoardstoryGameImpulse({ onClose, impulse, active, containerSize }) {
  const containerRef = useRef();
  const hasEnoughSpaceInline = containerSize.width > 700;
  const details =
    impulse && impulse.impulseDetails ? impulse.impulseDetails : null;

  const onClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onClose();
    }
  };

  const renderContent = () => {
    if (!details) return null;

    const { text, image, textAudio } = details;
    const hasAudioHint = isValidUrl(textAudio);

    return (
      <>
        <ImpulseInner
          style={{
            padding: hasEnoughSpaceInline ? '2rem 2.5rem' : '1.5rem 1rem 0',
          }}
        >
          {isValidUrl(image) && <img alt="" src={image} />}

          <p style={{textAlign: 'center'}}>{text}</p>

          <RoundedTextButton
            children="Weiter"
            onClick={onClose}
            style={{
              marginTop: 30,
              minWidth: 172,
              alignSelf: 'center',
            }}
          />
        </ImpulseInner>

        {hasAudioHint && (
          <AudioPreviewContainer>
            <AudioPreview url={textAudio} />
          </AudioPreviewContainer>
        )}
      </>
    );
  };

  if (hasEnoughSpaceInline) {
    if (!details) return null;

    const size = {
      width: Math.min(containerSize.width / 3, 450),
      height: Math.min(containerSize.height / 1.5, 200),
    };
    return (
      <FadeMount active={active}>
        <AbsoluteFill onClick={onClickOutside}>
          <ImpulseContainer
            ref={containerRef}
            style={{
              ...size,
              ...getImpulseModalPosition(details.position, size, containerSize),
            }}
          >
            {renderContent()}
          </ImpulseContainer>
        </AbsoluteFill>
      </FadeMount>
    );
  }

  return (
    <Modal animate isOpen={active} onRequestClose={onClose}>
      {renderContent()}
    </Modal>
  );
}

export default BoardstoryGameImpulse;
