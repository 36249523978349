/* eslint-disable */
import cc from 'classcat';
import FixedDateRangePicker from 'components/FixedDateRangePicker/FixedDateRangePicker';
import { InfoIcon } from 'components/StyledIcons';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactSwitch from 'react-switch';
import styled from 'styled-components';
import { getCurrentOrder } from '../../actions/user';
import { ReactComponent as AddNewIcon } from '../../assets/icons/AddNew.svg';
import { ReactComponent as AutoPlayIcon } from '../../assets/icons/Autoplay.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Delete.svg';
import { ReactComponent as QuizIcon } from '../../assets/icons/Quiz.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/SearchFind.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/Share.svg';
import { ReactComponent as SmallCheckmarkIcon } from '../../assets/icons/SmallCheckmark.svg';
import { ReactComponent as SmallCrossIcon } from '../../assets/icons/SmallCross.svg';
import { ReactComponent as SoundOnIcon } from '../../assets/icons/SoundOn.svg';
import { ReactComponent as ImpulseIcon } from '../../assets/icons/SpeechBubble.svg';
import { ReactComponent as TextIcon } from '../../assets/icons/Text.svg';
import { apiEndpoints, apiFetch } from '../../modules/api';
import { hasDateNotExpired } from '../../utils/helpers';
import media from '../../utils/media';
import { getMemberFromOrg, getRolesAsBoolean } from '../../utils/userRoleHelpers';
import { BaseButton, Button, ButtonWithIcon, CloseButton } from '../StyledElements';

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 0px;
  line-height: 1;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
`;

const ListContainer = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  height: 80px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 5px 15px 15px;

  &.lists-codes {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 14px 0 20px;
  }
`;

const ExpirationDate = styled.div`
  font-size: 0.875rem;
  line-height: 1.14;
  text-align: center;
  white-space: nowrap;

  strong {
    display: inline-block;
    margin-bottom: 5px;
  }

  .spacer {
    line-height: 1;
  }
`;

const StyledCodeWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #27b0d4;
  flex-direction: column;
  padding-top: 10px;

  a {
    color: #27b0d4;
    text-decoration: underline;
    margin-top: 5px;
  }
`;

const StyledCode = styled.div`
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 0.86;
  letter-spacing: 3.6px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #f26521;
  align-items: center;

  ${media.md`
    font-size: 1.75rem;
  `};

  .code-info {
    margin-right: 5px;
    height: 25px;
    width: 25px;
    color: #27b0d4;
    cursor: pointer;
  }
`;

const RemainingRentalCodes = styled.div`
  max-width: 4rem;
  font-size: 0.8rem;
  margin-right: 2rem;
`;

const Modal = styled.div`
  position: absolute;
  width: 340px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  bottom: 45px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  .modal-title {
    background: #27b0d4;
    color: #fff;
    padding: 15px 35px;
    font-size: 1.125em;
    text-align: center;

    .modal-title-subtitle {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .modal-content {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input[type='text'] {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      margin-bottom: 15px;
      max-width: 130px;
      text-align: center;
      margin: auto;
      display: block;

      &:focus {
        outline: none;
      }
    }

    > div {
      margin-bottom: 15px;
    }

    .info-modal-content {
      width: 90%;
      margin: auto;

      h3 {
        text-align: left;
      }

      label {
        text-align: left;
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        svg.checkmark {
          transform: rotate(14deg);
        }
      }
    }

    .modal-content-table {
      width: 100%;
      font-size: 1.1rem;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        word-break: break-all;
        margin-bottom: 15px;

        button {
          min-width: 91px;
          margin-left: 20px;
        }
      }

      .qr-code {
        div:last-child {
          font-size: 12px;
        }
      }
    }

    .modal-content-settings {
      width: 100%;
      max-height: 350px;
      overflow-y: auto;
      padding-bottom: 5px;
      padding-right: 5px;
      padding-left: 5px;
      position: relative;
      margin-right: -10px;

      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      scrollbar-width: auto;
      scrollbar-color: #27b0d4 #ffffff;

      /* Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #27b0d4;
        border-radius: 10px;
        border: 3px solid #ffffff;
      }

      &::after {
        content: '';
        display: block;
        position: sticky;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 30px; /* Specify the desired height for the pseudo element */
        background-image: linear-gradient(
          to top,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0)
        ); /* Customize the gradient */
        pointer-events: none; /* Ensure the pseudo element doesn't interfere with scrolling */
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
    }

    .modal-content-settings,
    .info-modal-content {
      label {
        display: flex;
        align-items: center;
        max-width: 80%;
        overflow: hidden;
        hyphens: auto;

        svg {
          margin-right: 5px;
          background: #f26521;
          border-radius: 50%;
          padding: 4px;

          &.blue {
            background: #27b0d4;
          }
        }
      }
    }
  }
`;

const settings = {
  soundEnabled: {
    label: 'Ton',
    icon: <SoundOnIcon />,
    show: (boardstory) => {
      return boardstory.hasAudio;
    },
  },
  subtitlesEnabled: {
    label: 'Text',
    icon: <TextIcon />,
    show: (boardstory) => {
      return !boardstory.explain;
    },
  },
  autoplayEnabled: {
    label: 'Autoplay',
    icon: <AutoPlayIcon />,
    show: (boardstory) => {
      return true;
    },
  },
  searchGameEnabled: {
    label: 'Suchspiel',
    icon: <SearchIcon className="blue" />,
    show: (boardstory) => {
      return boardstory.gameInfo?.some((game) => game.gameType === 'search');
    },
  },
  impulseEnabled: {
    label: 'Impuls',
    icon: <ImpulseIcon className="blue" />,
    show: (boardstory) => {
      return boardstory.gameInfo?.some((game) => game.gameType === 'impulse');
    },
  },
  quizEnabled: {
    label: 'Quiz',
    icon: <QuizIcon className="blue" />,
    show: (boardstory) => {
      return boardstory.gameInfo?.some((game) => game.gameType === 'quiz');
    },
  },
};

class AccessCodes extends Component {
  state = {
    accessCodes: [],
    accessCodeModals: {},
    error: undefined,
    isLoading: false,
    createModalOpen: false,
    newAccessCode: {
      startingDate: new Date(),
      config: {
        soundEnabled: true,
        subtitlesEnabled: true,
        autoplayEnabled: true,
        searchGameEnabled: true,
        impulseEnabled: true,
        quizEnabled: true,
      },
      hiddenAttachments: [],
    },
    newAccessCodeDateView: true,
  };

  componentDidMount() {
    if (this.props.boardstory && this.props.isAuthenticated) {
      this.getAccessCodes();
    }
    if (this.props.attachments) {
      this.setState({
        newAccessCode: {
          ...this.state.newAccessCode,
          // deselect all attachments by default
          hiddenAttachments: this.props.attachments.map((attachment) => attachment.id),
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    // update attachments
    if (prevProps.attachments !== this.props.attachments) {
      this.setState({
        newAccessCode: {
          ...this.state.newAccessCode,
          // deselect all attachments by default
          hiddenAttachments: this.props.attachments.map((attachment) => attachment.id),
        },
      });
    }
  }

  getAccessCodes() {
    const boardstory = this.props.boardstory;
    apiFetch
      .url(apiEndpoints.accessCodes.own)
      .query({ boardstory: boardstory?.id })
      .get()
      .json((response) => {
        const { accessCodes = [] } = response._embedded;
        if (accessCodes.length > 0) {
          const bsAccessCodes = accessCodes.filter(
            (i) => hasDateNotExpired(i.expirationDate) && i.boardstory === boardstory.id,
          );

          // There's a valid access code
          if (bsAccessCodes.length) {
            // add a modal state for each access code
            const accessCodeModals = bsAccessCodes.reduce((acc, code) => {
              acc[code.id] = {
                infoModal: false,
                shareModal: false,
                noteModal: false,
              };
              return acc;
            }, {});
            this.setState({ accessCodes: bsAccessCodes, accessCodeModals });
          }
        }
      })
      .catch((res) => {
        console.log(res);
      });
  }

  createAccessCode = () => {
    this.setState({ isLoading: true });

    apiFetch
      .url(apiEndpoints.accessCodes.root)
      .json({
        boardstory: this.props.boardstory?.id,
        organization: this.props.organization.id,
        // set starting date to tomorrow
        startingDate: this.state.newAccessCode.startingDate,
        soundEnabled: this.state.newAccessCode.config.soundEnabled,
        subtitlesEnabled: this.state.newAccessCode.config.subtitlesEnabled,
        autoplayEnabled: this.state.newAccessCode.config.autoplayEnabled,
        searchGameEnabled: this.state.newAccessCode.config.searchGameEnabled,
        impulseEnabled: this.state.newAccessCode.config.impulseEnabled,
        quizEnabled: this.state.newAccessCode.config.quizEnabled,
        hiddenAttachments: this.state.newAccessCode.hiddenAttachments,
      })
      .post()
      .json((accessCode) => {
        this.setState({ isLoading: false });
        if (accessCode.code) {
          this.state.accessCodes.unshift(accessCode);
          this.setState({ accessCodes: this.state.accessCodes });
          // add a modal state for new access code
          this.setState({
            accessCodeModals: {
              ...this.state.accessCodeModals,
              [accessCode.id]: {
                infoModal: false,
                shareModal: false,
                noteModal: false,
              },
            },
          });

          // reset new access code
          this.setState({
            newAccessCode: {
              startingDate: new Date(),
              config: {
                soundEnabled: true,
                subtitlesEnabled: true,
                autoplayEnabled: true,
                searchGameEnabled: true,
                impulseEnabled: true,
                quizEnabled: true,
              },
              // deselect all attachments by default
              hiddenAttachments: this.props.attachments.map((attachment) => attachment.id),
            },
          });
          // close modal
          this.setState({ createModalOpen: false });

          // track the event using inSites
          if (window.insites) window.insites.event('schülercode erstellt');
        } else {
          console.error(accessCode);
        }
        this.props.getCurrentOrder();
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  deleteAccessCode = (accessCodeId) => {
    if (!this.state.accessCodes) return;
    this.setState({ isLoading: true });

    apiFetch
      .url(`${apiEndpoints.accessCodes.root}/${accessCodeId}`)
      .delete()
      .res(() => {
        // remove access code from accessCodes array by id
        this.state.accessCodes.splice(
          this.state.accessCodes.findIndex((i) => i.id === accessCodeId),
          1,
        );
        this.setState({ accessCodes: this.state.accessCodes, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  renderEternalAccessCode(boardstoryId) {
    let eternalAccessCode;
    // Hier die sechs Boardstory IDs eintragen von den kostenlosen stories
    switch (boardstoryId) {
      case '5f89657aa6c84e001ad14755':
        eternalAccessCode = 'VIVO1';
        break;
      case '5f89667ea6c84e001ad14c07':
        eternalAccessCode = 'VIVO2';
        break;
      case '5f8968caa6c84e001ad158b1':
        eternalAccessCode = 'VIVO3';
        break;
      case '5f896ca4a6c84e001ad17c3e':
        eternalAccessCode = 'VIVO4';
        break;
      case '5fcaac5e699f8c0014ff7817':
        eternalAccessCode = 'VIVO5';
        break;
      case '5fd0473fdefcc1001a43a25a':
        eternalAccessCode = 'VIVO6';
        break;
      default:
        eternalAccessCode = null;
    }

    if (!eternalAccessCode) return null;

    return (
      <ListContainer className={cc({ 'lists-codes': eternalAccessCode })}>
        <Fragment>
          <StyledCode>
            <span>{`Code: ${eternalAccessCode}`}</span>
          </StyledCode>
        </Fragment>
      </ListContainer>
    );
  }

  writeToClipboard = (e, text) => {
    if (e.target)
      navigator.clipboard.writeText(text).then(() => {
        e.target.innerHTML = 'Kopiert';
        setTimeout(() => {
          e.target.innerHTML = 'Kopieren';
        }, 3000);
      });
  };

  generateQRCode = (code, url, name) => {
    QRCode.toDataURL(
      url,
      {
        width: 800,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#ffffff',
        },
      },
      (err, url) => {
        if (err) console.log(err);
        var link = document.createElement('a');
        link.download = name + '.png';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link = null;
      },
    );
  };

  truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  toggleModal = (accessCodeId, modalType) => {
    const accessCodeModals = this.state.accessCodeModals;
    accessCodeModals[accessCodeId][modalType] = !accessCodeModals[accessCodeId][modalType];

    // close all other modals
    Object.keys(accessCodeModals).forEach((key) => {
      if (key !== accessCodeId) {
        Object.keys(accessCodeModals[key]).forEach((modal) => {
          // loop through all modals and set to false
          accessCodeModals[key][modal] = false;
        });
      } else {
        // close other modals of the same access code
        Object.keys(accessCodeModals[key]).forEach((modal) => {
          if (modal !== modalType) {
            accessCodeModals[key][modal] = false;
          }
        });
      }
    });

    this.setState({ accessCodeModals });
  };

  formatDateTime = (date) => {
    if (!date) return null;
    const d = new Date(date);
    return d.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  updateAccessCode = (accessCodeId, data) => {
    this.setState({ isLoading: true });

    apiFetch
      .url(`${apiEndpoints.accessCodes.root}/${accessCodeId}`)
      .json(data)
      .patch()
      .json((accessCode) => {
        this.setState({ isLoading: false });
        if (accessCode.code) {
          // update access code in accessCodes array by id
          this.state.accessCodes[this.state.accessCodes.findIndex((i) => i.id === accessCodeId)] = accessCode;
          this.setState({ accessCodes: this.state.accessCodes });

          this.toggleModal(accessCode.id, 'noteModal');
        } else {
          console.error(accessCode);
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  toggleCreateModal = () => {
    this.setState({ createModalOpen: !this.state.createModalOpen });
  };

  toggleNewAccessCodeDateView = () => {
    this.setState({ newAccessCodeDateView: !this.state.newAccessCodeDateView });
  };

  renderAccessCode(index, accessCode, isLoading) {
    accessCode.draft = accessCode.draft ?? accessCode.note;
    return (
      <Fragment>
        <ExpirationDate>
          <strong>Gültigkeit:</strong>
          <br /> {this.formatDateTime(accessCode.startingDate)} <br />
          <span className="spacer">-</span>
          <br /> {this.formatDateTime(accessCode.expirationDate)}
        </ExpirationDate>
        <StyledCodeWrapper style={{ position: 'relative' }}>
          <StyledCode>
            <InfoIcon className="code-info" onClick={() => this.toggleModal(accessCode.id, 'infoModal')} />
            {accessCode.code}
          </StyledCode>
          <a onClick={() => this.toggleModal(accessCode.id, 'noteModal')} style={{ cursor: 'pointer' }}>
            {accessCode.note ? accessCode.note : 'Notiz einfügen'}
          </a>
          <Modal style={{ display: this.state.accessCodeModals[accessCode.id]?.shareModal ? 'block' : 'none' }}>
            <div className="modal-title">
              <CloseButton className="white small" onClick={() => this.toggleModal(accessCode.id, 'shareModal')} />
              Wie möchten Sie Ihren Code teilen?
            </div>
            <div className="modal-content">
              <div className="modal-content-table">
                <div style={{ textAlign: 'left' }}>
                  <div>{accessCode.code}</div>
                  <Button
                    onClick={(e) => {
                      e.persist();
                      this.writeToClipboard(e, accessCode.code);
                    }}
                    className="small"
                  >
                    Kopieren
                  </Button>
                </div>
                <div style={{ textAlign: 'left' }}>
                  <div>
                    {this.truncateString(
                      `https://${window.location.hostname}/boardstory/${this.props.boardstory?.slug}/${accessCode.code}`,
                      42,
                    )}
                  </div>
                  <Button
                    onClick={(e) => {
                      e.persist();
                      this.writeToClipboard(
                        e,
                        `https://${window.location.hostname}/boardstory/${this.props.boardstory?.slug}/${accessCode.code}`,
                      );
                    }}
                    className="small"
                  >
                    Kopieren
                  </Button>
                </div>
                <div style={{ textAlign: 'left' }}>
                  <div className="qr-code">
                    <div>QR Code</div>
                    <div>(Download startet automatisch)</div>
                  </div>
                  <Button
                    className="small"
                    onClick={() =>
                      this.generateQRCode(
                        accessCode.code,
                        `https://${window.location.hostname}/boardstory/${this.props.boardstory?.slug}/${accessCode.code}`,
                        this.props.boardstory?.title,
                      )
                    }
                  >
                    Download
                  </Button>
                </div>
              </div>
              <Button className="small" onClick={() => this.toggleModal(accessCode.id, 'shareModal')}>
                Schließen
              </Button>
            </div>
          </Modal>

          <Modal style={{ display: this.state.accessCodeModals[accessCode.id]?.noteModal ? 'block' : 'none' }}>
            <div className="modal-title">
              <CloseButton className="white small" onClick={() => this.toggleModal(accessCode.id, 'noteModal')} />
              Notiz einfügen
            </div>
            <div className="modal-content">
              <input
                type="text"
                style={{ marginBottom: '10px' }}
                value={accessCode.draft}
                // update access code in accessCodes array by id
                onChange={(event) =>
                  this.setState({
                    accessCodes: (() => {
                      this.state.accessCodes[index].draft = event.target.value;
                      return this.state.accessCodes;
                    })(),
                  })
                }
                name={`note-${accessCode.id}`}
              />
              <Button
                className="small"
                onClick={() =>
                  this.updateAccessCode(accessCode.id, {
                    note: document.querySelector(`input[name=note-${accessCode.id}]`).value,
                  })
                }
              >
                Speichern
              </Button>
            </div>
          </Modal>

          <Modal style={{ display: this.state.accessCodeModals[accessCode.id]?.infoModal ? 'block' : 'none' }}>
            <div className="modal-title">
              <CloseButton className="white small" onClick={() => this.toggleModal(accessCode.id, 'infoModal')} />
              Ihre ausgewählten Code-Einstellungen
            </div>
            <div className="modal-content">
              <div className="info-modal-content">
                <h3>Playerfunktionen</h3>
                {Object.keys(settings).map((key) => {
                  if (accessCode.hasOwnProperty(key)) {
                    return (
                      <div key={key}>
                        <label>
                          {settings[key].icon} {settings[key].label}
                        </label>
                        {accessCode[key] ? (
                          <SmallCheckmarkIcon className="checkmark" width={20} />
                        ) : (
                          <SmallCrossIcon width={20} />
                        )}
                      </div>
                    );
                  }
                })}
                {this.props.attachments?.length > 0 && (
                  <>
                    <h3>Begleitmaterial</h3>
                    {this.props.attachments?.map((attachment, index) => {
                      return (
                        <div key={index}>
                          <label>{attachment.title}</label>
                          {!accessCode.hiddenAttachments?.includes(attachment.id) ? (
                            <SmallCheckmarkIcon className="checkmark" width={20} />
                          ) : (
                            <SmallCrossIcon width={20} />
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </Modal>
        </StyledCodeWrapper>
        <div className={cc({ wait: isLoading })} style={{ marginRight: '10px', position: 'relative' }} disabled={isLoading}>
          <ShareIcon
            width={30}
            height={30}
            style={{ cursor: 'pointer' }}
            onClick={() => this.toggleModal(accessCode.id, 'shareModal')}
          />
        </div>
        <BaseButton
          className={cc({ wait: isLoading })}
          disabled={isLoading}
          onClick={() => this.deleteAccessCode(accessCode.id)}
        >
          <DeleteIcon width={30} height={30} />
        </BaseButton>
      </Fragment>
    );
  }

  renderCreateAccessCodeButton(isLoading) {
    const { allowRentals, rentalCodesUsed, maxNumberOfRentalCodes } = this.props.currentOrder;
    const numberOfRentalCodes = maxNumberOfRentalCodes - rentalCodesUsed;

    return (
      <Fragment>
        {allowRentals && numberOfRentalCodes !== undefined && (
          <RemainingRentalCodes>{`Noch ${numberOfRentalCodes} Code${
            numberOfRentalCodes === 1 ? '' : 's'
          } übrig`}</RemainingRentalCodes>
        )}
        <Modal style={{ display: this.state.createModalOpen ? 'block' : 'none' }}>
          <div className="modal-title">
            <CloseButton className="white small" onClick={this.toggleCreateModal} />
            {this.state.newAccessCodeDateView ? `Code Gültigkeit` : `Welche Funktionen soll Ihr Code enthalten?`}
            <div className="modal-title-subtitle">
              {this.state.newAccessCodeDateView
                ? `Wählen Sie ein Startdatum aus oder tippen Sie dieses ein.`
                : `Die Einstellungen sind nachträglich nicht mehr änderbar.`}
            </div>
          </div>
          <div className="modal-content">
            {this.state.newAccessCodeDateView && (
              <FixedDateRangePicker
                startingDate={this.state.newAccessCode.startingDate}
                onDateChange={(date) => {
                  this.state.newAccessCode.startingDate = date;
                  this.setState({ newAccessCode: this.state.newAccessCode });
                }}
                licenceEndDate={this.props.currentOrder.validUntil}
                licenceStartDate={this.props.currentOrder.validFrom}
              />
            )}
            {!this.state.newAccessCodeDateView && (
              <div className="modal-content-settings">
                <h3>Playerfunktionen</h3>
                {Object.entries(this.state.newAccessCode.config).map(([key, value]) => {
                  if (settings[key].show(this.props.boardstory))
                    return (
                      <div key={key}>
                        <label>
                          {settings[key].icon} {settings[key].label}
                        </label>
                        <ReactSwitch
                          onColor="#f26521"
                          offColor="#dadadc"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          width={42}
                          height={21}
                          checked={value}
                          onChange={() => {
                            this.state.newAccessCode.config[key] = !value;
                            this.setState({ newAccessCode: this.state.newAccessCode });
                          }}
                        />
                      </div>
                    );
                })}
                {this.props.attachments?.length > 0 && (
                  <>
                    <h3>Begleitmaterial</h3>
                    {this.props.attachments?.map((attachment, index) => {
                      return (
                        <div key={index}>
                          <label>{attachment.title}</label>
                          <ReactSwitch
                            onColor="#f26521"
                            offColor="#dadadc"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={42}
                            height={21}
                            checked={!this.state.newAccessCode.hiddenAttachments?.includes(attachment.id)}
                            onChange={() => {
                              if (this.state.newAccessCode.hiddenAttachments?.includes(attachment.id)) {
                                this.state.newAccessCode.hiddenAttachments?.splice(
                                  this.state.newAccessCode.hiddenAttachments.indexOf(attachment.id),
                                  1,
                                );
                              } else {
                                this.state.newAccessCode.hiddenAttachments?.push(attachment.id);
                              }
                              this.setState({ newAccessCode: this.state.newAccessCode });
                            }}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
            <Button className="small" onClick={this.createAccessCode}>
              Code erstellen
            </Button>
            <Button className="small outlined" style={{ marginTop: '10px' }} onClick={this.toggleNewAccessCodeDateView}>
              {this.state.newAccessCodeDateView ? `Code Anpassen` : `Zurück zur Datumseingabe`}
            </Button>
          </div>
        </Modal>
        <ButtonWithIcon
          className={cc({ wait: isLoading })}
          disabled={
            isLoading ||
            (this.props.currentOrder.allowRentals && numberOfRentalCodes < 1) ||
            this.state.accessCodes?.length > 19
          }
          onClick={this.toggleCreateModal}
        >
          <span>{`${this.state.accessCodes?.length ? 'weiteren ' : ''}Code einrichten`}</span> <AddNewIcon />
          {this.props.currentOrder.allowRentals && numberOfRentalCodes < 1 && (
            <ErrorMessage>Keine Codes mehr übrig. Bitte wenden Sie sich an den Kundenservice.</ErrorMessage>
          )}
          {this.state.accessCodes?.length > 19 && (
            <ErrorMessage>Sie haben die maximale Anzahl an Codes für diese Boardstory erreicht.</ErrorMessage>
          )}
        </ButtonWithIcon>
      </Fragment>
    );
  }

  render() {
    if (this.props.isFree) return this.renderEternalAccessCode(this.props.boardstory?.id);

    if (!this.props.boardstory?.id || !this.props.isAuthenticated || !this.props.allowsAccessCodes) {
      return null;
    }

    const { accessCodes, isLoading } = this.state;
    return (
      <>
        <ListContainer style={{ position: 'relative' }}>{this.renderCreateAccessCodeButton(isLoading)}</ListContainer>

        {accessCodes &&
          accessCodes.map((accessCode, index) => (
            <ListContainer key={index} className={cc({ 'lists-codes': accessCode })}>
              {this.renderAccessCode(index, accessCode, isLoading)}
            </ListContainer>
          ))}
      </>
    );
  }
}

AccessCodes.propTypes = {
  boardstory: PropTypes.object.isRequired,
  isFree: PropTypes.bool.isRequired,
  attachments: PropTypes.array,
};

const getAccessCodePermission = (state) => {
  const { currentOrder, license, organization, account: user } = state.user;
  const { isOwner } = getRolesAsBoolean(getMemberFromOrg(organization, user));
  // in case of rental licenses, only org owners are allowed to create access codes
  return (
    currentOrder?.active && license?.allowsAccessCodes && (!currentOrder.allowRentals || (currentOrder.allowRentals && isOwner))
  );
};

const select = (state) => ({
  allowsAccessCodes: getAccessCodePermission(state),
  isAuthenticated: state.auth.isAuthenticated,
  organization: state.user.organization,
  user: state.user,
  currentOrder: state.user.currentOrder,
});

export default connect(select, { getCurrentOrder })(AccessCodes);
