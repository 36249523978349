import cc from 'classcat';
import { addFilter, getValuesFromQs, removeFilter } from '../../utils/queryString';
import { BaseButton, Spacing } from '../StyledElements';

import { ReactComponent as CloseCircle } from '../../assets/icons/CloseCircle.svg';
import { withRouter } from 'react-router-dom';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import media from '../../utils/media';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectDropdown from '../SelectDropdown';
import styled from 'styled-components';
import values from 'lodash/fp/values';

const FilterField = styled(SelectDropdown)`
  font-size: 1rem;

  select {
    padding: 13px 10px 13px 0;
    font-style: normal;
    font-weight: bold;
  }
`;

const CloseButton = styled(BaseButton)`
  border-radius: 4px;
  background-color: #27b0d4;
  color: #fff;
  padding: 9px 10px 7px 13px;
  font-weight: bold;
  font-size: 1.125rem;
  margin-right: 1.25rem;
  margin-bottom: 1rem;

  ${media.lg`
    font-size: 1rem;
    padding: 7px 8px 5px 12px;
  `};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #f26521;
  }

  svg {
    display: inline-block;
    margin-left: 12px;
    margin-top: -2px;
    vertical-align: middle;
    height: 26px;
    width: 26px;

    ${media.lg`
      height: 20px;
      width: 20px;
    `};
  }
`;

class FilterSection extends Component {
  static defaultProps = {
    options: {},
    fields: [],
    omitKey: 'p',
    colNumber: 2,
  };

  onChange = ({ target }) =>
    this.props.history.push(addFilter(target.value, this.props.location.search, this.props.omitKey, target.name));

  onCloseClick = (filter) => this.props.history.push(removeFilter(filter, this.props.location.search));

  render() {
    const { options, fields } = this.props;
    if (options.year) options.year = options.year.map((year) => ({ name: year, value: year, category: 'year' }));
    if (options.category) options.category = options.category.map((category) => ({ ...category, category: 'category' }));
    const descriptors = getValuesFromQs(this.props.location.search, options);
    const activeFilters = descriptors.map((descriptor) => {
      const { category } = flow(
        values,
        flatten,
        find(({ value }) => descriptor === value),
      )(options);

      return {
        category,
        descriptor,
      };
    });

    return (
      <div>
        <div className={cc(['flex-wrap flex md:m-[-10px]', this.props.centerFilter ? 'justify-center' : 'justify-start'])}>
          {fields.map(({ name, placeholder, searchable }) => {
            if (!options[name] || options[name].length === 0) {
              return null;
            }

            return (
              <div key={name} className={cc(['md:p-[10px] w-full', this.props.colNumber === 3 ? 'md:w-1/3' : 'md:w-1/2'])}>
                <FilterField
                  withSearch={searchable}
                  name={name}
                  value=""
                  options={options[name]}
                  placeholder={placeholder}
                  onChange={this.onChange}
                />
              </div>
            );
          })}
        </div>
        <Spacing mt={1.5} mb={1}>
          {activeFilters.map(({ category, descriptor }, i) => {
            if (!options[category]) return '';

            const option = options[category].find((o) => o.value === descriptor) || {};

            const currentFilter = this.props.shouldRenderFilterName ? fields.find((f) => f.name === category) || {} : {};

            return (
              <CloseButton key={`${category}${i}`} onClick={(event) => this.onCloseClick({ category, descriptor })}>
                <span>
                  {this.props.shouldRenderFilterName && `${currentFilter.placeholder}: `}
                  {option && option.name ? option.name : descriptor}
                </span>
                <CloseCircle />
              </CloseButton>
            );
          })}
        </Spacing>
      </div>
    );
  }
}

FilterSection.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  options: PropTypes.object,
  fields: PropTypes.array,
  omitKeys: PropTypes.array,
  shouldRenderFilterName: PropTypes.bool,
  colNumber: PropTypes.number,
  centerFilter: PropTypes.bool,
};

export default withRouter(FilterSection);
