import React from 'react';
import { connect } from 'react-redux';
import { apiFetch, apiEndpoints } from '../../modules/api';
import PageTitle from '../../components/PageTitle';
import { PageContainer, TextBlock } from '../../components/StyledElements';
import { isProduction } from '../../config';
import { acceptInvitation } from '../../actions/user';
import AcceptedInvitationInfo from '../../components/AcceptedInvitationInfo';

class AcceptInvitation extends React.Component {
  state = {
    success: false,
    error: undefined,
  };

  componentDidMount() {
    const { invitationToken } = this.props.match.params;

    if (invitationToken) {
      this.getInvitation(invitationToken);
    } else {
      this.props.history.push('/');
    }
  }

  onError = (error, errorMessage) => {
    if (!isProduction && error) {
      console.error(error);
    }
    this.setState({ error: errorMessage || 'Der Einladungslink ist ungültig' });
  };

  getInvitation(invitationToken) {
    apiFetch
      .url(apiEndpoints.invitations)
      .query({ invitationToken })
      .get()
      .json((response) => {
        // Invitation not found
        if (response._embedded.invitations.length === 0) {
          return this.onError();
        }

        const invitation = response._embedded.invitations[0];

        if (invitation.state === 'ACCEPTED') {
          this.onError(false, 'Diese Einladung wurde bereits angenommen');
        } else {
          this.acceptInvitation(invitation);
        }
      })
      .catch(this.onError);
  }

  acceptInvitation(invitation) {
    if (!invitation.id || !invitation.email) return;

    this.props
      .acceptInvitation(invitation.id)
      .then((response) => {
        if (response?.state === 'ACCEPTED') {
          this.setState({
            success: true,
          });
        } else {
          this.props.history.push('/');
        }
      })
      .catch(({ json }) => {
        if (json && json.error.indexOf("doesn't exist") > -1) {
          // The user has to register before accepting the invitation
          this.props.history.push({
            pathname: '/signup',
            state: {
              invitationId: invitation.id,
              email: invitation.email,
            },
          });
        } else {
          this.onError(json);
        }
      });
  }

  render() {
    return (
      <PageContainer narrow>
        {this.state.success ? (
          <>
            <PageTitle>Willkommen bei Onilo!</PageTitle>
            <AcceptedInvitationInfo />
          </>
        ) : (
          <>
            <PageTitle>Einladung annehmen</PageTitle>
            <TextBlock fontSize={1.5} italic centered mt={2.5}>
              {this.state.error || 'Die Einladung wird angenommen...'}
            </TextBlock>
          </>
        )}
      </PageContainer>
    );
  }
}

export default connect(null, { acceptInvitation })(AcceptInvitation);
