// Cross-browser helper method to toggle fullscreen on a HTML5 video-element


export const toggleFullscreen = videoEl => {
  videoEl.requestFullScreen =
    videoEl.requestFullscreen ||
    videoEl.msRequestFullscreen ||
    videoEl.mozRequestFullScreen ||
    videoEl.webkitRequestFullscreen;
  document.exitFullscreen =
    document.exitFullscreen ||
    document.msExitFullscreen ||
    document.mozCancelFullScreen ||
    document.webkitExitFullscreen;
  const fullscreenElement =
    document.fullscreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement;
  if (fullscreenElement === videoEl) {
    document.exitFullscreen();
  } else {
    try {
      videoEl.requestFullScreen();
    } catch (err) {
      // Silence the error
    }
  }
};

export const fullscreenChangeEvents = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange'
];