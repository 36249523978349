export const selectBoardstoriesPaginatedState = (state) => {
  return {
    isLoading: state.boardstories.isLoading,
    pagesCount: state.boardstories.pagesCount,
    currentPage: state.boardstories.currentPage,
    boardstories: state.boardstories.data,
    teaserImages: state.images.data,
    tags: state.tags.data
  };
};
