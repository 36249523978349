import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const usePreventRouteChange = (isExternalUser) => {
  const history = useHistory();
  const [wasBlocked, setWasBlocked] = useState(false);

  useEffect(() => {
    if (!isExternalUser) return;

    const unblock = history.block(() => {
      if (isExternalUser) {
        setWasBlocked(true);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [isExternalUser, history]);

  return {
    wasBlocked,
    setWasBlocked,
  };
};

export default usePreventRouteChange;
