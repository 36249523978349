import { BoardstoryGameTypes } from './types';

const sortNumbers = (arr) => [...arr].sort((a, b) => a.position - b.position);

export function getRelevantGameCuepoints(videoData, duration, games, activeGameTypes) {
  let gameCuepoints = games
    .filter((g) => activeGameTypes.indexOf(g.gameType) > -1)
    // convert seconds to millis
    .map((g) => ({ position: g.position * 1000, type: g.gameType }));

  if(videoData.isTrailer) gameCuepoints = gameCuepoints.filter((g) => g.position < duration * 1000);
  // Apply ascending sort
  return sortNumbers(gameCuepoints);
}

export function getAvailableGameCuepoints(games, availableGameTypes) {
  if(!availableGameTypes || !games) return [];
  let gameCuepoints = games
    .filter((g) => availableGameTypes.indexOf(g.gameType) > -1)
    // convert seconds to millis
    .map((g) => ({ position: g.position * 1000, type: g.gameType }));
  // Apply ascending sort
  return sortNumbers(gameCuepoints);
}

export function getActiveGameTypes(availableGameTypes, activeGameTypes) {
  return availableGameTypes.reduce((types, type) => {
    types[type] = activeGameTypes.includes(type);
    return types;
  }, {});
}

export function trailerHasGameType(duration, gameType, gameCuePoints) {
  return gameCuePoints.some((cuepoint) => cuepoint.type === gameType && cuepoint.position < duration * 1000);
}

export function getVideoSizeFromContainer({ height, width }, aspectRatio) {
  const newWidth = Math.round(height * aspectRatio);
  const videoSize =
    height > width || newWidth > width
      ? {
          width,
          height: Math.round(width / aspectRatio),
        }
      : {
          width: newWidth,
          height,
        };
  const diff = {
    width: (width - videoSize.width) / 2,
    height: (height - videoSize.height) / 2,
  };
  return { videoSize, diff };
}

export const gameTypeLabels = {
  [BoardstoryGameTypes.Impulse]: 'Impuls',
  [BoardstoryGameTypes.Search]: 'Suchspiel',
  [BoardstoryGameTypes.Quiz]: 'Quiz',
};

// Returns the real container size based on the size of the video container and whether the video is fullscreen
// Taking into account the space taken by always visible controls
// Noet: this is not used anymore due to fulscreen controls becoming an overlay
export function getRealContainerSize({ height, width }, isFullscreen) {
  return {
    width,
    // controls take up to 82px in full screen,
    // so the video is shifted a bit while the container retains its size
    height: isFullscreen ? height - 82 : height,
  };
}
