import React from 'react';
import { PageContainer, FrontPageHeadline } from '../../../components/StyledElements';
import { Image, getWpImageSrcSet } from '../../../components/Image';

export default ({ items }) => {
  const { headline, image } = items || {};

  return (
    <PageContainer>
      <FrontPageHeadline
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <Image className="mx-auto block" width="870" lazy srcSet={getWpImageSrcSet(image)} />
    </PageContainer>
  );
};
