import React, { useState } from 'react';
import cc from 'classcat';
import {
  Grid,
  Column,
  Figure,
  FlexCenter,
  PostContent,
  ListItemContainer,
  VideoContainer,
  PageSectionHeadline,
  SectionBackground,
  FrontPageHeadline,
  ButtonLink,
} from '../../components/StyledElements';
import Section from '../../components/Section';
import Slider from '../../components/Slider/Slider';
import BoardstoryListLoader from '../../components/Boardstory/BoardstoryListLoader';
import BoardstoryLoader from '../../components/Boardstory/BoardstoryLoader';
import BoardstoryList from '../../components/Boardstory/BoardstoryList';
import BoardstorySingleItem from '../../pages/Boardstories/BoardstorySingleItem';
import { Collapsible, TriggerArrow } from '../../components/Accordion';
import { Image, getWpImageProps, imageSizesByColumn } from '../../components/Image';
import { updateIframeCode } from '../../utils/wpHelpers';
import NewsletterForm from '../../components/Newsletter/NewsletterForm';
import BoardstoryListItem from 'pages/Boardstories/BoardstoryListItem';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import SliderSlick from 'react-slick';
import './SectionsModuleStyles.css';
import Fetcher from 'components/Fetcher';
import { wpEndpoints } from 'modules/wp';
import SelectDropdown from 'components/SelectDropdown';

const SectionsModule = ({ sections, sectionClassName }) => {
  const refCallback = (element) => {
    if (element && encodeURI(element.id) === window.location.hash.slice(1)) {
      element.scrollIntoView();
    }
  };

  const renderColumnContent = (column, columnsNumber) => {
    switch (column.acf_fc_layout) {
      case 'image':
        return (
          <Figure>
            <Image {...getWpImageProps(column.image)} sizes={imageSizesByColumn[columnsNumber]} />
            {column.caption && <figcaption>&copy; {column.caption}</figcaption>}
          </Figure>
        );
      case 'text':
        return <PostContent dangerouslySetInnerHTML={{ __html: column.text }} />;
      case 'accordion':
        return (
          <>
            {column.accordion?.map((element, i) => {
              return (
                <ListItemContainer className="mx-2">
                  <Collapsible
                    className="p-4 cursor-pointer bg-white mb-2 w-full"
                    key={i}
                    renderTrigger={({ isOpen, onClick }) => (
                      <div>
                        <h2 className="text-center relative pr-8" onClick={onClick}>
                          {element.title} <TriggerArrow flipped={isOpen} />
                        </h2>
                      </div>
                    )}
                  >
                    <PostContent dangerouslySetInnerHTML={{ __html: element.content }} />
                  </Collapsible>
                </ListItemContainer>
              );
            })}
          </>
        );
      case 'video':
        return <VideoContainer dangerouslySetInnerHTML={{ __html: updateIframeCode(column.video_embed) }} />;
      case 'boardstory_player':
        return (
          <BoardstoryLoader boardstoryIdOrSlug={column.boardstory_id}>
            {({ boardstory, attachments, teaserImage }) => {
              if (!boardstory) return <></>;
              return column.small_player ? (
                <BoardstoryListItem className="text-blue" teaserImage={teaserImage} boardstory={boardstory} />
              ) : (
                <BoardstorySingleItem teaserImage={teaserImage} attachments={attachments} boardstory={boardstory} />
              );
            }}
          </BoardstoryLoader>
        );
      case 'video_grid':
        if ((!column.videos || column.videos.length === 0) && column?.video_category && column?.video_category?.length > 0) {
          const [selectedCategory, setSelectedCategory] = useState('all_categories');
          return (
            <>
              {column?.video_category?.length > 1 ? (
                <div className="max-w-lg mx-auto">
                  <SelectDropdown
                    options={[
                      { name: 'Alle Kategorien', value: 'all_categories' },
                      ...column?.video_category?.map((category) => ({ name: category.name, value: category.term_id })),
                    ]}
                    onChange={(e) => {
                      console.log(e?.target?.value);
                      setSelectedCategory(e?.target?.value);
                    }}
                    placeholder={'Alle Kategorien'}
                    value={selectedCategory}
                  />
                </div>
              ) : null}
              <Fetcher
                wordpress
                url={`${wpEndpoints.explanatoryVideos}?erklarvideokategorie=${column?.video_category
                  ?.map((u) => u.term_id)
                  .join(',')}`}
              >
                {({ data }) => {
                  return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-4">
                      {data &&
                        data.map((videoObj, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display:
                                  videoObj?.erklarvideokategorie?.includes(selectedCategory) ||
                                  selectedCategory === 'all_categories'
                                    ? 'block'
                                    : 'none',
                              }}
                            >
                              <video className="w-full" src={videoObj?.acf?.video?.url} controls preload="metadata" />
                              <h3>{videoObj.post_title}</h3>
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              </Fetcher>
            </>
          );
        }
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-4">
            {column.videos &&
              column?.videos?.map((videoObj, index) => {
                return (
                  <div key={index}>
                    <video className="w-full" src={videoObj?.acf?.video?.url} controls preload="metadata" />
                    <h3>{videoObj.post_title}</h3>
                  </div>
                );
              })}
          </div>
        );
      case 'video_rondell':
        const settings = {
          dots: false,
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        };

        if ((!column.videos || column.videos.length === 0) && column?.video_category && column?.video_category?.length > 0) {
          return (
            <Fetcher
              wordpress
              url={`${wpEndpoints.explanatoryVideos}?erklarvideokategorie=${column?.video_category
                ?.map((u) => u.term_id)
                .join(',')}`}
            >
              {({ data }) => {
                return (
                  <>
                    <SliderSlick {...settings}>
                      {data &&
                        data.map((videoObj, index) => {
                          return (
                            <div key={index}>
                              <video src={videoObj?.acf?.video?.url} controls preload="metadata" />
                              <h3>{videoObj.post_title}</h3>
                            </div>
                          );
                        })}
                    </SliderSlick>
                    {column.link && column.link_title && (
                      <div className="pt-4 text-center">
                        <ButtonLink to={column.link}>{column.link_title}</ButtonLink>
                      </div>
                    )}
                  </>
                );
              }}
            </Fetcher>
          );
        }

        return (
          <>
            <SliderSlick {...settings}>
              {column.videos &&
                column?.videos?.map((videoObj, index) => {
                  return (
                    <div key={index}>
                      <video src={videoObj?.acf?.video?.url} controls preload="metadata" />
                      <h3>{videoObj.post_title}</h3>
                    </div>
                  );
                })}
            </SliderSlick>
            {column.link && column.link_title && (
              <div className="pt-4 text-center">
                <ButtonLink to={column.link}>{column.link_title}</ButtonLink>
              </div>
            )}
          </>
        );
      case 'slider':
        return (
          <Slider>
            {column.slides.map((image) => (
              <FlexCenter key={image.id}>
                <Image {...getWpImageProps(image)} />
              </FlexCenter>
            ))}
          </Slider>
        );
      case 'boardstories':
        const { boardstories } = column;

        if (!boardstories || boardstories.length === 0) {
          return null;
        }

        return (
          <BoardstoryListLoader boardstoriesIdsOrSlugs={boardstories.map((i) => i.boardstory_id)}>
            {({ boardstories: items, teaserImages }) => <BoardstoryList teaserImages={teaserImages} boardstories={items} />}
          </BoardstoryListLoader>
        );
      case 'newsletter':
        return (
          <>
            <FrontPageHeadline className="m-small">Newsletter Anmeldung</FrontPageHeadline>
            <div className="text-center text-lg md:text-2xl mb-6">
              Melden Sie sich jetzt für den Newsletter an und erhalten Sie kostenlos viele interessante Informationen rund um
              das Thema digitales Lernen mit Onilo.
            </div>
            <div className="mx-auto max-w-sm">
              <NewsletterForm mode="light" position="standalone" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderSection = ({ columns, headline, background, hide_separator }, index, isLast) => {
    if (!columns || columns.length === 0) return null;

    const columnsNumber = columns.length;

    return (
      <SectionBackground key={index} className={background}>
        <Section className={sectionClassName} withSeparator={!isLast && background === 'default' && !hide_separator}>
          {headline && (
            <div id={headline} className={cc([index > 0 && 'pt-[5px]'])} ref={refCallback}>
              <PageSectionHeadline as="h2">{headline}</PageSectionHeadline>
            </div>
          )}
          {columns.length === 1 ? (
            renderColumnContent(columns[0])
          ) : (
            <Grid className="tablet">
              {columns.map((column, key) => (
                <Column key={key}>{renderColumnContent(column, columnsNumber)}</Column>
              ))}
            </Grid>
          )}
        </Section>
      </SectionBackground>
    );
  };

  if (!sections || sections.length === 0) return null;

  return <div>{sections.map((s, index) => renderSection(s, index, sections.length - 1 === index))}</div>;
};

export default SectionsModule;
