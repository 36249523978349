import React, { useMemo } from 'react';
import { Sticky } from 'react-sticky-17';
import cc from 'classcat';
import styled from 'styled-components';
import UserColumn from './UserColumn';
import { NavLink, Nav, StickyShadow } from './StyledElements';
import dateFormat from '../modules/dateformat';
import media from '../utils/media';
import { isBefore, parseJSON } from 'date-fns';
import { useSelector } from 'react-redux';
import { getRolesAsBoolean, getMemberFromOrg } from '../utils/userRoleHelpers';

const Container = styled.div`
  background-color: rgba(222, 248, 255, 0.96);
  padding: 0.75em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.md`
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${media.lg`
    padding-left: 80px;
    padding-right: 80px;
  `};
`;

const AccountNavbar = () => {
  const user = useSelector((state) => state.user.account);
  const currentOrder = useSelector((state) => state.user.currentOrder);
  const organization = useSelector((state) => state.user.organization);
  const memberEntry = useMemo(() => getMemberFromOrg(organization, user), [organization, user]);
  const { isOwner, isRentalUser, isExternalUser } = useMemo(() => getRolesAsBoolean(memberEntry), [memberEntry]);
  const rentalUntilDate = parseJSON(memberEntry?.rentalUntil);

  const showRentalOption = !isExternalUser && isOwner && currentOrder?.allowRentals;
  const licenseName = isRentalUser ? 'Verleihzugang' : currentOrder?.licenseName;
  const showExpirationDate = isRentalUser ? isBefore(Date.now(), rentalUntilDate) : currentOrder?.active;
  const expirationDate = isRentalUser ? rentalUntilDate : currentOrder?.validUntil;
  const dateFormatSetting = isRentalUser ? 'dd.mm.yy' : 'mmmm yyyy';
  const dateUntil = dateFormat(expirationDate, dateFormatSetting);
  const expirationMessage = isRentalUser ? ' abgelaufen' : ' noch nicht aktiviert';

  // hide completely for external (SSO) users
  if (user.isExternalUser) return '';

  return (
    <Sticky topOffset={-59}>
      {({ style, isSticky }) => (
        <StickyShadow style={{ ...style, top: 60 }} className={cc({ 'is-sticky': isSticky })} bgColor="transparent">
          <Container>
            <UserColumn user={user}>
              {!user.isExternalUser && licenseName && (
                <span>
                  {licenseName},{showExpirationDate ? ` gültig bis ${dateUntil}` : expirationMessage}
                </span>
              )}
            </UserColumn>
            <Nav>
              {!user.isExternalUser && (
                <>
                  <NavLink exact className="is-dark" to="/account">
                    Meine Daten
                  </NavLink>
                  <NavLink className="is-dark" to="/account/license">
                    Meine Lizenz
                  </NavLink>
                </>
              )}
              {showRentalOption && (
                <NavLink className="is-dark" to="/account/rental-license">
                  Verleihzugänge & -codes
                </NavLink>
              )}
              <NavLink className="is-dark" to="/account/merkliste">
                Merkliste
              </NavLink>
            </Nav>
          </Container>
        </StickyShadow>
      )}
    </Sticky>
  );
};

export default AccountNavbar;
