import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { BoxShadow } from './StyledElements';
import PopperTooltip from './PopperTooltip';
import usePrevious from '../modules/usePrevious';

const MenuFlyout = ({
  children,
  location,
  renderFlyout,
  triggerOn,
  shouldRenderInnerContainer = 'true',
  placementPaddingSize = 20,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (location !== prevLocation && isOpen) {
      setIsOpen(false);
    }
  }, [location, prevLocation, isOpen]);

  const renderFlyoutInner = () => <BoxShadow className="z-[100] text-blue">{renderFlyout()}</BoxShadow>;

  return (
    <div className="relative inline-block">
      <PopperTooltip
        isOpen={isOpen}
        render={shouldRenderInnerContainer ? renderFlyoutInner : renderFlyout}
        onUpdated={setIsOpen}
        placementPaddingSize={placementPaddingSize}
        triggerOn={triggerOn}
      >
        {children}
      </PopperTooltip>
    </div>
  );
};

MenuFlyout.propTypes = {
  renderFlyout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  placementPaddingSize: PropTypes.number,
  shouldRenderInnerContainer: PropTypes.bool,
};

export default withRouter(MenuFlyout);
