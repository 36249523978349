import React, { useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import { ResizeObserver } from '@juggle/resize-observer';
import BoardstoryGamesController from './shared/BoardstoryGamesController';
import { BoardstoryGameTypes } from './shared/types';
import { getImpulsePinPositionStyle, IMPULSE_ICON_SIZES } from './shared/gameImpulseConfig';
import ImpulsePin from '../../assets/images/impulse-pin.svg';
import ImpulsePinActive from '../../assets/images/impulse-pin-active.svg';
import BoardstoryGameImpulse from './BoardstoryGameImpulse';
import BoardstoryGameSearch from './BoardstoryGameSearch';
import BoardstoryGameQuiz from './BoardstoryGameQuiz';
import { Measurer, ImpulseTriggerButton, AnimateScaleUpDown, TriggerButtonTooltip } from './styles';

const activeGameTypes = [BoardstoryGameTypes.Impulse, BoardstoryGameTypes.Quiz, BoardstoryGameTypes.Search];

const BoardstoryGames = (props) => {
  const { width, height, observe: ref } = useDimensions({ polyfill: ResizeObserver });
  const containerSize = { width: width || 0, height: height || 0 };

  const [iconHovered, setIconHovered] = useState(false);

  const renderImpulseTrigger = ({ item, onImpulseToggle, activeImpulseId }) => {
    const impulsePosition =
      item && item.impulseDetails
        ? item.impulseDetails.impulsePosition
        : {
            x: 0,
            y: 0,
          };

    const impulseIconSize =
      props.isFullscreen && width !== null && width > 767 ? IMPULSE_ICON_SIZES.fullscreen : IMPULSE_ICON_SIZES.default;

    return (
      <div key={item.id} style={getImpulsePinPositionStyle(impulsePosition, containerSize, impulseIconSize, props.aspectRatio)}>
        <AnimateScaleUpDown onMouseEnter={() => setIconHovered(true)} onMouseLeave={() => setIconHovered(false)}>
          <ImpulseTriggerButton
            onClick={(e) => {
              e.stopPropagation();
              onImpulseToggle(item.id);
            }}
          >
            <img {...impulseIconSize} alt="Impuls-Icon" src={activeImpulseId === item.id ? ImpulsePinActive : ImpulsePin} />
            <TriggerButtonTooltip style={{ opacity: iconHovered ? '1' : '0', marginTop: '-5px' }}>
              Impuls
            </TriggerButtonTooltip>
          </ImpulseTriggerButton>
        </AnimateScaleUpDown>
      </div>
    );
  };

  return (
    <>
      <Measurer ref={ref} />

      <BoardstoryGamesController
        {...props}
        containerSize={containerSize}
        activeGameTypes={activeGameTypes}
        ImpulseComponent={BoardstoryGameImpulse}
        QuizComponent={BoardstoryGameQuiz}
        SearchComponent={BoardstoryGameSearch}
        renderImpulseTrigger={renderImpulseTrigger}
      />
    </>
  );
};

export default BoardstoryGames;
