import React from 'react';
import values from 'lodash/values';
import FilterSection from '../../components/Filter/FilterSection';
import { contentPagesWithOwnSlug } from '../../config';

const fields = [
  {
    name: 'category',
    placeholder: 'Art der Neuigkeit'
  }
];

export default ({ years = [], categories = {}, ...restProps }) => {
  const categoryOptions = values(categories)
  .filter((c) => contentPagesWithOwnSlug.indexOf(c.slug) === -1)
  .filter((c) => c.acf?.hide_in_frontend_filters != true)
  .map((c) => ({
    name: c.name,
    value: c.slug
  }));
  
  return (
    <FilterSection
      options={{ category: categoryOptions }}
      fields={fields}
      {...restProps}
    />
  );
};
