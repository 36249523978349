import React, { Fragment } from 'react';
import { BoardstoryGameTypes } from './types';
import useBoardstoryGamesSharedUI from './useBoardstoryGamesSharedUI';

function BoardstoryGamesController({
  games,
  containerSize,
  aspectRatio,
  time,
  ended,
  onRequestPause,
  onRequestPlay,
  gameTypes,
  boardstoryTitle,
  currentGameId,
  onCurrentGameDismiss,
  onSearchGameStart,
  onSearchGameEnd,
  SearchComponent,
  QuizComponent,
  ImpulseComponent,
  renderImpulseTrigger,
  isFullscreen,
}) {
  const {
    currentGame,
    currentSearchGame,
    currentImpulseGames,
    activeImpulse,
    activeImpulseId,
    onImpulseToggle,
    onImpulseClose,
    onSearchGameStatusChange,
    onGameEnd,
  } = useBoardstoryGamesSharedUI({
    games,
    time,
    ended,
    onRequestPause,
    onRequestPlay,
    currentGameId,
    onCurrentGameDismiss,
  });
  return (
    <>
      {gameTypes.search && currentSearchGame && currentSearchGame.id === currentGameId && (
        <SearchComponent
          onGameStatusChange={onSearchGameStatusChange}
          onGameStart={onSearchGameStart}
          onGameEnd={onSearchGameEnd}
          containerSize={containerSize}
          data={currentSearchGame}
          isFullscreen={isFullscreen}
          aspectRatio={aspectRatio}
        />
      )}

      {gameTypes.quiz && (
        <QuizComponent
          onClose={onGameEnd}
          boardstoryTitle={boardstoryTitle}
          data={currentGame?.gameType === BoardstoryGameTypes.Quiz ? currentGame : null}
        />
      )}

      {gameTypes.impulse && (
        <>
          {currentImpulseGames.map((i) => (
            <Fragment key={i.id}>
              {renderImpulseTrigger({
                item: i,
                activeImpulseId,
                onImpulseToggle,
              })}
              <ImpulseComponent
                containerSize={containerSize}
                onClose={onImpulseClose}
                impulse={i}
                active={activeImpulseId === i.id}
              />
            </Fragment>
          ))}
        </>
      )}
    </>
  );
}

export default BoardstoryGamesController;
