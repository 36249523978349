import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cc from 'classcat';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { MenuItemLink } from '../StyledElements';
import { getMenuItemLinkProps, menuItemHasActiveChild } from '../../utils/menuItemLink';
import { Collapsible, TriggerArrow } from '../Accordion';
import { TriggerArrowV2 } from '../Accordion/TriggerArrow';
import FlexibleLink from '../FlexibleLink';
import UserColumn from '../UserColumn';
import UserAvatar from '../UserAvatar';
import media from '../../utils/media';
import SearchBar from '../Search/SearchBar';
import { getSearchLocation } from '../Search/searchUtil';
import { hasDateNotExpired } from '../../utils/helpers';

const MenuContent = styled.div`
  flex: 1 0 auto;
  padding: 1.25em 1em 0;

  ${media.md`
    padding-left: 41px;
    padding-right: 41px;
  `};

  .collapsible-inner {
    padding-left: 14px;
  }
`;

const MenuLink = styled(MenuItemLink)`
  padding: 10px 0;

  &.standalone {
    padding: 15px 0;
    text-align: center;
  }
`;

const MenuFooter = styled.div`
  background-color: #e9fafd;
  padding: 0 1em;
  flex-shrink: 0;

  ${media.md`
    padding-left: 41px;
    padding-right: 41px;
  `};
`;

const AccountLinks = styled.div`
  padding: 0.5em 0;
  border-top: 1px solid rgba(39, 176, 212, 0.2);
  border-bottom: 1px solid rgba(39, 176, 212, 0.2);
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;

  ${media.md` padding: 1.5em 0; `};

  .user-avatar {
    svg {
      width: 40px;
      height: 40px;

      ${media.md`
        width: 48px;
        height: 48px;
      `};
    }
  }
  .user-description {
    margin-left: 18px;
    line-height: normal;
    font-size: 1rem;
    .username {
      font-size: 1em;
      ${media.md` font-size: 1.5em; `};
    }
  }
`;

const AccountTriggerArrow = styled(TriggerArrow)`
  transform: ${(props) => (props.flipped ? 'scaleY(-1)' : 'none')};
  top: 1em;

  ${media.md` top: 1.75em; `};
`;

const HeaderBurgerMenu = (props) => {
  const { isOwner, isExternalUser } = props.user;
  const showRentalOption = !isExternalUser && isOwner && props.currentOrder?.allowRentals;

  const onSearchSubmit = (searchTerm) => {
    if (searchTerm) {
      props.history.push(getSearchLocation(searchTerm));
    }
  };

  const renderMenuItems = (items) => {
    return items.map((item, key) => {
      const linkProps = getMenuItemLinkProps(item, key, props.location);

      // Render the flyout
      if (item.children && item.children.length > 0) {
        return (
          <Collapsible
            key={item.ID || key}
            renderTrigger={({ isOpen, onClick }) => (
              <div className="relative block text-2xl italic text-blue py-2.5 cursor-pointer leading-tight">
                <FlexibleLink
                  className={cc([
                    'text-blue hover:text-orange-light block',
                    menuItemHasActiveChild(item.children, props.location) ? 'underline' : 'no-underline',
                  ])}
                  to={linkProps.to}
                >
                  {linkProps.children}
                </FlexibleLink>
                <TriggerArrowV2 role="button" atRight onClick={onClick} flipped={isOpen} />
              </div>
            )}
          >
            {renderMenuItems(item.children)}
          </Collapsible>
        );
      }
      // Render the "normal" link
      return <MenuLink {...linkProps} />;
    });
  };

  return (
    <BurgerMenu
      className={props.className}
      render={({ toggleMenu }) => (
        <div className="flex flex-col h-full min-h-full">
          <MenuContent>
            <div className="my-5">
              <SearchBar onSubmit={onSearchSubmit} />
            </div>
            {renderMenuItems(props.menuItems)}
          </MenuContent>
          <MenuFooter>
            {props.isAuthenticated ? (
              <Collapsible
                renderTrigger={({ isOpen, onClick }) => (
                  <div className="relative cursor-pointer" onClick={onClick}>
                    <UserRow>
                      <UserAvatar className="user-avatar" isRenewable={props.currentOrder.isRenewable} />
                      <UserColumn className="user-description" user={props.user} />
                    </UserRow>
                    <AccountTriggerArrow flipped={isOpen} />
                  </div>
                )}
              >
                <AccountLinks>
                  {!props.user.isExternalUser && (
                    <MenuLink exact to="/account">
                      Meine Daten
                    </MenuLink>
                  )}
                  {!props.user.isExternalUser && (
                    <MenuLink exact to="/account/license">
                      Meine Lizenz
                    </MenuLink>
                  )}
                  {showRentalOption && (
                    <MenuLink className="is-dark" to="/account/rental-license">
                      Verleihzugänge & -codes
                    </MenuLink>
                  )}
                  <MenuLink exact to="/account/merkliste">
                    Merkliste
                  </MenuLink>
                  {props.currentOrder.isRenewable && (
                    <MenuLink className="text-orange-dark" exact to="/account/license">
                      {hasDateNotExpired(props.currentOrder.validUntil)
                        ? 'Lizenz läuft bald ab. Verlängern?'
                        : 'Lizenz abgelaufen. Verlängern?'}
                    </MenuLink>
                  )}
                </AccountLinks>
                <MenuLink
                  className="standalone"
                  onClick={(e) => {
                    toggleMenu(e, false);
                    props.onLogout();
                  }}
                >
                  Abmelden
                </MenuLink>
              </Collapsible>
            ) : (
              <MenuLink className="standalone" to="/login">
                Anmelden
              </MenuLink>
            )}
          </MenuFooter>
        </div>
      )}
    />
  );
};

HeaderBurgerMenu.defaultProps = {
  menuItems: [],
};

HeaderBurgerMenu.propTypes = {
  menuItems: PropTypes.array,
  className: PropTypes.string,
  onLogout: PropTypes.func,
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  currentOrder: PropTypes.object,
};

const select = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentOrder: state.user.currentOrder,
});

const HeaderBurgerMenuContainer = connect(select)(HeaderBurgerMenu);

export default withRouter(HeaderBurgerMenuContainer);
