import React from 'react';
import cc from 'classcat';
import BoardstoryPlayer from '../../components/Boardstory/BoardstoryPlayer';
import { ListItemContainer, Thumbnail } from '../../components/StyledElements';
import BoardstoryShortcuts from './BoardstoryShortcuts';
import BoardstoryProperties from './BoardstoryProperties';
import BoardstoryAccessCodes from '../../components/Boardstory/BoardstoryAccessCodes';
import { Image, getApiImageSrcSet, imageSizesByColumn } from '../../components/Image';

const BoardstoryPreview = ({ boardstory, teaserImage, videoError, onPlayRequest, hasPlayableVideos }) => {
  const onPreviewClick = (event) => {
    onPlayRequest(event, undefined, {
      label: 'button-image-play',
    });
  };

  return (
    <Thumbnail
      data-storyname={boardstory.slug}
      className={cc(['button-image-play', { unklickable: videoError || !hasPlayableVideos }])}
      onClick={onPreviewClick}
    >
      <Image
        srcSet={getApiImageSrcSet(teaserImage)}
        alt={teaserImage && teaserImage.title ? teaserImage.title : boardstory.title}
        sizes={imageSizesByColumn[2]}
      />
    </Thumbnail>
  );
};

export default (props) => {
  const renderPreview = (previewProps) => (
    <BoardstoryPreview {...previewProps} boardstory={props.boardstory} teaserImage={props.teaserImage} />
  );

  const { boardstory, accessCodeParam } = props;
  return (
    <ListItemContainer className="!p-0">
      <div className="flex flex-col self-stretch w-full md:w-1/2">
        <BoardstoryPlayer
          isFree={boardstory.free}
          teaserImage={props.teaserImage}
          isExplain={boardstory.explain}
          boardstorySlug={boardstory.slug}
          boardstoryTitle={boardstory.title}
          boardstoryId={boardstory.id}
          videoHasAudio={boardstory.hasAudio}
          videoState={boardstory.videoState}
          trailerState={boardstory.trailerState}
          tigerCreationState={boardstory.tigerCreationState}
          games={boardstory.gameInfo}
          gameActive={boardstory.gameActive}
          renderPreview={renderPreview}
          accessCodeParam={accessCodeParam}
          instantLoadVideo={true}
          renderController={({ onPlayRequest, allowedVideoTypes, availableVideoTypes }) => (
            <BoardstoryShortcuts
              boardstory={boardstory}
              onPlayRequest={onPlayRequest}
              className="is-horizontal has-no-br"
              allowedVideoTypes={allowedVideoTypes}
              availableVideoTypes={availableVideoTypes}
            />
          )}
        />
        <BoardstoryAccessCodes boardstory={boardstory} isFree={boardstory.free} attachments={props.attachments} />
      </div>
      <div className="flex-1 py-6 px-4 sm:p-6 md:p-[1.3rem]">
        <BoardstoryProperties hideMeta={props.hideMeta} attachments={props.attachments} {...boardstory} />
      </div>
    </ListItemContainer>
  );
};
