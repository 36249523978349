import React from 'react';

export default ({ children }) => (
  <a
    target="_blank"
    href="/allgemeine-geschaeftsbedingungen"
    style={{ color: 'inherit' }}
  >
    {children}
  </a>
);
