import React, { useState } from 'react';
import cc from 'classcat';
import useSound from 'use-sound';
import { TransitionGroup } from 'react-transition-group';
import { SearchGameStatus, getSearchObjectPositionStyle } from './shared/gameSearchConfig';
import { getVideoSizeFromContainer } from './shared/gameUtil';
import Modal from '../Modal/Modal';
import AudioPreview from './AudioPreview';
import { ReactComponent as SearchFindIcon } from '../../assets/icons/SearchFind.svg';
import {
  AbsoluteFill,
  AnimateScaleUpDown,
  RoundedTextButton,
  SearchFindButtonWrapper,
  SearchFindTriggerButton,
  ModalButtonWrapper,
  AudioPreviewContainer,
  SearchFindModalContent,
  SearchFindQuestionMark,
  TriggerButtonTooltip,
} from './styles';
import SvgCrop from './SvgCrop';
import FoundTick from './FoundTick';
import { isValidUrl } from '../../utils/helpers';
import { FadeTransition } from '../Transition';
import SuccessSound from '../../assets/sounds/success.mp3';
import FailSound from '../../assets/sounds/fail.mp3';

function BoardstoryGameSearch({ isFullscreen, data, containerSize, aspectRatio, onGameStatusChange, onGameStart, onGameEnd }) {
  const [status, setStatus] = useState(SearchGameStatus.Init);
  const [foundIds, setFoundIds] = useState({ found: [], foundWithHelp: [] });
  const [iconHovered, setIconHovered] = useState(false);
  const [lastFoundId, setLastFoundId] = useState(null);
  const [playSuccessSound] = useSound(SuccessSound);
  const [playFailSound] = useSound(FailSound);

  const details = data && data.searchDetails ? data.searchDetails : {};
  const { answers } = details;

  const openGameOverlay = (e) => {
    e.stopPropagation();

    if (status === SearchGameStatus.Init) {
      setStatus(SearchGameStatus.IntroOverlay);
      onGameStatusChange(true);
    } else {
      setStatus(SearchGameStatus.HelpOverlay);
      onGameStatusChange(true);
    }
  };

  const hideIntro = () => {
    setStatus(SearchGameStatus.Init);
  };

  const startSearchGame = () => {
    onGameStart()
    setStatus(SearchGameStatus.GameInProgress);
  };

  const onObjectFound = async (id) => {
    setLastFoundId(id);

    playSuccessSound();
  };

  const onGameSuccess = () => {
    setStatus(SearchGameStatus.SuccessFeedback);
    setTimeout(() => {
      onSuccessFeedbackShown();
    }, 550);
  };

  const onSuccessDismiss = () => {
    // reset the found objects count
    setFoundIds({ found: [], foundWithHelp: [] });
    setStatus(SearchGameStatus.Init);
    onGameStatusChange(false);
    onGameEnd()
  };

  const onPressOutside = (e) => {
    if (status !== SearchGameStatus.GameInProgress) return;

    e.stopPropagation();
    playFailSound();
  };

  const onObjectFoundFeedbackShown = () => {
    if (lastFoundId !== null) {
      foundIds.found.push(lastFoundId);
      setLastFoundId(null);
      setFoundIds({ ...foundIds });
      if (foundIds.found.length === answers.length) {
        onGameSuccess();
      }
    }
  };

  const onSuccessFeedbackShown = () => {
    onGameEnd()
    setStatus(SearchGameStatus.SuccessOverlay);
  };

  const onGiveUp = () => {
    foundIds.foundWithHelp = answers.map((el, i) => {
      if (!foundIds.found.includes(i)) return i;
    });
    setFoundIds({ ...foundIds });
    setStatus(SearchGameStatus.GiveUpOverlay);
    playSuccessSound();
    onGameEnd()
  };

  const active = status !== SearchGameStatus.Init;
  const lastFoundObjectStyle =
    lastFoundId !== null ? getSearchObjectPositionStyle(answers[lastFoundId], containerSize, aspectRatio) : null;

  const { diff } = getVideoSizeFromContainer(containerSize, aspectRatio);

  const fullScreenButtonOffset = diff.width > 50 ? 0 : 50;

  return (
    <AbsoluteFill onClick={onPressOutside}>
      {status === SearchGameStatus.GameInProgress &&
        answers.map((answer, id) =>
          foundIds.found.includes(id) ? null : (
            <div
              role="button"
              key={id}
              onClick={(e) => {
                e.stopPropagation();
                onObjectFound(id);
              }}
            >
              <div
                style={{
                  ...getSearchObjectPositionStyle(answer, containerSize, aspectRatio),
                  position: 'absolute',
                }}
              ></div>
            </div>
          ),
        )}
      {(status === SearchGameStatus.GameInProgress ||
        status === SearchGameStatus.SuccessOverlay ||
        status === SearchGameStatus.HelpOverlay ||
        status === SearchGameStatus.SuccessFeedback ||
        status === SearchGameStatus.GiveUpOverlay) &&
        answers.map((answer, id) => {
          return foundIds.found.includes(id) || foundIds.foundWithHelp.includes(id) ? (
            <FoundTick
              key={id}
              orange={foundIds.foundWithHelp.includes(id)}
              pos={getSearchObjectPositionStyle(answer, containerSize, aspectRatio)}
            />
          ) : null;
        })}

      <TransitionGroup
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {/* Semi-transparent mask shown around the area whenever the user successfully finds an object */}
        {lastFoundObjectStyle !== null && (
          <FadeTransition key={1} duration={500} onEntered={() => setTimeout(onObjectFoundFeedbackShown, 550)}>
            <SvgCrop {...lastFoundObjectStyle} />
          </FadeTransition>
        )}
      </TransitionGroup>

      {/* The modal shown when the game start */}
      <Modal animate usePortal={false} onRequestClose={hideIntro} isOpen={status === SearchGameStatus.IntroOverlay}>
        <SearchFindModalContent>
          <p>{details.hint}</p>

          {isValidUrl(details.audioHint) && (
            <AudioPreviewContainer>
              <AudioPreview uri={details.audioHint} />
            </AudioPreviewContainer>
          )}
          <ModalButtonWrapper>
            <RoundedTextButton style={{ minWidth: 240 }} onClick={startSearchGame} children="Auf zur Suche" />
          </ModalButtonWrapper>
        </SearchFindModalContent>
      </Modal>

      {/* The modal shown when the user needs help */}
      <Modal animate usePortal={false} onRequestClose={hideIntro} isOpen={status === SearchGameStatus.HelpOverlay}>
        <SearchFindModalContent>
          <p>Brauchst du Hilfe?</p>

          <ModalButtonWrapper>
            <RoundedTextButton style={{ minWidth: 240 }} onClick={onGiveUp} children="Jetzt auflösen" />
          </ModalButtonWrapper>
        </SearchFindModalContent>
      </Modal>

      {/* The modal shown when the game ends */}
      <Modal animate usePortal={false} isOpen={status === SearchGameStatus.SuccessOverlay} onRequestClose={onSuccessDismiss}>
        <SearchFindModalContent>
          <p>Du hast alles gefunden, sehr gut.</p>

          <ModalButtonWrapper>
            <RoundedTextButton style={{ minWidth: 240 }} onClick={onSuccessDismiss} children="Weiter" />
          </ModalButtonWrapper>
        </SearchFindModalContent>
      </Modal>

      {/* The button shown when the game is given up */}
      {status === SearchGameStatus.GiveUpOverlay && (
        <AnimateScaleUpDown
          style={{
            position: 'absolute',
            zIndex: 20,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <RoundedTextButton
            style={{
              position: 'absolute',
              minWidth: 140,
              transform: 'translate(-50%,-50%)',
              boxShadow: '0 0 0 5px #fff, 1px 2px 7px 6px rgb(100 100 100 / 70%)',
            }}
            onClick={onSuccessDismiss}
            children="Weiter"
          />
        </AnimateScaleUpDown>
      )}

      <SearchFindButtonWrapper
        style={{
          top: Math.max(diff.height, fullScreenButtonOffset) + 5,
          right: diff.width + 5,
        }}
      >
        <AnimateScaleUpDown
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          onMouseEnter={() => setIconHovered(true)}
          onMouseLeave={() => setIconHovered(false)}
        >
          <SearchFindTriggerButton
            type="button"
            style={{
              backgroundColor: active ? '#27b0d4' : '#fff',
            }}
            onClick={openGameOverlay}
            className={cc({
              fullscreen: isFullscreen,
            })}
          >
            {' '}
            {status === SearchGameStatus.Init && (
              <SearchFindIcon
                style={{
                  color: active ? '#fff' : '#27b0d4',
                }}
              />
            )}
            {status !== SearchGameStatus.Init && <SearchFindQuestionMark>?</SearchFindQuestionMark>}
          </SearchFindTriggerButton>
          <TriggerButtonTooltip style={{ opacity: iconHovered ? '1' : '0' }}>
            {status == SearchGameStatus.Init ? 'Suchspiel' : 'Hilfe'}
          </TriggerButtonTooltip>
        </AnimateScaleUpDown>
      </SearchFindButtonWrapper>
    </AbsoluteFill>
  );
}

export default BoardstoryGameSearch;
