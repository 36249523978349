import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import FormSection from '../FormSection';
import { SectionSeparator, Spacing, Button, NarrowWrapper } from '../StyledElements';
import { validateValues } from './orderDataConfig';
import ErrorMessage from '../ErrorMessage';
import { getPrefilledValues } from '../../modules/organizations';

class OrderDataForm extends Component {
  state = {
    initialValues: {},
  };

  static defaultProps = {
    submitButtonValue: 'Speichern und weiter',
    isSingleUserLicense: true,
  };

  componentWillMount() {
    this.setState({
      initialValues: this.getInitialValues(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Update the initial form values in case the Redux values have been updated
    if (this.props.formValues !== prevProps.formValues) {
      this.setState({
        initialValues: this.getInitialValues(),
      });
    }
  }

  getInitialValues() {
    // Pull the fields from Redux (previous state) if possible
    // This is only used when going back to the previous step
    if (!isEmpty(this.props.formValues)) {
      return this.props.formValues;
    }

    return getPrefilledValues(this.props.emptyValues, this.props.organization, this.props.user);
  }

  onSubmit = (values, bag) => {
    bag.setSubmitting(false);
    this.props.onSubmit && this.props.onSubmit(values);
  };

  render() {
    const { fields, emptyValues } = this.props;

    const mainFormSectionProps = this.props.isSingleUserLicense
      ? {
          headlineValue: 'Bestelladresse',
          groupName: 'orderAddress',
        }
      : {
          headlineValue: 'Institution',
          groupName: 'organizationAddress',
        };

    let sectionProps = {};

    if (this.props.renderPrettySectionsInitially) {
      sectionProps = {
        isActiveInitial: false,
        isInteractive: true,
      };
    }

    return (
      <NarrowWrapper>
        <Formik
          initialValues={this.state.initialValues}
          validate={validateValues}
          onSubmit={this.onSubmit}
          enableReinitialize={this.props.enableReinitialize}
        >
          {(formProps) => (
            <Form>
              <FormSection
                fields={fields}
                emptyValues={emptyValues}
                {...sectionProps}
                {...mainFormSectionProps}
                {...formProps}
              />
              <SectionSeparator mt={3} mb={3} />
              <FormSection
                isInteractive={true}
                headlineValue="Rechnungsadresse"
                groupName="billingAddress"
                defaultText="Identisch mit der Bestelladresse."
                fields={fields}
                emptyValues={emptyValues}
                {...sectionProps}
                {...formProps}
              />
              <Spacing textAlign="center" mt={4.5}>
                <Button type="submit">{this.props.submitButtonValue}</Button>
              </Spacing>
              <ErrorMessage error={this.props.errorMessage} />
            </Form>
          )}
        </Formik>
      </NarrowWrapper>
    );
  }
}

OrderDataForm.propTypes = {
  organization: PropTypes.object,
  formValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isSingleUserLicense: PropTypes.bool,
  user: PropTypes.object,
  submitButtonValue: PropTypes.string,
  fields: PropTypes.object.isRequired,
  emptyValues: PropTypes.object.isRequired,
  renderPrettySectionsInitially: PropTypes.bool,
  errorMessage: PropTypes.string,
  enableReinitialize: PropTypes.bool,
};

export default OrderDataForm;
