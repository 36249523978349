import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageHeadline } from './StyledElements';
import { parseHtmlEntities } from '../utils/htmlEntities';

const PageTitle = ({ children, headTitle, headlineComponent: Headline, encodeHtmlEntities, useHelmet }) => {
  const renderString = (str) => {
    return encodeHtmlEntities ? parseHtmlEntities(str) : str;
  };

  return (
    <Fragment>
      {useHelmet && (
        <Helmet>
          <title>{renderString(headTitle || children)}</title>
        </Helmet>
      )}
      <Headline>{renderString(children)}</Headline>
    </Fragment>
  );
};

PageTitle.defaultProps = {
  headlineComponent: PageHeadline,
  encodeHtmlEntities: false,
  useHelmet: true,
};

export default PageTitle;
