import React from 'react';
import PropTypes from 'prop-types';
import { wpApiUrl } from '../config';

const getFirstItem = (response) => {
  if (!response || response.length === 0) return {};

  return response[0];
};

export default ({ wordpress, url, shouldReturnFirstItem, ...restProps }) => {
  const fetchUrl = wordpress ? wpApiUrl + url : url;

  if (shouldReturnFirstItem) {
    restProps.transformResponse = getFirstItem;
  }

  return <Holen url={fetchUrl} {...restProps} />;
};

function childrenToArray(children) {
  return Array.isArray && Array.isArray(children) ? children : [].concat(children);
}

class Holen extends React.Component {
  static defaultProps = {
    method: 'get',
    type: 'json',
    onResponse: () => {},
    transformResponse: (data) => data,
  };

  static propTypes = {
    body: PropTypes.any,
    children: PropTypes.func,
    credentials: PropTypes.oneOf(['omit', 'same-origin', 'include']),
    headers: PropTypes.object,
    method: PropTypes.oneOf([
      'get',
      'post',
      'put',
      'patch',
      'delete',
      'options',
      'head',
      'GET',
      'POST',
      'PUT',
      'PATCH',
      'DELETE',
      'OPTIONS',
      'HEAD',
    ]),
    onResponse: PropTypes.func,
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['json', 'text', 'blob', 'arrayBuffer', 'formData']),
    transformResponse: PropTypes.func,
  };

  state = {
    fetching: true,
    response: undefined,
    data: undefined,
    error: undefined,
  };

  componentDidMount() {
    this.doFetch();
  }

  componentDidUpdate(nextProps) {
    // only refresh when url changes with primitive types change
    const refreshProps = ['url', 'method', 'type', 'body'];
    if (refreshProps.some((key) => this.props[key] !== nextProps[key])) {
      this.doFetch(nextProps);
    }
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  doFetch = (options) => {
    const { url, body, credentials, headers, method } = {
      ...this.props,
      ...options,
    };

    this.setState({ fetching: true });

    const updateState = (error, response) => {
      if (this.willUnmount) {
        return;
      }

      this.setState(
        {
          data: response && response.data ? this.props.transformResponse(response.data) : undefined,
          error,
          fetching: false,
          response,
        },
        () => void this.props.onResponse(error, response),
      );
    };

    return fetch(url, { body, credentials, headers, method })
      .then((res) => {
        if (this.willUnmount) return;

        const bodyMethod = res[this.props.type];
        return bodyMethod.apply(res).then((data) => {
          res.data = data;
          return res;
        });
      })
      .then((res) => {
        updateState(undefined, res);
        return res;
      })
      .catch((e) => {
        updateState(e, undefined);
        return e;
      });
  };

  render() {
    if (!this.props.children) return null;

    const renderFn = this.props.render || childrenToArray(this.props.children)[0];
    return (
      renderFn({
        fetching: this.state.fetching,
        response: this.state.response,
        data: this.state.data,
        error: this.state.error,
        fetch: this.doFetch,
      }) || null
    );
  }
}
