import React from 'react';
import styled from 'styled-components/macro';
import { FlexContainer, FlexColumn, FrontPageHeadline, PageContainer } from '../../../components/StyledElements';
import { Image, getWpImageSrcSet } from '../../../components/Image';
import ConfettiIcon from '../../../assets/icons/Confetti.svg';
import GooglePlayBadge from '../../../assets/images/google-play-badge.png';
import AppStoreBadge from '../../../assets/images/app-store-badge.png';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.oniloBlue};
  background-image: url(${ConfettiIcon});
  background-size: cover;
  background-repeat: repeat;
  position: relative;
  overflow: hidden;

  .container {
    color: #fff;
  }

  .store-icons {
    margin-top: 1.25rem;
  }

  .store-icons > * + * {
    margin-left: 1.5rem;
  }

  h3 {
  }

  p {
    font-size: inherit;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default ({ items, isAuthenticated }) => {
  const { headline, image, text, text_short } = items || {};

  const storeIcons = (
    <div className="store-icons">
      <a href="https://apps.apple.com/de/app/onilo/id1523051643" target="_blank" rel="noopener noreferrer">
        <img height="40" src={AppStoreBadge} loading="lazy" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.storydocks.onilo" target="_blank" rel="noopener noreferrer">
        <img height="40" src={GooglePlayBadge} loading="lazy" />
      </a>
    </div>
  );

  return (
    <Wrapper>
      <PageContainer className="container">
        <div className="mb-10">
          <FrontPageHeadline className="text-inherit m-small">{headline}</FrontPageHeadline>
        </div>
        {text_short && <div className="text-2xl italic text-center" dangerouslySetInnerHTML={{ __html: text_short }} />}
        {isAuthenticated ? (
          <FlexContainer className="flex-wrap">
            {image && (
              <FlexColumn className="flex flex-col items-center justify-center mb-2 md:mb-0 md:pr-12">
                <Image width="365" lazy srcSet={getWpImageSrcSet(image)} />
              </FlexColumn>
            )}
            <FlexColumn>
              <div className="text-base" dangerouslySetInnerHTML={{ __html: text }} />
              {storeIcons}
            </FlexColumn>
          </FlexContainer>
        ) : (
          <FlexContainer className="flex-wrap">
            <FlexColumn>
              <div className="text-2xl italic" dangerouslySetInnerHTML={{ __html: text }} />
              {storeIcons}
            </FlexColumn>
            {image && (
              <FlexColumn className="flex flex-col items-center justify-center mt-2 md:mt-0">
                <Image width="365" lazy srcSet={getWpImageSrcSet(image)} />
              </FlexColumn>
            )}
          </FlexContainer>
        )}
      </PageContainer>
    </Wrapper>
  );
};
