import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { apiFetch, apiEndpoints } from '../../modules/api';
import PageTitle from '../../components/PageTitle';
import { PageContainer, TextBlock } from '../../components/StyledElements';
import { connect } from 'react-redux';
import { getUserAccount } from '../../actions/user';

class ConfirmEmailChange extends Component {
  state = {
    response: 'pending'
  };

  componentDidMount() {
    const { emailChangeToken } = this.props.match.params;
    if (!emailChangeToken || !emailChangeToken.length) {
      return this.props.history.push('/login');
    }

    apiFetch
      .url(`${apiEndpoints.users.confirmEmailChange}/${emailChangeToken}`)
      .post()
      .json((apiResponse) => {
        this.setState({ response: 'success' });
        this.props.getUserAccount(apiResponse);
      })
      .catch((error) => {
        this.setState({ response: error });
      });
  }

  render() {
    return (
      <PageContainer narrow>
        <PageTitle>Emailänderung</PageTitle>
        <TextBlock fontSize={1.5} italic centered mt={2.5}>
          {(() => {
            switch (this.state.response) {
              case 'pending':
                return 'Die Email wird aktualisiert';
              case 'success':
                return (
                  <Fragment>
                    Ihre Email wurde aktualisiert
                    <br />
                    <Link to="/">zur Startseite</Link>
                  </Fragment>
                );
              default:
                const err = this.state.response.json.error;
                if (err.match(/expired/gi))
                  return 'Die Frist zur Bestätigung ihrer neuen Emailadresse ist leider abgelaufen';
                if (err.match(/unknown/gi))
                  return 'Ihr Bestätigungslink ist leider ungültig';
                if (err.match(/taken/gi))
                  return 'Es ist Bereits ein anderer Account auf diese Email registriert';
                return 'Es ist ein Fehler bei der Änderung ihrer Email aufgetreten.';
            }
          })()}
        </TextBlock>
      </PageContainer>
    );
  }
}

export default connect(
  null,
  { getUserAccount }
)(ConfirmEmailChange);
