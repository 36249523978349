import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Avatar } from '../assets/icons/Avatar.svg';
import { ReactComponent as AvatarWithWarning } from '../assets/icons/AvatarWithWarning.svg';
import media from '../utils/media';

const AvatarWrapper = styled.div`
  display: inline-block;
  align-self: center;

  svg {
    width: 40px;
    height: 40px;

    ${media.lg`
      width: 48px;
      height: 48px;
    `};
  }
`;

export default ({ className, isRenewable }) => {
  return (
    <AvatarWrapper className={className}>
      {isRenewable ? <AvatarWithWarning /> : <Avatar />}
    </AvatarWrapper>
  );
};
