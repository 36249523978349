import { Component } from 'react';
import PropTypes from 'prop-types';
import { apiFetch, apiEndpoints } from '../../modules/api';
import { toArray } from '../../utils/helpers';

class BoardstoryListLoader extends Component {
  static defaultProps = {
    shouldFetchTeaserImages: true
  };

  state = {
    boardstories: [],
    teaserImages: {},
    error: undefined
  };

  componentWillUnmount() {
    this.willUnmount = true;
  }

  componentDidMount() {
    this.fetchBoardstories();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.boardstoriesIdsOrSlugs !== prevProps.boardstoriesIdsOrSlugs
    ) {
      this.fetchBoardstories();
    }
  }

  updateState(nextState) {
    if (this.willUnmount) return;
    this.setState(nextState);
  }

  async fetchBoardstories() {
    const { boardstoriesIdsOrSlugs } = this.props;

    if (!boardstoriesIdsOrSlugs || boardstoriesIdsOrSlugs.length === 0) {
      return;
    }

    const fetchPromises = boardstoriesIdsOrSlugs.map((idOrSlug) =>
      apiFetch
        .url(`${apiEndpoints.boardstories.get}/${idOrSlug}`)
        .get()
        .json()
    );

    try {
      const boardstories = await Promise.all(fetchPromises);
      this.updateState({ boardstories });
      this.fetchTeaserImages(boardstories);
    } catch (error) {
      this.updateState({ error });
    }
  }

  fetchTeaserImages(boardstories) {
    // Get boardstories with teaser images
    const bIdsWithImages = boardstories
      .filter((b) => b.teaserImagesCount > 0)
      .map((b) => b.id);

    if (bIdsWithImages.length === 0) {
      return;
    }

    apiFetch
      .url(apiEndpoints.images.get)
      .query({ boardstoryId: bIdsWithImages, imageType: 'TEASER_IMAGE' })
      .get()
      .json((json) => json._embedded.images)
      .then((data) => {
        const teaserImages = {};

        data.forEach((image) => {
          teaserImages[image.boardstoryId] = image;
        });

        this.updateState({ teaserImages });
      })
      .catch((err) => {
        console.error('Fetching teaser images failed', err);
      });
  }

  render() {
    if (!this.props.children) {
      return null;
    }

    const renderFn = toArray(this.props.children)[0];

    return renderFn(this.state);
  }
}

BoardstoryListLoader.propTypes = {
  boardstoriesIdsOrSlugs: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.func,
  shouldFetchTeaserImages: PropTypes.bool
};

export default BoardstoryListLoader;
