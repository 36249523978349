import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckmarkIcon from '../../assets/icons/SmallCheckmark.svg';
import CrossIcon from '../../assets/icons/SmallCross.svg';
import parseDescription from '../../utils/parseDescription';

export const SmartListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;

  &::before {
    content: '';
    display: none;
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
    margin-right: 4px;
    height: 1em;
    width: 1em;
    margin-bottom: -2px;
  }

  &.in::before,
  &.out::before {
    display: inline-block;
  }

  &.in::before {
    background-image: url(${CheckmarkIcon});
  }

  &.out {
    color: #89d5e8;

    &::before {
      background-image: url(${CrossIcon});
    }
  }
`;

export const ListItemsContainer = styled.ul`
  line-height: 2;
  width: 100%;
  margin: 0;
  padding: 0 5%;
  color: #27b0d4;
`;

const LicenseDescription = ({ description, className }) => {
  if (!description) return null;

  return (
    <ListItemsContainer className={className}>
      {parseDescription(description, SmartListItem)}
    </ListItemsContainer>
  );
};

LicenseDescription.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string
};

export default LicenseDescription;
