import React, { Fragment } from 'react';
import styled from 'styled-components';
import { SectionSeparator } from './StyledElements';

const StyledSection = styled.div`
  margin: 3em auto;
  max-width: 972px;
`;

export default ({ withSeparator = true, children, className }) => (
  <Fragment>
    <StyledSection className={className}>{children}</StyledSection>
    {withSeparator && <SectionSeparator className="is-wide is-centered" />}
  </Fragment>
);
