import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { apiEndpoints, apiFetch } from './api';
import {
  createOrganization,
  updateOrganization,
  selectExistingOrganization,
  getOrganizationObject
} from './organizations';
import { hasNameUpdated } from '../utils/helpers'

const withOrganization = (WrappedComponent) => {
  class OrganizationContainer extends React.Component {
    state = {
      organization: {},
      existingOrganizations: [],
      error: undefined,
      updated: false,
      isInitialized: false
    };

    onError = ({ json }) => {
      this.setState({ error: isString(json) ? json : json.error });
    };

    fetchExistingOrganization = () => {
      apiFetch
        .url(apiEndpoints.organizations.get)
        .query({ owner: this.props.user.id })
        .get()
        .json((response) => {
          const { organizations } = response._embedded;
          const existingId = this.props.existingOrganizationId
            ? { id: this.props.existingOrganizationId }
            : null;
          const organization = selectExistingOrganization(
            organizations,
            existingId,
            this.props.isSingleUserLicense
          );

          this.setState({
            organization,
            existingOrganizations: organizations,
            isInitialized: true
          });
        })
        .catch(({ json }) => {
          this.setState({ isInitialized: true, error: json });
        });
    };

    createOrganization = (data, cb) => {
      this.setState({ updated: false });

      createOrganization(data)
        .json((newOrganization) => {
          this.setState(
            {
              organization: newOrganization,
              existingOrganizations: [
                newOrganization,
                ...this.state.existingOrganizations
              ],
              error: undefined,
              updated: true
            },
            cb
          );
        })
        .catch(this.onError);
    };

    updateOrganization = (nextState, prevState, cb) => {
      this.setState({ updated: false });

      updateOrganization(nextState, prevState)
        .json((organization) => {
          this.setState({ organization, error: undefined, updated: true }, cb);
        })
        .catch(this.onError);
    };

    handleOrganizationDataSubmit = (values, cb) => {
      // Create the organization object
      const organizationData = getOrganizationObject(
        values,
        this.props.user,
        this.props.isSingleUserLicense
      );

      // Update user account data using the order address (full name and salutation)
      // Only (!) if the user is ordering via a SINGLE_USER organization
      if (
        organizationData.type === 'SINGLE_USER' &&
        hasNameUpdated(values.orderAddress, this.props.user)
      ) {
        this.props.updateUser(this.props.user.id, values.orderAddress);
      }

      // Update the currently selected organization using the selected organization type
      if (this.state.existingOrganizations.length > 0) {
        const existingOrg = selectExistingOrganization(
          this.state.existingOrganizations,
          { type: organizationData.type }
        );

        if (existingOrg.id) {
          return this.updateOrganization(organizationData, existingOrg, cb);
        }
      }

      this.createOrganization(organizationData, cb);
    };

    render() {
      return (
        <WrappedComponent
          handleOrganizationDataSubmit={this.handleOrganizationDataSubmit}
          fetchExistingOrganization={this.fetchExistingOrganization}
          {...this.props}
          {...this.state}
        />
      );
    }
  }

  OrganizationContainer.propTypes = {
    isSingleUserLicense: PropTypes.bool,
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    existingOrganizationId: PropTypes.string
  };

  return OrganizationContainer;
};

export default withOrganization;
