import React from 'react';
import { TextBlock } from './StyledElements';

export default ({ error }) => {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <TextBlock centered color="#27b0d4" fontSize={1.5} mt={1.5} mb={1.5}>
        Etwas ist schief gelaufen!
      </TextBlock>
    </div>
  );
};
