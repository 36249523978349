import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import media from '../../utils/media';
import { BoxShadow, CloseButton } from '../StyledElements';
import { FadeMount } from 'components/Transition';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.zIndex || 999};
  ${(props) => props.overlayBgColor && `background-color: ${props.overlayBgColor}`};

  &.flex {
    display: flex;
    flex-direction: column;
  }
`;

const ModalContent = styled(BoxShadow)`
  max-width: 500px;
  width: calc(100% - 30px);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em 1em 1em;
  color: #27b0d4;

  ${media.md` padding: 2em; `};
`;

class Modal extends Component {
  static defaultProps = {
    isOpen: true,
    closeOnEsc: true,
    closeOnOutsideClick: true,
    renderCloseButton: true,
    onRequestClose: () => {},
    usePortal: true,
    wrapContent: true,
  };

  onOutsideClick = (event) => {
    if (!this.props.closeOnOutsideClick) return;
    if (event.target.dataset.modal) {
      this.props.onRequestClose(event);
    }
  };

  handleKeyDown = (event) => {
    if (this.props.isModal) return;

    // Close on Esc click
    if (event.keyCode === 27) {
      event.preventDefault();
      this.props.onRequestClose(event);
    }
  };

  componentDidMount() {
    if (this.props.closeOnEsc) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnEsc) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  render() {
    if (!this.props.animate && !this.props.isOpen) return null;

    let content = (
      <ModalOverlay
        data-modal="true"
        onClick={this.onOutsideClick}
        overlayBgColor={this.props.overlayBgColor}
        zIndex={this.props.zIndex}
        className={this.props.overlayClassName}
      >
        {this.props.wrapContent ? (
          <ModalContent className={this.props.className}>
            {this.props.renderCloseButton && <CloseButton onClick={this.props.onRequestClose} />}
            {this.props.children}
          </ModalContent>
        ) : (
          this.props.children
        )}
      </ModalOverlay>
    );

    if (this.props.animate) {
      content = <FadeMount active={this.props.isOpen}>{content}</FadeMount>;
    }

    if (this.props.usePortal) {
      return <Portal>{content}</Portal>;
    }

    return content;
  }
}

export const modalPropTypes = {
  isOpen: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  onRequestClose: PropTypes.func,
  isModal: PropTypes.bool,
  overlayBgColor: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  zIndex: PropTypes.number,
  renderCloseButton: PropTypes.bool,
  usePortal: PropTypes.bool,
  wrapContent: PropTypes.bool,
  animate: PropTypes.bool,
};

Modal.propTypes = modalPropTypes;

export default Modal;
