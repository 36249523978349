import React from 'react';
import styled from 'styled-components';
import media from '../utils/media';

const ToggleContainer = styled.div`
  background-color: #e6f6fa;
  box-shadow: 0 8px 16px 4px rgba(0, 98, 124, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  border-radius: 45px;
  position: relative;
  overflow: auto;
  z-index: 1;

  ${media.lg`
    border-radius: 50px;
    max-width: 430px;
  `};
`;

const ToggleButton = styled.button`
  cursor: pointer;
  width: 100%;
  max-width: 180px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: ${(props) => (props.selected ? '#fff' : '#27b0d4')};
  background-color: ${(props) => (props.selected ? '#27b0d4' : '#e6f6fa')};
  height: 52px;
  border: solid 1px #27b0d4;

  ${media.lg`
    font-size: 1.25rem;
    height: 72px;
    max-width: 215px;
  `};

  &:first-of-type {
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;

    ${media.lg`
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    `};
  }

  &:last-of-type {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;

    ${media.lg`
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    `};
  }
`;

const Toggle = ({ data, selected, onClick }) => {
  return (
    <ToggleContainer>
      {data.map(({ name, id }) => (
        <ToggleButton
          selected={selected === id}
          key={id}
          onClick={(event) => onClick(id, event)}
        >
          {name}
        </ToggleButton>
      ))}
    </ToggleContainer>
  );
};

export default Toggle;
