import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from '../../components/Modal/modalActions';
import {
  addBoardstoryBookmark,
  deleteBoardstoryBookmark,
} from '../../actions/user';

import { ReactComponent as Bookmark } from '../../assets/icons/Bookmark.svg';
import { ReactComponent as Unbookmark } from '../../assets/icons/Unbookmark.svg';

const BoardstoryBookmark = (props) => {
  const onBookmarkRequest = () => {
    if (props.isAuthenticated) {
      const bookmarkFunc = props.isBookmarked
        ? props.deleteBoardstoryBookmark
        : props.addBoardstoryBookmark;
      return bookmarkFunc(props.boardstoryId);
    }

    props.openModal({
      buttonValue: 'Anmelden',
      pushTo: '/login',
      content:
        'Um die Boardstory merken zu können, müssen Sie angemeldet sein.',
    });
  };

  const BookmarkIcon = props.isBookmarked ? Unbookmark : Bookmark;

  return props.children({
    onClick: onBookmarkRequest,
    isBookmarked: props.isBookmarked,
    icon: BookmarkIcon
  });
};

BoardstoryBookmark.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  boardstoryId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  addBoardstoryBookmark: PropTypes.func.isRequired,
  deleteBoardstoryBookmark: PropTypes.func.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
};

const select = (state, ownProps) => {
  const { bookmarks = [] } = state.user;

  return {
    isAuthenticated: state.auth.isAuthenticated,
    isBookmarked: bookmarks.indexOf(ownProps.boardstoryId) > -1,
  };
};

export default connect(select, {
  openModal,
  addBoardstoryBookmark,
  deleteBoardstoryBookmark,
})(BoardstoryBookmark);
