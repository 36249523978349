import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { wpEndpoints } from '../../modules/wp';
import qs from 'query-string';
import { AltContainer, ShortDescription, PageSectionHeadline } from '../../components/StyledElements';
import SectionsModule from '../../components/SectionsModule/SectionsModule';
import Fetcher from '../../components/Fetcher';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import YoastHead from '../../components/SEO/YoastHead';
import NotFound from '../../pages/ErrorPage/NotFound';
import { getPostItemBaseSlug } from '../../utils/wpHelpers';
import { Image } from '../../components/Image';
import Placeholder from '../../components/Placeholder';

const getFirstPost = (response) => {
  if (!response || response.length === 0) return [];

  return response[0];
};

const getPostData = (response) => {
  if (response.data) return response.data;
  return null;
};

const NewsPost = ({ location, match, queryParams, showHero }) => {
  const { slug } = match.params;
  const query = qs.stringify({ slug, ...queryParams });
  const params = new URLSearchParams(location.search);

  const endPoint = slug === '--preview--' ? wpEndpoints.postPreview : wpEndpoints.posts;
  const endPointQuery = slug === '--preview--' ? `/${params.get('id')}` : `?${query}`;

  return (
    <Fetcher
      wordpress
      transformResponse={slug === '--preview--' ? getPostData : getFirstPost}
      url={`${endPoint}${endPointQuery}`}
    >
      {({ data, fetching }) => {
        if (data && data.length === 0) {
          return <NotFound />;
        } else if (fetching) {
          return <Placeholder loading={fetching} />;
        } else if (!data || !data.acf) {
          return null;
        }

        // Make sure the post is only accessible through a single url
        const baseSlug = getPostItemBaseSlug(data);
        const singlePostUrl = `/${baseSlug}/${data.slug}`;
        if (singlePostUrl !== location.pathname && slug !== '--preview--') {
          return <Redirect to={singlePostUrl} />;
        }

        const { featured_image_src: thumbnailSrc = {} } = data;
        const { short_text, sections, image_position: imagePosition } = data.acf;

        return (
          <Fragment>
            {showHero && thumbnailSrc.full && (
              <div className="h-[40vh] max-h-[445px] relative md:h-[45vh] lg:h-[60vh]">
                <Image
                  className="absolute inset-0 object-cover w-full h-full"
                  style={{
                    objectPosition: imagePosition ? `center ${imagePosition}` : 'center',
                  }}
                  src={thumbnailSrc.large[0]}
                  srcSet={thumbnailSrc.srcset}
                  alt={thumbnailSrc.alt}
                />
              </div>
            )}
            <AltContainer>
              <PageTitle headlineComponent={PageSectionHeadline} encodeHtmlEntities={true}>
                {data.title.rendered}
              </PageTitle>
              <YoastHead meta={data.yoast_meta} />
              {short_text && (
                <Section className="has-first-child" withSeparator={sections && sections.length > 0}>
                  <ShortDescription>{short_text}</ShortDescription>
                </Section>
              )}
              <SectionsModule sectionClassName={short_text ? undefined : 'has-first-child'} sections={sections} />
            </AltContainer>
          </Fragment>
        );
      }}
    </Fetcher>
  );
};

export default NewsPost;
