import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky-17';
import isEmpty from 'lodash/isEmpty';
import { getCurrentOrder } from '../../actions/user';
import PrivateRoute from '../../components/PrivateRoute';
import AccountNavbar from '../../components/AccountNavbar';
import AccountLicense from './AccountLicense';
import AccountData from './AccountData';
import AccountBookmarks from './AccountBookmarks';
import { RentalUserManagement } from '../../components/UserManagement';
import { getRolesAsBoolean, getMemberFromOrg } from '../../utils/userRoleHelpers';

class Account extends Component {
  componentDidMount() {
    this.props.getCurrentOrder();
  }

  render() {
    const { currentOrder = {}, user, license, match, organization } = this.props;

    // Check if the user can manage the currently active organization
    const memberEntry = getMemberFromOrg(organization, user);
    const { isOwner } = getRolesAsBoolean(memberEntry);
    const accountDataProps = isOwner
      ? {
          isSingleUserLicense: license && license.maxUsers ? license.maxUsers === 1 : true,
          existingOrganizationId: currentOrder.organizationId,
        }
      : { isSingleUserLicense: true };

    return (
      <StickyContainer>
        <AccountNavbar />
        <Switch>
          <PrivateRoute
            exact
            path={match.path}
            extraProps={{
              user,
              ...accountDataProps,
            }}
            component={AccountData}
          />
          {!isEmpty(license) && isOwner && (
            <PrivateRoute path={`${match.path}/rental-license`} component={RentalUserManagement} />
          )}
          {!isEmpty(license) && (
            <PrivateRoute
              path={`${match.path}/license`}
              extraProps={{
                isOwner,
                currentOrder,
                user,
                license,
                organization,
              }}
              component={AccountLicense}
            />
          )}
          <PrivateRoute path={`${match.path}/merkliste`} component={AccountBookmarks} />
        </Switch>
      </StickyContainer>
    );
  }
}

const select = ({ user }) => ({
  currentOrder: user.currentOrder,
  user: user.account,
  license: user.license,
  organization: user.organization,
});

export default connect(select, { getCurrentOrder })(Account);
