import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { HelmetProvider } from 'react-helmet-async';

import store from './store';
import App from './components/App/App';
import './assets/fonts/MarineRounded/fonts.css';
import 'sanitize.css/sanitize.css';
import './global.css';

const container = document.getElementById('root');

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <Router>
        <LastLocationProvider>
          <App />
        </LastLocationProvider>
      </Router>
    </Provider>
  </HelmetProvider>,
  container,
);
