import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { wpEndpoints, wpFetch } from '../modules/wp';
import Modal from './Modal/Modal';
import { RoundedTextButton } from './BoardstoryGames/styles';

const BugPopup = () => {

  const [wpOptions, setWpOptions] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      wpFetch
        .url(wpEndpoints.acfOptions)
        .get()
        .json((data) => {
          if (data?.acf) initModal(data.acf)
        });
    };

    fetchData();
  }, [])

  const onClose = () => {
    setModalOpen(false)
    window.localStorage.setItem('bug-popup', wpOptions?.bug_popup_content);
  }

  const initModal = (acfOptions) => {
    setWpOptions(acfOptions);
    if(window.localStorage.getItem('bug-popup') != acfOptions?.bug_popup_content && acfOptions?.bug_popup_active) setModalOpen(true)
  }

  return (
    <Modal 
      buttonValue="Ok"
      animate 
      renderCloseButton={false}
      isOpen={modalOpen}
    >
      <div style={{
        padding: '1rem',
        textAlign: 'center'
      }}>
        <p dangerouslySetInnerHTML={{__html: wpOptions?.bug_popup_content}} />
        <RoundedTextButton
          children="Ok"
          onClick={onClose}
          style={{
            marginTop: 30,
            minWidth: 172,
            alignSelf: 'center',
          }}
        />
      </div>
    </Modal>
  );
};

export default BugPopup;