import styled, { keyframes } from 'styled-components';
import media from '../../utils/media';
import { BaseButton } from '../StyledElements';

export const AudioPreviewButton = styled(BaseButton)`
  audio {
    display: none;
  }

  svg {
    color: #f6793d;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const AudioPreviewContainer = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ImpulseContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: #f9f9f9;
  z-index: 11;
`;

export const ImpulseInner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  font-size: 16px;

  img {
    padding-bottom: 1.25rem;
  }

  p {
    margin-bottom: auto;
  }
`;

export const RoundedTextButton = styled.button`
  outline: 0;
  border: 0;
  background-color: #f6793d;
  border-radius: 30px;
  padding: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease-in;

  &:disabled {
    opacity: 0.7;
  }
`;

export const TextButton = styled(BaseButton)`
  font-size: 18px;
  color: #f6793d;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  margin-top: 1rem;
`;

export const AbsoluteFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Measurer = styled(AbsoluteFill)`
  pointer-events: none;
  z-index: -1;
`;

export const scaleUpDownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const AnimateScaleUpDown = styled.div`
  transform: scale(1);
  animation: ${scaleUpDownAnimation} 0.7s linear 2;
`;

export const ImpulseTriggerButton = styled(BaseButton)`
  &:active {
    opacity: 0.75;
  }
`;

export const TriggerButtonTooltip = styled.div`
  color: #27b0d4;
  font-size: 1.3em;
  background: white;
  padding: 10px 5px;
  border-radius: 10px;
  margin: 10px 10px;
  box-shadow: 1px 2px 7px rgb(100 100 100 / 50%);
  font-weight: 700;
  opcity: 0;
`;

export const SearchFindTriggerButton = styled(BaseButton)`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 50px;

  &:active {
    opacity: 0.9;
  }

  ${media.md`
    &.fullscreen {
      padding: 20px;

      svg { 
        width: 44px;
        height: 42px;
      }
    }
  `}
`;

export const SearchFindButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const ModalButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  align-items: center;
`;

export const SearchFindModalContent = styled.div`
  padding: 1rem 2rem;
  font-size: 16px;

  p {
    text-align: center;
  }
`;

export const SearchFindTickWrapper = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;

  svg {
    padding: 15px;
    height: 72px;
    width: 72px;
  }
`;

export const SearchFindQuestionMark = styled.div`
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Quiz

export const QuizTopBar = styled.div`
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  .quiz-topbar--title {
    font-weight: inherit;
    line-height: 1.375;
    text-align: center;
    font-style: italic;
    font-size: 1.25rem;

    ${media.lg`
      font-size: 1.75rem;
    `}
  }
`;

export const QuizMainView = styled.div`
  background-color: rgb(247, 247, 247);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const QuizInnerNarrow = styled.div`
  max-width: 20rem;
  width: 100%;

  &.flex-1 {
    flex: 1;
  }
`;

export const QuizOptionButton = styled.div`
  border-radius: 28px;
  border: 4px solid transparent;
  border-color: rgb(247, 247, 247);
  line-height: 1.5;

  &:active {
    opacity: 0.7;
  }

  .question-letter {
    color: #f6793d;
    font-weight: bold;
    margin-right: 1rem;
  }
`;

export const QuizModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(247, 247, 247);
  display: flex;
  flex-direction: column;
  color: #27b0d4;
  overflow: hidden;

  ${media.lg`
    border-radius: 16px;
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
    margin: auto auto;
    height: auto;
    max-width: 800px;
  `};

  // local helpers
  .w-full {
    width: 100%;
  }
  .text-success {
    color: #14c600;
  }
  .text-error {
    color: #ff0000;
  }
  .is-visible {
    visibility: visible;
  }
`;

export const QuizCloseButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0.5rem 0.75rem;

  svg {
    color: #f6793d;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const ProgressBarWrapper = styled.div`
  .progress-bar--text {
    font-size: 11px;
    line-height: 1;
    padding: 0 5px 2px;
    color: #f6793d;
  }

  .progress-bar--main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: #ecfbfe;
  }

  .progress-bar--fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #27b0d4;
    opacity: 0.4;
  }
`;

export const QuizOverallFeedback = styled.div`
  @media (min-width: 48em) and (min-height: 576px)  {
    &::before {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 20px;
      border-style: solid;
      border-width: 100px 17px 0 50px;
      border-color: #fff transparent transparent transparent;
      transform: rotate(41deg);
      z-index: -1;
    }

    &.chat-bubble--small { 
      position: absolute;
      right: -2rem;
      max-width: 200px;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
