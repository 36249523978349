import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from '../assets/icons/Download.svg';

const DownloadLink = styled.a`
  border-radius: 4px;
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  font-size: 0.875em;
  padding: 4px 8px 2px 6px;
  color: #f26521;
  background-color: rgba(242, 101, 33, 0.1);
  border: dashed 1px #f26521;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  line-height: 1.25;
  text-decoration: none;
  transition: all 0.3s;
  word-break: break-all;

  &:hover {
    border-color: #27b0d4;
    color: #27b0d4;
    background-color: #effbfe;

    svg g {
      stroke: #27b0d4;
    }
  }

  &.is-inactive, &.is-inactive:hover {
    background-color: #fff;
    border-color: #27b0d4;
    color: #27b0d4;
    cursor: pointer;

    svg g {
      stroke: #27b0d4;
    }
  }

  svg {
    display: inline-block;
    margin-left: 4px;
    margin-top: -2px;
    vertical-align: middle;
  }
`;

export default ({ children, ...restProps }) => (
  <DownloadLink {...restProps}>
    {children} <DownloadIcon width={14} height={14} />
  </DownloadLink>
);
