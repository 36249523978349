import * as actions from './modalActions';

const initialState = {
  modals: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return {
        ...state,
        modals: [...state.modals, action.modal]
      };
    case actions.CLOSE_MODAL:
      return {
        ...state,
        modals: state.modals.filter((item) => item.id !== action.modalId)
      };
    default:
      return state;
  }
};
