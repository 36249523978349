import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import omit from 'lodash/omit';
import reducers from './reducers';
import { isProduction } from './config';
import { loadState, saveState } from './modules/localStorage';

const STORE_VERSION = 1.0;

// Support Redux DevTools in development
const composeEnhancers =
  (!isProduction && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// Load state from localStorage
let persistedState = loadState();

// Reset state if the state shape is updated
if (persistedState && persistedState.version !== STORE_VERSION) {
  persistedState = undefined;
} else {
  persistedState = omit(persistedState, 'version');
}

const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

// Sync parts of Redux state with localStorage
store.subscribe(
  throttle(() => {
    const { auth, order, user, preferences } = store.getState();
    // Omit formValues from cached order
    const { formValues, ...restOrder } = order;
    // Omit access code from cached user
    const { accessCode, ...restUser } = user;

    saveState({
      auth,
      user: { ...restUser, accessCode: {} },
      order: { ...restOrder, formValues: {} },
      preferences,
      version: STORE_VERSION
    });
  }, 1000)
);

export default store;
