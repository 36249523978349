import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { updateAccessCode } from '../../actions/user';
import { apiFetch, apiEndpoints } from '../../modules/api';
import { accessCodeHasValidDate } from '../../utils/helpers';
import validate from '../../modules/validate';

const AccessCodeForm = ({ onAccessCodeSubmit, updateAccessCode, renderForm, history }) => {
  const onSubmit = ({ accessCode }, actions) => {
    apiFetch
      .url(`${apiEndpoints.accessCodes.root}/${accessCode}`)
      .get()
      .json((data) => {
        if (data && accessCodeHasValidDate(data)) {
          actions.resetForm();
          updateAccessCode(data);
          onAccessCodeSubmit && onAccessCodeSubmit(data);

          // data.slug contains the slug of the attached boardstory
          history.push(`/boardstory/${data.slug}/${accessCode}`);
        } else {
          onAccessCodeError(actions);
        }
      })
      .catch(({ json }) => {
        onAccessCodeError(actions);
      });
  };

  const onAccessCodeError = (bag) => {
    bag.setSubmitting(false);
    bag.setErrors({
      accessCode: 'Dieser Schüler- oder Verleihcode ist ungültig. Bitte versuch es noch einmal.',
    });
  };

  return (
    <Formik
      initialValues={{
        accessCode: '',
      }}
      validate={validate}
      onSubmit={onSubmit}
      children={renderForm}
    />
  );
};

AccessCodeForm.propTypes = {
  onAccessCodeSubmit: PropTypes.func,
  renderForm: PropTypes.func.isRequired,
  updateAccessCode: PropTypes.func,
};

const AccessCodeFormContainer = connect(null, {
  updateAccessCode,
})(AccessCodeForm);

export default withRouter(AccessCodeFormContainer);
