import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import cc from 'classcat';
import { TextBlock, H2, Thumbnail, FlexCenter } from '../../../components/StyledElements';
import VideoPreview from '../../../components/VideoPreview';
import BoardstoryLoader from '../../../components/Boardstory/BoardstoryLoader';
import BoardstoryPlayer from '../../../components/Boardstory/BoardstoryPlayer';
import { Image, getApiImageSrcSet } from '../../../components/Image';

const ThumbnailInfo = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  text-transform: uppercase;
  z-index: 2;
  font-weight: bold;
  font-style: italic;
  padding: 5px 30px;
  background: ${(props) => props.theme.oniloDarkOrange};
  color: #fff;
  border-radius: 6px;
  font-size: 21px;
`;

const BoardstoryLink = styled(Link)`
  margin-top: 1rem;
  color: inherit;
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.oniloDarkOrange};
  }
`;

const renderPreview = ({ videoError, onPlayRequest, hasPlayableVideos, teaserImage, boardstory }) => {
  const onPreviewClick = (event) => {
    onPlayRequest(event, 'trailers', {
      label: 'button-image-play',
    });
  };

  if (hasPlayableVideos) {
    return (
      <VideoPreview className="video__preview">
        {boardstory.free && <ThumbnailInfo>Kostenlos</ThumbnailInfo>}

        <Thumbnail
          className={cc(['button-image-play darkened video__thumbnail', { unklickable: videoError }])}
          data-storyname={boardstory.slug}
          onClick={onPreviewClick}
        >
          <Image
            lazy
            srcSet={getApiImageSrcSet(teaserImage)}
            alt={teaserImage && teaserImage.title ? teaserImage.title : undefined}
          />
        </Thumbnail>
      </VideoPreview>
    );
  } else {
    return (
      <Thumbnail className="unklickable video__thumbnail">
        <FlexCenter>
          <TextBlock centered color="#aaa" fontSize={1.5}>
            Trailer aktuell nicht vorhanden
          </TextBlock>
        </FlexCenter>
      </Thumbnail>
    );
  }
};

export default ({ boardstorySlug, title, className, renderTitle = true }) => {
  return (
    <BoardstoryLoader boardstoryIdOrSlug={boardstorySlug} fetchAttachments={false} fetchSimilarBoardstories={false}>
      {({ boardstory, teaserImage }) => {
        if (!boardstory) return null;

        return (
          <div className={className}>
            <BoardstoryPlayer
              nativePlayerUI
              boardstorySlug={boardstory.slug}
              boardstoryId={boardstory.id}
              videoHasAudio={boardstory.hasAudio}
              videoState={boardstory.videoState}
              trailerState={boardstory.trailerState}
              tigerCreationState={boardstory.tigerCreationState}
              renderPreview={(playerProps) => renderPreview({ ...playerProps, teaserImage, boardstory })}
            />
            {renderTitle && (
              <BoardstoryLink to={`/boardstory/${boardstory.slug}`}>
                <H2>{title || boardstory.title}</H2>
              </BoardstoryLink>
            )}
          </div>
        );
      }}
    </BoardstoryLoader>
  );
};
