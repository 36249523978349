import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router-dom';

import { getPostItemBaseSlug } from '../../utils/wpHelpers';
import SearchAutocomplete from '../Search/SearchAutocomplete';
import MenuFlyout from '../MenuFlyout';
import {
  getSearchLocation,
  renderSuggestionKey,
  getMixedSuggestions,
  selectedItemToString,
  renderSuggestionItem,
  fetchNewsSuggestions,
  fetchBoardstoriesSuggestions
} from './searchUtil';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';

const SearchWrapper = styled.div`
  cursor: pointer;

  svg {
    width: 42px;
    height: 42px;
    transition: all 0.3s;

    path {
      fill: #fff;
    }
  }
`;

const SearchFlyoutInner = styled.div`
  color: #27b0d4;
  .search-dropdown-container {
    width: 580px;
    max-width: none;
    margin-bottom: 0;

    .search-input-container {
      border-radius: 46px;
      background-color: #fff;
      padding: 10px;
    }
    .search-suggestions-list {
      top: 46px;
      margin: 0 10px;
    }
    .search-suggestions-list-item {
      padding-top: 9px;
      padding-bottom: 7px;
    }
  }
`;

class SearchFlyout extends React.Component {
  state = {
    loadingSuggestions: false,
    boardstories: [],
    posts: []
  };

  getSuggestions = () => {
    return getMixedSuggestions(this.state.boardstories, this.state.posts);
  };

  onSearchTermChange = (searchTerm) => {
    if (searchTerm) {
      this.props.history.push(getSearchLocation(searchTerm));
    }
  };

  onSuggestionClick = (item) => {
    const baseSlug = getPostItemBaseSlug(item) || 'boardstory';

    this.props.history.push(`/${baseSlug}/${item.slug}`);
  };

  fetchSuggestions = debounce(async (searchTerm) => {
    const boardstories = await fetchBoardstoriesSuggestions(searchTerm);
    this.setState({ boardstories });

    const posts = await fetchNewsSuggestions(searchTerm);
    this.setState({ posts });
  }, 500);

  render() {
    return (
      <MenuFlyout
        placementPaddingSize={10}
        triggerOn='click'
        renderFlyout={() => (
          <SearchFlyoutInner>
            <SearchAutocomplete
              items={this.getSuggestions()}
              onChange={this.onSearchTermChange}
              fetchItems={this.fetchSuggestions}
              onSuggestionClick={this.onSuggestionClick}
              itemToString={selectedItemToString}
              renderSuggestionKey={renderSuggestionKey}
              renderSuggestionItem={renderSuggestionItem}
              shouldFilterItems={false}
              placeholder="Suchbegriff eingeben"
            />
          </SearchFlyoutInner>
        )}
        shouldRenderInnerContainer={false}
      >
        <SearchWrapper>
          <SearchIcon />
        </SearchWrapper>
      </MenuFlyout>
    );
  }
}

export default withRouter(SearchFlyout);
