import React, { Component } from 'react';
import { Formik } from 'formik';
import qs from 'query-string';
import { apiFetch, apiEndpoints } from '../../modules/api';
import PageTitle from '../../components/PageTitle';
import CheckboxField from '../../components/CheckboxField';
import TosLink from '../../components/TosLink';
import { PageContainer, TextBlock, Spacing, Button, Form } from '../../components/StyledElements';

const initialValues = {
  tosConsent: false,
};

const validateValues = (values) => {
  let errors = {};
  if (!values.tosConsent) {
    errors.tosConsent = 'Sie müssen den AGB zustimmen, um fortzufahren';
  }

  return errors;
};

const { access_token } = qs.parse(location.search);

export default class extends Component {
  onSubmit = (values, { setSubmitting }) => {
    return apiFetch
      .url(apiEndpoints.users.tosConsent)
      .auth(`Bearer ${access_token}`)
      .catcher(401, (err) => {
        setSubmitting(false);
        console.log(err.message);
        alert('Anmeldung fehlgeschlagen.');
      })
      .json({ tosConsent: values.tosConsent })
      .post()
      .text((txt) => {
        window.location.replace(txt);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  render() {
    return (
      <PageContainer narrow>
        <PageTitle>Bestätigung der AGB</PageTitle>
        <TextBlock centered fontSize={1.25} lineHeight={1.5} mt={1.75} mb={5}>
          Willkommen auf <strong>Onilo.de</strong>.<br />
          Unsere Allgemeinen Geschäftsbedingungen können Sie <TosLink>hier</TosLink> nachlesen.
          <br />
          Damit Sie <strong>Onilo.de</strong> vollumfänglich nutzen können, benötigen wir Ihr Einverständnis zu den AGB.
          <br />
          Viel Spaß!
        </TextBlock>
        <Formik initialValues={initialValues} validate={validateValues} onSubmit={this.onSubmit}>
          {(formProps) => (
            <Form onSubmit={formProps.handleSubmit}>
              <Spacing mt={2.5} />
              <CheckboxField
                name="tosConsent"
                onChange={formProps.handleChange}
                checked={formProps.values.tosConsent}
                labelClassName="centered"
                error={formProps.touched.tosConsent && formProps.errors.tosConsent}
              >
                <TextBlock bold italic left lineHeight={1.5}>
                  Ja, ich stimme den <TosLink>AGB</TosLink> zu.
                </TextBlock>
              </CheckboxField>
              <Spacing textAlign="center" mt={2.5}>
                <Button
                  type="submit"
                  className={formProps.isSubmitting ? 'loading' : undefined}
                  disabled={formProps.isSubmitting}
                >
                  Absenden
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
      </PageContainer>
    );
  }
}
