import React from 'react'
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { apiFetch, apiEndpoints } from '../../modules/api';
import { wpFetch, wpEndpoints } from '../../modules/wp';
import { parseHtmlEntities } from '../../utils/htmlEntities';
import { getPostItemType } from '../../utils/wpHelpers';

export const selectedItemToString = (item) => {
  return !isEmpty(item) && item.title ? String(item.title) : '';
};

export const renderSuggestionKey = (item) => {
  return item.id;
};

export const renderSuggestionItem = (item) => {
  if (!item) return null;

  const itemType = getPostItemType(item) || 'Boardstory';

  return (
    <React.Fragment>
      <strong>{itemType}</strong> – {item.title}
    </React.Fragment>
  );
};

export const getMixedSuggestions = (boardstories, posts) => {
  // Try to render both boardstories and at least one post
  const maxBoardstoriesNumber =
    boardstories.length > 4 && posts.length > 0
      ? 5 - Math.min(posts.length, 2)
      : boardstories.length;
  return [...boardstories.slice(0, maxBoardstoriesNumber), ...posts];
};

export const fetchBoardstoriesSuggestions = (searchTerm) => {
  const query = qs.stringify({ fulltextsearch: searchTerm });

  return apiFetch
    .url(`${apiEndpoints.boardstories.get}?${query}`)
    .get()
    .json((json) => {
      return json._embedded.boardstories;
    })
    .catch(() => {
      return [];
    });
};

export const fetchNewsSuggestions = (searchTerm) => {
  const query = qs.stringify({ search: searchTerm });

  return wpFetch
    .url(`${wpEndpoints.posts}?${query}`)
    .get()
    .json((data) => {
      // Parse html entities in WordPress's title.rendered
      return data.map((s) => ({
        ...s,
        title: parseHtmlEntities(s.title.rendered)
      }));
    })
    .catch(() => {
      return [];
    });
};

export const getSearchLocation = (searchTerm) => {
  return {
    pathname: '/search',
    search: qs.stringify({ q: searchTerm })
  }
}