import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import qs from 'query-string';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getPostItemBaseSlug } from '../utils/wpHelpers';
import {
  renderSuggestionKey,
  getMixedSuggestions,
  selectedItemToString,
  renderSuggestionItem,
  fetchNewsSuggestions,
  fetchBoardstoriesSuggestions,
} from '../components/Search/searchUtil';
import withQueryString from '../modules/withQueryString';
import { wpFetch, wpEndpoints } from '../modules/wp';
import { normalize } from '../utils/helpers';
import SearchAutocomplete from '../components/Search/SearchAutocomplete';
import Pagination from '../components/Pagination';
import { PageContainer, TextBlock, Spacing } from '../components/StyledElements';
import NewsList from './News/NewsList';
import BoardstoryList from '../components/Boardstory/BoardstoryList';
import SearchResultsList from '../components/Search/SearchResultsList';
import { getBoardstories } from '../actions/boardstories';

const ToggleContainer = styled.div`
  text-align: center;
  margin: 48px 0 38px;
`;

const ToggleButton = styled.button`
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => (props.selected ? '#fff' : '#27b0d4')};
  background-color: ${(props) => (props.selected ? '#27b0d4' : 'transparent')};
  border: solid 1px #27b0d4;
  border-radius: 4px;
  padding: 6px 2px 5px;
  margin: 0 10px 10px;
  min-width: 160px;
`;

const Toggle = ({ onClick, name, ...restProps }) => {
  const onButtonClick = () => {
    onClick(name.toLowerCase());
  };
  return (
    <ToggleButton {...restProps} onClick={onButtonClick}>
      {name}
    </ToggleButton>
  );
};

const togglesList = ['Boardstories', 'Themenwelten', 'Didaktik', 'Blog', 'Aktuelles'];

const getPostsQuery = (contentType) => {
  switch (contentType) {
    case 'themenwelten':
      return {
        categories_in: 'themenwelten',
      };
    case 'didaktik':
      return {
        categories_in: 'didaktik',
      };
    case 'blog':
      return {
        categories_in: 'blog',
      };
    case 'aktuelles':
      return {
        categories_not_in: 'didaktik,themenwelten,blog',
      };
    default:
      // Every WP post is fetched
      return {};
  }
};

class SearchIndex extends React.Component {
  state = {
    loadingSuggestions: false,
    boardstoriesSuggestions: [],
    postsSuggestions: [],
    posts: {},
    postsCategories: {},
    currentContentType: undefined,
  };

  componentDidMount() {
    // Fetch results if the initial search query is not empty
    if (this.props.queryParams.q) {
      this.fetchResults();
    }
    // Pre-fetch WP categories: these are used to map category IDs
    // of WP content with the respective category names
    this.fetchWpCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    // Update results in case any of the query params or
    // the currently selected content type are updated
    if (prevProps.queryParams !== this.props.queryParams || prevState.currentContentType !== this.state.currentContentType) {
      this.fetchResults();
    }
  }

  getSuggestions = () => {
    return getMixedSuggestions(this.state.boardstoriesSuggestions, this.state.postsSuggestions);
  };

  onSearchTermChange = (searchTerm) => {
    // Ignore empty search queries
    if (!searchTerm) return;

    const nextQueryString = qs.stringify({
      p: this.props.queryParams.p,
      q: searchTerm === '' ? undefined : searchTerm,
    });

    if (nextQueryString !== this.props.location.search) {
      this.props.history.push({ search: nextQueryString });
    }
  };

  onSuggestionClick = (item) => {
    const baseSlug = getPostItemBaseSlug(item) || 'boardstory';

    this.props.history.push(`/${baseSlug}/${item.slug}`);
  };

  fetchResults() {
    const { currentContentType } = this.state;
    if (currentContentType === 'boardstories') {
      this.fetchBoardstories();
    } else if (currentContentType && currentContentType.length > 0) {
      this.fetchPosts();
    } else {
      // Fetch everything when the current content type is undefined
      this.fetchBoardstories();
      this.fetchPosts();
    }
  }

  fetchSuggestions = debounce(async (searchTerm) => {
    const boardstories = await fetchBoardstoriesSuggestions(searchTerm);
    this.setState({ boardstoriesSuggestions: boardstories });

    const posts = await fetchNewsSuggestions(searchTerm);
    this.setState({ postsSuggestions: posts });
  }, 500);

  fetchBoardstories() {
    this.props.getBoardstories({
      page: this.props.queryParams.p,
      fulltextsearch: this.props.queryParams.q,
    });
  }

  fetchPosts() {
    const query = qs.stringify({
      ...getPostsQuery(this.state.currentContentType),
      per_page: 10,
      page: this.props.queryParams.p,
      search: this.props.queryParams.q,
    });

    let pagesCount;

    wpFetch
      .url(`${wpEndpoints.posts}?${query}`)
      .get()
      .res((response) => {
        pagesCount = parseInt(response.headers.get('X-WP-TotalPages'), 10);

        return response.json();
      })
      .then((data) => {
        this.setState({
          posts: {
            pagesCount,
            data: data || [],
          },
        });
      });
  }

  fetchWpCategories() {
    wpFetch
      .url(wpEndpoints.categories)
      .query({ per_page: 100 })
      .get()
      .json((categoriesArray) => {
        this.setState({ postsCategories: normalize(categoriesArray) });
      });
  }

  renderResults() {
    if (!this.props.queryParams.q) {
      return null;
    }

    const { currentContentType } = this.state;
    const currentPage = parseInt(this.props.queryParams.p, 10) || 1;

    let { pagesCount } = currentContentType === 'boardstories' ? this.props.boardstories : this.state.posts;
    if (currentContentType === undefined) {
      pagesCount = Math.max(this.props.boardstories.pagesCount, this.state.posts.pagesCount, 1);
    }

    return (
      <Fragment>
        {currentContentType === undefined ? (
          <SearchResultsList
            teaserImages={this.props.boardstories.teaserImages}
            boardstories={this.props.boardstories.data}
            postsCategories={this.state.postsCategories}
            posts={this.state.posts.data}
          />
        ) : currentContentType === 'boardstories' ? (
          <BoardstoryList teaserImages={this.props.boardstories.teaserImages} boardstories={this.props.boardstories.data} />
        ) : (
          <NewsList categories={this.state.postsCategories} items={this.state.posts.data} />
        )}
        {pagesCount > 1 && (
          <Spacing mt={4.5}>
            <Pagination currentPage={currentPage} pagesCount={pagesCount} />
          </Spacing>
        )}
      </Fragment>
    );
  }

  onToggleClick = (clickedContentType) => {
    const nextContentType = clickedContentType === this.state.currentContentType ? undefined : clickedContentType;
    this.setState({ currentContentType: nextContentType });

    // Reset the active page when switching between the content types
    if (nextContentType !== this.state.currentContentType && this.props.queryParams.p && this.props.queryParams.p !== 1) {
      this.props.history.push({
        search: qs.stringify({ q: this.props.queryParams.q }),
      });
    }
  };

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>Suche</title>
        </Helmet>
        <SearchAutocomplete
          initialInputValue={this.props.queryParams.q}
          items={this.getSuggestions()}
          onChange={this.onSearchTermChange}
          fetchItems={this.fetchSuggestions}
          onSuggestionClick={this.onSuggestionClick}
          itemToString={selectedItemToString}
          renderSuggestionKey={renderSuggestionKey}
          renderSuggestionItem={renderSuggestionItem}
          shouldFilterItems={false}
          placeholder="Suchbegriff eingeben"
        />
        {this.props.queryParams.q && (
          <Fragment>
            <TextBlock centered italic mt={1.5} fontSize={1.5}>
              Alle Ergebnisse zum Begriff:
              <br />
              <strong>„{this.props.queryParams.q}“</strong>
            </TextBlock>
            <ToggleContainer>
              {togglesList.map((toggleName) => (
                <Toggle
                  key={toggleName}
                  selected={this.state.currentContentType === toggleName.toLowerCase()}
                  onClick={this.onToggleClick}
                  name={toggleName}
                />
              ))}
            </ToggleContainer>
          </Fragment>
        )}
        {this.renderResults()}
      </PageContainer>
    );
  }
}

SearchIndex.defaultProps = {
  queryParams: {},
  boardstories: {},
};

const select = (state) => ({
  boardstories: { ...state.boardstories, teaserImages: state.images.data },
});

const SearchIndexContainer = connect(select, { getBoardstories })(SearchIndex);

export default withQueryString(SearchIndexContainer);
