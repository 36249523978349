import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { apiEndpoints } from '../../modules/api';
import { PageContainer, Spacing, TextBlock } from '../../components/StyledElements';
import { getBoardstoriesBookmarks } from '../../actions/boardstories';
import BoardstoryList from '../../components/Boardstory/BoardstoryList';
import Pagination from '../../components/Pagination';
import { selectBoardstoriesPaginatedState } from '../../selectors/boardstory';
import withQueryString from '../../modules/withQueryString';

class AccountBookmarks extends Component {
  static defaultProps = {
    queryParams: {},
  };

  componentDidMount() {
    this.fetchBoardstories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryParams.p !== this.props.queryParams.p) {
      this.fetchBoardstories();
    }
  }

  fetchBoardstories() {
    this.props.getBoardstoriesBookmarks({
      page: this.props.queryParams.p,
      endpoint: apiEndpoints.boardstories.bookmarks,
    });
  }

  render() {
    return (
      <PageContainer className="with-placeholder">
        <Helmet>
          <title>Merkliste</title>
        </Helmet>
        <BoardstoryList teaserImages={this.props.teaserImages} boardstories={this.props.boardstories} />
        {!this.props.isLoading && this.props.boardstories.length === 0 && (
          <TextBlock mt={3} mb={3} centered fontSize={1.5}>
            Keine zutreffenden Boardstories gefunden
          </TextBlock>
        )}
        {this.props.pagesCount > 1 && (
          <Spacing mt={4.5}>
            <Pagination currentPage={this.props.currentPage} pagesCount={this.props.pagesCount} />
          </Spacing>
        )}
      </PageContainer>
    );
  }
}

const AccountBookmarksContainer = connect(selectBoardstoriesPaginatedState, { getBoardstoriesBookmarks })(AccountBookmarks);

export default withQueryString(AccountBookmarksContainer);
