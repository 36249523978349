import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextBlock } from '../../components/StyledElements';
import OrderDataForm from '../../components/OrderDataForm/OrderDataForm';
import {
  accountFields,
  getFieldsAndInitialValues
} from '../../components/OrderDataForm/orderDataConfig';
import withOrganization from '../../modules/withOrganization';
import ErrorMessage from '../../components/ErrorMessage';

class AccountOrganisationForm extends Component {
  state = {
    prevFormValues: undefined
  };

  componentDidMount() {
    this.props.fetchExistingOrganization();
  }

  onFormSubmit = (values) => {
    this.setState({ prevFormValues: values });

    this.props.handleOrganizationDataSubmit(values);
  };

  render() {
    if (!this.props.isInitialized) {
      return null;
    }

    return (
      <Fragment>
        {this.props.organization && (
          <OrderDataForm
            user={this.props.user}
            organization={this.props.organization}
            onSubmit={this.onFormSubmit}
            submitButtonValue="Änderungen speichern"
            isSingleUserLicense={this.props.isSingleUserLicense}
            renderPrettySectionsInitially={true}
            formValues={this.state.prevFormValues}
            enableReinitialize={true}
            {...getFieldsAndInitialValues(
              accountFields,
              this.props.isSingleUserLicense
            )}
          />
        )}
        {this.props.updated && (
          <TextBlock centered mt={1} fontSize={0.875}>
            Die Daten wurden erfolgreich aktualisiert
          </TextBlock>
        )}
        {this.props.error && <ErrorMessage error={this.props.error} />}
      </Fragment>
    );
  }
}

AccountOrganisationForm.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  isSingleUserLicense: PropTypes.bool.isRequired,
  existingOrganizationId: PropTypes.string
};

export default withOrganization(AccountOrganisationForm);
