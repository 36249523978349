import React from 'react';
import { ReactComponent as TickIcon } from '../../assets/icons/TickGame.svg';
import {
  AnimateScaleUpDown
} from './styles';

export default function FoundTick({ pos, orange = false }) {
  const left = pos.left + pos.width / 2
  const top = pos.top + pos.height / 2
  return (
    <span style={{
      left: left, 
      top: top, 
      background: 'rgba(255,255,255,0.9)',
      borderRadius: '50%',
      padding: '20px',
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
      boxShadow: '1px 2px 7px rgb(100 100 100 / 70%)',
      display: 'inline-block'
    }}>
      <AnimateScaleUpDown 
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <TickIcon stroke={orange ? "#f26521" : "#14c600"} />
      </AnimateScaleUpDown>
    </span>
  );
}
