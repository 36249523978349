import React from 'react';
import styled from 'styled-components';
import { PageHeadline, InlineButton } from './StyledElements';

const Headline = styled(PageHeadline)`
  display: inline-block;
  margin: 0 1rem 0 0;
  text-align: left;
`;

const HeadlineContainer = styled.div`
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export default ({ headlineValue, buttonValue, onClick }) => {
  return (
    <HeadlineContainer>
      <Headline>{headlineValue}</Headline>
      {buttonValue && (
        <InlineButton type="button" onClick={onClick}>
          {buttonValue}
        </InlineButton>
      )}
    </HeadlineContainer>
  );
};
