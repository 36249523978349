export const OPEN_MODAL = 'modal/OPEN_MODAL';
export const CLOSE_MODAL = 'modal/CLOSE_MODAL';

let nextModalId = 0;

export const openModal = (modal) => {
  const modalId = nextModalId++;

  return {
    type: OPEN_MODAL,
    modal: {
      id: modalId,
      ...modal
    }
  };
};

export const closeModal = (modalId) => ({
  type: CLOSE_MODAL,
  modalId
});
