import { apiFetch, apiEndpoints } from './api';

/**
 * A function that can be used to track multiple events. If events need to be patched,
 * store the returned tracking token in the tracking data object to prevent unwanted
 * post request. Accepts the update object as a second argument.
 * @param {trackingData, update}
 * @returns a function that either posts or patches dependent on whether a trackingToken is present
 */
export const createTracker = (event) => (trackingData, update) => {
  const request = (data, url) => apiFetch.url(url).json(data);

  const method = trackingData.trackingToken
    ? request(update, `${apiEndpoints.boardstories.trackingEvent}/${trackingData.trackingToken || ''}`).patch()
    : request({ ...trackingData, ...update, event }, apiEndpoints.boardstories.trackingEvent).post();

  return method.json((trackingResponse) => trackingResponse);
};
