import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import cc from 'classcat';
import TriggerArrow from './Accordion/TriggerArrow'
import { ErrorContent} from './StyledElements';

const SelectContainer = styled("div")`
  background: none;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.25;
  font-style: italic;
  position: relative;
  outline: 0;
  &.is-open{
    background: #ffffff;
    width: 110%;
    left: -5%;
  }
  &.hasError{
    border-color: #ff001f;
  }
  &.disabled{
    opacity: 80%;
  }
`;

const HeaderContainer = styled("div")`
  font-style: italic;
  &.is-open{
        padding-left: calc(5 / 110 * 100%);
        padding-right: calc(5 / 110 * 100%);
    }
`;

const SelectHeader = styled("div")`
  border-bottom: 1px solid #27b0d4;
  padding: 1.4rem 0.8rem;
  position: relative;
  &.is-default{
    color: rgb(39,176,212,0.6)
    }
`;

const OptionListContainer = styled("div")`
  position: absolute;
  background: #ffffff;
  width: 100%;
  left: 0;
  z-index: 10;
  max-height: 300px; 
  overflow: auto;
`;

const OptionList = styled("div")`
  margin: 1.3rem 1.5rem;
  padding-left: 2rem;
  font-size: 1.3rem;
`;

const Option = styled("a")`
  list-style: none;
  display: block;
  color: #27b0d4;
  cursor: pointer;
  &:hover{
    color: #f26521;
  }
  &:not(:last-child){
    margin-bottom: 1rem;
  }
`;

class SelectField extends Component {
  constructor(props){
    super(props);
    this.state = {isOpen: false, value: this.props.value || "", listFilter: ""};
  }

  toggle = () => {
    this.setState(prev => ({isOpen: !prev.isOpen}))
  }

  clicker(elem){
    if (elem && elem.value !== this.props.value) elem.click();
  }

  handleKeyDown = (event) =>{
    const key = event.key;
    if (/[a-zA-Z]/.test(key)) return this.setState({listFilter: key.toLowerCase()});
    this.setState({listFilter: ""});
  }
  
  handleClick = (event) => {
    const value = event.target.getAttribute("value");
    value && this.setState({value, listFilter: ""});}

  handleBlur = () => this.setState({isOpen: false, listFilter: ""});
  
  render(){
    const {name, value, onChange, options, disabled, error} = this.props;
    const isOpen = this.state.isOpen;
    const hasError = error && error.length;

    const filteredOptions = options.filter((option) => this.state.listFilter ? option.name[0].toLowerCase() === this.state.listFilter : true);
    
    const optionsToRender = filteredOptions[0] ? filteredOptions : options;

    const listItems = optionsToRender.map((option, key) =>{
      const { value: optionValue, name: optionName, altText } = option;
      return optionValue !== value && (
        <Option key={key} name={optionName} value={optionValue}>
          { altText ? altText : optionName }
        </Option>
      );
    });

    const userValue = options.filter(({ value }) => value === this.state.value)
    const currentSelectHeader = userValue[0] ? userValue[0].name : (this.state.value || this.props.placeholder)

    return (
      <Fragment>
        <SelectContainer
          name={name}
          onBlur={this.handleBlur}
          onClick={() => !disabled && this.toggle()}
          className={cc([{'is-open': isOpen, 'hasError': hasError, 'disabled': disabled }])}
          tabIndex={0}
          onKeyDown={this.handleKeyDown}
        >
          <HeaderContainer className={cc([{ 'is-open': isOpen }])}  >
            <SelectHeader className={cc([{ 'is-default': !value }])} >
              { currentSelectHeader }
              <TriggerArrow width={18} height={18} flipped={isOpen} />
            </SelectHeader>
          </HeaderContainer>
        {isOpen && 
          <OptionListContainer onClick={this.handleClick}>
            <OptionList>
              {listItems}
            </OptionList>
          </OptionListContainer>
          }
        </SelectContainer>
        <input
          style={{ display: "none" }}
          ref={element => this.clicker(element)}
          value={this.state.value}
          name={name}
          onClick={e => onChange(e)}
          readOnly
        />
        {hasError && <ErrorContent>{error}</ErrorContent>}
      </Fragment>
      );
  }
}

export default SelectField;