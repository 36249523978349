import React from 'react';
import styled from 'styled-components/macro';
import media from '../../../utils/media';
import { Link } from 'react-router-dom';

const Heading = styled.h1`
  font-size: 1.875em;
  line-height: 1.125;
  color: #fff;
  text-shadow: 0px 4px 9px rgba(255, 247, 247, 0.25), 0px 2px 0px #27b0d4;
  margin: 0.5rem 0 0;

  @media (min-width: 375px) {
    font-size: 2em;
    margin-bottom: 0.5rem;
  }

  ${media.md`
    font-size: 42px;
    line-height: 1.25;
  `};
`;

const HeroButtonLink = styled(Link)`
  border-radius: 28px;
  color: #fff;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.oniloDarkOrange};
  background: ${(props) => props.theme.oniloDarkOrange};
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  padding: 6px 18px;
  text-align: center;

  &.button--primary {
    ${media.sm`
      padding-left: 36px;
      padding-right: 36px;
    `};
  }

  &.button--secondary {
    border-color: #fff;
    background: none;
  }
`;

export default ({ items, signupButton, readMoreButton }) => {
  const { video, poster } = items || {};

  if (!video) return null;

  return (
    <div className="relative w-full h-[50vh] md:h-[80vh] max-h-[608px] flex flex-col">
      <div className="mt-auto p-5 relative z-[1] md:p-[70px]">
        <Heading>Lernerfolg durch Lesespaß</Heading>
        <div className="flex flex-col sm:flex-row sm:space-x-6">
          {signupButton?.url && (
            <HeroButtonLink className="mt-4 sm:mt-6 button--primary" to={signupButton.url}>
              {signupButton?.text || 'Jetzt Onilo testen'}
            </HeroButtonLink>
          )}
          <HeroButtonLink className="mt-4 sm:mt-6 button--secondary" to={readMoreButton?.url ? readMoreButton.url : '/faq'}>
            {readMoreButton?.text ? readMoreButton.text : 'Mehr erfahren'}
          </HeroButtonLink>
        </div>
      </div>

      <video className="absolute inset-0 w-full h-full object-cover" muted loop autoPlay controls={false} poster={poster}>
        <source src={video} />
      </video>
    </div>
  );
};
