import React, { useCallback } from 'react';
import { PopUpContainer } from '../../StyledElements';

const LicenseInfoPopUp = ({ className, children }) => {
  const handleClick = useCallback((event) => event.stopPropagation());

  return (
    <PopUpContainer onClick={handleClick} className={className}>
      {children}
    </PopUpContainer>
  );
};

export default LicenseInfoPopUp;
