import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './style.css';

export const FadeTransition = ({
  duration = { appear: 500, exit: 0 },
  ...restProps
}) => <CSSTransition {...restProps} timeout={duration} classNames="fade" />;

export const FadeMount = ({
  active,
  children,
  duration = 300,
  ...restProps
}) => (
  <CSSTransition
    in={active}
    appear={true}
    unmountOnExit
    classNames="fadeMount"
    timeout={duration}
    exit={true}
    {...restProps}
  >
    {children}
  </CSSTransition>
);
