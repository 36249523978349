import { useState, useCallback } from 'react';

export const OptionStatus = {
    Init: 0,
    Correct: 1,
    Wrong: 2
}

export default function useBoardstoryGameQuiz(data) {
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(OptionStatus.Init);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const questions = data?.quizDetails?.questions || [];
  const currentQuestion = questions[questionIndex];
  const quizFinished = questionIndex === questions.length;
  const quizIntro = questionIndex === -1;
  const quizInProgress = !quizFinished && !quizIntro;
  
  const getQuizStatus = useCallback(() => {
    if (correctAnswers.length === questions.length) {
      return 'allCorrect';
    } else if (correctAnswers.length > 0) {
      return 'partiallyCorrect';
    }
    return 'noneCorrect';
  }, [correctAnswers, questions]);
  
  const getFeedbackText = useCallback(() => {
    let correctAnswersText = `${correctAnswers.length} von ${questions.length}`;
    if (correctAnswers.length === questions.length) {
      correctAnswersText = 'alle';
    } else if (correctAnswers.length === 0) {
      correctAnswersText = 'keine';
    }
    return correctAnswersText;
  }, [correctAnswers, questions]);
  
  const onOptionSelect = useCallback((index) => {
    if (selectedOptionStatus === OptionStatus.Init) { 
      setSelectedOptionIndex(index);
    }
  }, [selectedOptionStatus]);
  
  const onConfirmSelectedOption = () => {
    if (currentQuestion && selectedOptionIndex !== null) {
      const selectedOption = currentQuestion.options[selectedOptionIndex];
      setSelectedOptionStatus(selectedOption.correct ? OptionStatus.Correct : OptionStatus.Wrong);
      if (selectedOption.correct) {
        setCorrectAnswers([...correctAnswers, questionIndex]);
      }
      let correctAnswerIndex = 0
      for(let i = 0; i < currentQuestion.options.length; i++) {
        if(currentQuestion.options[i].correct) {
          correctAnswerIndex = i
          break;
        }
      }
      setCorrectAnswer(correctAnswerIndex)
    }
  };

  const onSubmitQuestion = useCallback(() => {
    if (quizFinished) return;
    setQuestionIndex(questionIndex + 1);
    setSelectedOptionIndex(null);
    setSelectedOptionStatus(OptionStatus.Init);
  }, [questionIndex, quizFinished]);

  const onRestartQuiz = useCallback(() => {
    setQuestionIndex(-1);
    setCorrectAnswers([]);
    setCorrectAnswer(null);
    setSelectedOptionIndex(null);
    setSelectedOptionStatus(OptionStatus.Init);
  }, [questionIndex, quizFinished]);

  const getOptionColor = useCallback(
    (index) => {
      if (selectedOptionIndex === index) {
        if (selectedOptionStatus === OptionStatus.Correct) {
          return '#14c600';
        }
        if (selectedOptionStatus === OptionStatus.Wrong) {
          return '#ff0000';
        }
        return '#f26521';
      }
      if (selectedOptionStatus === OptionStatus.Wrong && index === correctAnswer) {
        return '#14c600';
      }
      return '#f7f7f7';
    },
    [selectedOptionIndex, selectedOptionStatus]
  );

  return [
    {
      questionIndex,
      quizInProgress,
      questions,
      currentQuestion,
      quizIntro,
      correctAnswers,
      quizFinished,
      selectedOptionIndex,
      selectedOptionStatus,
    },
    {
      onOptionSelect,
      getOptionColor,
      getQuizStatus,
      getFeedbackText,
      onConfirmSelectedOption,
      onSubmitQuestion,
      onRestartQuiz
    },
  ];
}
