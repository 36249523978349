import React from 'react';
import styled from 'styled-components';
import { BigFloatingLabel } from './FloatingLabel';
import { ReactComponent as PlusIcon } from '../assets/icons/Plus.svg';

const FieldContainer = styled.div`
  position: relative;
  input {
    padding-right: 45px;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
`;

export default ({ onIconClick, ...restProps }) => {
  return (
    <FieldContainer>
      <BigFloatingLabel {...restProps} />
      <IconContainer>
        <PlusIcon onClick={onIconClick} />
      </IconContainer>
    </FieldContainer>
  );
};
