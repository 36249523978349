import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import NewsPost from './News/NewsPost';
import ContentPage from './ContentPage/ContentPage';

const TopicAreaIndex = ({ slug, categorySlug, match }) => {
  const postQueryParams = {
    categories_in: categorySlug || slug
  };

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={(routeProps) => <ContentPage {...routeProps} slug={slug} />}
      />
      <Route
        path={`${match.path}/:slug`}
        render={(routeProps) => (
          <NewsPost {...routeProps} queryParams={postQueryParams} showHero={true} />
        )}
      />
    </Switch>
  );
};

TopicAreaIndex.propTypes = {
  slug: PropTypes.string.isRequired,
  categorySlug: PropTypes.string,
  match: PropTypes.object
};

export default TopicAreaIndex;
