import React, { Fragment } from 'react';
import styled from 'styled-components';
import cc from 'classcat';
import MenuFlyout from '../MenuFlyout';
import { NavLink, Thumbnail, MenuItemLink } from '../StyledElements';
import { getMenuItemLinkProps, menuItemHasActiveChild } from '../../utils/menuItemLink';
import FlexibleLink from '../FlexibleLink';
import { Image, getWpImageSrcSet } from '../../components/Image';

const FlyoutContent = styled.div`
  padding: 20px 40px 25px;
`;

const TeasersList = styled.div`
  display: flex;
  margin: 29px -9px 0;
  color: #27b0d4;
  min-width: 406px;

  > div {
    width: 50%;
    padding: 0 9px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${Thumbnail} {
    border-radius: 2px;
    cursor: inherit;
  }
`;

const TeaserTitle = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  margin: 8px 0 0;
`;

export default ({ item, location }) => {
  const renderTeaser = (teaser, key) => {
    if (!teaser.image || !teaser.text) return null;

    const teaserData = (
      <Fragment>
        {teaser.image && (
          <Thumbnail>
            <Image
              srcSet={getWpImageSrcSet(teaser.image)}
              alt={teaser.image.alt}
              sizes="25vw"
            />
          </Thumbnail>
        )}
        {teaser.text && <TeaserTitle>{teaser.text}</TeaserTitle>}
      </Fragment>
    );

    return (
      <div key={key}>
        {teaser.url ? (
          <FlexibleLink to={teaser.url}>{teaserData}</FlexibleLink>
        ) : (
          teaserData
        )}
      </div>
    );
  };

  const renderFlyout = () => {
    const teasers = item.acf ? item.acf.teasers : null;

    return (
      <FlyoutContent>
        {item.children.map((i) => (
          <MenuItemLink {...getMenuItemLinkProps(i)} />
        ))}
        {teasers && <TeasersList>{teasers.map(renderTeaser)}</TeasersList>}
      </FlyoutContent>
    );
  };

  return (
    <MenuFlyout renderFlyout={renderFlyout}>
      <NavLink
        className={cc({
          active: menuItemHasActiveChild(item.children, location)
        })}
        {...getMenuItemLinkProps(item)}
      />
    </MenuFlyout>
  );
};
