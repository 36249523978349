import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { apiFetch, apiEndpoints } from './api';
import { hasNonEmptyValues, removeEmptyValues } from '../utils/helpers';
import { parseName } from '../utils/parseName';
import { organizationUserRoles } from '../config';

// Returns the address in the API-friendly format
const getAddressObject = (originalAddress) => {
  // Omit some "virtual" props
  let {
    firstName,
    lastName,
    salutation,
    comment, // Comment is submitted later directly to /orders
    type, // Organization type is not used as an address prop
    ...address
  } = originalAddress;

  // Name consists of non-empty extracted virtual props
  const nameParts = [salutation, firstName, lastName].filter((p) => p);
  const fullName = nameParts.join(' ');

  // If a full name is provided
  if (fullName) {
    // If the address already has a non-empty name field, use the contactPerson field
    if (address.name) {
      address.contactPerson = fullName;
    } else {
      address.name = fullName;
      address.contactPerson = null;
    }
  }

  // Remove blank props
  return pickBy(address);
};

// Returns the organization in the API-friendly format
export const getOrganizationObject = (data, user, isSingleUserLicense) => {
  const { orderAddress, billingAddress, organizationAddress = {} } = data;

  let organizationData = {
    billingAddress: null,
    members: [
      {
        userId: user.id,
        userRole: organizationUserRoles.OWNER,
      },
    ],
  };

  // Single-user organizations
  if (isSingleUserLicense) {
    organizationData = {
      ...organizationData,
      type: 'SINGLE_USER',
      name: user.email,
      address: getAddressObject(orderAddress),
    };
  } else {
    organizationData = {
      ...organizationData,
      type: organizationAddress.type,
      name: organizationAddress.name,
      address: getAddressObject(organizationAddress),
    };
  }

  // If all required fields of the billing address aren't empty, use it
  if (hasNonEmptyValues(billingAddress)) {
    organizationData.billingAddress = getAddressObject(billingAddress);
  }

  return organizationData;
};

// Selects an existing organization out of available ones
export const selectExistingOrganization = (organizations, requiredProps, isSingleUserLicense) => {
  let foundOrganizations = [];

  if (organizations && organizations.length > 0) {
    if (!isEmpty(requiredProps)) {
      foundOrganizations = organizations.filter((o) =>
        Object.keys(requiredProps).every((propName) => o[propName] === requiredProps[propName]),
      );
    } else if (isSingleUserLicense !== undefined) {
      foundOrganizations = organizations.filter((o) =>
        isSingleUserLicense ? o.type === 'SINGLE_USER' : o.type !== 'SINGLE_USER',
      );
    }
  }

  return foundOrganizations.length > 0 ? foundOrganizations[0] : {};
};

export const createOrganization = (data) => {
  return apiFetch.url(apiEndpoints.organizations.post).json(data).post();
};

export const updateOrganization = ({ type, members, ...restData }, prevState) => {
  if (!prevState || !prevState.id) {
    throw new Error('Should receive existing organization');
  }

  return apiFetch
    .url(`${apiEndpoints.organizations.patch}/${prevState.id}`)
    .json({ ...restData, members: prevState.members })
    .patch();
};

/*
  The address props that should be omitted,
  when prefilling the form with existing values,
  as they're currenly not used in the actual form and
  are therefore only saved by the API
 */
const apiAddressProps = {
  single: ['name', 'contactPerson', 'email'],
  multiple: ['contactPerson', 'email', 'phone'],
};

// A helper to merge the initial address values (usually empty strings)
// with the respective values provided by the API
const mergeAddressValues = (initialAddress, currentAddress, omitProps = []) => {
  if (isEmpty(currentAddress)) return initialAddress;

  return {
    ...initialAddress,
    ...removeEmptyValues(omit(currentAddress, omitProps)),
  };
};

// Handles prefilling the initial form values with the respective address groups
export const getPrefilledValues = (initialValues, organization = {}, user = {}) => {
  const { firstName = '', lastName = '', salutation = '' } = user;

  if (isEmpty(organization)) {
    return {
      ...initialValues,
      orderAddress: {
        ...initialValues.orderAddress,
        firstName,
        lastName,
        salutation,
      },
    };
  }

  let values;

  if (organization.type === 'SINGLE_USER') {
    values = {
      orderAddress: {
        ...mergeAddressValues(initialValues.orderAddress, organization.address, apiAddressProps.single),
        firstName,
        lastName,
        salutation,
      },
      billingAddress: {
        ...mergeAddressValues(initialValues.billingAddress, organization.billingAddress, apiAddressProps.single),
        ...(organization.billingAddress ? parseName(organization.billingAddress) : {}),
      },
    };
  } else {
    values = {
      organizationAddress: {
        ...mergeAddressValues(initialValues.organizationAddress, organization.address, apiAddressProps.multiple),
        type: organization.type,
      },
      billingAddress: mergeAddressValues(initialValues.billingAddress, organization.billingAddress, apiAddressProps.multiple),
    };
  }

  return values;
};
