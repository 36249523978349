import React, { createRef } from 'react';
import cc from 'classcat';
import { Formik, Form } from 'formik';
import styled from 'styled-components/macro';
import validate from '../../modules/validate';
import FloatingLabel from '../FloatingLabel';
import { Spacing, CaptchaWrapper } from '../StyledElements';
import { TeaserContainer, TeaserHeadline, SmallOrangeButton } from '../Footer/footerTeaserStyles';
import { apiFetch, apiEndpoints } from '../../modules/api';
import PrivacyPolicyButton from './PrivacyPolicyButton';
import FriendlyCaptcha from '../../components/FriendlyCaptcha';
import CheckboxField from '../CheckboxField';

const ButtonsWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  > button {
    text-align: left;
    &:first-child {
      margin-right: 0.5em;
    }

    &:last-child {
      margin-left: auto;
    }
  }
`;

class NewsletterForm extends React.Component {
  state = {
    status: null,
  };

  form = createRef();

  onSubmit = (values, actions) => {
    apiFetch
      .url(apiEndpoints.newsletter)
      .json({
        email: values.email,
        frcSolution: values.frcsolution,
      })
      .post()
      .res((response) => {
        actions.setSubmitting(false);
        this.setState({ status: response.status === 200 ? 'SUCCESS' : 'FAILURE' });
      })
      .catch(() => {
        actions.setSubmitting(false);
        this.setState({ status: 'FAILURE' });
      });
  };

  render() {
    const lightMode = this.props.mode === 'light';
    const standalone = this.props.position === 'standalone';
    const themeClassName = lightMode ? 'light-mode' : 'dark-mode';

    if (this.state.status) {
      return (
        <TeaserContainer className={cc({ 'light-mode': lightMode })}>
          {!standalone && <TeaserHeadline>Newsletter Anmeldung</TeaserHeadline>}
          <Spacing mt={0.5}>
            {this.state.status === 'SUCCESS' ? (
              <p>
                Vielen Dank für Ihre Anmeldung. Sie erhalten umgehend eine E-Mail von uns. Bitte klicken Sie hier auf den
                enthaltenen Link, um Ihre Anmeldung zu bestätigen.
              </p>
            ) : (
              <p>Leider ist etwas schiefgegangen. Bitte versuchen Sie es später erneut.</p>
            )}
          </Spacing>
        </TeaserContainer>
      );
    } else {
      return (
        <TeaserContainer className={cc({ 'light-mode': lightMode })}>
          {!standalone && (
            <>
              <TeaserHeadline>Newsletter Anmeldung</TeaserHeadline>
              <Spacing mt={0.5} mb={1.5}>
                <p>
                  Melden Sie sich jetzt für den Newsletter an und erhalten Sie kostenlos viele interessante Informationen rund
                  um das Thema digitales Lernen mit Onilo.
                </p>
              </Spacing>
            </>
          )}

          <Formik
            innerRef={this.form}
            initialValues={{
              email: '',
              frcsolution: '',
              consent: standalone ? false : true,
            }}
            validate={validate}
            onSubmit={this.onSubmit}
          >
            {(formProps) => (
              <Form>
                <div className="input-wrapper">
                  <FloatingLabel
                    className={themeClassName}
                    name="email"
                    placeholder="Ihre E-Mail Adresse*"
                    onChange={formProps.handleChange}
                    value={formProps.values.email}
                    error={formProps.touched.email && formProps.errors.email}
                  />
                  {standalone && (
                    <CheckboxField name="consent" onChange={formProps.handleChange} checked={formProps.values.consent}>
                      <div className="text-xl mt-5">Ja, ich möchte regelmäßig über Onilo informiert werden.</div>
                    </CheckboxField>
                  )}
                  <CaptchaWrapper className={themeClassName}>
                    <FriendlyCaptcha
                      verifyCallback={(response) => {
                        formProps.setFieldValue('frcsolution', response);
                      }}
                      value={formProps.values.frcsolution}
                      styles={{ margin: '20px auto' }}
                    />
                  </CaptchaWrapper>
                </div>
                <ButtonsWrapper>
                  <PrivacyPolicyButton />
                  <SmallOrangeButton
                    type="submit"
                    disabled={formProps.isSubmitting || !formProps.values.frcsolution}
                    className={formProps.isSubmitting ? 'loading' : !formProps.values.frcsolution ? 'not-allowed' : undefined}
                  >
                    Senden
                  </SmallOrangeButton>
                </ButtonsWrapper>
              </Form>
            )}
          </Formik>
        </TeaserContainer>
      );
    }
  }
}

export default NewsletterForm;
