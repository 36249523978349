import React, { Component } from 'react';
import { Formik } from 'formik';
import { apiFetch, apiEndpoints } from '../../modules/api';
import FormFields from '../../components/FormFields';
import PageTitle from '../../components/PageTitle';
import validate from '../../modules/validate';
import CheckboxField from '../../components/CheckboxField';
import TosLink from '../../components/TosLink';
import { PageContainer, TextBlock, Spacing, Button, Form } from '../../components/StyledElements';

const initialValues = {
  password: '',
  passwordConfirmation: '',
  tosConsent: false,
};

const fields = [
  {
    name: 'password',
    placeholder: 'Neues Passwort',
    type: 'password',
  },
  {
    name: 'passwordConfirmation',
    placeholder: 'Passwort wiederholen',
    type: 'password',
  },
];

const validateValues = (values) => {
  let errors = validate(values, {
    passwordField: 'password',
    passwordConfirmationField: 'passwordConfirmation',
  });

  if (!values.tosConsent) {
    errors.tosConsent = 'Sie müssen den AGB zustimmen, um fortzufahren';
  }

  return errors;
};

export default class extends Component {
  state = {
    success: false,
  };

  componentDidMount() {
    // Redirect any requests missing the email
    if (!this.props.location.state || !this.props.location.state.email) {
      this.props.history.push('/login');
    }
  }

  onSubmit = (values, { setSubmitting }) => {
    const { email = 'maxim@teege.me' } = this.props.location.state || {};
    if (!email) return;

    apiFetch
      .url(apiEndpoints.users.forceNewPassword)
      .json({ password: values.password, email, tosConsent: values.tosConsent })
      .post()
      .json(() => {
        window.scrollTo(0, 0);
        this.setState({ success: true });
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  render() {
    const { email = 'Ihre E-Mail-Adresse' } = this.props.location.state || {};

    if (this.state.success) {
      return (
        <PageContainer narrow>
          <PageTitle>Passwort zurücksetzen</PageTitle>
          <TextBlock fontSize={1.5} italic centered mt={2.5}>
            Eine E-Mail wurde an {email} gesendet. Folgen Sie den Anweisungen, um die Änderung zu bestätigen.
          </TextBlock>
        </PageContainer>
      );
    }

    return (
      <PageContainer narrow>
        <PageTitle>Bestätigung der neuen AGB</PageTitle>
        <TextBlock centered fontSize={1.25} lineHeight={1.5} mt={1.75} mb={5}>
          Willkommen auf dem neuen <strong>Onilo.de</strong>.<br />
          Unsere Allgemeinen Geschäftsbedingungen haben sich geändert - für noch mehr Service und Sicherheit auf unserer
          Website. Unsere angepassten AGB können Sie <TosLink>hier</TosLink> nachlesen.
          <br />
          Damit Sie <strong>Onilo.de</strong> auch weiterhin vollumfänglich nutzen können, benötigen wir Ihr Einverständnis zu
          den angepassten AGB.
          <br />
          Danach können Sie onilo.de wieder wie gewohnt nutzen.
          <br />
          Viel Spaß!
          <br />
          <br />
          Wir haben unsere Sicherheitsbestimmungen angepasst. Bitte vergeben Sie ein neues Passwort, mit dem Sie sich zukünftig
          bei Onilo einloggen.
        </TextBlock>
        <Formik initialValues={initialValues} validate={validateValues} onSubmit={this.onSubmit}>
          {(formProps) => (
            <Form onSubmit={formProps.handleSubmit}>
              <FormFields fields={fields} {...formProps} />
              <Spacing mt={2.5} />
              <CheckboxField
                name="tosConsent"
                onChange={formProps.handleChange}
                checked={formProps.values.tosConsent}
                labelClassName="centered"
                error={formProps.touched.tosConsent && formProps.errors.tosConsent}
              >
                <TextBlock bold italic left lineHeight={1.5}>
                  Ja, ich stimme den <TosLink>AGB</TosLink> zu.
                </TextBlock>
              </CheckboxField>
              <Spacing textAlign="center" mt={2.5}>
                <Button
                  type="submit"
                  className={formProps.isSubmitting ? 'loading' : undefined}
                  disabled={formProps.isSubmitting}
                >
                  Passwort vergeben
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
        <TextBlock centered fontSize={1.25} lineHeight={1.5} mt={5}>
          Sollten Sie den AGB nicht zustimmen, kontaktieren Sie bitte den Onilo Service: <br />
          Service-Hotline +49 (0)40 / 468 962 390 (zum nationalen Festnetztarif)
          <br />
          E-Mail: service@onilo.de
        </TextBlock>
      </PageContainer>
    );
  }
}
