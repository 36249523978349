export const parseName = (values) => {
  const { name, contactPerson } = values;
  const fullName =
    contactPerson && contactPerson.length > 0 ? contactPerson : name;

  if (!fullName) {
    return {};
  }

  let nameParts = fullName.split(' ');

  if (nameParts.length === 0) {
    return {};
  }

  let firstName, lastName, salutation;

  if (nameParts[0] === 'Herr' || nameParts[0] === 'Frau') {
    salutation = nameParts[0];
    nameParts = nameParts.slice(1, nameParts.length);
  }

  if (nameParts.length > 1) {
    firstName = nameParts[0];
    lastName = nameParts.slice(1, nameParts.length).join(' ');
  } else {
    firstName = nameParts.join(' ');
  }

  return {
    firstName,
    lastName,
    salutation
  };
};