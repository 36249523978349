import { useEffect, useState } from 'react';
import { TextBlock, Button } from '../../../components/StyledElements';
import LicenseTeaser from '../../../components/License/LicenseTeaser';
import dateFormat from 'dateformat';
import { hasDateNotExpired, notHiddenLicense } from '../../../utils/helpers';
import { apiFetch, apiEndpoints } from '../../../modules/api';
import { useHistory } from 'react-router-dom';
import { UserManagement } from '../../../components/UserManagement/';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
// TODO: why all the unused vars in old file?

const GenericVariant = ({ isOwner, isGenericUser, currentOrder, license }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [error, setError] = useState(null);
  const isMultiUserOrg = currentOrder.maxUsers > 1;

  const history = useHistory();

  const onLicenseRenewalRequest = () => {
    if (!isEmpty(license)) {
      history.push('/lizenzen');
    }
  };

  const getRenewalText = (id, validUntil, isOwner) => {
    if (isOwner) {
      if (notHiddenLicense(id)) {
        if (hasDateNotExpired(validUntil)) {
          return 'Lizenz läuft bald ab – hier verlängern';
        } else {
          return 'Lizenz abgelaufen – hier verlängern';
        }
      } else {
        return 'Lizenz umwandeln';
      }
    } else {
      if (hasDateNotExpired(validUntil)) {
        return 'Lizenz läuft bald ab';
      } else {
        return 'Lizenz abgelaufen';
      }
    }
  };

  const getRenewalElement = (currentOrder, isOwner) => {
    const renewalText = getRenewalText(currentOrder.licenseId, currentOrder.validUntil, isOwner);

    return isOwner ? (
      <Button className="big" onClick={onLicenseRenewalRequest}>
        {renewalText}
      </Button>
    ) : (
      <TextBlock centered italic fontSize={1.25} color={'#f26521'}>
        {renewalText}
      </TextBlock>
    );
  };

  useEffect(() => {
    setIsLoading(true);

    apiFetch
      .url(apiEndpoints.licenses.get)
      .get()
      .json((response) => {
        setIsLoading(false);
        setLicenses(response._embedded.licenses);
      })
      .catch(() => {
        setIsLoading(false);
        setError(true);
      });
  }, []);

  return (
    <>
      <LicenseTeaser name={currentOrder.licenseName}>
        {currentOrder.active ? (
          <>
            <TextBlock centered bold italic lineHeigth={1.5}>
              Laufzeit bis:
            </TextBlock>
            <TextBlock mt={0.5} mb={1.5} centered italic fontSize={2.25}>
              {dateFormat(currentOrder.validUntil, 'dd.mm.yyyy')}
            </TextBlock>
            {currentOrder.isRenewable && getRenewalElement(currentOrder, isOwner)}
          </>
        ) : (
          <TextBlock centered bold italic lineHeigth={1.5}>
            Bestellung wurde noch nicht aktiviert
          </TextBlock>
        )}
      </LicenseTeaser>

      {isGenericUser && (
        <TextBlock centered italic fontSize={1.25} color={'#f26521'}>
          Zur Verlängerung und bei Fragen zur Lizenz wenden Sie sich bitte an den Onilo-Administrator Ihrer Organisation.
        </TextBlock>
      )}
      {isMultiUserOrg && isOwner && <UserManagement />}
    </>
  );
};

GenericVariant.propTypes = {
  currentOrder: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default GenericVariant;
