import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { updateUser } from '../../actions/user';
import { PageContainer, TextBlock, NarrowWrapper, Spacing } from '../../components/StyledElements';
import AccountOrganizationForm from './AccountOrganisationForm';
import AccountUserData from './AccountUserData';
import AccountEmail from './AccountEmail';
import SelectField from '../../components/SelectField';

const formOptions = [
  { name: 'Persönliche Daten', value: 'userData' },
  { name: 'E-Mail-Adresse', value: 'accountEmail' },
  { name: 'Adressen', value: 'organization' },
];

class AccountData extends React.Component {
  state = {
    currentForm: formOptions[0].value,
  };

  onCurrentFormChange = (event) => {
    this.setState({ currentForm: event.target.getAttribute('value') });
  };

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>Meine Daten</title>
        </Helmet>
        <NarrowWrapper>
          <Spacing mb={3}>
            <SelectField value={this.state.currentForm} options={formOptions} onChange={this.onCurrentFormChange} />
          </Spacing>

          {
            {
              accountEmail: <AccountEmail {...this.props} />,
              organization: <AccountOrganizationForm {...this.props} />,
              userData: <AccountUserData {...this.props} />,
            }[this.state.currentForm]
          }
        </NarrowWrapper>
        <TextBlock centered mt={6}>
          Um Ihr Konto löschen zu lassen, wenden Sie sich bitte an unseren Kundenservice:{' '}
          <a href="mailto:service@onilo.de">service@onilo.de</a>
        </TextBlock>
      </PageContainer>
    );
  }
}

AccountData.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  isSingleUserLicense: PropTypes.bool.isRequired,
  existingOrganizationId: PropTypes.string,
};

export default connect(null, { updateUser })(AccountData);
