export const ADD_PREFERENCE = 'preferences/ADD';
export const DELETE_PREFERENCE = 'preferences/DELETE';

export const addPreference = (key, value) => ({
  type: ADD_PREFERENCE,
  key,
  value
})

export const deletePreference = key => ({
  type: DELETE_PREFERENCE,
  key
})