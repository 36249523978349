import React from 'react';
import cc from 'classcat';
import styled from 'styled-components/macro';
import { PageContainer, PageHeadline } from '../../../components/StyledElements';
import { Image, getWpImageProps } from '../../../components/Image';
import SignupCTA from '../../../components/SignupCTA';

export const ImageShadow = styled(Image)`
  filter: drop-shadow(0 8px 8px rgba(0, 98, 124, 0.2));
`;

const HotlineBlock = styled.div`
  padding: 36px;
  box-shadow: 0px 8px 16px 4px rgba(0, 98, 124, 0.1);
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.25rem;
  line-height: 1.5;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  p {
    font-size: inherit;
    line-height: 1.4;
    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 220px;
    margin: 0 auto;
  }
`;

export default ({ items, isAuthenticated, signupButton }) => {
  if (!items) return null;

  const { button, headline, link, hotlines_guest } = items || {};

  const renderHotline = ({ image, copytext }, index) => {
    return (
      <HotlineBlock key={index}>
        {image && <ImageShadow {...getWpImageProps(image)} />}
        <div dangerouslySetInnerHTML={{ __html: copytext }} />
      </HotlineBlock>
    );
  };

  return (
    <PageContainer className="text-center">
      <PageHeadline>{headline}</PageHeadline>

      <div className={cc(['grid', isAuthenticated ? 'items-center' : 'gap-5 md:grid-cols-2'])}>
        {hotlines_guest && hotlines_guest.map(renderHotline)}
      </div>

      {signupButton?.url && (
        <div className="text-center mt-12 mb-6">
          <SignupCTA button={signupButton} />
        </div>
      )}

      {link?.url && (
        <a href={link.url} style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
          {link.text}
        </a>
      )}
    </PageContainer>
  );
};
