import React, { Fragment } from 'react';
import isArray from 'lodash/isArray';
import { BigFloatingLabel } from '../components/FloatingLabel';
import SelectField from '../components/SelectField';
import InputWithButton from '../components/InputWithButton';
import { FieldGroup } from '../components/StyledElements';

const FormFields = (props) => {
  const { groupName, handleChange, handleBlur, setFieldValue } = props;
  let { fields = [], values, touched, errors } = props;

  if (groupName) {
    fields = fields[groupName] || [];
    values = values[groupName] || {};
    touched = touched[groupName] || {};
    errors = errors[groupName] || {};
  }

  const onChange = (event) => {
    props.onPublicFieldChange && props.onPublicFieldChange(event);
    handleChange(event);
  };

  const renderField = (fieldProps, key) => {
    const {
      name,
      options,
      type,
      placeholder,
      component: Component,
      valueProp,
      disabled
    } = fieldProps;

    if (fieldProps.isHidden && !props.renderHiddenFields && !values[name]) {
      return null;
    }

    const componentProps = {
      key: name,
      name: groupName ? `${groupName}.${name}` : name,
      placeholder,
      value: values[name],
      error: touched[name] && errors[name],
      onChange: fieldProps.isPublic ? onChange : handleChange,
      onBlur: handleBlur,
      disabled
    };

    if (Component === 'select' && options.length > 0) {
      return <SelectField options={options} {...componentProps} />;
    }

    if (typeof Component === 'function') {
      const valueProps = valueProp ? { [valueProp]: values[valueProp] } : {};

      return (
        <Component
          options={options}
          setFieldValue={setFieldValue}
          type={type}
          {...valueProps}
          {...componentProps}
        />
      );
    }

    if (name === 'additionalInformation1') {
      return (
        <InputWithButton
          {...componentProps}
          onIconClick={props.onHiddenFieldsRequest}
        />
      );
    }

    return (
      <BigFloatingLabel
        type={type || 'text'}
        component={Component}
        {...componentProps}
      />
    );
  };

  return (
    <Fragment>
      {fields.map((f, key) => {
        if (isArray(f)) {
          return <FieldGroup key={key}>{f.map(renderField)}</FieldGroup>;
        }
        return renderField(f, key);
      })}
    </Fragment>
  );
};

export default FormFields;
