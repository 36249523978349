import { GET_REQUEST, GET_SUCCESS, GET_FAILURE } from '../actions/images';

const initialState = {
  data: {},
  isLoading: false,
  error: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: false
      };

    case GET_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    default:
      return state;
  }
};
