import validate from '../../modules/validate';
import { hasNonEmptyValues } from '../../utils/helpers';
import countriesData from '../../data/countries';
import flatten from 'lodash/flatten';
import RegionField from '../../components/RegionField/RegionField';
import regions from '../../components/RegionField/regions';

export const getInitialValues = (fields) => {
  let initialValues = {};

  for (let group in fields) {
    flatten(fields[group]).forEach((field) => {
      initialValues[group] = initialValues[group] || {};
      initialValues[group][field.name] = '';
    });
  }

  return initialValues;
};

export const getFieldsAndInitialValues = (fields, isSingleUserLicense) => {
  const fieldsSubset = fields[isSingleUserLicense ? 'single' : 'multiple'];

  return {
    emptyValues: getInitialValues(fieldsSubset),
    fields: fieldsSubset
  };
};

// Base address fields

export const baseAddressFields = [
  [
    {
      name: 'street',
      placeholder: 'Strasse*',
      type: 'text'
    },
    {
      name: 'streetNumber',
      placeholder: 'Haus-Nr.*',
      type: 'text'
    }
  ],
  {
    name: 'additionalInformation1',
    placeholder: 'Adresszusatz',
    type: 'text'
  },
  {
    name: 'additionalInformation2',
    placeholder: 'Adresszusatz',
    type: 'text',
    isHidden: true
  },
  {
    name: 'zip',
    placeholder: 'PLZ*',
    type: 'text'
  },
  {
    name: 'city',
    placeholder: 'Ort*',
    type: 'text'
  },
  {
    name: 'country',
    placeholder: 'Land*',
    component: 'select',
    options: [  
    ...Object
    .keys(countriesData)
    .map((key) => ({ name: countriesData[key], value: countriesData[key] }))
  ]
},
{
  name: 'state',
  placeholder: 'Bundesland/Kanton*',
  component: RegionField,
  valueProp: 'country',
  type: 'text'
},
];

export const baseNameFields = [
  {
    name: 'salutation',
    component: 'select',
    placeholder:'Anrede',
    options: [
      { name: 'Herr', 
        value: 'Herr'
      },
      { name: 'Frau',
        value: 'Frau'   
      }
    ]
  },
  {
    name: 'firstName',
    placeholder: 'Vorname*',
    type: 'text'
  },
  {
    name: 'lastName',
    placeholder: 'Nachname*',
    type: 'text'
  }
];

const baseOrderAddress = [
  ...baseNameFields,
  {
    name: 'phone',
    placeholder: 'Telefon',
    type: 'text'
  },
  ...baseAddressFields
];

const organizationTypeField = {
  name: 'type',
  placeholder: 'Institutionstyp*',
  component: 'select',
  isPublic: true,
  options: [
    {
      name: 'Schule',
      value: 'SCHOOL'
    },
    {
      name: 'Bibliothek',
      value: 'LIBRARY'
    },
    {
      name: 'Sonstiges',
      value: 'MISC'
    }
  ]
};

// Actual addresses

// Single-user billing address
const billingAddressSingleUser = [...baseNameFields, ...baseAddressFields];
// Multi-user billing address
const billingAddressMultiUser = [
  {
    name: 'name',
    placeholder: 'Name / Organisation*',
    type: 'text'
  },
  ...baseAddressFields
];

const organizationAddress = [
  {
    name: 'name',
    placeholder: 'Name der Institution*',
    type: 'text'
  },
  ...baseAddressFields
];

// Sets of addresses

export const orderFields = {
  single: {
    orderAddress: [
      ...baseOrderAddress,
      {
        name: 'comment',
        placeholder: 'Kommentar',
        component: 'textarea'
      }
    ],
    billingAddress: billingAddressSingleUser
  },
  multiple: {
    organizationAddress: [organizationTypeField, ...organizationAddress],
    billingAddress: billingAddressMultiUser
  }
};

export const accountFields = {
  single: {
    orderAddress: baseOrderAddress,
    billingAddress: billingAddressSingleUser
  },
  multiple: {
    organizationAddress: [
      {
        ...organizationTypeField,
        disabled: true
      },
      ...organizationAddress
    ],
    billingAddress: billingAddressMultiUser
  }
};

export const validateValues = (values) => {
  let errors = {};

  for (let groupName in values) {
    const valuesGroup = values[groupName];

    // Skip billing address validation if not a single field has been specified
    if (groupName === 'billingAddress' && !hasNonEmptyValues(valuesGroup)) {
      continue;
    }

    // Omit optional props
    const {
      salutation,
      phone,
      comment,
      state, 
      additionalInformation1,
      additionalInformation2,
      ...restErrors
    } = validate(valuesGroup);

    // Make state selection required if the relevant country has a predefined set of states/regions
    const countriesRequiringStateSelection = Object.values(regions).map((value) => value.name.de)
    const errorsAmended = {...(countriesRequiringStateSelection.includes(valuesGroup.country) && {state}), ...restErrors };

    // This is a quick fix. The errorsAmmended can equal {state: undefined} which breaks the test 
    // further down. We should figure out how to prevent the {state: undefined} state.
    if (Object.keys(errorsAmended)[0] === 'state' && errorsAmended.state == undefined) {
      delete errorsAmended.state;
    }

    if (Object.keys(errorsAmended).length > 0) {
      errors[groupName] = errorsAmended;
    }
  }

  return errors;
};
