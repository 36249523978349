import wretch from 'wretch';
import { wpApiUrl } from '../config';

export const wpFetch = wretch(wpApiUrl).errorType('json');

export const wpEndpoints = {
  acfOptions: '/acf/v3/options/options',
  categories: '/wp/v2/categories',
  pages: '/wp/v2/pages',
  posts: '/wp/v2/posts',
  postPreview: '/preview/v1/posts',
  postsYears: '/onilo/v2/posts_years',
  navMenus: '/onilo/v2/menus',
  explanatoryVideos: '/wp/v2/erklaervideo',
};
