import React from 'react'

const TeaserVideo = () =>{
 return( 
        <video controls style={{width: "100%"}} poster="https://onilo-misc-stage.s3.eu-central-1.amazonaws.com/info-video-poster.png">
            <source src="https://onilo-misc-stage.s3.eu-central-1.amazonaws.com/onilo-info-video.mov"   />
        </video>
    )
}

export default TeaserVideo;