import { apiFetch, apiEndpoints } from '../modules/api';

export const GET_REQUEST = 'images/GET_REQUEST';
export const GET_SUCCESS = 'images/GET_SUCCESS';
export const GET_FAILURE = 'images/GET_FAILURE';

const getImagesRequest = () => ({
  type: GET_REQUEST
});

const getImagesSuccess = (data) => ({
  type: GET_SUCCESS,
  data
});

const getImagesFailure = () => ({
  type: GET_FAILURE
});

export const getImages = (boardstoryIds) => (dispatch) => {
  dispatch(getImagesRequest());

  return apiFetch
    .url(apiEndpoints.images.get)
    .query({ boardstoryId: boardstoryIds, imageType: 'TEASER_IMAGE' })
    .get()
    .json((json) => json._embedded.images)
    .then((data) => {
      const teaserImages = {};

      data.forEach((image) => {
        teaserImages[image.boardstoryId] = image;
      });
      dispatch(getImagesSuccess(teaserImages));
    })
    .catch(() => dispatch(getImagesFailure()));
};
