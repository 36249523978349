import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createOrder } from '../../actions/order';
import { apiFetch, apiEndpoints } from '../../modules/api';
import withWordPressPageData from '../../modules/withWordPressPageData';
import { Grid, Container, TextBlock, BlockSpacing, PageSectionHeadline } from '../../components/StyledElements';
import { CardGridWrapper, CustomPageContainer } from './licensesStyles';
import Toggle from '../../components/Toggle';
import Placeholder from '../../components/Placeholder';
import PageTitle from '../../components/PageTitle';
import ServerError from '../../pages/ErrorPage/ServerError';
import LicenseItem from './LicenseItem';
import GeschnasselImg from '../../assets/images/geschnassel.png';
import filter from 'lodash/filter';
import { organizationUserRoles } from '../../config';

const licenseGroups = [
  {
    id: 'yearly',
    name: 'Jahreslizenzen',
  },
  {
    id: 'monthly',
    name: 'Monatslizenzen',
  },
];

class Licenses extends Component {
  state = {
    activeLicenseGroup: 'yearly',
    licenses: [],
    isLoading: false,
    error: undefined,
  };

  componentDidMount() {
    this.getLicenses();
  }

  handleLicenseToggle = (id, event) => {
    this.setState({
      activeLicenseGroup: id,
    });
  };

  getLicenses() {
    this.setState({ isLoading: true });

    apiFetch
      .url(apiEndpoints.licenses.get)
      .get()
      .json((response) => {
        this.setState({
          isLoading: false,
          licenses: filter(response._embedded.licenses, (l) => l.visible),
        });
      })
      .catch(() => {
        this.setState({ isLoading: false, error: true });
      });
  }

  orderLicense = (license) => {
    // Initialize the new order by attaching the clicked license to it
    this.props.createOrder(license);
    this.props.history.push('/order');
  };

  render() {
    if (this.state.error) {
      return <ServerError />;
    } else if (this.state.isLoading) {
      return <Placeholder loading={this.state.isLoading} />;
    } else if (this.state.licenses.length === 0) {
      return null;
    }

    const isOwner =
      this.props.organization && this.props.organization.members
        ? this.props.organization.members.find(
            (m) => m.userRole === organizationUserRoles.OWNER && m.userId === this.props.userId,
          ) != null
        : true;

    return (
      <CustomPageContainer className="fluid">
        <PageTitle headTitle="Lizenzen" headlineComponent={PageSectionHeadline}>
          Eine Übersicht unserer Lizenzen
        </PageTitle>
        <Container className="p-0">
          <BlockSpacing>
            <TextBlock fontSize={1.5} italic centered>
              Nutzen Sie jetzt alle Vorteile einer Onilo-Lizenz: Unbegrenzten Zugriff auf alle Boardstories und
              Unterrichtsmaterialien
            </TextBlock>
          </BlockSpacing>
        </Container>
        <Toggle onClick={this.handleLicenseToggle} selected={this.state.activeLicenseGroup} data={licenseGroups} />
        <CardGridWrapper url={GeschnasselImg}>
          <Grid className="desktop">
            {this.state.licenses.map((license) => {
              // Get license props set via WordPress Dashboard
              const { highlightedLicenses = [], licensesLinks = {} } = this.props.data;

              return (
                <LicenseItem
                  key={license.id}
                  license={license}
                  moreInfoPage={licensesLinks[license.id]}
                  isHighlighted={highlightedLicenses.indexOf(license.id) > -1}
                  currentOrder={this.props.currentOrder}
                  onOrderRequest={this.orderLicense}
                  activeLicenseGroup={this.state.activeLicenseGroup}
                  isOwner={isOwner}
                />
              );
            })}
          </Grid>
        </CardGridWrapper>
        <TextBlock mt={4.5} centered fontSize={0.875}>
          * Alle Preise inkl. 19 % MwSt.
        </TextBlock>
      </CustomPageContainer>
    );
  }
}

Licenses.defaultProps = {
  data: {
    highlightedLicenses: [],
    licensesLinks: {},
  },
};

const select = ({ user }) => ({
  userId: user.account.id,
  currentOrder: user.currentOrder || {},
  organization: user.organization,
});

const LicensesContainer = connect(select, { createOrder })(Licenses);

// The handler that takes the WP data "blob" fetched by the withWordpressPageData HOC
// and transforms the props before passing them to the licenses container
const selectDataProps = (data) => {
  if (!data || !data.acf) {
    return {
      highlightedLicenses: [],
      licensesLinks: {},
    };
  }

  // Normalize licenses' links to efficiently access them later via license IDs
  const { licenses_links = [] } = data.acf;
  let licensesLinks = {};
  licenses_links.forEach((i) => (licensesLinks[i.license_id] = i));

  return {
    highlightedLicenses: data.acf.highlighted_licenses.split(','),
    licensesLinks,
  };
};

export default withWordPressPageData(LicensesContainer, selectDataProps);
