import React from 'react';
import { Headline, PostContent, BoxShadow } from '../../components/StyledElements';
import Fetcher from '../../components/Fetcher';
import { wpEndpoints } from '../../modules/wp';
import { Collapsible } from '../../components/Accordion';
import TriggerButton from '../../components/TriggerButton';
import Placeholder from '../../components/Placeholder';
import TeaserVideo from '../../components/TeaserVideo';

const BoardstoryInlineTeaser = () => {
  const renderTeaser = (teaserData) => {
    return (
      <div className="flex flex-wrap md:mb-[-10px]">
        <div className="w-full md:w-1/2 md:p-[10px] mt-6 md:mt-0 mb-4 md:mb-0">
          <Headline>{teaserData.headline}</Headline>
          <PostContent dangerouslySetInnerHTML={{ __html: teaserData.text }} />
        </div>
        {
          <div className="w-full md:w-1/2 md:p-[10px]">
            <BoxShadow style={{ overflow: 'hidden' }}>
              <TeaserVideo />
            </BoxShadow>
          </div>
        }
      </div>
    );
  };

  return (
    <div className="mb-12 md:mb-[72px] lg:mb-24">
      <Fetcher wordpress url={wpEndpoints.acfOptions}>
        {({ data, fetching }) => {
          const renderedTeaser = data && data.acf ? renderTeaser(data.acf['boardstories_listview_teaser']) : null;

          return (
            <Placeholder loading={fetching} className="min-h-[60px] md:min-h-[260px]">
              <Collapsible className="md:hidden" renderTrigger={TriggerButton} trigger="Was sind Boardstories">
                {renderedTeaser}
              </Collapsible>
              <div className="hidden md:block">{renderedTeaser}</div>
            </Placeholder>
          );
        }}
      </Fetcher>
    </div>
  );
};

export default BoardstoryInlineTeaser;
