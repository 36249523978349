import React from 'react';
import styled from 'styled-components';
import { Container, BoxShadow, FrontPageHeadline } from '../../../components/StyledElements';
import TeaserVideo from '../../../components/TeaserVideo';
import TeaserBackgroundImg from '../../../assets/images/schulalltag-bg.png';
import media from '../../../utils/media';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 4.5rem;
  overflow: hidden;

  ${media.md`
    padding-bottom: 6rem;
  `}

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  > .container {
    position: relative;
    z-index: 1;
  }
`;

export default ({ data = {} }) => {
  if (!data.is_active) {
    return null;
  }

  return (
    <Wrapper>
      <img className="bg" src={TeaserBackgroundImg} loading="lazy" />
      <Container className="container">
        {data.headline && <FrontPageHeadline>{data.headline}</FrontPageHeadline>}
        <BoxShadow style={{ overflow: 'hidden' }}>
          <TeaserVideo />
        </BoxShadow>
      </Container>
    </Wrapper>
  );
};
