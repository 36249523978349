export default {
    "DE": {
        "name": {
            "de": "Deutschland",
            "en": "Germany"
        },
        "states": [
            {
                "id": "DE-BEBB",
                "customData": {
                    "key": "berlinbrandenburg",
                    "de": "Berlin & Brandenburg",
                    "en": "Berlin & Brandenburg"
                }
            },
            {
                "id": "DE-BW",
                "customData": {
                    "key": "baden-wuerttemberg",
                    "de": "Baden-W\u00fcrttemberg",
                    "en": "Baden-Wurttemberg"
                }
            },
            {
                "id": "DE-BY",
                "customData": {
                    "key": "bayern",
                    "de": "Bayern",
                    "en": "Bavaria"
                }
            },
            {
                "id": "DE-HB",
                "customData": {
                    "key": "bremen",
                    "de": "Bremen",
                    "en": "Bremen"
                }
            },
            {
                "id": "DE-HE",
                "customData": {
                    "key": "hessen",
                    "de": "Hessen",
                    "en": "Hesse"
                }
            },
            {
                "id": "DE-HH",
                "customData": {
                    "key": "hamburg",
                    "de": "Hamburg",
                    "en": "Hamburg"
                }
            },
            {
                "id": "DE-MV",
                "customData": {
                    "key": "mecklenburg-vorpommern",
                    "de": "Mecklenburg-Vorpommern",
                    "en": "Mecklenburg-Vorpommern"
                }
            },
            {
                "id": "DE-NI",
                "customData": {
                    "key": "niedersachsen",
                    "de": "Niedersachsen",
                    "en": "Lower Saxony"
                }
            },
            {
                "id": "DE-NW",
                "customData": {
                    "key": "nordrhein-westfalen",
                    "de": "Nordrhein-Westfalen",
                    "en": "North Rhine-Westphalia"
                }
            },
            {
                "id": "DE-RP",
                "customData": {
                    "key": "rheinland-pfalz",
                    "de": "Rheinland-Pfalz",
                    "en": "Rhineland-Palatinate"
                }
            },
            {
                "id": "DE-SH",
                "customData": {
                    "key": "schleswig-holstein",
                    "de": "Schleswig-Holstein",
                    "en": "Schleswig-Holstein"
                }
            },
            {
                "id": "DE-SL",
                "customData": {
                    "key": "saarland",
                    "de": "Saarland",
                    "en": "Saarland"
                }
            },
            {
                "id": "DE-SN",
                "customData": {
                    "key": "sachsen",
                    "de": "Sachsen",
                    "en": "Saxony"
                }
            },
            {
                "id": "DE-ST",
                "customData": {
                    "key": "sachsen-anhalt",
                    "de": "Sachsen-Anhalt",
                    "en": "Saxony-Anhalt"
                }
            },
            {
                "id": "DE-TH",
                "customData": {
                    "key": "thueringen",
                    "de": "Th\u00fcringen",
                    "en": "Thuringia"
                }
            }
        ]
    },
    "AT": {
        "name": {
            "de": "\u00d6sterreich",
            "en": "Austria"
        },
        "states": [
            {
                "id": "ATH-1",
                "customData": {
                    "key": "burgenland",
                    "de": "Burgenland",
                    "en": "Burgenland"
                }
            },
            {
                "id": "ATH-2",
                "customData": {
                    "key": "kaernten",
                    "de": "K\u00e4rnten",
                    "en": "Carinthia"
                }
            },
            {
                "id": "ATH-3",
                "customData": {
                    "key": "niederoesterreich",
                    "de": "Nieder\u00f6sterreich",
                    "en": "Lower Austria"
                }
            },
            {
                "id": "ATH-4",
                "customData": {
                    "key": "oberoesterreich",
                    "de": "Ober\u00f6sterreich",
                    "en": "Upper Austria"
                }
            },
            {
                "id": "ATH-5",
                "customData": {
                    "key": "salzburg",
                    "de": "Salzburg",
                    "en": "Salzburg"
                }
            },
            {
                "id": "ATH-6",
                "customData": {
                    "key": "steiermark",
                    "de": "Steiermark",
                    "en": "Styria"
                }
            },
            {
                "id": "ATH-7",
                "customData": {
                    "key": "tirol",
                    "de": "Tirol",
                    "en": "Tyrol"
                }
            },
            {
                "id": "ATH-8",
                "customData": {
                    "key": "vorarlberg",
                    "de": "Vorarlberg",
                    "en": "Vorarlberg"
                }
            },
            {
                "id": "ATH-9",
                "customData": {
                    "key": "wien",
                    "de": "Wien",
                    "en": "Vienna"
                }
            }
        ]
    },
    "CH": {
        "name": {
            "de": "Schweiz",
            "en": "Switzerland"
        },
        "states": [
            {
                "id": "CH-AG",
                "customData": {
                    "key": "aargau",
                    "de": "Aargau",
                    "en": "Aargau"
                }
            },
            {
                "id": "CH-AI",
                "customData": {
                    "key": "appenzell-innerrhoden",
                    "de": "Appenzell Innerrhoden",
                    "en": "Appenzell Inner-Rhoden"
                }
            },
            {
                "id": "CH-AR",
                "customData": {
                    "key": "appenzell-ausserrhoden",
                    "de": "Appenzell Ausserrhoden",
                    "en": "Appenzell Ausser-Rhoden"
                }
            },
            {
                "id": "CH-BE",
                "customData": {
                    "key": "bern",
                    "de": "Bern",
                    "en": "Bern"
                }
            },
            {
                "id": "CH-BL",
                "customData": {
                    "key": "basel-landschaft",
                    "de": "Basel-Landschaft",
                    "en": "Basel-Landschaft"
                }
            },
            {
                "id": "CH-BS",
                "customData": {
                    "key": "basel-stadt",
                    "de": "Basel-Stadt",
                    "en": "Basel-Stadt"
                }
            },
            {
                "id": "CH-FR",
                "customData": {
                    "key": "freiburg",
                    "de": "Freiburg",
                    "en": "Fribourg"
                }
            },
            {
                "id": "CH-GE",
                "customData": {
                    "key": "genf",
                    "de": "Genf",
                    "en": "Gen\u00e8ve"
                }
            },
            {
                "id": "CH-GL",
                "customData": {
                    "key": "glarus",
                    "de": "Glarus",
                    "en": "Glarus"
                }
            },
            {
                "id": "CH-GR",
                "customData": {
                    "key": "graubuenden",
                    "de": "Graub\u00fcnden",
                    "en": "Graub\u00fcnden"
                }
            },
            {
                "id": "CH-JU",
                "customData": {
                    "key": "jura",
                    "de": "Jura",
                    "en": "Jura"
                }
            },
            {
                "id": "CH-LU",
                "customData": {
                    "key": "luzern",
                    "de": "Luzern",
                    "en": "Lucerne"
                }
            },
            {
                "id": "CH-NE",
                "customData": {
                    "key": "neuenburg",
                    "de": "Neuenburg",
                    "en": "Neuch\u00e2tel"
                }
            },
            {
                "id": "CH-NW",
                "customData": {
                    "key": "nidwalden",
                    "de": "Nidwalden",
                    "en": "Nidwalden"
                }
            },
            {
                "id": "CH-OW",
                "customData": {
                    "key": "obwalden",
                    "de": "Obwalden",
                    "en": "Obwalden"
                }
            },
            {
                "id": "CH-SG",
                "customData": {
                    "key": "sankt-gallen",
                    "de": "Sankt Gallen",
                    "en": "Sankt Gallen"
                }
            },
            {
                "id": "CH-SH",
                "customData": {
                    "key": "schaffhausen",
                    "de": "Schaffhausen",
                    "en": "Schaffhausen"
                }
            },
            {
                "id": "CH-SO",
                "customData": {
                    "key": "solothurn",
                    "de": "Solothurn",
                    "en": "Solothurn"
                }
            },
            {
                "id": "CH-SZ",
                "customData": {
                    "key": "schwyz",
                    "de": "Schwyz",
                    "en": "Schwyz"
                }
            },
            {
                "id": "CH-TG",
                "customData": {
                    "key": "thurgau",
                    "de": "Thurgau",
                    "en": "Thurgau"
                }
            },
            {
                "id": "CH-TI",
                "customData": {
                    "key": "tessin",
                    "de": "Tessin",
                    "en": "Ticino"
                }
            },
            {
                "id": "CH-UR",
                "customData": {
                    "key": "uri",
                    "de": "Uri",
                    "en": "Uri"
                }
            },
            {
                "id": "CH-VD",
                "customData": {
                    "key": "waadt",
                    "de": "Waadt",
                    "en": "Vaud"
                }
            },
            {
                "id": "CH-VS",
                "customData": {
                    "key": "wallis",
                    "de": "Wallis",
                    "en": "Valais"
                }
            },
            {
                "id": "CH-ZG",
                "customData": {
                    "key": "zug",
                    "de": "Zug",
                    "en": "Zug"
                }
            },
            {
                "id": "CH-ZH",
                "customData": {
                    "key": "zuerich",
                    "de": "Z\u00fcrich",
                    "en": "Z\u00fcrich"
                }
            }
        ]
    }
}