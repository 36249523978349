/*
  Original pagination algorithm: https://github.com/deoxxa/paginator (licensed under MIT)
  Relevant parts have been extracted and updated.
*/

export default (currentPage, totalPagesCount, rangeLength = 5) => {
  const pageRange =
    rangeLength > totalPagesCount ? totalPagesCount : rangeLength;

  // Set initial bounds
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPagesCount) {
    currentPage = totalPagesCount;
  }

  let firstPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
  let lastPage = Math.min(
    totalPagesCount,
    currentPage + Math.floor(pageRange / 2)
  );

  // Triggered when bound are near extremes
  if (lastPage - firstPage + 1 < pageRange) {
    if (currentPage < totalPagesCount / 2) {
      lastPage = Math.min(
        totalPagesCount,
        lastPage + (pageRange - (lastPage - firstPage))
      );
    } else {
      firstPage = Math.max(1, firstPage - (pageRange - (lastPage - firstPage)));
    }
  }

  // Triggered on an odd pageRange
  if (lastPage - firstPage + 1 > pageRange) {
    if (currentPage > totalPagesCount / 2) {
      firstPage++;
    } else {
      lastPage--;
    }
  }

  return { firstPage, lastPage };
};