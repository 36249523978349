import styled from 'styled-components';
import dateFormat from '../modules/dateformat';
import media from '../utils/media';
import PropTypes from 'prop-types';

const UserCardTimestamp = ({ timestamp, className }) =>
  timestamp ? (
    <Wrapper className={className}>
      <p>Gültig bis</p>
      <p>{dateFormat(timestamp, 'dd.mm.yy')}</p>
    </Wrapper>
  ) : null;

UserCardTimestamp.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
};

export default UserCardTimestamp;

/*****************************************
 *  components
 * ****************************************/
const Wrapper = styled.div`
  p {
    margin: 0 1px;
  }
  font-size: 0.7rem;
  margin-right: 7rem;
  display: flex;
 
  

  ${media.md`
  font-size: 1rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  `};
}
`;
