import React from 'react';
import { ProgressBarWrapper } from './styles';

function QuizProgressBar({ progress, text, height = 40 }) {
  return (
    <ProgressBarWrapper
      style={{
        height,
      }}
    >
      <div
        className="progress-bar--main"
        style={{
          height,
          borderRadius: height / 2,
        }}
      >
        <div
          className="progress-bar--fill"
          style={{
            width: `${progress * 100}%`,
            height,
            borderTopLeftRadius: height / 2,
            borderBottomLeftRadius: height / 2,
          }}
        />
        <span className="progress-bar--text">{text}</span>
      </div>
    </ProgressBarWrapper>
  );
}

export default QuizProgressBar;
