import React from 'react';
import {
  SearchInput,
  SearchInputContainer,
  SearchButton
} from './searchStyles';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';

class SearchBar extends React.Component {
  state = {
    searchTerm: ''
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.state.searchTerm) {
      this.props.onSubmit(this.state.searchTerm);
    }
    
    // Clear up the current search term after submit
    this.setState({ searchTerm: '' });
  };

  onChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <SearchInputContainer className="small">
          <SearchInput
            name="searchTerm"
            value={this.state.searchTerm}
            onChange={this.onChange}
            autoCorrect="off"
            spellCheck="false"
            placeholder={this.props.placeholder}
          />
          <SearchButton>
            <SearchIcon />
          </SearchButton>
        </SearchInputContainer>
      </form>
    );
  }
}

SearchBar.defaultProps = {
  placeholder: 'Suchbegriff eingeben'
};

export default SearchBar;
