import React from 'react';
import styled from 'styled-components';
import { BoxShadow } from './StyledElements';
import { TriggerArrow } from './Accordion';

const TriggerWrapper = styled(BoxShadow)`
  background-color: #f26521;
  color: #fff;
  height: 57px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.4px;
  line-height: 1;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
`;

export default ({ isOpen, onClick, className, trigger }) => (
  <TriggerWrapper onClick={onClick} className={className}>
    {trigger || "Toggle"}
    <TriggerArrow strokeColor="#fff" rightOffset={20} flipped={isOpen} />
  </TriggerWrapper>
);