import React from 'react';
import { wpEndpoints, wpFetch } from './wp';
import YoastHead from '../components/SEO/YoastHead';

const getSlugFromUrl = (props) => {
  return props.match.url.replace('/', '');
};

const withWordPressPageData = (
  WrappedComponent,
  selectDataProps,
  selectSlug = getSlugFromUrl
) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        data: undefined,
        isLoading: false
      };
    }

    componentDidMount() {
      this.loadPage(selectSlug(this.props));
    }

    componentWillUnmount() {
      this.willUnmount = true;
    }

    componentDidUpdate(prevProps, prevState) {
      const currentSlug = selectSlug(this.props);
      if (currentSlug !== selectSlug(prevProps)) {
        this.loadPage(currentSlug);
      }
    }

    updateState(nextState) {
      if (this.willUnmount) return;
      this.setState(nextState);
    }

    loadPage(slug) {
      this.updateState({ isLoading: true });

      wpFetch
        .url(wpEndpoints.pages)
        .query({ slug })
        .get()
        .json((response) => {
          this.updateState({
            data: response && response.length > 0 ? response[0] : null,
            isLoading: false
          });
        })
        .catch(() => {
          this.updateState({ data: null, isLoading: false });
        });
    }

    render() {
      const { data } = this.state;

      return (
        <React.Fragment>
          {data && data.yoast_meta && <YoastHead meta={data.yoast_meta} />}
          <WrappedComponent
            wpIsLoading={this.state.isLoading}
            data={selectDataProps ? selectDataProps(data) : data}
            {...this.props}
          />
        </React.Fragment>
      );
    }
  };
};

export default withWordPressPageData;
