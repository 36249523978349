import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { updateAccessCode } from '../../actions/user';
import { ReactComponent as LogoIcon } from '../../assets/icons/Logo.svg';
import { apiEndpoints, apiFetch } from '../../modules/api';
import { wpEndpoints } from '../../modules/wp';
import { afterStartingDate, hasDateNotExpired } from '../../utils/helpers';
import media from '../../utils/media';
import { getMenuItemLinkProps } from '../../utils/menuItemLink';
import AccessCodeInlineTeaser from '../AccessCodeModule/AccessCodeInlineTeaser';
import Breadcrumb from '../Breadcrumb';
import Fetcher from '../Fetcher';
import FlexibleLink from '../FlexibleLink';
import NewsletterForm from '../Newsletter/NewsletterForm';
import { Container, FlexCenter } from '../StyledElements';

import Badge3Img from '../../assets/images/badges/bmbwf.png';
import Badge2Img from '../../assets/images/badges/edumedia-2022.png';
import Badge1Img from '../../assets/images/badges/fragfinn.png';

const badgeImages = [Badge3Img, Badge1Img, Badge2Img];

const FooterContainer = styled(Container)`
  background-color: #27b0d4;
  color: #fff;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  ${media.lg`
    margin-bottom: 2.5rem;
  `};

  a,
  a:hover,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  font-size: 18px;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.78;
  margin-top: 2.5rem;
  margin-bottom: 3rem;

  ${media.md`
    font-size: 14px;
    font-style: normal;
    line-height: 1.57;
    margin-left: -7px;
    margin-right: -7px;
  `};

  ${media.lg`
    margin-bottom: 6rem;
    margin-left: -10px;
    margin-right: -10px;
  `};

  p {
    margin: 0;
  }
`;

const NavColumn = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${media.md`
    margin-bottom: 0;
    width: 33.33%;
    padding-left: 7px;
    padding-right: 7px;
  `};

  ${media.lg`
    margin-bottom: 0;
    width: 24%;
    padding-left: 10px;
    padding-right: 10px;

    &:last-of-type {
      width: 28%;
    }
  `};

  br.xs-only {
    ${media.sm` display: none; `};
  }
`;

const FooterLink = styled(FlexibleLink)`
  display: block;
  color: inherit;

  &:hover {
    font-weight: normal;
  }
`;

const BadgesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -10px;
  padding: 0 36px 4.5rem;

  ${media.lg`
    margin: -13px;
    padding-bottom: 0;
  `};
`;

const Badge = styled.div`
  padding: 10px;
  width: 25%;

  ${media.lg` padding: 13px; `};

  img {
    height: 77px;
    margin: 0 auto;
    display: block;

    ${media.lg` height: 95px; `};
  }

  ${media.sm`
    width: auto;
    flex: 1 auto;
  `};
`;

const Copyright = styled.div`
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.57;
  padding-top: 8px;
  ${media.lg` padding-top: 19px; `};
`;

const Divider = styled.div`
  margin: 2.5rem 0;
  width: 100%;
  height: 1px;
  opacity: 0.14;
  border-radius: 1px;
  background-color: #ffffff;
`;

const BrandSection = ({ className }) => (
  <div className={className}>
    <LogoIcon width={90} height={20} />
    <Copyright>© 2018 StoryDOCKS GmbH</Copyright>
  </div>
);

const Footer = () => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isFrontPage = location.pathname === '/';

  const splittedUrl = location.pathname.split('/');
  const accessCodeParam = splittedUrl.length === 4 && splittedUrl[1] === 'boardstory' ? splittedUrl[3] : null;

  const [isExternalUser, setIsExternalUser] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessCodeParam) {
      const fetchAccessCode = async () => {
        try {
          const response = await apiFetch.url(`${apiEndpoints.accessCodes.root}/${accessCodeParam}`).get();
          const data = await response.json();
          if (data && hasDateNotExpired(data.expirationDate) && afterStartingDate(data.startingDate)) {
            dispatch(updateAccessCode(data));
            setIsExternalUser(data.isExternalUser);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchAccessCode();
    }
  }, [accessCodeParam, dispatch]);

  return (
    <footer>
      <FooterContainer>
        <Breadcrumb />
        <div className="flex flex-wrap justify-center md:-mx-2 lg:mx-[-10px]">
          {(isAuthenticated && isFrontPage) || isExternalUser ? null : (
            <div className="md:w-1/2 md:p-2 lg:p-[10px] mb-6 md:mb-0">
              <NewsletterForm />
            </div>
          )}
          <div className="md:w-1/2 md:p-2 lg:p-[10px]">
            <AccessCodeInlineTeaser />
          </div>
        </div>
        <Divider />
        <Fetcher wordpress url={wpEndpoints.navMenus}>
          {({ data: menus }) => {
            if (!menus || !menus.footer) return null;

            return (
              <NavContainer>
                <NavColumn className="hidden lg:block">
                  <BrandSection />
                </NavColumn>
                {menus.footer.map((menu, key) => {
                  if (menu.length === 0) return null;

                  return (
                    <NavColumn key={key}>
                      {menu.map((item) => (
                        <FooterLink {...getMenuItemLinkProps(item)} />
                      ))}
                    </NavColumn>
                  );
                })}
                <NavColumn>
                  <p>
                    Kontakt
                    <br />
                    Service-Hotline: <br className="xs-only" />
                    +49 (0)40 / 468 962 390 (zum nationalen Festnetztarif)
                    <br />
                    E-Mail: <a href="mailto:service@onilo.de">service@onilo.de</a>
                    <br />
                  </p>
                </NavColumn>
              </NavContainer>
            );
          }}
        </Fetcher>
        <FlexCenter>
          <BadgesSection>
            {badgeImages.map((badgeImage, key) => (
              <Badge key={key}>
                <img src={badgeImage} alt="" />
              </Badge>
            ))}
          </BadgesSection>
        </FlexCenter>
        <BrandSection className="text-center lg:hidden" />
      </FooterContainer>
    </footer>
  );
};

export default Footer;
