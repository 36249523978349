import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal, { modalPropTypes } from './Modal';
import { Button, TextBlock, Spacing } from '../StyledElements';

const ModalDialog = ({
  onConfirm,
  buttonValue = 'OK',
  content,
  history,
  pushTo,
  ...modalProps
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    } else if (pushTo) {
      history.push(pushTo);
    }
    modalProps.onRequestClose();
  };

  return (
    <Modal {...modalProps}>
      <TextBlock centered italic lineHeight={1.25} fontSize={1.5}>
        {content}
      </TextBlock>
      <Spacing textAlign="center" mt={1.5}>
        <Button className="smaller" type="button" onClick={handleConfirm}>
          {buttonValue}
        </Button>
      </Spacing>
    </Modal>
  );
};

ModalDialog.propTypes = {
  onConfirm: PropTypes.func,
  buttonValue: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.object,
  pushTo: PropTypes.string,
  ...modalPropTypes
};

export default withRouter(ModalDialog);
