import { connect } from 'react-redux';
import { fetchBoardstoriesSuggestions } from '../../components/Search/searchUtil';
import { getBoardstories } from '../../actions/boardstories';
import { getTags } from '../../actions/tags';
import { PageContainer, Spacing } from '../../components/StyledElements';
import { selectBoardstoriesPaginatedState } from '../../selectors/boardstory';
import { updateQs } from '../../utils/queryString';
import BoardstoryFilterSection from '../../components/BoardstoryFilter/BoardstoryFilterSection';
import BoardstoryInlineTeaser from './BoardstoryInlineTeaser';
import BoardstoryList from '../../components/Boardstory/BoardstoryList';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/fp/isEmpty';
import PageTitle from '../../components/PageTitle';
import Pagination from '../../components/Pagination';
import React, { Component } from 'react';
import SearchAutocomplete from '../../components/Search/SearchAutocomplete';
import withQueryString from '../../modules/withQueryString';
import flatten from 'lodash/flatten';

class Boardstories extends Component {
  static defaultProps = {
    queryParams: {}
  };

  state = {
    suggestions: [],
    loadingSuggestions: false
  };

  componentDidMount() {
    this.fetchBoardstories();
    this.fetchTags();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.fetchBoardstories();
    }
  }

  fetchTags() {
    this.props.getTags();
  }

  fetchBoardstories() {
    const { queryParams } = this.props;
    const { p, q } = queryParams;
    // Make sure tags is an array.
    const tags = flatten([queryParams['tags[]']]).filter((i) => !isEmpty(i));

    this.props.getBoardstories({
      page: p,
      fulltextsearch: q,
      tags: tags
    });
  }

  onSearchTermChange = (searchTerm) => {
    const newQuery = {
      q: searchTerm === '' ? undefined : searchTerm
    };

    // Update the query string, reset the page (p) query parameter, keep the rest
    const nextLocation = updateQs(newQuery, this.props.location.search, 'p');

    if (nextLocation.search !== this.props.location.search) {
      this.props.history.push(nextLocation);
    }
  };

  getSuggestions = () => {
    return this.state.suggestions
      .map((i) => i.title)
      .filter((i) => !isEmpty(i));
  };

  onSuggestionClick = (item) => {
    const boardstory = this.state.suggestions.find((s) => s.title === item);

    if (boardstory) {
      this.props.history.push(`/boardstory/${boardstory.slug}`);
    }
  };

  fetchSuggestions = debounce(async (searchTerm) => {
    this.setState({ loadingSuggestions: true });
    const suggestions = await fetchBoardstoriesSuggestions(searchTerm);
    this.setState({ loadingSuggestions: false, suggestions });
  }, 500);

  render() {
    return (
      <PageContainer className="with-placeholder">
        <PageTitle headTitle="Boardstories">Boardstory Übersicht</PageTitle>
        <BoardstoryInlineTeaser />
        <SearchAutocomplete
          initialInputValue={this.props.queryParams.q}
          items={this.getSuggestions()}
          onChange={this.onSearchTermChange}
          onSuggestionClick={this.onSuggestionClick}
          fetchItems={this.fetchSuggestions}
          shouldFilterItems={false}
          loading={this.state.loadingSuggestions}
          placeholder="Boardstory suchen"
        />
        <BoardstoryFilterSection tags={this.props.tags} />
        <BoardstoryList
          teaserImages={this.props.teaserImages}
          boardstories={this.props.boardstories}
        />
        {this.props.pagesCount > 1 && (
          <Spacing mt={4.5}>
            <Pagination
              currentPage={this.props.currentPage}
              pagesCount={this.props.pagesCount}
            />
          </Spacing>
        )}
      </PageContainer>
    );
  }
}

const BoardstoriesContainer = connect(
  selectBoardstoriesPaginatedState,
  { getBoardstories, getTags }
)(Boardstories);

export default withQueryString(BoardstoriesContainer);
