import React from 'react';
import styled from 'styled-components/macro';
import { PageContainer, H1, BoxShadow, FlexCenter, BackgroundImage } from '../../../components/StyledElements';
import media from '../../../utils/media';
import ConfettiIcon from '../../../assets/icons/Confetti.svg';
import { Image, getWpImageSrcSet, imageSizesByColumn } from '../../../components/Image';

const TestimonialBox = styled(BoxShadow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  ${media.md`
    flex-wrap: nowrap;
  `};

  .testimonial-image {
    padding: 0 1.25em;

    img {
      display: block;
      margin: -50% auto 0;
      border-radius: 100%;
      background-color: #fff;
      height: 129px;
      width: 129px;
    }
  }

  .testimonial-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;

    .testimonial-quote {
      font-size: 20px;
      font-weight: normal;
      font-style: italic;
      line-height: 1.5;
    }
    .testimonial-author {
      padding-top: 1em;
      margin-top: auto;
      font-size: 1em;
      font-weight: normal;
      line-height: 1.6;
    }
  }
`;

const TestimonialList = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 85px;
  margin-top: 130px;
  position: relative;

  ${media.md`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `};
`;

const BackgroundWrapper = styled(FlexCenter)`
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  ${media.md`
    flex-direction: row;
  `};

  > div {
    height: 100%;
  }
`;

export default ({ headline, items }) => {
  const renderTestimonial = (item, key) => {
    return (
      <TestimonialBox key={key}>
        {item.image && (
          <div className="testimonial-image">
            <Image
              srcSet={getWpImageSrcSet(item.image)}
              alt={item.image.alt || item.username}
              width={item.image.width}
              sizes={imageSizesByColumn[2]}
            />
          </div>
        )}
        <div className="testimonial-content">
          <div className="testimonial-quote">{item.text}</div>
          {item.username && <div className="testimonial-author">{item.username}</div>}
        </div>
      </TestimonialBox>
    );
  };

  if (!items || items.length === 0) return null;

  return (
    <div className="relative">
      <BackgroundWrapper>
        <BackgroundImage url={ConfettiIcon} />
        <BackgroundImage url={ConfettiIcon} />
      </BackgroundWrapper>
      <PageContainer>
        <H1 textAlign="center">{headline}</H1>
        <TestimonialList>{items.map(renderTestimonial)}</TestimonialList>
      </PageContainer>
    </div>
  );
};
