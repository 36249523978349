import React from 'react';
import styled from 'styled-components/macro';
import media from '../../../utils/media';
import { PageContainer, FrontPageHeadline, Button } from '../../../components/StyledElements';
import SignupCTA from '../../../components/SignupCTA';
import BoardstoryTeaser from './BoardstoryTeaser';
import { ReactComponent as VideoHighlight } from '../../../assets/icons/VideoHighlight.svg';
import { ReactComponent as PlaySmall } from '../../../assets/icons/PlaySmall.svg';

const VideoContainer = styled.div`
  img,
  video {
    object-fit: cover;
    padding: 4.1% 3.1% 5.2%;
  }

  .video-highlight {
    filter: drop-shadow(0px 25px 50px rgba(0, 98, 124, 0.3));
  }
`;

const BoardstoryList = styled.div`
  > div {
    width: 100%;
    max-width: 460px;
  }

  > * + * {
    margin-top: 40px;
  }

  ${media.md`
    > div { 
      max-width: 50%;
    }

    > * + * {
      margin-left: 20px;
      margin-top: 0;
    }
  `}
`;

export default ({ items, signupButton }) => {
  const [videoActive, setVideoActive] = React.useState(false);

  if (!items) return null;

  const { headline, text, boardstories, video, video_poster: poster, headline_boardstories, button } = items;

  const onVideoPlay = () => {
    setVideoActive(true);
  };

  return (
    <PageContainer>
      <FrontPageHeadline className="m-small">{headline}</FrontPageHeadline>
      <div className="italic text-center text-xl leading-tight" dangerouslySetInnerHTML={{ __html: text }} />
      {video && (
        <VideoContainer className="relative m-0 mb-8 flex items-center justify-center content-[''] before:block before:pb-[62%] before:h-0">
          <VideoHighlight className="video-highlight inset-0 absolute h-full w-full" />
          {videoActive ? (
            <video
              autoPlay
              className="inset-0 absolute h-full w-full"
              controls
              controlsList="nodownload"
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={video} />
            </video>
          ) : (
            <>
              <img src={poster} className="inset-0 absolute h-full w-full" />
              <Button onClick={onVideoPlay} className="z-[1] with-icon">
                Erklärvideo abspielen
                <PlaySmall />
              </Button>
            </>
          )}
        </VideoContainer>
      )}

      {headline_boardstories && <FrontPageHeadline className="m-small">{headline_boardstories}</FrontPageHeadline>}
      {boardstories && (
        <BoardstoryList className="flex flex-col items-center md:flex-row md:items-start md:justify-center">
          {boardstories.map(({ boardstory, title }) => (
            <BoardstoryTeaser key={boardstory} title={title} boardstorySlug={boardstory} />
          ))}
        </BoardstoryList>
      )}

      {signupButton?.url && (
        <div className="text-center mt-12 mb-8">
          <SignupCTA button={signupButton} />
        </div>
      )}
    </PageContainer>
  );
};
