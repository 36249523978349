import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from './modalActions';
import ModalDialog from './ModalDialog';

const ModalRoot = ({ modals, closeModal }) => {
  return modals.map(({ id, component, ...modalProps }, i) => {
    const ModalComponent = component || ModalDialog;

    return (
      <ModalComponent
        key={id}
        isOpen={true}
        zIndex={i + 999}
        onRequestClose={() => closeModal(id)}
        {...modalProps}
      />
    )
  });
};

const select = (state) => ({
  modals: state.modal.modals
});

export default connect(select, { closeModal })(ModalRoot);
