import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PageContainer } from '../../components/StyledElements';
import SectionsModule from '../../components/SectionsModule/SectionsModule';
import PageTitle from '../../components/PageTitle';
import Placeholder from '../../components/Placeholder';
import TopicAreasList from '../../components/TopicArea/TopicAreasList';
import NotFound from '../../pages/ErrorPage/NotFound';
import { contentPagesWithOwnSlug } from '../../config';
import withWordPressPageData from '../../modules/withWordPressPageData';

const selectSlug = (props) => {
  return props.slug || props.match.params.slug;
};

class ContentPage extends Component {
  componentWillMount() {
    this.checkCanonicalUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    // Reload the page on slug refresh
    if (selectSlug(this.props) !== selectSlug(prevProps)) {
      this.checkCanonicalUrl();
    }
  }

  // Push to the canonical URL to avoid "duplicate pages"
  checkCanonicalUrl() {
    const slug = selectSlug(this.props);
    const rootPageUrl = `/${slug}`;

    if (
      contentPagesWithOwnSlug.indexOf(slug) > -1 &&
      this.props.location.pathname !== rootPageUrl
    ) {
      this.props.history.replace(rootPageUrl);
    }
  }

  render() {
    const { data: page } = this.props;
    if (page === null) {
      return <NotFound />;
    } else if (this.props.wpIsLoading) {
      return <Placeholder loading={this.props.wpIsLoading} />;
    } else if (page === undefined) {
      return null;
    }

    const { acf = {} } = page;

    const isTopicAreaList = page.template === 'Themenwelten-Liste';

    return (
      <PageContainer fullScreen={!isTopicAreaList}>
        <PageTitle encodeHtmlEntities >
          {page.title.rendered}
        </PageTitle>
        {isTopicAreaList ? (
          <TopicAreasList {...acf} baseSlug={this.props.slug} />
        ) : (
          <SectionsModule sections={acf.sections} />
        )
        }
      </PageContainer>
    );
  }
}

ContentPage.propTypes = {
  slug: PropTypes.string,
  match: PropTypes.object,
  data: PropTypes.object
};

export default withWordPressPageData(ContentPage, null, selectSlug);
