import React, { Component, Fragment } from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import { BoxShadow, CloseButton, BaseButton } from '../StyledElements';
import KeyboardIcon from '../../assets/icons/Keyboard.svg';
import AccessCodeOverlay from './AccessCodeOverlay';
import AccessCodeMobileSection from './AccessCodeMobileSection';

const StickyWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2000;
  color: #27b0d4;
`;

const PopupTriggerButton = styled(BaseButton)`
  width: 125px;
  height: 125px;
  box-shadow: 0 8px 16px 4px rgba(0, 98, 124, 0.1);
  border-radius: 50%;
  background-color: #f26521;
  background-image: url(${KeyboardIcon});
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  font-size: 1.125rem;
  color: #fff;
  font-weight: bold;
  line-height: 1.25;
  padding-top: 21px;
  transition: 0.3s transform;

  &:hover {
    transform: scale(1.05);
  }
`;

const OverlayWrapper = styled(BoxShadow)`
  max-width: 500px;
  padding: 24px 40px 24px 80px;
  position: relative;
`;

class AccessCodeSticky extends Component {
  state = {
    popupIsOpen: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (!this.state.popupIsOpen) return;

    // Close on Esc click
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePopup();
    }
  };

  togglePopup = () => {
    this.setState({ popupIsOpen: !this.state.popupIsOpen });
  };

  closePopup = () => {
    this.setState({ popupIsOpen: false });
  };

  render() {
    return (
      <Fragment>
        <StickyWrapper className="hidden md:block">
          {this.state.popupIsOpen ? (
            <OverlayWrapper>
              <CloseButton onClick={this.closePopup} />
              <AccessCodeOverlay onAccessCodeSubmit={this.closePopup} />
            </OverlayWrapper>
          ) : (
            <PopupTriggerButton onClick={this.togglePopup}>
              Schüler- &<br />
              Verleihcode
            </PopupTriggerButton>
          )}
        </StickyWrapper>
        <AccessCodeMobileSection className="md:hidden" onAccessCodeSubmit={this.onAccessCodeSubmit} />
        {this.state.popupIsOpen && (
          <Portal>
            <div className="fixed inset-0 z-[1999]" onClick={this.closePopup} />
          </Portal>
        )}
      </Fragment>
    );
  }
}

export default AccessCodeSticky;
