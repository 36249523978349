import React, { Component } from 'react';
import styled from 'styled-components';
import cc from 'classcat';
import { ErrorContent } from './StyledElements';

const Label = styled.label`
  font-size: 1em;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition-property: font-size, margin;
  transition-duration: 0.2s;
  opacity: 0.6;
  font-style: italic;
  margin-top: 20px;
  white-space: nowrap;

  &.is-floating {
    font-size: 0.66em;
    margin-top: 10px;
  }

  /*
    Chrome doesn't fire onChange event on auto-fill,
    so we have to apply the floating styles using the pseudo-selector.
    This rule shouldn't include any other selectors,
    otherwise non-WebKit browsers will freak out.
  */

  input:-webkit-autofill ~ & {
    font-size: 0.66em;
    margin-top: 10px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  input,
  textarea {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: solid 1px #27b0d4;
    background: none;
    padding-top: 32px;
    padding-bottom: 10px;
  }

  textarea {
    padding-top: 0;
    margin-top: 32px;
  }

  &.has-error {
    input,
    textarea {
      border-color: #ff001f;
    }
  }

  &.dark-mode,
  &.light-mode {
    font-size: 1.25rem;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      margin-top: 12px;
      margin-bottom: 11px;
      &.is-floating {
        font-size: 13px;
        margin-top: 5px;
        font-weight: bold;
        font-style: normal;
      }
    }

    input,
    textarea {
      padding-top: 19px;
      padding-bottom: 3px;
      font-style: italic;
    }
  }

  &.dark-mode {
    input,
    textarea {
      border-color: rgba(255, 255, 255, 0.6);
    }

    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.light-mode {
    background-color: #fff;

    input,
    textarea {
      border-color: #fff;
      padding-top: 21px;
    }

    > * {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

class FloatingLabel extends Component {
  static defaultProps = {
    component: 'input',
  };

  state = {
    isFloating: false,
  };

  handleFocus = (event) => {
    this.setState({ isFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleBlur = (event) => {
    this.setState({ isFocused: false });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  isFloating() {
    const { value } = this.props;
    return this.state.isFocused || (value && value.length > 0);
  }

  render() {
    const { id, placeholder, onFocus, onBlur, error, className, component: Component, ...restProps } = this.props;

    const hasError = error && error.length;

    return (
      <InputContainer className={cc([className, { 'has-error': hasError }])}>
        <Component id={id} onFocus={this.handleFocus} onBlur={this.handleBlur} {...restProps} />
        <Label className={cc({ 'is-floating': this.isFloating() })} htmlFor={id}>
          {placeholder}
        </Label>
        {hasError && <ErrorContent>{error}</ErrorContent>}
      </InputContainer>
    );
  }
}

export default FloatingLabel;

export const BigFloatingLabel = styled(FloatingLabel)`
  max-width: 460px;
  margin-bottom: 1.5rem;
  font-size: 1.5em;

  textarea {
    font-size: 1rem;
    font-family: inherit;
    line-height: 1.6;
    height: 7.6rem;
  }
`;

export const InlineFloatingLabel = styled(FloatingLabel)`
  font-size: 1rem;
  background-color: rgb(236, 251, 254);
  border-radius: 4px;

  &.dark-mode > * {
    padding-left: 12px;
  }

  input,
  textarea {
    border: 0;
  }
`;
