import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';

// White fallback image
const FallbackImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8///vfwAJ/AP86aXrPQAAAABJRU5ErkJggg==';

class Image extends React.Component {
  state = {
    error: false
  };

  onError = () => {
    this.setState({ error: true });
  };

  getSrc() {
    if (this.state.error) {
      return this.props.fallbackImageSrc;
    } else if (this.props.src) {
      return this.props.src;
    } else if (this.props.srcSet && typeof this.props.srcSet === 'object') {
      const srcSetKeys = Object.keys(this.props.srcSet);
      if (srcSetKeys.length > 0) {
        return this.props.srcSet[srcSetKeys[0]];
      }
    }
    return this.props.fallbackImageSrc;
  }

  getSrcSet() {
    if (typeof this.props.srcSet !== 'object') {
      return this.props.srcSet;
    }

    return Object.keys(this.props.srcSet)
      .map((descriptor) => `${this.props.srcSet[descriptor]} ${descriptor}`)
      .join(',');
  }

  getSizes() {
    if (!isArray(this.props.sizes)) {
      return this.props.sizes;
    }

    return this.props.sizes
      .map((s) => (s.media ? `${s.media} ${s.size}` : `${s.size}`))
      .join(',');
  }

  render() {
    return (
      <img
        className={this.props.className}
        title={this.props.title}
        alt={this.props.alt}
        onError={this.onError}
        src={this.getSrc()}
        srcSet={this.getSrcSet()}
        sizes={this.getSizes()}
        width={this.props.width}
        height={this.props.height}
        loading={this.props.lazy ? 'lazy' : 'eager'}
        style={this.props.style}
      />
    );
  }
}

Image.defaultProps = {
  alt: '',
  fallbackImageSrc: FallbackImg
};

Image.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  srcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  src: PropTypes.string,
  fallbackImageSrc: PropTypes.string,
  sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  lazy: PropTypes.bool,
  style: PropTypes.object
};

export default Image;
