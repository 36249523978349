import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { apiFetch, apiEndpoints } from '../../modules/api';
import validate from '../../modules/validate';
import FormFields from '../../components/FormFields';
import PageTitle from '../../components/PageTitle';
import { acceptInvitation } from '../../actions/user';
import { PageContainer, TextBlock, Spacing, Button, Form } from '../../components/StyledElements';
import CheckboxField from '../../components/CheckboxField';
import TosLink from '../../components/TosLink';
import AcceptedInvitationInfo from '../../components/AcceptedInvitationInfo';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  tosConsent: false,
};

const fields = [
  {
    name: 'firstName',
    placeholder: 'Vorname*',
  },
  {
    name: 'lastName',
    placeholder: 'Nachname*',
  },
  {
    name: 'email',
    placeholder: 'Ihre E-Mail-Adresse*',
    type: 'email',
  },
  {
    name: 'password',
    placeholder: 'Passwort erstellen*',
    type: 'password',
  },
  {
    name: 'passwordConfirmation',
    placeholder: 'Passwort wiederholen*',
    type: 'password',
  },
];

const validateFields = (values) => {
  let errors = validate(values, { passwordField: 'password', passwordConfirmationField: 'passwordConfirmation' });

  if (!values.tosConsent) {
    errors.tosConsent = 'Sie müssen den AGB und Nutzungsbedingungen zustimmen, um fortzufahren';
  }

  return errors;
};

class Signup extends Component {
  state = {
    registeredEmail: null,
    invitationAccepted: false,
  };

  componentWillMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const { invitationId } = this.props.location.state || {};
    const { passwordConfirmation, ...credentials } = values;
    apiFetch
      .url(apiEndpoints.users.signup)
      .json(credentials)
      .post()
      .json(async ({ email }) => {
        setSubmitting(false);
        window.scrollTo(0, 0);

        try {
          // Automatically accept the invitation if provided with an invitation id
          if (invitationId) {
            const response = await this.props.acceptInvitation(invitationId);
            if (response?.state === 'ACCEPTED') {
              this.setState({ invitationAccepted: true });
            }
          }
        } catch (err) {
          console.log('Error accepting the invitation', err);
        } finally {
          if (email && email.length) {
            this.setState({ registeredEmail: email });
          }
        }
      })
      .catch(({ json }) => {
        setSubmitting(false);
        if (!json.error) return;

        if (json.error.indexOf('already exists') > -1) {
          setErrors({ email: 'Diese E-Mail-Adresse existiert bereits' });
        } else if (json.error.indexOf('be shorter than') > -1) {
          setErrors({
            password: 'Das Passwort muss mindestens 8 Zeichen lang sein',
          });
        }
      });
  };

  render() {
    if (this.state.registeredEmail) {
      return (
        <PageContainer narrow>
          {this.state.invitationAccepted ? (
            <>
              <PageTitle>Willkommen bei Onilo!</PageTitle>
              <AcceptedInvitationInfo isNewUser />
            </>
          ) : (
            <>
              <PageTitle>Bestätigung der E-Mail-Adresse</PageTitle>
              <TextBlock fontSize={1.5} italic centered mt={2.5}>
                Vielen Dank für Ihre Registrierung. Sie erhalten umgehend eine E-Mail von uns. Bitte klicken Sie hier auf den
                enthaltenen Link, um Ihre E-Mail-Adresse zu bestätigen und die Registrierung abzuschließen.
              </TextBlock>
            </>
          )}
        </PageContainer>
      );
    }

    // Pre-fill the email field if it's provided via location state
    const { email: initialEmail = '' } = this.props.location.state || {};

    return (
      <PageContainer narrow>
        <PageTitle>Konto erstellen</PageTitle>
        <TextBlock fontSize={1.5} italic centered mt={4.5} mb={4.5}>
          Um eine Lizenz zu erwerben, geben Sie hier Ihren Namen, Ihre E-Mail-Adresse und ein Passwort ein, mit dem Sie sich
          zukünftig in Ihrem Onilo-Kundenkonto einloggen möchten.
          <br />
          Nachdem Sie Ihre E-Mail-Adresse verifiziert haben, können Sie mit dem Bestellprozess fortfahren.
        </TextBlock>
        <Formik initialValues={{ ...initialValues, email: initialEmail }} validate={validateFields} onSubmit={this.onSubmit}>
          {(formProps) => (
            <Form onSubmit={formProps.handleSubmit}>
              <FormFields fields={fields} {...formProps} />
              <TextBlock mt={1} mb={2.5} centered fontSize={0.875}>
                * Pflichtfelder
              </TextBlock>
              <CheckboxField
                name="tosConsent"
                onChange={formProps.handleChange}
                checked={formProps.values.tosConsent}
                error={formProps.touched.tosConsent && formProps.errors.tosConsent}
              >
                <TextBlock bold italic centered lineHeight={1.5}>
                  Ja, ich stimme den <TosLink>AGB und Nutzungsbedingungen</TosLink> von Onilo zu.
                </TextBlock>
              </CheckboxField>
              <Spacing textAlign="center" mt={2.5}>
                <Button
                  type="submit"
                  className={formProps.isSubmitting ? 'loading' : undefined}
                  disabled={formProps.isSubmitting}
                >
                  Konto erstellen
                </Button>
              </Spacing>
            </Form>
          )}
        </Formik>
        <TextBlock fontSize={1.5} italic centered mt={4.5}>
          Sie haben bereits ein Konto?{' '}
          <Link
            to={{
              pathname: '/login',
              state: this.props.lastLocation ? { from: this.props.lastLocation } : undefined,
            }}
          >
            Jetzt anmelden
          </Link>
        </TextBlock>
      </PageContainer>
    );
  }
}

const select = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const SignupContainer = connect(select, { acceptInvitation })(Signup);

export default withLastLocation(SignupContainer);
