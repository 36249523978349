import dateFormat, { i18n } from 'dateformat';

i18n.dayNames = [
  'Son',
  'Mon',
  'Die',
  'Mit',
  'Don',
  'Fre',
  'Sam',
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
];
i18n.monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];
i18n.timeNames = ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'];

export default dateFormat;
