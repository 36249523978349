import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { StickyContainer } from 'react-sticky-17';
import { Wizard, Steps, Step } from 'react-albus';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { PageContainer } from '../../components/StyledElements';
import OrderDataForm from '../../components/OrderDataForm/OrderDataForm';
import { orderFields, validateValues, getFieldsAndInitialValues } from '../../components/OrderDataForm/orderDataConfig';
import CheckOrderDataStep from './CheckOrderDataStep';
import OrderSuccessStep from './OrderSuccessStep';
import ProgressBar from '../../components/ProgressBar';
import { resetOrder } from '../../actions/order';
import { updateUser } from '../../actions/user';
import { addFormValues } from '../../actions/order';
import { apiFetch, apiEndpoints } from '../../modules/api';
import withOrganization from '../../modules/withOrganization';
import { getPrefilledValues } from '../../modules/organizations';

const stepNames = ['Anmelden', 'Daten prüfen', 'Bestellung prüfen', 'Fertig'];

class Order extends Component {
  state = {
    error: undefined,
    success: false,
    newsletterConsent: false,
  };

  componentDidMount() {
    // Push the user back to the licenses page if the ordered license is missing
    if (isEmpty(this.props.order.license)) {
      return this.props.history.push('/lizenzen');
    }
    /*
      Fetch the organization only if the user account has already been loaded
      It might be empty if the user has just logged in: in that case postpone the init
      (see componentDidUpdate for reference)
    */
    if (!isEmpty(this.props.user)) {
      this.props.fetchExistingOrganization();
    }
  }

  componentDidUpdate(prevProps) {
    // If the "user" prop was empty before, fetch the organization a bit later
    // (once the prop has been set)
    if (isEmpty(prevProps.user) && !isEmpty(this.props.user) && !this.props.isInitialized) {
      this.props.fetchExistingOrganization();
    }
  }

  componentWillUnmount() {
    // Reset the current license, entered form data, etc before leaving the container
    this.props.resetOrder();
  }

  onRenewalOrderMount(wizard) {
    // Get the empty relevant values for the ordered license
    const { emptyValues } = getFieldsAndInitialValues(orderFields, this.props.isSingleUserLicense);

    // Get prefilled values using the data provided by the API and the empty values
    const prefilledValues = getPrefilledValues(emptyValues, this.props.organization, this.props.user);

    // Validate the values to make sure the user has provided enough data to proceed
    const errors = validateValues(prefilledValues);

    if (isEmpty(errors)) {
      // Save the values into Redux for usage during the next step
      this.props.addFormValues(prefilledValues);

      // Skip the first step if all checks are passed
      wizard.push('checkOrderData');
    }
  }

  onError = ({ json }) => {
    this.setState({ error: isString(json) ? json : json.error });
  };

  onNewsletterConsentChange = (event) => {
    this.setState({ newsletterConsent: !this.state.newsletterConsent });
  };

  makeOrder = (next) => {
    const { order, currentOrder } = this.props;
    const { orderAddress = {} } = order.formValues;

    if (isEmpty(this.props.organization) || !this.props.organization.id) {
      console.error('Missing organizationId');
      return;
    }

    // Signup to the newsletter
    if (this.state.newsletterConsent) {
      this.newsletterSignup();
    }

    let orderRequestBody = {
      licenseId: order.license.id,
      organizationId: this.props.organization.id,
      comment: orderAddress.comment,
      promocode: order.promoCode && order.promoCode.code ? order.promoCode.code : '',
    };

    // If the user is already using the same license and wants to renew it,
    // include the validUntil param of the previous order when submitting the renewal request
    if (this.props.shouldRenew) {
      orderRequestBody.validFrom = currentOrder.validUntil;
    }

    apiFetch
      .url(apiEndpoints.orders.post)
      .json(orderRequestBody)
      .post()
      .json(() => {
        this.setState({ success: true, error: undefined });
        // Go to the next step
        next();
      })
      .catch(this.onError);
  };

  onOrganizationDataSubmit = (values, cb) => {
    // Add the submitted values to the Redux store
    this.props.addFormValues(values);
    // Create or update the organization using the submitted values
    this.props.handleOrganizationDataSubmit(values, cb);
  };

  newsletterSignup = () => {
    if (!this.state.newsletterConsent) return;

    const { user } = this.props;

    apiFetch
      .url(apiEndpoints.newsletter)
      .json({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        salutation: user.salutation,
      })
      .post();
  };

  // Note: this handler is automatically called during the initial page render
  onNextStep = (wizard) => {
    // On initial render skip the first step if the user is trying to renew the license
    if (wizard.step.id === null && this.props.shouldRenew) {
      this.onRenewalOrderMount(wizard);
    } else {
      // Reset the scroll position
      window.scrollTo(0, 0);
      // Open the next page
      wizard.push();
    }
  };

  /*
    Handles "linear" previous steps
    Background: react-albus uses location.history to navigate between steps, 
    In some cases (when the initial step is not the first one),
    we still might need to let the user go back to the previous step, 
    wizard.previous() wouldn't work then, as there're no steps in the history yet
  */
  onPreviousStep = (wizard) => {
    const prevStepIndex = wizard.steps.indexOf(wizard.step) - 1;

    if (prevStepIndex > -1 && prevStepIndex < wizard.steps.length) {
      wizard.push(wizard.steps[prevStepIndex].id);
    }
  };

  openFrontPage = () => {
    this.props.history.push('/');
  };

  render() {
    // Wait until the HOC is initialized
    // -> to make sure the form is initialized with the current address data (if there's any)
    // Alternatively this can be solved by passing enableReinitialize=true to the OrderDataForm
    if (!this.props.isInitialized) return null;

    return (
      <Fragment>
        <Helmet>
          <title>Lizenzabschluss</title>
        </Helmet>
        <StickyContainer>
          <Wizard onNext={this.onNextStep}>
            <ProgressBar stepNames={stepNames} />
            <PageContainer narrow>
              <Steps>
                <Step
                  id="orderDataForm"
                  render={({ next }) => (
                    <OrderDataForm
                      user={this.props.user}
                      isSingleUserLicense={this.props.isSingleUserLicense}
                      organization={this.props.organization}
                      onSubmit={(values) => this.onOrganizationDataSubmit(values, next)}
                      errorMessage={this.props.error}
                      formValues={this.props.order.formValues}
                      {...getFieldsAndInitialValues(orderFields, this.props.isSingleUserLicense)}
                    />
                  )}
                />
                <Step
                  id="checkOrderData"
                  render={({ next }) => (
                    <CheckOrderDataStep
                      {...this.props.order}
                      next={next}
                      previous={this.onPreviousStep}
                      onSubmit={() => this.makeOrder(next)}
                      isSingleUserLicense={this.props.isSingleUserLicense}
                      newsletterConsent={this.state.newsletterConsent}
                      onNewsletterConsentChange={this.onNewsletterConsentChange}
                      errorMessage={this.state.error}
                    />
                  )}
                />
                <Step
                  id="orderSuccess"
                  render={() => <OrderSuccessStep success={this.state.success} onClick={this.openFrontPage} />}
                />
              </Steps>
            </PageContainer>
          </Wizard>
        </StickyContainer>
      </Fragment>
    );
  }
}

Order.propTypes = {
  order: PropTypes.shape({
    license: PropTypes.object,
    formValues: PropTypes.object,
    promoCode: PropTypes.object,
  }),
  user: PropTypes.object,
  isSingleUserLicense: PropTypes.bool,
  resetOrder: PropTypes.func,
  updateUser: PropTypes.func,
  addFormValues: PropTypes.func,
  history: PropTypes.object,
  currentOrder: PropTypes.object,
};

const select = ({ order, user }) => ({
  order,
  user: user.account,
  currentOrder: user.currentOrder, // the currently active order
  isSingleUserLicense: order.license && order.license.maxUsers === 1,
  shouldRenew: isEmpty(user.currentOrder)
    ? false
    : order.license.id === user.currentOrder.licenseId && user.currentOrder.isRenewable,
});

const mapDispatchToProps = { resetOrder, updateUser, addFormValues };

export default connect(select, mapDispatchToProps)(withOrganization(Order));
