import styled from 'styled-components';
import { InfoIcon } from '../../../components/StyledIcons';
import { TextBlock } from '../../../components/StyledElements';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const RentalsCounter = ({ currentOrder }) => {
  const { rentalCodesUsed, rentalAccessesUsed, maxNumberOfRentalAccesses, maxNumberOfRentalCodes } = currentOrder;
  const remainingRentalAccesses = maxNumberOfRentalAccesses - rentalAccessesUsed;
  const remainingAccessCodes = maxNumberOfRentalCodes - rentalCodesUsed;
  return (
    <>
      <CountOuterWrapper>
        <Counter title="Ihre verbleibenden Verleihzugänge:" count={remainingRentalAccesses} />
        <VeticalDivider />
        <Counter title="Ihre verbleibenden Codes:" count={remainingAccessCodes} />
      </CountOuterWrapper>
      <TextBlock centered fontSize={1.1}>
        <InfoIcon /> Kontigent ausgeschöpft? Hier finden Sie die{' '}
        <Link to="/verleihlizenz-fuer-bibliotheken" style={{ color: 'inherit' }}>
          Informationen zum Nachbestellen
        </Link>
        .
      </TextBlock>
    </>
  );
};

RentalsCounter.propTypes = {
  currentOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentOrder: state.user.currentOrder,
});

export default connect(mapStateToProps)(RentalsCounter);

/*****************************************
 *  components
 * ****************************************/

const CountOuterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.oniloDarkOrange};
  margin-bottom: 1rem;
`;

const VeticalDivider = styled.div`
  display: inline-block;
  width: 1px;
  height: 2rem;
  background-color: ${(props) => props.theme.oniloDarkOrange};
`;

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Counter = ({ title, count }) => {
  return (
    <CounterWrapper>
      <div>{title}</div>
      <div>{count}</div>
    </CounterWrapper>
  );
};
