import isEmpty from 'lodash/isEmpty';
import { apiFetch, apiEndpoints } from '../modules/api';
import sortBy from 'lodash/fp/sortBy';

export const ACCOUNT_FETCH_SUCCESS = 'user/ACCOUNT_FETCH_SUCCESS';
export const CURRENT_ORDER_FETCH_SUCCESS = 'user/CURRENT_ORDER_FETCH_SUCCESS';
export const LICENSE_FETCH_SUCCESS = 'user/LICENSE_FETCH_SUCCESS';
export const ORGANIZATION_FETCH_SUCCESS = 'user/ORGANIZATION_FETCH_SUCCESS';
export const ACCESS_CODE_UPDATE = 'user/ACCESS_CODE_UPDATE';
export const ADD_BOARDSTORY_BOOKMARK = 'user/ADD_BOARDSTORY_BOOKMARK';
export const DELETE_BOARDSTORY_BOOKMARK = 'user/DELETE_BOARDSTORY_BOOKMARK';

export const accountFetchSuccess = (account) => ({
  type: ACCOUNT_FETCH_SUCCESS,
  account,
});

export const currentOrderFetchSuccess = (currentOrder = {}) => ({
  type: CURRENT_ORDER_FETCH_SUCCESS,
  currentOrder,
});

export const licenseFetchSuccess = (license) => ({
  type: LICENSE_FETCH_SUCCESS,
  license,
});

export const organizationFetchSuccess = (organization) => ({
  type: ORGANIZATION_FETCH_SUCCESS,
  organization,
});

export const getUserAccount = () => (dispatch) =>
  apiFetch
    .url(apiEndpoints.users.self)
    .get()
    .json((account) => {
      // Fetch active order
      if (account.id) {
        dispatch(getCurrentOrder());
      }

      dispatch(accountFetchSuccess(account));
    });

export const updateUser = (userId, values) => (dispatch) => {
  if (!values || !userId) return false;

  return apiFetch
    .url(`${apiEndpoints.users.patch}/${userId}`)
    .json({
      firstName: values.firstName,
      lastName: values.lastName,
      salutation: values.salutation,
    })
    .patch()
    .json((response) => {
      dispatch(accountFetchSuccess(response));
    })
    .catch(({ json }) => console.error(json));
};

export const getLicense = (licenseId) => (dispatch) =>
  apiFetch
    .url(`${apiEndpoints.licenses.get}/${licenseId}`)
    .get()
    .json((license) => {
      if (!isEmpty(license)) {
        dispatch(licenseFetchSuccess(license));
      }
    });

export const getOrganizationById = (id) => (dispatch) =>
  apiFetch
    .url(`${apiEndpoints.organizations.get}/${id}`)
    .get()
    .json((organization) => {
      if (!isEmpty(organization)) {
        dispatch(organizationFetchSuccess(organization));
      }
    });

export const getCurrentOrder = () => (dispatch) =>
  apiFetch
    .url(apiEndpoints.orders.getCurrent)
    .get()
    .json((currentOrder) => {
      // Api returned an empty object, so User does not have an active and current order
      if (Object.keys(currentOrder).length === 0) {
        return dispatch(currentOrderFetchSuccess(currentOrder));
      }

      // Update current active order
      dispatch(currentOrderFetchSuccess(currentOrder));

      // Fetch the corresponding organization
      if (currentOrder.active && currentOrder.organizationId) {
        dispatch(getOrganizationById(currentOrder.organizationId));
      }

      // Fetch the corresponding license
      if (currentOrder.licenseId) {
        dispatch(getLicense(currentOrder.licenseId));
      }
    });

export const updateAccessCode = (accessCode) => ({
  type: ACCESS_CODE_UPDATE,
  accessCode,
});

export const acceptInvitation = (invitationId) => (dispatch) =>
  apiFetch
    .url(`${apiEndpoints.invitations}/${invitationId}`)
    .json({ state: 'ACCEPTED' })
    .patch()
    .json((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const addBoardstoryBookmark = (boardstoryId) => (dispatch) =>
  apiFetch
    .url(apiEndpoints.boardstories.bookmarks)
    .json({ boardstoryId })
    .post()
    .json(() =>
      dispatch({
        type: ADD_BOARDSTORY_BOOKMARK,
        boardstoryId,
      }),
    );

export const deleteBoardstoryBookmark = (boardstoryId) => (dispatch) =>
  apiFetch
    .url(`${apiEndpoints.boardstories.bookmarks}/${boardstoryId}`)
    .delete()
    .res(() =>
      dispatch({
        type: DELETE_BOARDSTORY_BOOKMARK,
        boardstoryId,
      }),
    );
