import { organizationUserRoles } from '../config';
// TODO: adjust to new DB structure
export const isOrganizationOwner = (organization, userId) =>
  organization?.members?.some((member) => _isOwner(member) && member.userId === userId);

const _isOwner = (member) => member?.userRole === organizationUserRoles.OWNER;

const _isRentalUser = (member) => member?.userRole === organizationUserRoles.RENTAL_USER;

const _isGenericUser = (member) => member?.userRole === organizationUserRoles.USER;

/**
 * Accepts any organization and user object as returned by the API method
 * and returns the corresponding member entry from said organization's member array.
 * @param {Object} organization
 * @param {Object} user
 * @returns {Object} the corresponding member or undefined
 */
export const getMemberFromOrg = (organization, user) => {
  return organization?.members?.find((member) => member.userId === user.id);
};

/**
 * Accepts a single member object extracted from the member array of any organization API response
 * an returns the ownership of said roles as boolean.
 * @param {Object} member
 * @return {Object} as Object: isOwner, isRentalUser, isGenericUser
 */
export const getRolesAsBoolean = (member) => {
  const isOwner = _isOwner(member);
  const isRentalUser = _isRentalUser(member);
  const isGenericUser = _isGenericUser(member);

  return { isOwner, isRentalUser, isGenericUser };
};
