import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cc from 'classcat';
import {
  CardMain,
  CardContent,
  LicenseName,
  LicenseMeta,
  LicensePrice,
  ButtonWrapper,
  MoreInfoLinkContainer,
  LicenseDescription,
} from './licensesStyles';
import { Button, Column } from '../../components/StyledElements';
import { updatedIdMap } from '../../utils/helpers';
import { LicenseInfoPopUp, licensesWithAdditionalInfo } from '../../components/License/LicenseAdditionalInfo/';

const LicenseItem = ({ license, currentOrder, moreInfoPage, isHighlighted, onOrderRequest, activeLicenseGroup, isOwner }) => {
  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  const additionalInfo = licensesWithAdditionalInfo[license.id];

  const timeout = useRef();
  const delayInMs = 400;

  const openInfoPopUp = useCallback(() => {
    clearTimeout(timeout.current);
    setTimeout(() => setShowInfoPopUp(true), delayInMs);
  }, [timeout.current]);

  const closeInfoPopUp = useCallback(() => {
    timeout.current = setTimeout(() => setShowInfoPopUp(false), delayInMs);
  }, []);

  const renderMoreInfoLink = (obj) => {
    if (!obj || !obj.link) return null;

    return (
      <Link onClick={onMoreInfoClick} to={`/${obj.link.post_name}`}>
        Mehr Informationen
      </Link>
    );
  };

  const onMoreInfoClick = (event) => {
    // Make sure the click on the "more info" link isnt interpreted as a license order request
    event.stopPropagation();
  };

  const onLicenseClick = () => {
    onOrderRequest && onOrderRequest(license);
  };

  // Bail if the current license is not active or is monthly
  // while the selected license group is yearly or vice versa
  if (
    !license.active ||
    (activeLicenseGroup === 'yearly' && license.lifetime < 12) ||
    (activeLicenseGroup === 'monthly' && license.lifetime > 11)
  ) {
    return null;
  }

  const currentLicense = updatedIdMap[currentOrder.licenseId] || currentOrder.licenseId;
  const isActiveLicense = currentLicense === license.id;
  const isDisabled = !currentOrder.isRenewable && isActiveLicense;

  let licenseButtonValue = 'Jetzt bestellen';
  if (isActiveLicense) licenseButtonValue = 'Aktive Lizenz';
  if (isActiveLicense && currentOrder.isRenewable && isOwner) licenseButtonValue = 'Jetzt hier verlängern';

  const moreInfoLink = renderMoreInfoLink(moreInfoPage);
  return (
    <div>
      <Column onClick={isDisabled ? undefined : onLicenseClick} onMouseEnter={openInfoPopUp} onMouseLeave={closeInfoPopUp}>
        {additionalInfo && (
          <LicenseInfoPopUp className={cc({ hide: !showInfoPopUp })}>{additionalInfo.content()}</LicenseInfoPopUp>
        )}
        <CardContent
          className={cc({
            'is-selected': isHighlighted,
          })}
        >
          <CardMain>
            <div>
              <LicenseName>{license.name}</LicenseName>
              <LicenseMeta
                className="inline"
                maxUsers={license.maxUsers}
                licenseName={license.name}
                lifetime={license.lifetime}
              />

              {moreInfoLink && <MoreInfoLinkContainer className="lg:hidden">{moreInfoLink}</MoreInfoLinkContainer>}
            </div>
            <LicensePrice price={license.netPrice} />
            <LicenseMeta maxUsers={license.maxUsers} lifetime={license.lifetime} licenseName={license.name} />
            {moreInfoLink && <MoreInfoLinkContainer className="hidden lg:block">{moreInfoLink}</MoreInfoLinkContainer>}
            <ButtonWrapper>
              <Button onClick={onLicenseClick} className="smallerNoHeight" disabled={isDisabled}>
                {licenseButtonValue}
              </Button>
            </ButtonWrapper>
          </CardMain>
          <LicenseDescription className="lg:hidden" description={license.description} />
        </CardContent>
      </Column>
      <LicenseDescription className="hidden lg:block" description={license.description} />
    </div>
  );
};

LicenseItem.propTypes = {
  isHighlighted: PropTypes.bool,
  license: PropTypes.object.isRequired,
  currentOrder: PropTypes.object.isRequired,
  moreInfoPage: PropTypes.object,
  onOrderRequest: PropTypes.func.isRequired,
  activeLicenseGroup: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default LicenseItem;
