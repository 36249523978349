import React, { Component } from 'react';
import { apiFetch, apiEndpoints } from '../../modules/api';
import PageTitle from '../../components/PageTitle';
import {
  PageContainer,
  TextBlock,
  Spacing
} from '../../components/StyledElements';
import LoginView from '../../pages/User/Login/LoginView';

export default class extends Component {
  state = {
    success: false
  };

  componentDidMount() {
    const { confirmationToken } = this.props.match.params;
    if (!confirmationToken || !confirmationToken.length) {
      return this.props.history.push('/login');
    }

    apiFetch
      .url(apiEndpoints.users.confirmRegistration)
      .json({ confirmationToken })
      .post()
      .json(() => {
        this.setState({ success: true });
      })
      .catch(() => this.props.history.push('/'));
  }

  render() {
    if (!this.state.success) {
      return (
        <PageContainer narrow>
          <PageTitle>Registrierung wird abgeschlossen</PageTitle>
        </PageContainer>
      );
    }

    return (
      <PageContainer narrow>
        <PageTitle>Vielen Dank!</PageTitle>
        <TextBlock fontSize={1.5} italic centered mt={2.5}>
          Die Registrierung Ihres Nutzerkontos war erfolgreich. Wenn Sie nun
          eine Lizenz erwerben wollen, dann loggen Sie sich bitte hier ein.
        </TextBlock>
        <Spacing mt={4.5}>
          <LoginView redirectTo="/order" />
        </Spacing>
      </PageContainer>
    );
  }
}
