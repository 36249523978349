import React, { useEffect } from 'react';
import { PageContainer, FrontPageHeadline, ButtonLink } from '../../../components/StyledElements';
import Fetcher from '../../../components/Fetcher';
import { wpEndpoints, wpFetch } from '../../../modules/wp';
import { contentPagesWithOwnSlug } from '../../../config';
import NewsListItem from '../../News/NewsListItem';

export default ({ items, isAuthenticated }) => {
  // Fetch 3 latest posts excluding the ones in the "special" categories
  const { headline, pinned_post: pinnedPostId } = items || {};
  const [pinnedPost, setPinnedPost] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const basePostsApiQuery = `${wpEndpoints.posts}?per_page=3`;
  // Users can  access all latest news if they are logged in
  // Otherwise, they can only access the posts from a special category for "guests"
  const postsApiQuery = isAuthenticated
    ? `${basePostsApiQuery}&categories_not_in=${contentPagesWithOwnSlug.join(',')}`
    : `${basePostsApiQuery}&categories_in=for_guests`;

  useEffect(() => {
    const fetchPost = async (postId) => {
      try {
        setLoading(true);
        const data = await wpFetch.url(`${wpEndpoints.posts}/${postId}`).get().json();
        if (data) {
          setPinnedPost(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (pinnedPostId) {
      fetchPost(pinnedPostId);
    }
  }, []);

  return (
    <Fetcher wordpress url={postsApiQuery}>
      {({ data }) => {
        if (!data || data.length === 0 || loading) return null;
        // Remove pinned post from the list of posts if it exists
        // to avoid duplicating the pinned post in the list
        const posts = pinnedPostId ? [pinnedPost, ...data.filter((post) => post.id !== pinnedPostId)].slice(0, 3) : data;

        return (
          <PageContainer>
            {isAuthenticated ? (
              <div className="mb-12 text-center">
                <div className="italic opacity-60 mb-2">NEWS</div>
                <h2 className="m-0 text-2xl italic">{headline}</h2>
              </div>
            ) : (
              <FrontPageHeadline>{headline}</FrontPageHeadline>
            )}
            <div className="grid gap-3 md:grid-cols-3">
              {posts.map((post) => (
                <NewsListItem vertical key={post.id} item={post} />
              ))}
            </div>

            <div className="text-center mt-10">
              <ButtonLink to="/aktuelles">Alle Neuigkeiten anzeigen</ButtonLink>
            </div>
          </PageContainer>
        );
      }}
    </Fetcher>
  );
};
