import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import styled from 'styled-components';
import { BoxShadow, BaseButton } from '../StyledElements';
import UserColumn from '../UserColumn';
import UserAvatar from '../UserAvatar';
import media from '../../utils/media';
import { ReactComponent as Dots } from '../../assets/icons/Dots.svg';
import UserCardTimestamp from '../UserCardTimestamp';

class UserCard extends Component {
  static defaultProps = {
    user: {},
  };

  state = {
    shouldRenderOptions: false,
  };

  toggleOptions = () => {
    this.setState({ shouldRenderOptions: !this.state.shouldRenderOptions });
  };

  onRemoveClick = () => {
    this.setState({ renderOptions: false });
    this.props.onRemoveRequest && this.props.onRemoveRequest(this.props.user.id);
  };

  renderOptions() {
    return (
      <>
        {this.state.shouldRenderOptions && <OptionButton onClick={this.onRemoveClick}>löschen</OptionButton>}
        <Dots className="toggle-options" />
      </>
    );
  }

  render() {
    const { isCurrentUser, isOwner, isClickable } = this.props;
    return (
      <Box className={cc({ 'is-clickable': !isCurrentUser && isClickable })} onClick={this.toggleOptions}>
        <UserAvatar className="user-avatar" isRenewable={this.props.isRenewable} />
        <UserColumn
          user={this.props.user}
          renderSalutation={false}
          timestamp={this.props.timestamp}
          children={isOwner && <span className="text-blue-60">Lizenzinhaber</span>}
        />
        <UserCardTimestamp timestamp={this.props.timestamp} className="hidden md:flex" />
        {!isCurrentUser && isClickable && this.renderOptions()}
      </Box>
    );
  }
}

UserCard.propTypes = {
  user: PropTypes.object,
  isOwner: PropTypes.bool,
  isCurrentUser: PropTypes.bool,
  onRemoveRequest: PropTypes.func,
  isClickable: PropTypes.bool,
  isRenewable: PropTypes.bool,
  relevantUserRole: PropTypes.string,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default UserCard;

const Box = styled(BoxShadow)`
  padding: 1.5em 1em;
  margin-bottom: 1.5em;
  display: flex;
  position: relative;
  overflow: hidden;

  .user-avatar {
    margin-right: 10px;
    ${media.lg`
      margin-right: 2em;
    `};
  }
  .toggle-options {
    align-self: center;
    ${media.lg` display: none; `};
  }

  &.is-clickable {
    cursor: pointer;
  }
`;

const OptionButton = styled(BaseButton)`
  height: 100%;
  width: 100px;
  background-color: #27b0d4;
  font-size: 0.875em;
  font-weight: bold;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  transition: background 0.15s;

  &:hover {
    background-color: #f26521;
  }
`;
