import styled from 'styled-components';
import media from 'utils/media';

export const FullscreenButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 10px;
  opacity: 1;
  transition: all 0.3s;

  .player-control {
    padding: 5px;
    width: 16.66%;
    transition: all 0.3s;

    ${media.md`
      padding: 10px;
    `};

    &.inline-ml-auto {
      margin-left: auto;
    }
  }

  &.is-tiger {
    justify-content: space-between;
  }

  &.is-fullscreen {
    background: rgba(255, 255, 255, 0.95);

    .player-control {
      width: auto;
      padding: 5px;

      span {
        display: none;
      }

      &.inline-ml-auto {
        margin-left: 0;
      }
    }

    .player-control-jumpforward {
      margin-right: auto;
    }
    .player-control-autoplay {
      order: 1;
    }
    .player-control-videotexttoggle {
      order: 2;
    }
    .player-control-volume {
      order: 3;
    }

    .player-control-trailer,
    .player-control-bookmark {
      display: none;

      ${media.md`
        display: block;
      `};
    }

    &.is-transparent {
      display: none;
      opacity: 0;
      cursor: none;
    }
  }
`;

export const BottomControls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const TimeControlList = styled.div`
  display: flex;
  padding: 0 10px 15px;

  > div:first-child {
    margin-right: auto;
  }
`;

export const PlayerContainer = styled.div`
  position: relative;
  font-size: 11px;
  width: 100%;
  height: 100%;
  background-color: #000;
  outline: 0;

  &.is-hidden {
    display: none;
  }

  video {
    width: 100%;
    height: 100%;

    &.is-transparent {
      cursor: none;
    }
  }

  &:hover ${ControlsWrapper} {
    opacity: 1;
  }
`;
