import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

const withQueryString = (Component) => {
  class HOC extends React.Component {
    render() {
      const queryParams = qs.parse(this.props.location.search);

      return <Component queryParams={queryParams} {...this.props} />;
    }
  }
  return withRouter(HOC);
};

export default withQueryString;
