import TargetGroups from './TargetGroups';
import TopicAreasTeaser from './TopicAreasTeaser';
import Testimonials from './Testimonials';
import SupportTeaser from './SupportTeaser';
import PartnerLogos from './PartnerLogos';
import LatestNews from './LatestNews';
import VideoTeaser from './VideoTeaser';
import AccessCodeSticky from '../../../components/AccessCodeModule/AccessCodeSticky';
import HeroVideo from './HeroVideo';
import HeroImage from './HeroImage';
import Numbers from './Numbers';
import WhatIsBoardstory from './WhatIsBoardstory';
import AppTeaser from './AppTeaser';
import Achievements from './Achievements';
import Features from './Features';
import OurOffer from './OurOffer';
import AccountNavbar from '../../../components/AccountNavbar';
import WelcomeLoggedIn from './WelcomeLoggedIn';
import TeamRecommendation from './TeamRecommendation';
import DidYouKnow from './DidYouKnow';
import NewsletterTeaser from './NewsletterTeaser';

// the first (and only the first) appearance of the FrontPageHeadline Styled Component in any of the components listed in the sections array below
// needs an as="h1"-prop for SEO reasons.
export const sectionsBeforeLogin = [
  {
    name: 'header_guest',
    component: HeroVideo,
    hasNoSeparator: true,
  },
  {
    component: AccessCodeSticky,
  },
  {
    name: 'our_offer',
    component: OurOffer,
    hasNoSeparator: true,
  },
  {
    name: 'numbers',
    component: Numbers,
    hasNoSeparator: true,
  },
  {
    name: 'what_is_boardstory',
    component: WhatIsBoardstory,
  },
  {
    name: 'target_group_list',
    component: TargetGroups,
    hasNoSeparator: true,
  },
  {
    name: 'features',
    component: Features,
    hasNoSeparator: true,
  },
  {
    name: 'video_teaser',
    component: VideoTeaser,
    dataPropName: 'data',
    hasNoSeparator: true,
  },
  {
    name: 'app_teaser',
    component: AppTeaser,
    hasNoSeparator: true,
  },
  {
    name: 'news_teaser',
    component: LatestNews,
    defaultHeadline: 'Neuigkeiten von Onilo',
  },
  {
    name: 'achievements',
    component: Achievements,
    hasNoSeparator: true,
  },
  {
    name: 'user_testimonials',
    component: Testimonials,
    defaultHeadline: 'Stimmen von unseren Nutzern',
  },
  {
    name: 'support_teaser',
    component: SupportTeaser,
  },
  {
    name: 'publishers_logos',
    component: PartnerLogos,
    defaultHeadline: 'Verlage unseres Vertrauens',
    hasNoSeparator: true,
  },
  {
    name: 'partner_logos',
    component: PartnerLogos,
    defaultHeadline: 'Wir danken unseren Partnern',
    hasNoSeparator: true,
    props: {},
  },
];

export const sectionsAfterLogin = [
  {
    component: AccountNavbar,
  },
  {
    name: 'header_loggedin',
    component: HeroImage,
    hasNoSeparator: true,
  },
  {
    name: 'welcome_teaser',
    component: WelcomeLoggedIn,
    hasNoSeparator: true,
  },
  {
    name: 'team_recommendation',
    component: TeamRecommendation,
  },
  {
    name: 'news_teaser_loggedin',
    component: LatestNews,
    defaultHeadline: 'Was ist neu bei Onilo.de?',
  },
  {
    name: 'did_you_know',
    component: DidYouKnow,
    hasNoSeparator: true,
  },
  {
    name: 'topicareas_teaser',
    component: TopicAreasTeaser,
    dataPropName: 'settings',
    hasNoSeparator: true,
    defaultHeadline: 'Unsere Themenwelten im Überblick',
  },
  {
    name: 'app_teaser_loggedin',
    component: AppTeaser,
    hasNoSeparator: true,
  },
  {
    name: 'support_teaser_loggedin',
    component: SupportTeaser,
    hasNoSeparator: true,
  },
  {
    component: NewsletterTeaser,
  },
];
