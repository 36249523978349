import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Route, NavLink } from 'react-router-dom';
import RightArrowIcon from '../assets/icons/SmallRightArrow.svg';

const BreadcrumbContainer = styled.nav`
  margin-bottom: 2.5rem;
`;

const BreadcrumbList = styled.ul`
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    list-style-type: none;
    margin-right: 10px;

    &::after {
      content: '';
      background-image: url(${RightArrowIcon});
      background-repeat: no-repeat;
      background-size: 100%;
      display: inline-block;
      width: 7px;
      height: 11px;
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:last-child::after {
      display: none;
    }
  }
`;

const parentRoutes = {
  aktuelles: 'Aktuelles',
  themenwelten: 'Themenwelten',
  didaktik: 'Didaktik',
  blog: 'Blog',
  boardstory: 'Boardstories'
};

const BreadcrumbItem = ({ to, name, position, showLink }) => (
  <li
    itemScope
    itemProp="itemListElement"
    itemType="http://schema.org/ListItem"
  >
    {showLink ? (
      <NavLink
        exact
        to={to}
        itemScope
        itemType="http://schema.org/Thing"
        itemProp="item"
      >
        <span itemProp="name">{name}</span>
      </NavLink>
    ) : (
      <span itemProp="name">{name}</span>
    )}
    <meta itemProp="position" content={position} />
  </li>
);

const Breadcrumb = (props) => {
  const getBreadcrumbName = (match) => {
    if (match.isExact) return props.pageTitle;

    const parentName = parentRoutes[match.params.path];

    return parentName ? parentName : null;
  };

  const renderBreadcrumbItem = ({ match, position = 2 }) => {
    const breadcrumbName = getBreadcrumbName(match);
    return (
      <Fragment>
        {breadcrumbName && (
          <BreadcrumbItem
            to={breadcrumbName === 'Boardstories' ? '/boardstories' : match.url}
            name={breadcrumbName}
            position={position}
            showLink={!match.isExact}
          />
        )}
        <Route
          path={`${match.url}/:path`}
          render={(routeProps) =>
            renderBreadcrumbItem({ ...routeProps, position: position + 1 })
          }
        />
      </Fragment>
    );
  };

  return (
    <BreadcrumbContainer>
      <BreadcrumbList itemScope itemType="http://schema.org/BreadcrumbList">
        <BreadcrumbItem to="/" name="Home" position={1} showLink />
        <Route path="/:path" render={renderBreadcrumbItem} />
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
};

const select = (state) => ({
  pageTitle: state.options.pageTitle.replace('| ONILO.DE', '').trim()
});

export default connect(select)(Breadcrumb);
