import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { FadeTransition } from '../Transition';
import BoardstoryListItem from '../../pages/Boardstories/BoardstoryListItem';

const BoardstoryList = ({ boardstories = [], teaserImages = {} }) => {
  if (boardstories.length === 0) return null;

  return (
    <TransitionGroup>
      {boardstories.map((item, index) => (
        <FadeTransition key={item.id || index}>
          <BoardstoryListItem boardstory={item} teaserImage={teaserImages[item.id] || item.image} />
        </FadeTransition>
      ))}
    </TransitionGroup>
  );
};

export default BoardstoryList;
