const colors = {
  blue: '#27b0d4',
};

const breakpoints = {
  sm: 512, // 32em
  md: 768, // 48em
  lg: 1024, // 64em
  xl: 1440, // 90em
  xsMax: 511,
  smMax: 767,
  mdMax: 1023,
  lgMax: 1439,
};

const fixedTagCategoryOrder = {
  Altersstufe: 0,
  Leseniveau: 1,
  Unterrichtsfach: 2,
  Fremdsprache: 3,
  Genre: 4,
  Themen: 5,
  Kompetenzen: 6,
  Funktionen: 7,
};

const organizationUserRoles = {
  OWNER: 'OWNER',
  RENTAL_USER: 'RENTAL_USER',
  USER: 'USER',
};

const contentPagesWithOwnSlug = ['didaktik', 'themenwelten', 'blog'];
const isProduction = process.env.NODE_ENV === 'production';
const wpApiUrl = process.env.REACT_APP_WP_API_URL || '/wp/wp-json';

const saml2Login = {
  path: process.env.REACT_APP_SAML2_PATH,
  text: process.env.REACT_APP_SAML2_TEXT,
};

const vidisLogin = {
  url: process.env.REACT_APP_VIDIS_URL,
};

export {
  colors,
  breakpoints,
  fixedTagCategoryOrder,
  contentPagesWithOwnSlug,
  isProduction,
  wpApiUrl,
  saml2Login,
  organizationUserRoles,
  vidisLogin,
};
