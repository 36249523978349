import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import media from '../../utils/media';
import { BaseButton } from '../../components/StyledElements';
import { openModal } from '../../components/Modal/modalActions';
import BoardstoryBookmark from '../../components/Boardstory/BoardstoryBookmark';
import { getMemberFromOrg } from '../../utils/userRoleHelpers';
import { accessCodeHasValidDate, hasDateNotExpired } from '../../utils/helpers';

import { ReactComponent as Play } from './icons/Play.svg';
import { ReactComponent as PlaySmall } from './icons/PlaySmall.svg';
import { ReactComponent as Trailer } from '../../assets/icons/Trailer.svg';

const ControlList = styled.div`
  width: 80px;
  margin: -8px -8px -8px 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 0;

  &.is-horizontal {
    flex-direction: row;
    width: 100%;
    margin: auto 0;
    padding: 16px 0 8px;

    ${media.md`
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    `};
  }

  &.has-no-br {
    border-radius: 0;
  }
`;

const ControlItem = styled(BaseButton)`
  text-align: center;
  line-height: 1;
  transition: color 0.3s linear;
  color: #f26521;

  &:hover,
  &:active {
    color: #f26521;
  }

  &:disabled,
  &:disabled:hover {
    cursor: initial;
    color: #f26521;
    opacity: 0.5;
  }

  & ~ & {
    margin-top: 1.2em;
  }

  .is-horizontal & {
    margin: 0 5% auto;
  }

  svg {
    display: block;
    margin: 0 auto;
    color: inherit;
  }

  span {
    display: block;
    margin-top: 12px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
  }

  .play-icon-large {
    display: inline-block;
  }
  .play-icon-small {
    display: none;
  }

  .is-horizontal & {
    .play-icon-large {
      display: none;
    }
    .play-icon-small {
      display: inline-block;
    }
  }
`;

const BoardstoryShortcuts = (props) => {
  const onVideoPlayClick = (event) => {
    if (!props.rentalLicenseIsNotExpired) {
      props.openModal({
        buttonValue: 'Lizenz prüfen',
        pushTo: '/account/license',
        content:
          'Um die Boardstory in voller Länge abspielen zu können, müssen Sie eine gültige und nicht abgelaufene Lizenz besitzen.',
      });
    } else if (props.allowedVideoTypes.indexOf('tigerCreations') > -1) {
      props.onPlayRequest &&
        props.onPlayRequest(event, 'tigerCreations', {
          label: 'button-video-play',
        });
    } else if (props.allowedVideoTypes.indexOf('videos') > -1) {
      props.onPlayRequest &&
        props.onPlayRequest(event, 'videos', {
          label: 'button-video-play',
        });
    } else if (props.isAuthenticated) {
      props.openModal({
        buttonValue: 'Lizenz kaufen',
        pushTo: '/lizenzen',
        content: 'Um die Boardstory in voller Länge abspielen zu können, müssen Sie eine Lizenz kaufen.',
      });
    } else {
      props.openModal({
        buttonValue: 'Anmelden',
        pushTo: '/login',
        content: 'Um die Boardstory in voller Länge abspielen zu können, müssen Sie angemeldet sein.',
      });
    }
  };

  const onTrailerPlayClick = (event) => {
    props.onPlayRequest(event, 'trailers', {
      label: 'button-trailer-play',
    });
  };

  return (
    <ControlList className={props.className}>
      <BoardstoryBookmark boardstoryId={props.boardstory.id}>
        {({ onClick, icon: Icon }) => (
          <ControlItem onClick={onClick}>
            <Icon />
            <span>Merken</span>
          </ControlItem>
        )}
      </BoardstoryBookmark>
      <ControlItem
        className="button-video-play"
        data-storyname={props.boardstory.slug}
        onClick={onVideoPlayClick}
        disabled={
          props.availableVideoTypes.indexOf('videos') === -1 && props.availableVideoTypes.indexOf('tigerCreations') === -1
        }
      >
        <Play className="play-icon-large" />
        <PlaySmall className="play-icon-small" />
        <span>Play</span>
      </ControlItem>
      <ControlItem
        className="button-trailer-play"
        data-storyname={props.boardstory.slug}
        onClick={onTrailerPlayClick}
        disabled={props.availableVideoTypes.indexOf('trailers') === -1}
      >
        <Trailer />
        <span>Zum Trailer</span>
      </ControlItem>
    </ControlList>
  );
};

BoardstoryShortcuts.defaultProps = {
  allowedVideoTypes: [],
  availableVideoTypes: [],
  boardstory: {},
};

BoardstoryShortcuts.propTypes = {
  allowedVideoTypes: PropTypes.array,
  availableVideoTypes: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  rentalLicenseIsNotExpired: PropTypes.bool,
  onPlayRequest: PropTypes.func,
  className: PropTypes.string,
  openModal: PropTypes.func,
  boardstory: PropTypes.object,
};

const select = (state, ownProps) => {
  let { organization, account, accessCode } = state.user;
  const member = getMemberFromOrg(organization, account);
  const rentalLicenseIsNotExpired = member?.rentalUntil && hasDateNotExpired(member.rentalUntil);
  const accessCodeIsNotExpired =
    accessCode?.boardstory === ownProps.boardstory.id && accessCodeHasValidDate(accessCode);

  return {
    isAuthenticated: state.auth.isAuthenticated,
    rentalLicenseIsNotExpired:
      ownProps.boardstory.free ||
      rentalLicenseIsNotExpired ||
      hasDateNotExpired(state.user?.currentOrder?.validUntil) ||
      accessCodeIsNotExpired,
  };
};

export default connect(select, {
  openModal,
})(BoardstoryShortcuts);
