import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LicensePrice from './LicensePrice';
import LicenseMeta from './LicenseMeta';
import { Spacing, TextBlock } from '../StyledElements';
import media from '../../utils/media';
import BadgeIcon from '../../assets/icons/Badge.svg';

const BadgeContainer = styled.div`
  position: relative;
  flex-basis: 100%;
  margin-bottom: 2.5em;

  ${media.md`
    flex-basis: auto;
    margin-bottom: 0;
  `};
`;

const Badge = styled.div`
  margin: 0 auto;
  background-image: url(${BadgeIcon});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 183px;
  height: 181px;
`;

const BadgeText = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  text-align: center;
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  color: #fff;
`;

const Content = styled.div`
  text-align: center;
  flex-basis: 100%;
  ${media.md` flex: 1; `};
`;

const TeaserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto 10px;

  ${media.md`
    padding: 30px 34px;
    max-width: 550px;
    border-radius: 123px;
    background-color: rgba(39, 176, 212, 0.05);
  `};

  ${media.lg`
    padding: 32px 36px;
    max-width: 620px;
  `};
`;

const LicenseTeaser = ({ name, children }) => {
  return (
    <TeaserContainer>
      <BadgeContainer>
        <Badge />
        <BadgeText>{name}</BadgeText>
      </BadgeContainer>
      <Content>{children}</Content>
    </TeaserContainer>
  );
};

export const LicensePriceTeaser = ({ license }) => {
  return (
    <LicenseTeaser name={license.name}>
      <LicensePrice price={license.netPrice} />
      <LicenseMeta 
        maxUsers={license.maxUsers} 
        lifetime={license.lifetime} 
        licenseName={license.name} 
      />
      <Spacing textAlign="center" mt={0.625}>
        <Link to="/lizenzen">Lizenz ändern</Link>
      </Spacing>
      <TextBlock centered mt={1.5} fontSize={0.875}>
        *Preis inklusive MwSt.
      </TextBlock>
    </LicenseTeaser>
  );
};

export default LicenseTeaser;
