import React from 'react';
import { InfoIcon } from '../../StyledIcons';
import { Link } from 'react-router-dom';
const config = require('../../../config');

const LibraryLicenseInfo = () => (
  <>
    <p>
      <InfoIcon color="#FFF" /> Jetzt neu auch als Verleihlizenz buchbar!
    </p>

    <p>
      Mehr Informationen finden Sie <Link to={'/verleihlizenz-fuer-bibliotheken'}>hier</Link>.
    </p>
  </>
);

const SchoolLicenseInfo = () => (
  <>
    <p>
      <InfoIcon color="#FFF" /> Jetzt neu unsere Schulträger-Lizenz.
    </p>

    <p>
      Mehr Informationen finden Sie <Link to={'/schultraeger-lizenz'}>hier</Link>.
    </p>
  </>
);

const licensesWithAdditionalInfo = {
  // Add some test license here
  ...(config.environment !== 'development' && {
    '61521ab00b5ef1bf95efa75e': {
      name: 'Some Test License',
      content: LibraryLicenseInfo,
    },
  }),
  // production licenses here
  '5a5ca3f456ac2c0014782a2b': {
    name: 'Bibliotheks Lizenz',
    content: LibraryLicenseInfo,
  },
  '5a5ca3c956ac2c0014782a29': {
    name: 'Team Lizenz',
    content: SchoolLicenseInfo,
  },
  '5a5ca3e156ac2c0014782a2a': {
    name: 'Schul Lizenz',
    content: SchoolLicenseInfo,
  },
};

export default licensesWithAdditionalInfo;
