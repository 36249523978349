import React from 'react';
import styled from 'styled-components';
import Sticky from 'react-sticky-17';
import PropTypes from 'prop-types';
import cc from 'classcat';
import media from '../utils/media';
import { StickyShadow } from './StyledElements';
import TickIcon from '../assets/icons/Tick.svg';

const ProgressWrapper = styled.div`
  margin: 0 auto;
  padding: 2.5em 0;
  max-width: 890px;
  width: 100%;
`;

const StatusBar = styled.div`
  height: 2px;
  background: rgba(39, 176, 212, 0.5);
  position: relative;
  top: 16px;
  margin: 0 auto;
  width: 100%;
`;

const CurrentStatus = styled.div`
  height: 2px;
  width: 0;
  background: #f26521;
  width: 75%;
  transition: width 0.3s linear;
`;

const Steps = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
`;

const Step = styled.li`
  display: inline-block;
  padding-top: 32px;
  line-height: normal;
  vertical-align: top;
  position: relative;
  text-align: center;
  overflow: hidden;
  font-size: 0;
  font-weight: bold;
  color: rgba(39, 176, 212, 0.5);
  text-overflow: ellipsis;
  transition: color 0.3s linear;

  ${media.md` font-size: 14px; `};

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: calc(50% - 13px);
    z-index: 1;
    width: 26px;
    height: 26px;
    border: 2px solid rgba(39, 176, 212, 0.5);
    border-radius: 50%;
    line-height: 26px;
    background-color: #effbfe;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    transition: all 0.3s;
  }

  &.is-current,
  &.is-visited,
  &.is-last {
    color: #f26521;
    &::before {
      border-color: #f26521;
    }
  }

  &.is-visited::before,
  &.is-last::before {
    background-color: #f26521;
    background-image: url(${TickIcon});
  }
`;

const ProgressBar = ({ stepNames }, { wizard }) => {
  const currentStepId = wizard.steps.indexOf(wizard.step) + 1;
  const progress = (100 * currentStepId) / (stepNames.length - 1);
  const stepWidth = 100 / stepNames.length;

  return (
    <Sticky topOffset={-59}>
      {({ style, isSticky }) => (
        <StickyShadow style={{ ...style, top: 60 }} className={cc({ 'is-sticky': isSticky })}>
          <ProgressWrapper>
            <StatusBar style={{ width: `${100 - stepWidth}%` }}>
              <CurrentStatus style={{ width: `${progress}%` }} />
            </StatusBar>
            <Steps>
              {stepNames.map((name, key) => {
                return (
                  <Step
                    key={key}
                    className={cc({
                      'is-current': currentStepId === key,
                      'is-visited': currentStepId > key,
                      'is-last': currentStepId === stepNames.length - 1,
                    })}
                    style={{ width: `${stepWidth}%` }}
                  >
                    {name}
                  </Step>
                );
              })}
            </Steps>
          </ProgressWrapper>
        </StickyShadow>
      )}
    </Sticky>
  );
};

ProgressBar.propTypes = {
  stepNames: PropTypes.arrayOf(PropTypes.string),
};

ProgressBar.contextTypes = { wizard: PropTypes.object };

export default ProgressBar;
