import React, { useMemo, useState } from 'react';
import cc from 'classcat';
import Downshift from 'downshift';
import isString from 'lodash/isString';
import styled from 'styled-components';
import { BaseButton } from './StyledElements';
import TriggerArrow from './Accordion/TriggerArrow';
import { InlineFloatingLabel } from './FloatingLabel';

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 0 24px;
`;

const DropdownToggle = styled(BaseButton)`
  border-bottom: 1px solid #27b0d4;
  line-height: 1.25;
  padding: 13px 10px 13px 0;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  text-align: left;
  position: relative;
`;

const DropdownToggleWrapper = styled.div`
  padding: 0 20px;
`;

const DropdownMenu = styled.div`
  &.active {
    overflow-y: auto;
    overflow-x: hidden;
    outline: 0px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 8px 10px 0 rgba(0, 98, 124, 0.1);
    position: absolute;
    top: -7px;
    left: -20px;
    right: -20px;
    z-index: 10;
    padding: 7px 0 0;
  }
`;

const DropdownMenuList = styled.div`
  padding: 0 20px 24px;
  margin-top: 16px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const DropdownMenuItem = styled(BaseButton)`
  width: 100%;
  text-align: left;
  font-size: 1rem;
  line-height: 1.25;
  cursor: pointer;
  padding: 9px 0 7px;

  &.is-highlighted {
    border-radius: 2px;
    font-weight: bold;
    background-color: #f26521;
    color: #fff;
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% + 20px);
  }
`;

const DropdownSearch = styled.div`
  padding: 0 20px;
  margin-top: 15px;
`;

const SelectDropdown = ({ name, options, placeholder, onChange, withSearch, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const handleChange = (selectedItem) => {
    onChange({
      target: {
        name,
        value: selectedItem.value || selectedItem,
      },
    });
    setSelectedItem(selectedItem.name || selectedItem);
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const itemToString = (item) => {
    if (!item) return '';
    return isString(item) ? item : item.name;
  };

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return options;

    return options.filter((option) => option.name.toLowerCase().includes(searchTerm.trim().toLowerCase()));
  }, [searchTerm, options]);

  return (
    <Downshift itemToString={itemToString} onChange={handleChange} {...rest}>
      {({ isOpen, toggleMenu, getItemProps, getRootProps, highlightedIndex }) => {
        const renderedToggle = (
          <DropdownToggle
            type="button"
            className="dropdown-toggle"
            onClick={toggleMenu}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={isOpen}
          >
            {selectedItem || placeholder}
            <TriggerArrow flipped={isOpen} width={18} height={18} transitionDuration={0} />
          </DropdownToggle>
        );

        return (
          <DropdownContainer {...getRootProps({}, { suppressRefError: true })}>
            <div>
              {renderedToggle}
              {isOpen && (
                <DropdownMenu className={cc({ active: isOpen })}>
                  <DropdownToggleWrapper>{renderedToggle}</DropdownToggleWrapper>
                  {withSearch && (
                    <DropdownSearch>
                      <InlineFloatingLabel
                        className="dark-mode"
                        name="searchTerm"
                        placeholder="Suche"
                        onChange={onSearchChange}
                        value={searchTerm}
                      />
                    </DropdownSearch>
                  )}
                  <DropdownMenuList>
                    {filteredOptions.map((option, index) => (
                      <DropdownMenuItem
                        {...getItemProps({ item: option })}
                        key={option.value || option}
                        className={cc({
                          'is-highlighted': highlightedIndex === index,
                        })}
                      >
                        {option.name || option}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuList>
                </DropdownMenu>
              )}
            </div>
          </DropdownContainer>
        );
      }}
    </Downshift>
  );
};

export default SelectDropdown;
