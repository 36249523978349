import React, { createRef } from 'react';
import { WidgetInstance } from 'friendly-challenge';

class FriendlyCaptcha extends React.Component {
   container = createRef()
   widget = createRef()

  componentDidMount(){
    if (!this.widget.current && this.container.current) {
        this.widget.current = new WidgetInstance(this.container.current, { 
          startMode: "focus",
          language: "de",
          solutionFieldName: "frcsolution",
          doneCallback: this.props.verifyCallback,
        });
      }
  }

  componentWillUnmount() {
    if (this.widget.current !== undefined) this.widget.current.reset();
  }

   render() {
       return <div ref={this.container} className="frc-captcha" data-sitekey="FCMG8SH5HSTA6TP4" />
   }
}

export default FriendlyCaptcha