import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { onPageTitleUpdate } from '../../actions/options';

const Head = ({ onPageTitleUpdate, pageTitle }) => {
  const onHelmetChange = (newState) => {
    if (pageTitle !== newState.title) {
      onPageTitleUpdate(newState.title);
    }
  };

  return <Helmet onChangeClientState={onHelmetChange} titleTemplate="%s | ONILO.DE" defaultTitle="ONILO.DE" />;
};

const select = (state) => ({
  pageTitle: state.options.pageTitle,
});

export default connect(select, { onPageTitleUpdate })(Head);
