import React from 'react';
import cc from 'classcat';
import styled from 'styled-components/macro';
import { FrontPageHeadline, Container } from '../../../components/StyledElements';
import SpeechBubbleImage from '../../../assets/images/speech-bubble.png';
import WomanImage from '../../../assets/images/features-woman.png';
import media from '../../../utils/media';

const FeatureCircle = styled.div`
  height: 876px;
  width: 876px;
  position: relative;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.oniloDarkOrange};
  display: none;
  margin: 2rem 0 6rem;

  ${media.lg`
    display: block;
  `};

  .feature {
    position: absolute;
    height: 95%;
    margin: 0 50%;
    width: 0;
    cursor: pointer;

    &:nth-child(3) {
      height: 92%;
    }

    &:nth-child(4),
    &:nth-child(7) {
      height: 94%;
    }

    &:nth-child(5) {
      height: 96%;
    }

    > * {
      position: absolute;
      left: 50%;
      text-align: center;
      overflow: hidden;
      width: 200px;
      padding: 10px;
      hyphens: auto;
      font-style: italic;
    }
  }

  .feature__inner {
    transform: scale(1);
    transition: transform 0.2s ease-in-out;

    &.active,
    &:hover {
      transform: scale(1.13);
    }
  }
`;

const CenterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 478px;
  width: 478px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: #ecfbfe;
  border: 1px solid ${(props) => props.theme.oniloDarkOrange};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .circle__content {
    max-width: 330px;
    margin: 0 auto 0;
    text-align: center;
  }

  .circle__text p {
    font-size: 1rem;
    margin-bottom: 0;
  }
`;

const FeaturesMobile = styled.div`
  max-width: 500px;
  margin: 2rem auto 5rem;

  ${media.lg`
    display: none;
  `};

  .features__grid {
    background-color: ${(props) => props.theme.oniloBlue};
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1px;
    font-size: 13px;
    hyphens: auto;
  }

  .features__grid__item {
    background-color: #fff;
    color: ${(props) => props.theme.oniloBlue};

    &.active {
      background-color: #e2f5fa;
    }
  }

  .circle__content {
    color: ${(props) => props.theme.oniloBlue};
    text-align: center;
    padding: 0.5rem 3.5rem;
    font-size: 0.875rem;
    line-height: 1.25;

    @media (min-width: 375px) {
      font-size: 1rem;
    }

    p {
      margin-bottom: 0;
      line-height: inherit;
      font-size: inherit;
    }
  }
`;

export default ({ items, headline, text }) => {
  const [activeId, setActiveId] = React.useState(0);

  if (!items || !items.length) return null;

  const activeItem = items?.[activeId];

  return (
    <>
      <Container className="mt-16">
        <FrontPageHeadline className="m-small">{headline}</FrontPageHeadline>
        <div className="italic text-center text-xl leading-tight" dangerouslySetInnerHTML={{ __html: text }} />
      </Container>
      <FeaturesMobile>
        <div className="relative h-[310px] flex items-center justify-center overflow-hidden">
          <div className="relative -mr-8 ml-4">
            <img className="relative -bottom-10 circle__bubble" src={SpeechBubbleImage} width="360" />

            <div className="circle__content absolute flex flex-col items-center justify-center inset-0">
              {activeItem.icon && <img className="mt-5" width={80} height={80} loading="lazy" src={activeItem.icon} />}
              <div className="text-lg leading-tight italic font-bold mb-1">{activeItem.title}</div>
              <div dangerouslySetInnerHTML={{ __html: activeItem.text }} />
            </div>

            <div className="transform translate-x-[-60%] xs:-translate-x-1/2 absolute bottom-0">
              <img src={WomanImage} width="191" />
            </div>
          </div>
        </div>
        <div className="features__grid">
          {items.map(({ icon, title }, key) => (
            <div
              key={key}
              className={cc([
                {
                  active: key === activeId,
                },
                'features__grid__item',
                'flex flex-col items-center p-2 pb-4 cursor-pointer',
              ])}
              role="button"
              onClick={() => setActiveId(key)}
            >
              {icon && <img width={83} loading="lazy" src={icon} />}
              <div className="text-center font-bold italic">{title}</div>
            </div>
          ))}
        </div>
      </FeaturesMobile>

      <Container className="flex flex-col items-center">
        <FeatureCircle>
          {items.map(({ title, icon }, key) => {
            const deg = key * (360 / items.length);
            return (
              <div
                key={key}
                className="feature"
                style={{
                  transform: `rotate(${deg}deg)`,
                }}
                role="button"
                onClick={() => setActiveId(key)}
              >
                <div
                  style={{
                    transform: `rotate(${-deg}deg)`,
                  }}
                >
                  <div
                    className={cc([
                      'feature__inner',
                      {
                        active: key === activeId,
                      },
                    ])}
                  >
                    {icon && <img width={121} height={121} loading="lazy" src={icon} />}
                    <div
                      style={{
                        fontSize: 19,
                      }}
                    >
                      {title}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {activeItem && (
            <CenterCircle className="circle">
              <div className="absolute top-[50px]">
                <div className="circle__content absolute flex flex-col items-center justify-center inset-0 pt-2 pb-20">
                  {activeItem.icon && <img width={80} height={80} loading="lazy" src={activeItem.icon} />}
                  <div className="text-xl italic font-bold mb-1 leading-tight">{activeItem.title}</div>
                  <div className="circle__text text-center" dangerouslySetInnerHTML={{ __html: activeItem.text }} />
                </div>
                <img className="circle__bubble" src={SpeechBubbleImage} width="384" />
              </div>
              <div className="absolute bottom-0">
                <img src={WomanImage} width="191" />
              </div>
            </CenterCircle>
          )}
        </FeatureCircle>
      </Container>
    </>
  );
};
