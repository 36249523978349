import React from 'react';
import { connect } from 'react-redux';
import { BaseButton } from '../StyledElements';
import { openModal } from '../Modal/modalActions';

const PrivacyPolicyButton = ({ openModal }) => {
  const openDataPrivacyPopup = (event) => {
    event.stopPropagation();

    openModal({
      buttonValue: 'OK',
      renderCloseButton: false,
      content:
        'Wir möchten Sie darauf hinweisen, dass wir die angegebene E-Mail-Adresse verwenden, um Sie per E-Mail über eigene ähnliche Produkt- und Dienstleistungsangebote zu informieren. Sollten Sie keine weitere Zusendung von Informationen per E-Mail wünschen, können Sie der werblichen Verwendung Ihrer E-Mail-Adresse jederzeit widersprechen. In jeder E-Mail finden Sie einen Abmeldelink.'
    });
  };

  return (
    <BaseButton
      type="button"
      onClick={openDataPrivacyPopup}
      style={{ textDecoration: 'underline' }}
    >
      Hinweis zum Datenschutz
    </BaseButton>
  );
};

export default connect(null, { openModal })(PrivacyPolicyButton);
