import { stubTrue } from 'lodash';
import React from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { gameTypeLabels } from '../../BoardstoryGames/shared/gameUtil';

const StyledSeek = styled.div`
  position: relative;
  flex-grow: 1;

  .track {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    transform: translateY(-50%);
    background-color: #c9c9c9;
    border-radius: 2px;
  }

  .tip-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 26px);
  }

  .buffer,
  .track-fill,
  input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .buffer {
    background-color: #aaa;
  }

  .track-fill {
    background: #f26521;
    position: relative;
  }

  input {
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const TrackTip = styled.div`
  position: absolute;
  top: -11px;
  z-index: 2;
  pointer-events: none;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #f26521;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
`;

const GameCuePointInfo = styled.div`
  position: absolute;
  bottom: 12px;
  white-space: nowrap;
  padding: 3px 5px;
  border-radius: 10px;
  display: none;
  background-color: #fff;
  font-size: 1.4em;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 30%);
  left: 50%;
`;

const CuePoint = styled.div`
  position: absolute;
  top: 0;
  background-color: rgba(74, 74, 74, 0.5);
  width: 1px;
  height: 100%;

  &.highlight {
    background-color: #27b0d4;
    width: 1%;
    max-width: 7px;
    top: -130%;
    height: 230%;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s ease;

    &::before {
      content: '';
    }

    &:hover {
      height: 300%;
      top: -200%;
      width: 1.2%;
      transform: translateX(-0.1%);
      max-width: 9px
    }
  }

  &:hover ${GameCuePointInfo} {
    display: inline-block;
  }
`;

const Seek = ({
  onChange,
  cuePoints,
  gameCuePoints,
  duration,
  percentagePlayed,
  percentageBuffered,
  className,
  ariaLabel,
  storyName,
}) => {

  const inputRef = useRef(0);

  const updateInput = (left) => {
    onChange({target: {value: left - 0.1}})
  }

  const renderCuePoint = (cuePoint, highlight = false) => {
    if (cuePoint === 0) return null;

    let cuePosition = 0

    if(typeof cuePoint == 'number') {
      cuePosition = cuePoint
    } else {
      cuePosition = cuePoint.position
    } 

    const left = (cuePosition / (duration * 1000)) * 100;
    if (left > 100) return null

    return (<CuePoint onClick={() => updateInput(left)} className={highlight ? 'highlight' : ''} key={cuePosition} style={{ left: `${left}%` }} >
              {typeof cuePoint !== 'number' && cuePoint.type && (
                <GameCuePointInfo style={{transform: `translateX(-${left > 90 ? 100 : (left < 10 ? 0 : 50)}%)`}}>{gameTypeLabels[cuePoint.type]}</GameCuePointInfo>
              )}
            </CuePoint>);
  };

  return (
    <StyledSeek className={className}>
      <div className="track">
        <div
          className="buffer"
          style={{
            width: `${percentageBuffered || 0}%`,
          }}
        />
        <div
          className="track-fill"
          style={{
            width: `${percentagePlayed || 0}%`,
          }}
        />
        <div>
        {cuePoints && (<>{cuePoints.map((p) => renderCuePoint(p))}</>)}
        {gameCuePoints && (<>{gameCuePoints.map((p) => renderCuePoint(p, true))}</>)}
        </div>
        <div className="tip-wrapper">
          <TrackTip style={{ left: `${percentagePlayed || 0}%` }} />
        </div>
        <input
          min="0"
          ref={inputRef}
          step={1}
          max="100"
          type="range"
          orient="horizontal"
          onChange={onChange}
          aria-label={ariaLabel}
          value={percentagePlayed || 0}
          data-storyname={storyName}
          className="player-control-seek"
        />
      </div>
    </StyledSeek>
  );
};

export default Seek;
