import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HugeHeadline } from './errorPageStyles';

export default ({ headline }) => (
  <React.Fragment>
    <Helmet>
      <title>{headline}</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <HugeHeadline>{headline}</HugeHeadline>
  </React.Fragment>
);
