import {
  GET_REQUEST,
  GET_SUCCESS,
  GET_FAILURE,
  EVENT_TRACKED
} from '../actions/boardstories';

const initialState = {
  data: [],
  isLoading: false,
  error: false,
  pagesCount: 0,
  currentPage: 0,
  trackingToken: ''
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case GET_SUCCESS:
      return {
        ...state,
        data: action.data,
        currentPage: action.currentPage,
        pagesCount: action.pagesCount,
        isLoading: false,
        error: false
      };

    case GET_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case EVENT_TRACKED:
      if (action.saveToken) {
        return {
          ...state,
          trackingToken: action.trackingToken
        }
      } else {
        return state
      }

    default:
      return state;
  }
};
