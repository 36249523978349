import styled from 'styled-components';
import { BaseButton } from '../StyledElements';
import media from '../../utils/media';

const MenuBaseElement = styled.div`
  top: 80px;
  height: calc(100% - 80px);
  position: fixed;

  ${media.md`
    top: 100px;
    height: calc(100% - 100px);
  `};

  &.has-small-offset {
    top: 60px;
    height: calc(100% - 60px);
  }
`;

export const MenuOverlay = styled(MenuBaseElement)`
  z-index: 1000;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s, transform 0s 0.3s;
  transform: translate3d(100%, 0px, 0px);

  &.open {
    opacity: 1;
    transform: none;
  }
`;

export const MenuWrap = styled(MenuBaseElement)`
  left: 0;
  z-index: 1100;
  background-color: #fff;
  transition: all 0.4s;
  transform: translate3d(-100%, 0px, 0px);
  max-width: 488px;
  width: 100%;

  &.open {
    box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.15);
    transform: none;
  }
`;

export const MenuInner = styled.div`
  height: 100%;
  overflow: auto;
`;

export const BurgerButton = styled(BaseButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1em;
`;
