import { useCallback, useState, useEffect } from 'react';
import UserCard from './UserCard';
import AddUser from './AddUser';
import { apiFetch, apiEndpoints } from '../../modules/api';
import { openModal } from '../Modal/modalActions';
import { SmallRoundInput, FieldGroup as OriginalFieldGroup } from '../StyledElements';
import { filter, get } from 'lodash';
import styled from 'styled-components';
import OriginalSelectField from '../SelectField';
import { connect } from 'react-redux';
import { organizationUserRoles } from 'config';
import PropTypes from 'prop-types';
import { getCurrentOrder } from '../../actions/user';
import { getRolesAsBoolean } from '../../utils/userRoleHelpers';

const formOptions = [
  // OnChange function is not triggeres correctly with empty string as value
  { name: 'Sortieren…', value: 'Sortieren', altText: 'Sortierung aufheben' },
  { name: 'Sortiert nach E-Mail', value: 'user.email' },
  { name: 'Sortiert nach Nachname', value: 'user.lastName' },
  { name: 'Sortiert nach Anmeldedatum', value: 'user.createdAt' },
];

const sortByStringField = (items, sortBy) => {
  if (sortBy === 'Sortieren') {
    return items.sort((itemA, itemB) => get(itemA, 'user.createdAt', '').localeCompare(get(itemB, 'user.createdAt', '')));
  }

  return items.sort((itemA, itemB) => get(itemA, sortBy, '').localeCompare(get(itemB, sortBy, '')));
};

const UserList = ({ currentOrder, userId, openModal, relevantUserRole, getCurrentOrder }) => {
  const [members, setMembers] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [sortString, setSortString] = useState('');
  const [filterString, setFilterString] = useState('');
  const { organizationId, maxUsers, maxNumberOfRentalAccesses, rentalAccessesUsed } = currentOrder;

  const config = {
    [organizationUserRoles.USER]: {
      maxUsers: maxUsers,
      listOwners: true,
      cardHasTimestamp: false,
      addUserText: 'Nutzer hinzufügen',
    },
    [organizationUserRoles.RENTAL_USER]: {
      maxUsers: maxNumberOfRentalAccesses,
      listOwners: false,
      cardHasTimestamp: true,
      addUserText: 'Verleihzugang hinzufügen',
    },
  };

  /*****************************************
   *  data preparation
   * ****************************************/
  const { maxUsers: maxRelevantUsers, listOwners, cardHasTimestamp, addUserText } = config[relevantUserRole];

  const relevantMembers = members.filter((member) => {
    const isRelevantUser = relevantUserRole === member.userRole;
    const isRelevantOwner = listOwners && organizationUserRoles.OWNER === member.userRole;
    return isRelevantUser || isRelevantOwner;
  });

  const relevantInvitations = invitations.filter((invitation) => relevantUserRole === invitation.userRole);
  const showAddUser = maxRelevantUsers > relevantInvitations.length + relevantMembers.length;

  const filteredMembers = (() => {
    const filteredMembers = filter(relevantMembers, ({ user }) => {
      if (filterString === '') return true;

      return [user.firstName, user.lastName, user.email]
        .join(' ')
        .toLocaleLowerCase()
        .includes(filterString.toLocaleLowerCase());
    });

    return sortByStringField(filteredMembers, sortString);
  })();

  const filteredInvitations = (() => {
    return filter(relevantInvitations, ({ email }) => {
      if (filterString === '') return true;

      return email.toLocaleLowerCase().includes(filterString.toLocaleLowerCase());
    });
  })();

  /*****************************************
   *  handlers
   * ****************************************/
  const onFilterInputChange = useCallback((event) => setFilterString(event.target.value), []);

  const onSortSelectChange = useCallback((event) => setSortString(event.target.value), []);

  const onInviteSuccess = (invitation) => {
    getCurrentOrder();
    openModal({
      buttonValue: 'OK',
      renderCloseButton: false,
      content: 'Der User wurde erfolgreich eingeladen und erhält umgehend eine E-Mail mit dem Einladungslink.',
    });
    setInvitations((prev) => [...prev, invitation]);
  };

  const onError = (error) => {
    console.error(error.json || error);
    return Promise.reject(error);
  };
  /*****************************************
   *  API
   * ****************************************/
  const fetchPendingInvitations = () => {
    apiFetch
      .url(apiEndpoints.invitations)
      .query({
        state: 'CREATED',
        organizationId: organizationId,
      })
      .get()
      .json((response) => {
        const { invitations } = response._embedded;
        if (invitations && invitations.length > 0) {
          setInvitations(invitations);
        }
      })
      .catch(onError);
  };

  const fetchOrganizationMembers = () => {
    if (!organizationId) return;

    apiFetch
      .url(`${apiEndpoints.organizations.get}/${organizationId}`)
      .get()
      .json(({ members }) => {
        if (members && members.length > 0) {
          const { isOwner } = getRolesAsBoolean(members.find((i) => i.userId === userId));

          setMembers(members);
          setIsOwner(isOwner);

          // If current user is the admin of this organization, fetch invitations
          if (isOwner) {
            fetchPendingInvitations();
          }
        }
      })
      .catch(onError);
  };

  const updateMembers = (newMembers) => {
    return apiFetch
      .url(`${apiEndpoints.organizations.patch}/${organizationId}`)
      .json({ members: newMembers })
      .patch()
      .json(() => {
        setMembers(newMembers);
      })
      .catch(onError);
  };

  const removeMember = (memberId) => {
    // Can't remove yourself
    if (!memberId || memberId === userId || !isOwner) {
      return;
    }

    // Update members
    const newMembers = members.filter((i) => i.userId !== memberId);
    updateMembers(newMembers);
  };

  const deleteInvitation = (invitationId) => {
    if (!invitationId || !isOwner) {
      return;
    }

    apiFetch
      .url(`${apiEndpoints.invitations}/${invitationId}`)
      .delete()
      .res(() => {
        setInvitations(invitations.filter((i) => i.id !== invitationId));
        getCurrentOrder();
      })
      .catch(onError);
  };

  useEffect(() => fetchOrganizationMembers(), []);

  useEffect(() => {
    if (sortString === '') {
      setSortString('Sortieren');
    }
  }, [sortString]);

  return (
    <>
      {relevantMembers.length > 0 && (
        <FieldGroup>
          <SelectField name="sortSelect" value={sortString} options={formOptions} onChange={onSortSelectChange} />

          <SmallRoundInput name="filterInput" value={filterString} placeholder="Suche…" onChange={onFilterInputChange} />
        </FieldGroup>
      )}
      {showAddUser && relevantUserRole === organizationUserRoles.RENTAL_USER && (
        <AddUser
          organizationId={organizationId}
          existingMembers={relevantMembers}
          onSuccess={onInviteSuccess}
          relevantUserRole={relevantUserRole}
          addUserText={addUserText}
          invertColors
        />
      )}

      {filteredMembers.map((member) => (
        <UserCard
          key={member.userId}
          user={member.user}
          isOwner={member.isOwner}
          isCurrentUser={member.userId === userId}
          isClickable={true}
          onRemoveRequest={removeMember}
          currentOrder={currentOrder}
          relevantUserRole={relevantUserRole}
          timestamp={cardHasTimestamp && member.rentalUntil}
        />
      ))}

      {filteredInvitations.map((invitation) => (
        <UserCard
          key={invitation.id}
          user={{
            id: invitation.id,
            name: invitation.email,
            email: 'Einladung verschickt',
          }}
          isOwner={false}
          isClickable={true}
          onRemoveRequest={deleteInvitation}
          isRenewable={currentOrder.isRenewable}
          relevantUserRole={relevantUserRole}
        />
      ))}

      {showAddUser && relevantUserRole === organizationUserRoles.USER && (
        <AddUser
          organizationId={organizationId}
          existingMembers={relevantMembers}
          onSuccess={onInviteSuccess}
          relevantUserRole={relevantUserRole}
          addUserText={addUserText}
        />
      )}
    </>
  );
};

UserList.propTypes = {
  userId: PropTypes.string,
  currentOrder: PropTypes.object.isRequired,
  relevantUserRole: PropTypes.string.isRequired,
  openModal: PropTypes.func,
  getCurrentOrder: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userId: state.user.account.id,
  currentOrder: state.user.currentOrder,
});

export default connect(mapStateToProps, { openModal, getCurrentOrder })(UserList);

/*****************************************
 *  components
 * ****************************************/

const SelectField = styled(OriginalSelectField)`
  :not(body) {
    border: none;

    select {
      padding: 10px;
    }
  }
`;

const FieldGroup = styled(OriginalFieldGroup)`
  margin-bottom: 1.5rem;

  > div {
    width: 100%;
    margin-bottom: 0;
  }
`;
