import { Component } from 'react';
import PropTypes from 'prop-types';
import { apiFetch, apiEndpoints } from '../../modules/api';
import { toArray } from '../../utils/helpers';
import flow from 'lodash/fp/flow';
import pick from 'lodash/fp/pick';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';

const clearedFetchables = {
  boardstory: undefined,
  attachments: [],
  similarBoardstories: [],
  similarBsTeaser: [],
  teaserImage: undefined,
  bookReference: undefined,
};

class BoardstoryLoader extends Component {
  static defaultProps = {
    fetchAttachments: true,
    fetchSimilarBoardstories: true,
  };

  state = {
    isLoading: false,
    error: undefined,
    ...clearedFetchables,
  };

  componentDidMount() {
    if (!this.props.boardstoryIdOrSlug) return;
    this.getBoardstory(this.props.boardstoryIdOrSlug);
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  updateState(nextState) {
    if (this.willUnmount) return;
    this.setState(nextState);
  }

  componentDidUpdate(prevProps) {
    if (this.props.boardstoryIdOrSlug !== prevProps.boardstoryIdOrSlug) this.getBoardstory(this.props.boardstoryIdOrSlug);
  }

  getBoardstory(boardstoryIdOrSlug) {
    this.updateState({ isLoading: true, ...clearedFetchables });

    apiFetch
      .url(`${apiEndpoints.boardstories.get}/${boardstoryIdOrSlug}`)
      .get()
      .json((boardstory = {}) => {
        if (this.willUnmount) return;

        if (this.props.fetchSimilarBoardstories) { 
          this.getSimilarBoardstories(boardstory.id);
        } else if (boardstory.teaserImagesCount > 0) {
          // If we don't need similar boardstories, we should fetch boardstory images here
          // Otherwise the images will be fetched in the getSimilarBoardstories call
          this.getImages(boardstory.id, []);
        }

        if (boardstory.attachmentsCount > 0 && this.props.fetchAttachments) {
          this.getAttachments(boardstory.id);
        }
        this.updateState({ boardstory, isLoading: false, error: false });
      })
      .catch((error) => {
        this.updateState({
          error: error.status || error.json,
          isLoading: false,
        });
      });
  }

  getAttachments(boardstoryId) {
    apiFetch
      .url(`${apiEndpoints.attachments.get}${boardstoryId}`)
      .get()
      .json((data) => {
        const { attachments } = data._embedded;
        this.updateState({ attachments });
      });
  }

  getImages(id, additionalBsIds) {
    apiFetch
      .url(apiEndpoints.images.get)
      .query({ boardstoryId: [id, ...additionalBsIds] })
      .get()
      .json((json) => json._embedded.images)
      .then((images) => {
        const mainBsImages = images.filter((i) => i.boardstoryId === id);
        const teaserImage = mainBsImages.find((i) => i.type === 'TEASER_IMAGE');
        const bookReference = mainBsImages.find((i) => i.type === 'BOOK_REFERENCE');
        const similarBsTeaser = images.filter((i) => i.boardstoryId !== id && i.type === 'TEASER_IMAGE');

        if (similarBsTeaser) this.updateState({ similarBsTeaser });
        if (teaserImage && teaserImage.path) this.updateState({ teaserImage });
        if (bookReference) this.updateState({ bookReference });
      });
  }

  getSimilarBoardstories(id) {
    apiFetch
      .url(`${apiEndpoints.boardstories.similar}/${id}`)
      .get()
      .json((data) => {
        const similarBoardstories = data.map(pick(['id', 'title', 'slug']));
        this.updateState({ similarBoardstories });

        // We call the images route here, because the similar bs's might need images too.
        // This reduces the number of requests needed.
        const boardstoriesNeedingAdditionalImages = flow(
          filter((bs) => bs.teaserImagesCount > 0),
          map(({ id }) => id),
        )(data);
        if (boardstoriesNeedingAdditionalImages.length > 0) this.getImages(id, boardstoriesNeedingAdditionalImages);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    if (!this.props.children) {
      return null;
    }
    const renderFn = toArray(this.props.children)[0];

    return renderFn(this.state);
  }
}

BoardstoryLoader.propTypes = {
  boardstoryIdOrSlug: PropTypes.string,
  fetchAttachments: PropTypes.bool,
  fetchSimilarBoardstories: PropTypes.bool,
  children: PropTypes.func,
};

export default BoardstoryLoader;
