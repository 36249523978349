import { contentPagesWithOwnSlug } from '../config';

export const getPostItemBaseSlug = (
  item,
  allowedBaseSlugs = contentPagesWithOwnSlug
) => {
  if (!item || !item.category_slugs) {
    return undefined;
  }

  const commonBaseSlugs = allowedBaseSlugs.filter(
    (slug) => item.category_slugs.indexOf(slug) > -1
  );
  if (commonBaseSlugs.length > 0) {
    return commonBaseSlugs[0];
  }
  return 'aktuelles';
};

export const getPostItemType = (item) => {
  switch (getPostItemBaseSlug(item)) {
    case 'themenwelten':
      return 'Themenwelt';
    case 'didaktik':
      return 'Didaktik';
    case 'blog':
      return 'Blog';
    case 'aktuelles':
      return 'News' 
    default:
      return undefined;
  }
};

// Update YouTube urls with rel=0 to hide related videos

export const updateIframeCode = (iframe) => {
  const embed = iframe.match(/<iframe.*?src="(.*?)"/);
  if (embed && embed.length > 0 && embed[1].indexOf('youtu') > -1) {
    return `<iframe width="640" height="360" src="${
      embed[1]
    }?feature=oembed&rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
  }
  return iframe;
};
