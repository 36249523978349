const getNonEmptyKeys = (values = {}) =>
  Object.keys(values).filter((key) => values[key]);

export const hasNonEmptyValues = (values) => getNonEmptyKeys(values).length > 0;

export const joinValues = (...values) => values.filter((v) => v).join(' ');

export const toArray = (data) => {
  return Array.isArray && Array.isArray(data) ? data : [].concat(data);
};

export const hasDateNotExpired = (dateString) => {
  return new Date(dateString) > new Date();
};

export const afterStartingDate = (dateString) => {
  return new Date(dateString) <= new Date();
};

export const accessCodeHasValidDate = (accessCode) => {
  return (
    hasDateNotExpired(accessCode?.expirationDate) &&
    afterStartingDate(accessCode?.startingDate)
  );
};

export const normalize = (arr = [], keyProp = 'id') => {
  const obj = {};
  arr.forEach((i) => {
    const key = i[keyProp];
    if (key) {
      obj[key] = i;
    }
  });
  return obj;
};

export const formatAmount = (amount, numOfDigits = 2) => {
  return amount.toFixed(numOfDigits).replace('.', ',');
};

export const removeEmptyValues = (obj) => {
  return Object.keys(obj)
    .filter((k) => obj[k] && obj[k].length > 0)
    .reduce((r, i) => {
      r[i] = obj[i];
      return r;
    }, {});
};

export const hasNameUpdated = (nextState, prevState) => {
  const nextValues = {
    firstName: nextState.firstName,
    lastName: nextState.lastName,
    salutation: nextState.salutation
  };

  return Object.keys(nextValues).some(
    (valueName) => prevState[valueName] !== nextValues[valueName]
  );
};

const teamLicense = '5a5ca3c956ac2c0014782a29';
export const updatedIdMap = {
  // Onilo Entdeckerlizenz -> Entdeckerlizenz
  '5a5ca25056ac2c0014782a22': '5a5ca3a056ac2c0014782a27',
  // Onilo Profilizenz -> Einzellizenz
  '5a5ca27956ac2c0014782a23': '5a5ca3b456ac2c0014782a28',
  // Onilo Projektlizenz -> Teamlizenz
  '5a5ca29c56ac2c0014782a24': teamLicense,
  // Onilo Schullizenz -> Schullizenz
  '5a5ca2cb56ac2c0014782a25': '5a5ca3e156ac2c0014782a2a',
  // Onilo Bibliothekslizenz -> Bibliothekslizenz
  '5a5ca2e556ac2c0014782a26': '5a5ca3f456ac2c0014782a2b'
};

export const updateExpiredLicense = (oldId, licenses) => {
  const updatedLicense = licenses.find((l) => l.id === updatedIdMap[oldId]);
  return updatedLicense || licenses.find((l) => l.id === oldId);
};

// The testlicense should not be shown on the license overview page
const hiddenLicense = '5d662bf0907d260019485b81';
export const notHiddenLicense = (id) => id !== hiddenLicense;

export const switchOutTestLicense = (id, licenses) => {
  const licenseIdToUse = notHiddenLicense(id) ? id : teamLicense;
  return licenses.find((l) => l.id === licenseIdToUse);
};

// Checks whether the passed string is a valid url
export const isValidUrl = (string) => {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const getTimeRemaining = (date) => {
  const msDiff = new Date(date).getTime() - new Date().getTime();
  const hours = Math.round(msDiff / (1000 * 60 * 60));
  const days = Math.round(hours / 24);

  let text = hours === 1 ? `${hours} Stunde` : `${hours} Stunden`;

  if (hours > 24) {
    text = days === 1 ? `${days} Tag` : `${days} Tage`;
  }

  return {
    hours,
    days,
    text,
  };
};