import React from 'react';
import { Link } from 'react-router-dom';
import ErrorPageHead from './ErrorPageHead';
import {
  PageContent,
  PageBackground,
  CustomPageContainer
} from './errorPageStyles';

export default () => {
  return (
    <PageBackground>
      <CustomPageContainer className="fluid">
        <ErrorPageHead headline="404" />
        <PageContent>
          <p>Huch, diese Seite ist uns leider nicht bekannt.</p>
          <p>
            <Link to="/">Zurück zu onilo.de</Link>
          </p>
        </PageContent>
      </CustomPageContainer>
    </PageBackground>
  );
};
