import cc from 'classcat'

const isBoardstoryUrl = (location) => {
  if (location.pathname && location.pathname.indexOf("/boardstory") === 0) {
    return true;
  }

  return false;
}

export const getMenuItemLinkProps = (item, key, location) => {
  // Handle WordPress URLs 
  let baseSlug = item.object + '/';

  if (item.object === 'post') {
    baseSlug = 'aktuelles/';
  } else if (item.object === 'page') {
    baseSlug = '';
  }

  // Pre-defined URLs (see defaultMenuItems)
  const itemUrl =
    item.type === 'custom' ? item.url : `/${baseSlug}${item.object_slug}`;

  let props = {
    key: item.ID || key,
    children: item.title,
    to: itemUrl
  };

  // Handle boardstory URLs active state: these should also be active,
  // when the user is viewing an individual boardstory
  if (location && itemUrl === '/boardstories') {
    props.className = cc({ 'active': isBoardstoryUrl(location) })
  }

  return props;
};

export const menuItemHasActiveChild = (children, location = {}) => {
  if (!children || !location.pathname) {
    return false;
  }

  return (
    children.find((i) => {
      return location.pathname.indexOf(getMenuItemLinkProps(i).to) > -1;
    }) !== undefined
  );
};