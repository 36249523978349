import React from 'react';
import styled from 'styled-components';
import { BoxShadow } from './StyledElements';

const TeaserContainer = styled(BoxShadow)`
  padding: 2em 1em;
  text-align: center;
`;

const Title = styled.h1`
  font-weight: 400;
  font-style: italic;
  font-size: 2.25em;
  text-align: center;
  margin: 0 0 2rem;
`;

export default ({ title, children }) => {
  return (
    <TeaserContainer>
      <Title>{title}</Title>
      <div>{children}</div>
    </TeaserContainer>
  );
};
