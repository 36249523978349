import React from 'react';
import ErrorPageHead from './ErrorPageHead';
import {
  PageContent,
  PageBackground,
  CustomPageContainer
} from './errorPageStyles';

export default () => {
  return (
    <PageBackground>
      <CustomPageContainer className="fluid">
        <ErrorPageHead headline="500" />
        <PageContent>
          <p>
            Oh nein, unser Server spielt verrückt!<br />Wir sind bald wieder für
            Sie da.
          </p>
        </PageContent>
      </CustomPageContainer>
    </PageBackground>
  );
};
