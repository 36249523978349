import React from 'react';
import { Image, getWpImageSrcSet } from '../../../components/Image';
import Tooltip from '../../../components/Tooltip';

export default ({ items }) => {
  const { image, tooltip } = items || {};

  if (!image) return null;

  return (
    <div className="relative w-full h-[300px]">
      <Image className="inset-0 h-full w-full absolute object-cover" lazy srcSet={getWpImageSrcSet(image)} />
      {tooltip?.headline && (
        <Tooltip className="absolute bottom-[-28px] left-1/2 transform -translate-x-1/2 md:bottom-[-38px]">
          <span dangerouslySetInnerHTML={{ __html: tooltip.headline }} />
        </Tooltip>
      )}
      {tooltip?.url && <a className="absolute inset-0" href={tooltip.url}></a>}
    </div>
  );
};
