import React from 'react';
import PropTypes from 'prop-types';
import CheckboxField from '../CheckboxField';
import { TextBlock, FormSectionHeadline } from '../StyledElements';
import PrivacyPolicyButton from './PrivacyPolicyButton';

const NewsletterCheckbox = (props) => {
  return (
    <div>
      <FormSectionHeadline>Newsletter</FormSectionHeadline>
      <CheckboxField
        name="newsletterConsent"
        onChange={props.onChange}
        checked={props.checked}
      >
        <TextBlock lineHeight={1.5} fontSize={1.25}>
          Ja, ich möchte regelmäßig über Onilo informiert werden. (<PrivacyPolicyButton />)
        </TextBlock>
      </CheckboxField>
    </div>
  );
};

NewsletterCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default NewsletterCheckbox;
